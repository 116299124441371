import React from "react";
import { Empty, Spin} from "antd";
import { useCasinoAllGameQuery } from "../../../Services/CasinoService";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaSpinner } from "react-icons/fa";

const CasinoAllGame = () => {
  const uType = localStorage.getItem("userType");
  const nav = useNavigate()

  const { state } = useLocation();
  const { id } = useParams();

  const { data, isFetching, isLoading } = useCasinoAllGameQuery(
    {
      casinoId: id,
      date: state?.rouletteDate,
    },
    { refetchOnMountOrArgChange: true }
  );

const handleShowBet = (val)=>{
    nav(`/casino/show-bets/${val}`, {state:{state, id}})
}

const antIcon = (
  <FaSpinner className="spin_roatet"/>
);

  return (
    <div>
      <div>
        <p className="match_bet casino_rept">{state?.isAuraDetails} ({state?.rouletteDate})</p>
      </div>
      <br />
      {
        (isFetching || isLoading) ?  <Spin  indicator={antIcon} className="loading_active"  size="large">
        <div className="content" />
      </Spin> :  <div className="accountlist-table-container">
        <table className="table_section">
          <tr>
            <th>S no.</th>
            <th>Game ID</th>
            <th>Match Name</th>
            <th>Net Pnl</th>
            <th>Action</th>
          </tr>
          {
            data?.data?.map((res, id)=>{
                return(
                    <tr >
            <td>{id+1}</td>
            <td>{res?.roundId}</td>
            <td>{res?.matchName}</td>
            {/* <td>{data?.data?.totalAmount}</td> */}
            <td
              className={
                res?.netPnl < 0 ? "text_danger" : "text_success"
              }>
              {(res?.netPnl)?.toFixed(2)}
            </td>
            <td>
                <button onClick={()=>handleShowBet(res?.roundId)} className="show_bet">Show Bet</button>
            </td>
          </tr>
                )
            })
          }
          {(data?.data === undefined || data?.data?.length === 0) && (
            <tr>
              {" "}
              <td colSpan={9}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          )}
        </table>
      </div>
      }
     
    </div>
  );
};

export default CasinoAllGame;
