import React, { useEffect, useState } from 'react';
import MatchBetTable from './MatchBetTable';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

const MatchBet = ({title, type}) => {
	const dispatch = useDispatch();
	return (
		<div >
			<div>
				<p className='match_bet'>{title}</p>
			</div>

			<MatchBetTable type={type}/>
		</div>
	);
};

export default MatchBet;
