import React, { useEffect, useState } from "react";
import style from "./BetHistoryCasino.module.scss";
import { Select } from "antd";

// import axiosInstance from '../../../Services/AxiosInstance';
import { axiosInstance } from "../../../Services/AxiosInstance";
import CasinoTable from "./CasinoTable";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const BetHistoryCasino = () => {
  // const [casinoSelector, setCasinoSelector] = useState('');
  const [casinoData, setCasinoData] = useState([]);
  const [casinoSelect, setCasinoSelect] = useState([]);
  const [record, setRecord] = useState(323334);
  const [matchId, setMatchId] = useState("");
  const [deleted, setDeleted] = useState(false);
  const [index, setIndex] = useState(0);
  const [casinoListData, setCasinoListData] = useState([]);
  const [loading, setLoading] = useState(true)

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const handleOption = (e) => {
    const value = e;
    casinoMatch(value);
  };
  // useEffect(() => {
  // setMatchId("");
  const casinoMatch = (value) => {
    const endPoint = "casino/casino-tables-by-types";
    setMatchId("");
    // const token = localStorage.getItem('token');
    axiosInstance
      .post(
        endPoint,
        { appUrl: window.location.hostname.slice(6), id: value }
        // { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setCasinoData(res?.data?.data);
      });
  };
  // }, [record]);

  useEffect(() => {
    BetHistoryApi();
  }, []);
  const BetHistoryApi = async () => {
    const endPoint = "casino/all-casino-types";
    const token = localStorage.getItem("token");
    const response = await axiosInstance.post(
      endPoint,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setCasinoSelect(response?.data?.data);
  };

  useEffect(() => {
    casinoBetHistory();
  }, [
    accountStatementBody.index,
    accountStatementBody.totalPages,
    accountStatementBody.noOfRecords,
  ]);

  const casinoBetHistory = () => {
    loadingRef("bethistoryCasino", true);
    const endPoint = "/report/bet-history";
    
    axiosInstance
      .post(endPoint, {
        ...accountStatementBody,
        ...{
          sportId: +record,
          matchId: matchId,
          userId: "",
          sportType: 2,
          isDeleted: deleted,
        },
      })
      .then((res) => {
        setCasinoListData(res?.data?.data?.dataList);
        if(res?.data?.status){
          setLoading(false)
        }
        // loadingRef("bethistoryCasino", false);
        setAccountStatementBody((prev) => {
          return {
            ...prev,
            totalPages: res?.data?.data?.totalPages,
          };
        });
      });
    loadingRef("bethistoryCasino", false);
  };
  // console.log( matchId ? matchId : "Select Match list", "FGDTGDYD")

  return (
    <>
      <div className={style["profit_loss-cont"]}>
        <Select
          defaultValue="Matched"
          className={style["select-match"]}
          onChange={(value) => setDeleted(value)}
          options={[
            {
              value: false,
              label: "Matched",
            },
            {
              value: true,
              label: "Deleted",
            },
          ]}
        />

        <Select
          className={style["select-match"]}
          // value={ "Select Casino"}
          onChange={handleOption}
          placeholder="Select Casino"
        >
          {/* <Select.Option>Select Casino</Select.Option> */}
          {casinoSelect?.map((el) => {
            return <Select.Option value={el.id}>{el.name}</Select.Option>;
          })}
        </Select>

        <Select
          className={style["select-match"]}
          onChange={(e) => setMatchId(e)}
          value={matchId ? matchId : "Select Match list"}
          placeholder="Casino List"
        >
          {casinoData?.map((casino) => {
            return (
              <Select.Option key={casino.gameId} value={casino.gameId}>
                {casino.gameName}
              </Select.Option>
            );
          })}
        </Select>
        {/* </div> */}
      </div>
      <div className={style["profit_loss-date"]}>
        <button
          className={style["bet_history-load"]}
          type="submit"
          onClick={casinoBetHistory}
          style={{
            padding: "8px 10px",
            fontSize: "14px",
            background: "#2C3E50",
            color: "white",
            border: "none",
            borderRadius: "3px",
          }}
        >
          Load
        </button>
        {/* <button style={{ background: 'rgb(239, 242, 247)', color: 'black', border: '1px solid #d9d9d9', fontSize: '14px', height: '35px' }} onClick={resetHandler}>Reset</button> */}
      </div>
      <CasinoTable
        casinoListData={casinoListData}
        loading={loading}
        accountStatementBody={accountStatementBody}
        setAccountStatementBody={setAccountStatementBody}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
    </>
  );
};

export default BetHistoryCasino;
