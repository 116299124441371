import { Modal, Table, Tooltip, message } from "antd";
import React from "react";
import { useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { toast } from "react-toastify";
import "./UserWise.scss";
const TableComponent = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [matchId, setMatchId] = useState("");
  const userType = localStorage.getItem("userType");
  const dataSource = [];
  data?.map((res, index) =>
    dataSource?.push({
      key: res?.userid + index + res?.pricevalue,
      UserName: res?.userid,
      Nation: res?.selectionname,
      Rate: `${res.odds} (${res?.pricevalue})`,
      Amount: res?.stack,
      Date: res?.matchedtime,
      IP: res?.userIp,
      BDetails: (
        <Tooltip title={res?.deviceInfo} placement="top">
          <span style={{ color: "#128412" }}>Detail</span>
        </Tooltip>
      ),
      isback: res?.isback,
      action: (
        <RiDeleteBin5Line
          fontSize={15}
          cursor={"pointer"}
          onClick={() => {
            showModal(res.id);
          }}
        />
      ),
    })
  );
  const columns = [
    {
      title: "UserName",
      dataIndex: "UserName",
      key: "UserName",
    },
    {
      title: "Nation",
      dataIndex: "Nation",
      key: "Nation",
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
    {
      title: "Date Time",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
    },
    {
      title: "B Details",
      dataIndex: "BDetails",
      key: "B Details",
    },
    userType == 4
      ? {
          title: "Action",
          dataIndex: "action",
          key: "action",
        }
      : {},
  ];
  const showModal = (id) => {
    setMatchId(id);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteBets(matchId);
    // setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteBets = async () => {
    await axiosInstance
      .post(
        "admin/delete-bet-by-id",
        { id: matchId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
          // notifyToast().succes(res.data.message);
          handleCancel();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((erro) => {});
  };
  return (
    <div className="">
      <Modal
        title="Delete bet"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Continue"
      >
        <p>Are you sure you want to continue.....</p>
      </Modal>
      <div
        className="bet-list-view-more-table"
        style={{ width: "100%", overflowX: "scroll", height: "300px" }}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          rowClassName={(record) => {
            return record.isback ? "blue" : "pink";
          }}
          pagination={false}
          // scroll={{
          // 	y: 300,
          // 	x: 'auto'
          // }}
        />
      </div>
    </div>
  );
};
export default TableComponent;
