import React, { useState } from "react";
import style from "./LeftButtons.module.scss";
import { useLocation } from "react-router";

const LeftButtons = ({ betData, setActiveKey }) => {
  const [buttonClicked, setButtonClicked] = useState(null);

  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const value = pathParts[2];

  const buttonHandler = (id) => {
    setButtonClicked(id);
    setActiveKey((prev) => {
      if (prev[0] === id) {
        return [];
      }
      return [id];
    });
  };

  return (
    <>
      {pathParts[2] == 4 ? (
        ""
      ) : (
        <div className={style["nav_cont"]}>
          {betData?.Odds?.map((el, index) => {
            return (
              <div className={style["nav-button"]} key={index}>
                <button
                  className={
                    style[buttonClicked === index ? "button_active" : "buttons"]
                  }
                  onClick={() => {
                    buttonHandler(el.marketId);
                  }}
                >
                  {el.Name}
                </button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default LeftButtons;
