import React, { useEffect, useState } from 'react';
import style from './UserBookPortal.module.scss';
import { Table } from 'antd';
import axios from 'axios';
import { axiosInstance } from '../../../Services/AxiosInstance';

const BookMarkPortal = ({ bookmakerIdRef }) => {
	const [userBookData, setUserBookData] = useState([])
	const columns = [{ title: '', dataIndex: '' }];

	useEffect(() => {
		bookMarkApi();
	}, []);

	const bookMarkApi = async e => {
		const endPoint = 'bets/user-book';
		const response = await axiosInstance.post(endPoint, { marketId: bookmakerIdRef, userId: '' });
		setUserBookData(response)

	};

	return (
		<div className={style['portal_cont']}>
			<div className={style['heading']}>
				<p>User Book</p>
			</div>
			<Table columns={columns} dataIndex={userBookData} />
		</div>
	);
};

export default BookMarkPortal;
