import { Card, Col, Empty,  Modal,  Row } from "antd";
import "./Settings.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import ChangePassword from "../../LoginPage/ChangePassword";
import AccountStatement from "../../components/Report/AccountStatement/AccountStatement";

const data = [];

const Settings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Card className="setting_main">
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="setting_data1">
              <Link to="/account-statement">Statements</Link>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4} xl={4}>
            <div className="setting_data" onClick={()=>setIsModalOpen(true)}>
              <p >Change Password</p>
            </div>
          </Col>
        </Row>
          <AccountStatement/>
      </div>

      <Modal
        className="change_pass"
        open={isModalOpen}
        footer={false} 
        onCancel={handleCancel}         
        >
        <div className="ch_pass">
         <ChangePassword setIsModalOpen={setIsModalOpen}/>
        </div>
      </Modal>
    </Card>
  );
};

export default Settings;
