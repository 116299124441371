import { Table } from 'antd';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { fetchDeleted } from '../../../Redux/feature/FetchDeletedData/action';
import { selectfetchDeleted } from '../../../Redux/feature/FetchDeletedData/selector';
import { columns } from './columns';



const DeletedData = () => {
    const {state} = useLocation();
  const dispatch = useDispatch();

  const selector = useSelector(selectfetchDeleted);
  
  useEffect(()=>{
      dispatch(fetchDeleted({
          userId:state?.searchId
        }))
    }, [state?.searchId])


	return (
		<div className="cash-tran-cont">
			<div className='cash-tran_heading'>
				<p>Deleted Lenden</p>
			</div>
                
           <div className="acc-cont">
            <div className="account_state_bottom"></div>
            <div className="accountlist-table-container">
              <Table
                columns={columns}
                pagination={false}
                dataSource={selector?.data}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
		</div>
	);
};

export default DeletedData;
