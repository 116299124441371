import React, { useState } from 'react';
import style from './MathOddsRightRow.module.scss';
// import Modal from '../../Modal/Modal'
import MathOddsRightRowModal from './MathOddsRightRowModal';

const MathOddsRightRow = () => {
	const navArray = ['Bet Lock', 'Fancy Lock', 'User Book', 'Bookmarkers book'];

	const [isMatchShown, setIsMatchShown] = useState(true);
	const [isUnmatchedShown, setIsUnmatchedShown] = useState(false);

	const [isCompVisible, setIsCompVisible] = useState(false);

	const matchButtonHandler = c => {
		setIsMatchShown(true);
		setIsUnmatchedShown(false);
	};

	const unmatchedButtonHandler = () => {
		setIsUnmatchedShown(true);
		setIsMatchShown(false);
	};

	const viewHandler = () => {
		setIsCompVisible(true);
	};

	const closeHandler = () => {
		setIsCompVisible(false);
	};

	return (
		<div className={style['right-cont']}>
			<div className={style['navigations']}>
				{navArray.map(items => (
					<div className={style['nav']} key={items}>
						<p>{items}</p>
					</div>
				))}
			</div>
			<div className={style['live']}>
				<p>Live Match</p>
			</div>
			<div className={style['score_card']}>
				<p>Score Card</p>
			</div>
			<div className={style['matched-cont']}>
				<div className={style['matched-div']}>
					<div className={style['combo-div']}>
						<p>
							<span className={isMatchShown ? 'active-class' : 'matched'} onClick={matchButtonHandler}>
								Matched
							</span>
							<span className={isUnmatchedShown ? 'active-class' : 'unmatched'} onClick={unmatchedButtonHandler}>
								Unmatched
							</span>
						</p>
					</div>
					<div>
						<button onClick={viewHandler}>View More</button>
					</div>
				</div>
				{isMatchShown && (
					<div className={style['user_name-cont']}>
						<p className={style['user_name-left']}>UserName Nation</p>
						<p className={style['user_name-right']}>
							<span>Rate</span>
							<span>Amount</span>
							<span>PlaceDate</span>
						</p>
					</div>
				)}
				{isUnmatchedShown && (
					<div className={style['user_name-cont']}>
						<p className={style['user_name-left']}>UserName UnMatchedNation</p>
						<p className={style['user_name-right']}>
							<span>Rate</span>
							<span>Amount</span>
							<span>PlaceDate</span>
						</p>
					</div>
				)}
			</div>
			{isCompVisible && <MathOddsRightRowModal closeHandler={closeHandler} />}
		</div>
	);
};

export default MathOddsRightRow;
