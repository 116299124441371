import { Button, Card, Col, DatePicker, Input, Row, Select, Form } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import "./DataReport.scss";

import { useLocation, useNavigate } from "react-router-dom";
import {
  useDataReportMutation,
  useFilterByUserMutation,
} from "../../Services/CasinoService";
import ReportTable from "./ReportTable";

const DataReport = ({ reportName, userType }) => {
  const [clientId, setClientId] = useState("");
  const timeBefore = moment().subtract(30, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);

  const nav = useNavigate();

  const { Option } = Select;
  const [form] = Form.useForm();

  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const { pathname } = useLocation();
  const [userList, { data: resultData }] = useFilterByUserMutation();
  const [trigger, { data: loginReport, isLoading }] = useDataReportMutation();

  useEffect(() => {
    trigger({
      userType: userType,
      startDate: startDate,
      endDate: endDate,
      reportType: "all",
      userId: "",
    });
  }, [userType]);

  const handleChange = (value) => {
    userList({
      userType: userType,
      userName: value,
    });
  };

  useEffect(() => {
    form?.resetFields();
    setClientId("");
    userList({
      userType: userType,
      userName: "",
    });
  }, [pathname]);

  const handleSelect = (value) => {
    setClientId(value);
  };

  const onFinish = (value) => {
    trigger({
      userType: userType,
      startDate: startDate,
      endDate: endDate,
      reportType: value?.reportType || "All",
      userId: clientId || "",
    });
  };

  return (
    <div className="cash-tran-cont">
      <div className="cash-tran_heading">
        <p>{`${reportName} Reports`}</p>
      </div>

      <div className="">
        <Form
          className="form_data mt-16 cash_data"
          name="basic"
          style={{ marginTop: "12px" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          autoComplete="off">
          <Row>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item  name="client">
                <div style={{ marginBottom: "5px" }}>{reportName}</div>
                <Select
                  placeholder="Select Client"
                  options={
                    resultData?.data.map((i) => ({
                      label: `${i?.userId}  (${i?.userName})`,
                      value: i?.userId,
                    })) || []
                  }
                  showSearch
                  allowClear
                  onSelect={handleSelect}
                  onSearch={handleChange}></Select>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item className="report_type" label="Report Type:" name="reportType">
                <Select defaultValue="All">
                  <Option value="All">All</Option>
                  <Option value="Share">Share</Option>
                  <Option value="Status">Status</Option>
                  <Option value="Password">Password</Option>
                  <Option value="Mobile">Mobile</Option>
                  <Option value="UserName">UserName</Option>
                  {/* <Option value="Casino Commission">Casino Commission</Option> */}
                  <Option value="Session Commission">Session Commission</Option>
                  <Option value="Match Commission">Match Commission</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item label="" name="Date">
                <div className="data_report">
                  <div style={{ marginBottom: "5px" }}>Start Date</div>
                  <DatePicker
                    defaultValue={dayjs(timeBefore)}
                    onChange={onStartDate}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item label="" name="Date">
                <div className="data_report">
                  <div style={{ marginBottom: "5px" }}>End Date</div>
                  <DatePicker
                    defaultValue={dayjs(time)}
                    onChange={onEndDateDate}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item >
                <Button  className="submit_btn" loading={isLoading} htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <ReportTable data={loginReport?.data} isLoading={isLoading} />
    </div>
  );
};

export default DataReport;
