import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import MainLayout from "../common/MainLayout";
import {
  AccountList_Screen,
  Home_Screen,
  Odds_Deatil_Screen,
  ADD_ACCOUNT,
  ACC_STATEMENT,
  CURRENT_BETS,
  PROFIT_LOSS,
  LIVE_MARKET_BINARY,
  BINARY_DATE,
  PASSWORD_RESET,
  USER_LOGIN,
  USER_HISTORY,
  BET_HISTORY,
  MARKET_ANALYSIS,
  LIVE_MARKET_BOLLYWOOD,
  LIVE_MARKET_LUCKY,
  LIVE_MARKET_DRAGON,
  LIVE_MARKET_TEEN_PATTI,
  ADD,
  MATCHBOX_LOGO,
  DELETED_LENDEN,
  MY_LEDGER,
  SPORT_DETAILS,
  MATCH_SLIPS,
  FANCY_SLIPS,
  COMPLETE_FANCY,
  LIVE_REPORT,
  REJECTES_BET,
  AURA_DETAILS,
  SUPERNOWA_DETAILS,
  CASINO_PLUS_MINUS,
  CASINO_ALL_GAME,
  SHOW_BET,
  CASH_TRANSACTION_S,
  CASH_TRANSACTION_M,
  CASH_TRANSACTION_A,
  CASH_TRANSACTION_C,
  LEDGER_SUPER,
  LEDGER_MASTER,
  LEDGER_AGENT,
  LEDGER_CLIENT,
  PROFIT_LOSS_EVENT,
  REPORT_SUPER,
  REPORT_MASTER,
  REPORT_AGENT,
  REPORT_CLIENT,
  COMM_REPORT_SUPER,
  COMM_REPORT_MASTER,
  COMM_REPORT_AGENT,
  COMM_REPORT_CLIENT,
  PLUS_MINUS,
  PLUS_MINUS_REPORT,
  LEDGER_DETAILS,
  CASH_TRA,
  MARKET,
  Q_TECH,
  REPORT_ADMIN,
  COMM_REPORT_ADMIN,
  CASH_TRANSACTION_AD,
  LEDGER_ADMIN,
} from "../utils/linkConstant";
import Home from "../pages/home/Home";
import AccountList from "../pages/accountList/AccountList";
import OddsDetail from "../pages/oddsDetail/OddsDetail";
import AddAccount from "../pages/addAccount/AddAccount";
import ProfitLoss from "../components/Report/ProfitLoss/ProfitLoss";
import CurrentBets from "../components/Report/Current Bets/CurrentBets";
import AccountStatement from "../components/Report/AccountStatement/AccountStatement";
import Binary from "../components/LiveMarket/Binary/Binary";
import Bin from "../components/LiveMarket/Binary/Bin";
import PasswordReset from "../LoginPage/PasswordReset";
import LoginPage from "../LoginPage/LoginPage";
import UserHistory from "../components/Report/UserHistory/UserHistory";
import BetsHistory from "../components/Report/BetHistory/BetsHistory";
import Bollywood from "../components/LiveMarket/Bollywood/Bollywood";
import Lucky7 from "../components/LiveMarket/Lucky7/Lucky7";
import DragonTiger from "../components/LiveMarket/DragonTiger/DragonTiger";
import TeenPatti from "../components/LiveMarket/TeenPatti/TeenPatti";
import MarketAnalysisLogo from "../MarketAnalysisLogo/MarketAnalysis";
import LogoAddHome from "../MarketAnalysisLogo/LogoAddAccount/LogoAddHome/LogoAddHome";
import { axiosInstance } from "../Services/AxiosInstance";
import CashTranstions from "../components/Report/cashTranstions/CashTranstions";
import DeletedData from "../components/Report/cashTranstions/DeletedData";
import MatchLadger from "../components/Report/MatchLedger/MatchLadger";
import SportDetails from "../components/sportDetails/SportDetails";
import MatchBet from "../components/sportDetails/MatchBet/MatchBet";
import CompleteFancy from "../components/sportDetails/CompleteFancy/CompleteFancy";
import LiveReport from "../components/sportDetails/CompleteFancy/LiveReport/LiveReport";
import RejectedBets from "../components/sportDetails/RejectedBets/RejectedBets";
import CasinoDetails from "../components/CasinoDetails/CasinoDetails";
import CasinoPlusMinus from "../components/CasinoDetails/CasinoPlusMinus/CasinoPlusMinus";
import CasinoAllGame from "../components/CasinoDetails/CasinoAllGame/CasinoAllGame";
import ShowBet from "../components/CasinoDetails/ShowBet/ShowBet";
import ProfitLossLedger from "../components/LedgerReport/ProfitLoss/ProfitLossLedger";
import LedgerData from "../components/LedgerReport/LegderData/LedgerData";
import DataReport from "../components/DataReport/DataReport";
import CommReport from "../components/CommReport/CommReport";
import PlusMinusReport from "../components/sportDetails/plusminusreport/PlusMinusReport";
import PlusMinusDetails from "../components/sportDetails/plusminusreport/plusMinusDetails/PlusMinusDetails";
import Dashboard from "../pages/Dashboard/Dashboard";
import LadgerDetails from "../pages/LadgerDetails/LadgerDetails";
import CashTransanction from "../pages/CashTransanction/CashTransanction";
import Settings from "../pages/Settings/Settings";
import MarketAnalysis from "../components/MarketAnalysis/BetDetails/MarketAnalysisTable";
import ChangePassword from "../LoginPage/ChangePassword";
import SetCommission from "../components/SetCommission/SetCommission";
// import UserDetails from '../MarketAnalysisLogo/UserDetails';

const LazyLoadedTableData = React.lazy(() =>
  import("../components/MarketAnalysis/BetDetails/MarketAnalysisTable")
);

// import MarketAnalysis from '../components/MarketAnalysis/BetDetails/MarketAnalysisTable';

const RoutesPages = () => {
  const AUTH_TOKEN = localStorage.getItem("token");
  axiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${AUTH_TOKEN}`;
  

  const nav = useNavigate();
  const loginChecker = localStorage.getItem("token");

  
  useEffect(() => {
    if (loginChecker) {
      return nav('/');
    } else {
      nav("/user-login");
    }
  }, [loginChecker]);

  

  return (
    <Routes>
      {loginChecker ? (
        <Route path="" element={<MainLayout />}>
          <Route path="/market-analysis" element={<Home />} />
          <Route path={Home_Screen} element={<Home/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path={MARKET_ANALYSIS} element={<MarketAnalysis />} />
          <Route path={MARKET_ANALYSIS} element={<LazyLoadedTableData />} />
          <Route path={AccountList_Screen} element={<AccountList />} />
          <Route path={Odds_Deatil_Screen} element={<OddsDetail />} />
          <Route path={ADD_ACCOUNT} element={<AddAccount />} />
          <Route path={ACC_STATEMENT} element={<AccountStatement />} />
          <Route path={CASH_TRANSACTION_AD} element={<CashTranstions name={"Sub Admin"} userType={5} />} />
          <Route path={CASH_TRANSACTION_S} element={<CashTranstions name={"Super Master"} userType={0} />} />
          <Route path={CASH_TRANSACTION_M} element={<CashTranstions name={"Master"} userType={1} />} />
          <Route path={CASH_TRANSACTION_A} element={<CashTranstions name={"Agent"} userType={2} />} />
          <Route path={CASH_TRANSACTION_C} element={<CashTranstions name={"Client"} userType={3} />} />
          <Route path={CURRENT_BETS} element={<CurrentBets />} />
          <Route path={USER_HISTORY} element={<UserHistory />} />
          <Route path={BET_HISTORY} element={<BetsHistory />} />
          <Route path={PROFIT_LOSS} element={<ProfitLoss />} />
          <Route path={LIVE_MARKET_BINARY} element={<Binary />} />
          <Route path={BINARY_DATE} element={<Bin />} />
          <Route path={LIVE_MARKET_BOLLYWOOD} element={<Bollywood />} />
          <Route path={LIVE_MARKET_DRAGON} element={<DragonTiger />} />
          <Route path={LIVE_MARKET_TEEN_PATTI} element={<TeenPatti />} />
          <Route path={MATCHBOX_LOGO} element={<MarketAnalysisLogo />} />
          <Route path={ADD} element={<LogoAddHome />} />
          <Route path={DELETED_LENDEN} element={<DeletedData />} />
          <Route path={MY_LEDGER} element={<MatchLadger />} />
          <Route path={LIVE_MARKET_LUCKY} element={<Lucky7 />} />
          <Route path={SPORT_DETAILS} element={<SportDetails />} />
          <Route path={MATCH_SLIPS} element={<MatchBet title={"Match Bet"} type={2} />} />
          <Route path={FANCY_SLIPS} element={<MatchBet title={"Session Bet"} type={1} />} />
          <Route path={COMPLETE_FANCY} element={<CompleteFancy />} />
          <Route path={LIVE_REPORT} element={<LiveReport />} />
          <Route path={REJECTES_BET} element={<RejectedBets />} />
          <Route path={AURA_DETAILS} element={<CasinoDetails isAura={"Aura"} id={323334} />} />
          <Route path={SUPERNOWA_DETAILS} element={<CasinoDetails isAura={"Super Nowa"} id={323338} />} />
          <Route path={Q_TECH} element={<CasinoDetails isAura={"Q Tech"} id={323337} />} />
          <Route path={CASINO_PLUS_MINUS} element={<CasinoPlusMinus />} />
          <Route path={CASINO_ALL_GAME} element={<CasinoAllGame />} />
          <Route path={SHOW_BET} element={<ShowBet />} />
          <Route path={PROFIT_LOSS_EVENT} element={<ProfitLossLedger />} />
          <Route path={LEDGER_ADMIN} element={<LedgerData userType={5} Listname={"Sub Admin"} />}/>
          <Route path={LEDGER_SUPER}  element={<LedgerData userType={0} Listname={"Super Master"} />}  />
          <Route path={LEDGER_MASTER}  element={<LedgerData userType={1} Listname={"Master"} />} />
          <Route path={LEDGER_AGENT}  element={<LedgerData userType={2} Listname={"Agent"} />} />
          <Route path={LEDGER_CLIENT} element={<LedgerData userType={3} Listname={"Client"} />} />
          <Route path={REPORT_SUPER} element={<DataReport userType={0} reportName={"Super Master"}/>}/>
          <Route path={REPORT_ADMIN} element={<DataReport userType={5} reportName={"Sub Admin"}/>}/>
          <Route path={REPORT_MASTER} element={<DataReport userType={1} reportName={"Master"}/>}/>
          <Route path={REPORT_AGENT} element={<DataReport userType={2} reportName={"Agent"}/>}/>
          <Route path={REPORT_CLIENT} element={<DataReport userType={3} reportName={"Clients"}/>}/>
          <Route path={COMM_REPORT_ADMIN} element={<CommReport userType={5} reportName={"Sub Admin"}/>}/>
          <Route path={COMM_REPORT_SUPER} element={<CommReport userType={0} reportName={"Super Master"}/>}/>
          <Route path={COMM_REPORT_MASTER} element={<CommReport userType={1} reportName={"Master"}/>}/>
          <Route path={COMM_REPORT_AGENT} element={<CommReport userType={2} reportName={"Agent"}/>}/>
          <Route path={COMM_REPORT_CLIENT} element={<CommReport userType={3} reportName={"Clients"}/>}/>
          <Route path={PLUS_MINUS} element={<PlusMinusReport />}/>
          <Route path={PLUS_MINUS_REPORT} element={<PlusMinusDetails />}/>
          <Route path={LEDGER_DETAILS} element={<LadgerDetails/>}/>
          <Route path={CASH_TRA} element={<CashTransanction/>}/>
          <Route path={MARKET} element={<Settings/>}/>
          <Route path="/change-password" element={<ChangePassword/>}/>
          <Route path="/set-commission" element={<SetCommission/>}/>
        </Route>
      ) : (
        ""
      )}

      <Route path={PASSWORD_RESET} element={<PasswordReset />} />
      <Route path={USER_LOGIN} element={<LoginPage />} />
    </Routes>
  );
};

export default RoutesPages;
