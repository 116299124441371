import React from 'react';
import style from './DragonTiger.module.scss';
import IframeContainer from '../IFrame/IframeContainer';
import MarketAnalysisRight from '../../MarketAnalysis/BetDetails/MarketAnalysisRight';
import { AiFillLock } from 'react-icons/ai';

const DragonTiger = () => {
	const firstRowData = [
		{ id: 'd1', name: 23.88, status: 'Back', number: 0 },
		{ id: 'd2', name: 25.6, status: 'Back', number: 0 },
		{ id: 'd3', name: 95.2, status: 'Back', number: 0 },
		{ id: 'd4', name: 65.33, status: 'Back', number: 0 }
	];

	const dragon = [
		{ id: 'd1', name: 65.3, status: 'Back', number: 0 },
		{ id: 'd2', name: 2.4, status: 'Back', number: 0 },
		{ id: 'd3', name: 4.2, status: 'Back', number: 0 },
		{ id: 'd4', name: 8.2, status: 'Back', number: 0 }
	];

	const tiger = [
		{ id: 'd1', name: 2.25, status: 'Back', number: 0 },
		{ id: 'd2', name: 3.3, status: 'Back', number: 0 },
		{ id: 'd3', name: 33.5, status: 'Back', number: 0 },
		{ id: 'd4', name: 99.2, status: 'Back', number: 0 }
	];

	const dragonCards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 5, 12, 13, 44];

	return (
		<div className={style['cont']}>
			<div className={style['left']}>
				{/* <IframeContainer /> */}

				<div className={style['dragon_cont']}>
					<div className={style['first_cont']}>
						<div className={style['first']}>
							{firstRowData.map(t => {
								return (
									<div className={style['content']} key={t.id}>
										<p>{t.name}</p>
										<AiFillLock className={style['icon']} />
										{/* <p>{t.status}</p> */}
										<p>{t.number}</p>
									</div>
								);
							})}
						</div>
					</div>

					<div className={style['second']}>
						<div className={style['dragon_desc-cont']}>
							<div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: '700', letterSpacing: '-0.1px', fontFamily: '-moz-initial' }}>
								<span>DRAGON</span>
							</div>

							<div className={style['dragon']}>
								{dragon.map(data => {
									return (
										<div className={style['dragon-desc']} key={data.id}>
											<p>{data.name}</p>
											<AiFillLock className={style['icon']} />
											{/* <p>{data.status}</p> */}
											<p>{data.number}</p>
										</div>
									);
								})}
							</div>
						</div>
						<div className={style['tiger_desc-cont']}>
							<div style={{ textAlign: 'center', marginBottom: '0px', fontWeight: '700', letterSpacing: '-0.1px', fontFamily: '-moz-initial' }}>TIGER</div>
							<div className={style['tiger']}>
								{tiger.map(d => {
									return (
										<div className={style['tiger-desc']} key={d.id}>
											<p>{d.name}</p>
											<AiFillLock className={style['icon']} />
											{/* <p>{d.status}</p> */}
											<p>{d.number}</p>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className={style['third']}>
						<div className={style['dragon_cards']}>
							<div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: '700', letterSpacing: '-0.1px', fontFamily: '-moz-initial' }}>DRAGON 12.00</div>
							<div className={style['desc']}>
								{dragonCards.map(el => (
									<p key={el}>{el}</p>
								))}
							</div>
						</div>

						<div className={style['tiger_cards']}>
							<div style={{ textAlign: 'center', marginBottom: '10px', fontWeight: '700', letterSpacing: '-0.1px', fontFamily: '-moz-initial' }}>TIGER 12.00</div>
							<div className={style['tiger_desc']}>
								{dragonCards.map(el => (
									<p key={el}>{el}</p>
								))}
							</div>
						</div>
					</div>

					<div className={style['forth_row']}>
						<p className={style['result']}>
							<span>Last Results</span>
							<span>View All</span>
						</p>
						<p>dcsdvfev erfe</p>
					</div>
				</div>
			</div>
			<div className={style['right']}>
				<MarketAnalysisRight />
			</div>
		</div>
	);
};

export default DragonTiger;
