import { Button, Card, Col, DatePicker, Input, Row, Select, Form, Spin } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import dayjs from "dayjs";


import { useLocation, useNavigate } from "react-router-dom";
import CommReportTable from "./CommReportTable";
import axios from "axios";
import { useCommReportMutation, useFilterByUserMutation } from "../../Services/CasinoService";
import { FaSpinner } from "react-icons/fa";

const CommReport = ({ reportName, userType }) => {
  const timeBefore = moment().subtract(30, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);
  const [clientId, setClientId] = useState("");
  const [paginationTotal, setPaginationTotal] = useState(50);
  const [totalPage, setTotalPage] = useState();
  const [indexData, setIndexData] = useState(0);

  const [form] = Form.useForm();


  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };


  const { pathname } = useLocation();
  const [userList, { data: resultData }] = useFilterByUserMutation();
  const [trigger, { data: commReport, isLoading }] = useCommReportMutation();

  useEffect(() => {
    trigger({
      userType: userType,
      startDate: startDate,
      endDate: endDate,
      userId: "",
      noOfRecords: paginationTotal,
      index: indexData,
    });
  }, [userType, indexData, paginationTotal]);


  useEffect(()=>{
    setTotalPage(commReport?.data?.totalPages);

  }, [commReport?.data?.totalPages])


  const handleChange = (value) => {
    userList({
      userType: userType,
      userName: value,
    });
  };

  useEffect(() => {
    form?.resetFields();
    setClientId("");
    userList({
      userType,
      userName: "",
    });
  }, [pathname]);

  const handleSelect = (value) => {
    setClientId(value);
  };

  const onFinish = (value) => {
    trigger({
      userType: userType,
      startDate: startDate,
      endDate: endDate,
      userId: clientId || "",
      noOfRecords: "50",
      index: "0",
    });
  };

 

  return (
    <div className="cash-tran-cont">
      <div className="cash-tran_heading">
        <p>{`${reportName} Comm Reports`}</p>
      </div>
      <div className="">
        <Form
          className="form_data mt-16 cash_data"
          name="basic"
          style={{ marginTop: "12px" }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          autoComplete="off">
          <Row>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item name="client">
              <div style={{ marginBottom: "5px" }}>{reportName}</div>
                <Select
                  placeholder={`Select ${reportName}`}
                  options={
                    resultData?.data.map((i) => ({
                      label: `${i?.userId}  (${i?.userName})`,
                      value: i?.userId,
                    })) || []
                  }
                  showSearch
                  allowClear
                  onSelect={handleSelect}
                  onSearch={handleChange}></Select>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item label="" name="Date">
                <div className="data_report">
                  <div style={{ marginBottom: "5px" }}>Start Date</div>
                  <DatePicker
                    defaultValue={dayjs(timeBefore)}
                    onChange={onStartDate}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={24} xs={24}>
              <Form.Item label="" name="Date">
                <div className="data_report">
                  <div style={{ marginBottom: "5px" }}>End Date</div>
                  <DatePicker
                    defaultValue={dayjs(time)}
                    onChange={onEndDateDate}
                  />
                </div>
              </Form.Item>
            </Col>
            <Col xl={4} lg={4} md={12} xs={12}>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button className="submit_btn" loading={isLoading}  htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <CommReportTable
        data={commReport?.data?.list}
        paginationTotal={paginationTotal}
        totalPage={totalPage}
        indexData={indexData}
        setIndexData={setIndexData}
        setPaginationTotal={setPaginationTotal}
        isLoading={isLoading}
      />
      </div>
  );
};

export default CommReport;
