import React from "react";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";

const UsersHeader = ({ searchQuery, setSearchQuery }) => {
  return (
    <div className="search" style={{ marginTop: "20px" }}>
      <div className="format">
        <p
          className="pdf-icon"
          style={{ cursor: "pointer" }}
          // onClick={handleDownload}
        >
          <span className="icon">
            <AiOutlineFilePdf />
          </span>
          <span className="pdf">PDF</span>
        </p>

        <p className="excel-icon">
          <span className="icon">
            <AiOutlineFileExcel />
          </span>
          <span className="excel">Excel</span>
        </p>
      </div>
      <p className="input">
        <span
          className="search"
          style={{ marginTop: "8px", marginRight: "10px" }}
        >
          Search:
        </span>
        <form>
          <input
            type="text"
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            placeholder="Search here..."
          />
        </form>
      </p>
    </div>
  );
};

export default UsersHeader;
