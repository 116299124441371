import { createAsyncThunk } from "@reduxjs/toolkit";
import { FilterCashTranstion } from "../../../Services/ApiEndingPoint";
import { axiosInstance } from "../../../Services/AxiosInstance";


export const filterCashTransaction = createAsyncThunk('filtercashtransation', async (payload, {rejectWithValue})=>{
    try{
        const response = await axiosInstance.post(FilterCashTranstion, {...payload});
        if(response){
            return response?.data;
        }
    }catch(error){
        return rejectWithValue(error);
    }
})