import { Dropdown } from 'antd';
import React from 'react';
import './styles.scss';
const DropDownComp = ({ name, icon, items }) => {
	return (
		<Dropdown
			menu={{
				items
			}}
			placement="bottomLeft"
			className="antd-drop-down-comp">
			<li>
				{name}
				<span>{icon}</span>
			</li>
		</Dropdown>
	);
};

export default DropDownComp;
