import React, { useEffect, useState } from "react";
import style from "./AccountListAccountHistory.module.scss";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { Table } from "antd";
import { axiosInstance } from "../../../Services/AxiosInstance";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const AccountListAccountHistory = (props) => {
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const columns = [
    {
      title: "S_no.",
      dataIndex: "sno",
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "Pts",
      dataIndex: "pts",
    },
    {
      title: "Fromto",
      dataIndex: "fromto",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
  ];
  const [accountStatement, setAccountStatement] = useState([]);

  useEffect(() => {
    accountHistoryData();
  }, [accountStatementBody?.index]);

  const accountHistoryData = async () => {
    loadingRef("reportccountstatement", true);
    const endPoint = "report/account-statement";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        {
          fromDate: "00:00:0000",
          index: accountStatementBody?.index,
          noOfRecords: 25,
          toDate: "00:00:0000",
          type: 5,
          userid: props.userDetails.userId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setAccountStatement(res?.data?.data?.dataList);
        setAccountStatementBody((prev) => {
          return {
            ...prev,
            totalPages: res?.data?.data?.totalPages,
          };
        });
      });
    loadingRef("reportccountstatement", false);
  };
  return (
    <>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          dataSource={accountStatement}
          pagination={false}
        />
      </div>
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
      />
    </>
  );
};

export default AccountListAccountHistory;
