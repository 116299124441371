import React, { useState } from "react";
// import style from './ProfitLossTable.module.scss';
import { Button, Modal, Select, Table } from "antd";
import ViewButtonPortal from "./ViewButtonPortal";
import "./ProfitLossCasinoTable.scss";

const ProfitLossCasinoTable = ({
  pnlTableData,
  userID,
  setTableRecords,
  tableRecords,
}) => {
  const [searchData, setSearchData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleCancel = () => {
    setShowModal(false);
  };

  const columns = [
    {
      title: "Match Name",
      dataIndex: "matchName",
      key: "matchName",
      filteredValue: [searchData],
      onFilter: (value, record) => {
        return (
          String(record?.matchName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.pnl).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.uplineAmount)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Pnl",
      dataIndex: "pnl",
      key: "pnl",
      render: (record) => {
        return (
          <span style={{ color: record > 0 ? "green" : "red" }}>
            {Number(record).toFixed(2)}
          </span>
        );
      },
    },

    {
      title: "Upline Amount",
      dataIndex: "uplineAmount",
      key: "uplineAmount",
    },

    {
      title: "Action",
      dataIndex: "matchId",
      key: "matchId",
      render: (record) => {
        return (
          <Button
            onClick={() => {
              // onViewMoreApi();
              setShowModal(true);
              setSelectedItem(record);
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p>Show</p>
          <Select
            value={tableRecords.noOfRecords}
            onChange={(e) =>
              setTableRecords((prev) => {
                return {
                  ...prev,
                  noOfRecords: e,
                };
              })
            }
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={200}>200</Select.Option>
          </Select>
          <p>Enteries</p>
        </div>
        <div
          style={{
            marginRight: "7px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label>Search:</label>
          <input
            type="text"
            onChange={searchHandler}
            value={searchData}
            placeholder="Search here..."
          />
        </div>
      </div>
      <div className="accountlist-table-container">
        <Table columns={columns} dataSource={pnlTableData} pagination={false} />
      </div>
      <Modal
        className="more-modal-more"
        title="Bet History"
        footer={null}
        open={showModal}
        onCancel={handleCancel}
        destroyOnClose
        style={{ padding: "15px" }}
        width={800}
      >
        {showModal && (
          <ViewButtonPortal selectedItem={selectedItem} userID={userID} />
        )}
      </Modal>
    </>
  );
};

export default ProfitLossCasinoTable;
