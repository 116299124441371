import React, { useEffect, useState } from "react";
import "./ActiveUser.scss";
import InactiveUserTable from "./InactiveUserTable";
import { axiosInstance } from "../Services/AxiosInstance";
import Pagination from "../Pagination/Pagination";
import { loadingRef } from "../App";

const ActiveUsers = ({ userId, searchQuery, setSearchQuery, setIsLoading, isLoading, antIcon}) => {
  const [activeClientData, setActiveClientData] = useState([]);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const inactiveUserdataApi = async () => {
    loadingRef("userchildist", true);
    const baseUrl = "/user/child-list";

    await axiosInstance
      .post(baseUrl, {
        ...accountStatementBody,
        ...{ id: userId, username: searchQuery },
      })
      .then((res) => {
        setActiveClientData(res?.data?.data?.dataList);
        loadingRef("userchildist", false);
        if(res?.data?.status){
          setIsLoading(false)
        }else if(res?.data?.status == false){
          setIsLoading(false)
        }
        setAccountStatementBody((prev) => ({
          ...prev,
          totalPages: res?.data?.data?.totalPages,
        }));
      });
    loadingRef("userchildist", false);
  };

  useEffect(() => {
    inactiveUserdataApi();
  }, [userId, searchQuery, accountStatementBody?.index]);

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };

  const filteredData = activeClientData?.filter((item) =>
    item?.username?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedData = filteredData?.slice().sort((a, b) => a?.id - b?.id);

  const startRange = sortedData?.length > 0 ? 1 : 0;
  const endRange = sortedData?.length;
  const totalEntries = activeClientData?.length;

  const showFilteredText = searchQuery !== "";

  const enteriesText = `Showing ${startRange} to ${endRange} of ${endRange} entries ${
    showFilteredText ? `(filtered from ${totalEntries} total entries)` : ""
  }`;

  

  return (
    <>
      {/* <div className="search">
          <div className="format">
            <p className="pdf-icon" style={{ cursor: "pointer" }}>
              <span className="icon">
                <AiOutlineFilePdf />
              </span>
              <span className="pdf">PDF</span>
            </p>

            <p className="excel-icon">
              <span className="icon">
                <AiOutlineFileExcel />
              </span>
              <span className="excel">Excel</span>
            </p>
          </div>
          <p className="input">
            <span className="search" style={{ marginTop: "8px" }}>
              Search:
            </span>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search here..."
            />
          </p>
        </div> */}
      <div className="active_user-table">
        <InactiveUserTable
          filteredData={sortedData}
          getRowClassName={getRowClassName}
          inactiveUserdataApi={inactiveUserdataApi}
          className="active-user-table"
          setSearchQuery={setSearchQuery}
          isLoading={isLoading}
          antIcon={antIcon}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2px",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          {enteriesText}
          {/* Showing {startRange} to {endRange} of {totalEntries} entries */}
          <Pagination
            paginationData={accountStatementBody}
            setPaginationData={setAccountStatementBody}
          />
          {/* <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <button
                style={{
                  background: color.themeColor2,
                  color: "white",
                  borderRadius: "5px",
                  padding: "10px",
                  border: "none",
                }}
              >
                Previous
              </button>
              <span
                style={{
                  background: color.themeColor2,
                  color: "white",
                  borderRadius: "4px",
                  padding: "8px 12px",
                  border: "none",
                }}
              >
                1
              </span>
              <button
                style={{
                  background: color.themeColor2,
                  color: "white",
                  borderRadius: "5px",
                  padding: "10px",
                  border: "none",
                }}
              >
                Next
              </button>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default ActiveUsers;
