import React from "react";
import { useLocation, useParams } from "react-router";
import { useCasinoRoundWiseQuery } from "../../../Services/CasinoService";
import { Empty, Spin } from "antd";
import { FaSpinner } from "react-icons/fa";

const ShowBet = () => {
  const { state } = useLocation();

  const { id } = useParams();

  const { data, isFetching, isLoading } = useCasinoRoundWiseQuery({
    casinoId: state?.id,
    roundId: id,
    date: state?.state?.rouletteDate,
  }, {refetchOnMountOrArgChange: true});

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );


  return (
    <div>
      <div>
        <div>
          <p className="match_bet casino_rept">
            {state?.state?.isAuraDetails} ({id})
          </p>
        </div>
        <br />
        {
          (isFetching || isLoading) ?  <Spin  indicator={antIcon} className="loading_active"  size="large">
          <div className="content" />
        </Spin> :  <div className="accountlist-table-container">
          <table className="table_section">
            <tr>
              <th>Date</th>
              <th>Client</th>
              <th>Market</th>
              <th>Rate</th>
              <th>Number</th>
              <th>Stake</th>
              <th>Profit</th>
              <th>Loss</th>
              <th>Net Pnl</th>
            </tr>
            {data?.data?.map((res, id) => {
              return (
                <tr className={res?.isBack ? "back" : "lay"}>
                  <td>{res?.date}</td>
                  <td>{res?.clientId}</td>
                  <td>{res?.market}</td>
                  <td>{res?.rate}</td>
                  <td>{res?.selection}</td>
                  <td>{res?.stake}</td>
                  <td>{res?.profit}</td>
                  <td>{res?.loss}</td>
                  <td
                    className={
                      res?.netpnl < 0 ? "text_danger" : "text_success"
                    }>
                    {res?.netpnl}
                  </td>
                </tr>
              );
            })}
            {(data?.data === undefined || data?.data?.length === 0) && (
              <tr>
                {" "}
                <td colSpan={9}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            )}
          </table>
        </div>
        }
       
      </div>
    </div>
  );
};

export default ShowBet;
