import React, { useMemo } from 'react';
import style from './MathOddsLeftRowMiddle.module.scss';

const MathOddsLeftRowMiddle = () => {
	const teamArray = useMemo(
		() => [
			{ name: 'Sunrisers Hyderabad', first: '', second: '', back: '', lay: '', third: '', fourth: '' },
			{ name: 'Mumbai Indians', first: '', second: '', back: { bet1: 86, bet2: '100k' }, lay: { bet1: 90, bet2: '100k' }, third: '', fourth: '' }
		],
		[]
	);
	return (
		<table className={style['mathOdd_table']}>
			<thead className={style['mathOdd_head']}>
				<tr className={style['mathOdd_tr']}>
					<th className={style['min_heading']}>Min:100 Max:1000000</th>
					<th className={style['first']}></th>
					<th className={style['second']}></th>
					<th className={style['back-middle']}>Back</th>
					<th className={style['lay-middle']}>Lay</th>
					<th className={style['third']}></th>
					<th className={style['fourth']}></th>
				</tr>
			</thead>
			<tbody className={style['mathOdd_body']}>
				{teamArray.map(data => (
					<tr className={style['mathOdd_body_tr']} key={data}>
						<td className={style['name']}>
							{data.name}
							<p>0</p>
						</td>
						<td className={style['first-middle']}></td>
						<td className={style['second-middle']}></td>
						<td className={style['back-middle']}>
							<span className={style['span']}>{data.back.bet1}</span> <span className={style['numb-2']}>{data.back.bet2}</span>
						</td>
						<td className={style['lay-middle']}>
							<span className={style['span']}>{data.lay.bet1}</span>
							<span className={style['numb-2']}>{data.lay.bet2}</span>
						</td>
						<td className={style['third-middle']}></td>
						<td className={style['fourth-middle']}></td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default MathOddsLeftRowMiddle;
