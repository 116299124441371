import React from 'react'
import MathOddsLeftRowHeader from './MathOddsLeftRowHeader'
import MathOddsLeftRowBottom from './MathOddsLeftRowBottom'
import MathOddsLeftRowMiddle from './MathOddsLeftRowMiddle'
// import './MathOddsLeftRow.scss'

const MathOddsLeftRow = () => {
  return (
      <div>
      <MathOddsLeftRowHeader />
      <MathOddsLeftRowMiddle />
          <MathOddsLeftRowBottom />
      </div>
  )
}



export default MathOddsLeftRow
