import React, { useEffect, useState } from 'react';
import style from './BookmakerTable.module.scss';
import '../style.scss';
import { axiosInstance } from '../../../../Services/AxiosInstance';
import { useParams } from 'react-router';
const backColor = ['#72BBEF', '#72BBEFA3', '#72BBEFA3'];
const layColor = ['#F994BA', '#F994BACC', '#F994BACC'];

const BookmakerTable = ({ data, prev }) => {
	const [oddData, setOddData] = useState([]);
	const { id } = useParams();
	function formatNumber(num) {
		if (num >= 1000) {
			return num / 1000 + 'K';
		}
		return num;
	}

	const oddPnl = () => {
		const endPoint = 'bets/odds-pnl';
		axiosInstance.post(endPoint, { matchId: id }).then(res => {
			setOddData(res?.data?.data);
		});
	};

	useEffect(() => {
		oddPnl();
	}, [5000]);

	const uType = localStorage.getItem("userType")

	return (
		<>
			{/* {betData?.map(el => ( */}
			<table className={style['table']}>
				<thead className={style['head']}>
					<tr className={style['blank_tr']}>
						<th style={{color:"#17a2b8"}} className={style['min_heading']}>
							Min:{formatNumber(data[0].minBet)} Max:{formatNumber(data[0].maxBet)}
						</th>
					</tr>
					<tr style={{marginBottom:`${uType == 4?"2px":"4px"}`}} className={style['head_tr']}>
						<th></th>
						<th></th>
						<th className={style['back-middle']} style={{ fontSize: '16px', width: '16.2%', background: '#72BBEF',  }}>
							Back
						</th>
						<th className={style['lay-middle']} style={{ fontSize: '16px', width: '16.2%', background: '#F994BA',  }}>
							Lay
						</th>
						<th ></th>
						<th ></th>
					</tr>
				</thead>

				<tbody className={style['body']}>
					{data?.map((el, index) => (
						<tr style={{marginBottom:`${uType == 4?"1px":"4px"}`}} className={style['body_tr']} key={el}>
							<td className={style['name']}>
								{el?.nation}
								{oddData?.map(res => {
									if (el?.mid !== res?.marketId) return <></>;
									const oddsPnl = {
										[res?.selection1]: res?.pnl1,
										[res?.selection2]: res?.pnl2,
										[res?.selection3]: res?.pnl3
									};
									return <p style={{color: oddsPnl[el?.sid] > 0 ? "green" : "red"}}>{oddsPnl[el?.sid] || '0'}</p>;
								})}
							</td>

							<div  className={style['data_cont']}>
								{el?.gstatus === 'SUSPENDED' ? (
									<div className={style['suspended-cont']}>
										{' '}
										<p className={style['suspended']}>{el?.gstatus}</p>{' '}
									</div>
								) : (
									''
								)}

								<td className={style['back-first']} style={{ width: '100%', background: '#72BBEFA3', height: '100%', textAlign: 'center', padding: '5px' }}>
									<span className={style['span']}>-</span>
									{/* <span className={style['numb-2']}>{el.b1}</span> */}
								</td>
								<td className={style['back-second']} style={{ width: '100%', background: '#72BBEFA3', height: '100%', textAlign: 'center', padding: '5px' }}>
									<span className={style['span']}>-</span>
									{/* <span className={style['numb-2']}>{el.b1}</span> */}
								</td>
								<td
									className={style['back-third']}
									style={{
										background: el?.b1 > prev[index]?.b1 ? '#03B37F' : el?.b1 < prev[index]?.b1 ? '#FC4242' : backColor[0]
									}}>
									<span className={style['span']}>{el?.b1}</span>
									<span className={style['numb-2']}>{el?.bs1}</span>
								</td>
								<td
									className={style['lay_first']}
									style={{
										backgroundColor: el?.l1 > prev[index]?.l1 ? '#03B37F' : el?.l1 < prev[index]?.l1 ? '#FC4242' : layColor[0]
									}}>
									<span className={style['']}>{el?.l1}</span>
									<span className={style['numb-2']}>{el?.ls1}</span>
								</td>
								<td className={style['lay-second']} style={{ width: '100%', background: '#F994BACC', height: '100%', textAlign: 'center', padding: '5px' }}>
									<span className={style['span']}>-</span>
								</td>
								<td className={style['lay-third']} style={{ width: '100%', background: '#F994BACC', height: '100%', textAlign: 'center', padding: '5px' }}>
									<span className={style['span']}>-</span>
								</td>
							</div>
						</tr>
					))}
				</tbody>
			</table>
			{/* ))} */}
		</>
	);
};

export default BookmakerTable;
