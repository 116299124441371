import { Button, Checkbox, Modal, Table } from "antd";
import React, { useState } from "react";
import Deposit from "./InactiveActionButton/Deposit/Deposit";
import Withdraw from "./InactiveActionButton/Withdraw/Withdraw";
import Limit from "./Portals/Limit/Limit";
// import Status from "./Portals/Status/Status";
import Password from "./InactiveActionButton/Password/Password";
import Credit from "./Portals/Credit/Credit";
import "./ActiveUser.scss";
import { useNavigate } from "react-router";
import Status from "./InactiveActionButton/Status/Status";
import { useSearchParams } from "react-router-dom";
import ModalsData from "../components/MarketAnalysis/ModalData/ModalData";
import { usePartnershipMutation } from "../Services/CasinoService";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FaSpinner } from "react-icons/fa";

const InactiveUserTable = ({
  filteredData,
  getRowClassName,
  handleTableChange,
  inactiveUserdataApi,
  setSearchQuery,
  isLoading,
  antIcon

}) => {
  const [showModal, setShowModal] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userIds, setUserIds] = useState("");
  const userId = searchParams.get("id");

  const [partnerShipData, { data: partnerShipDetail, isLoading: loading }] =
    usePartnershipMutation();

  const showModalPar = (val) => {
    setUserIds(val);
    const partnerShipDetail = {
      userId: val,
    };
    partnerShipData(partnerShipDetail);
    setIsModalOpen(true);
  };

  const handleActiveChild = (val)=>{
    setSearchQuery("");
    nav(`/matchbox_active-user?id=${val}`)
  }
  const columnsClient = [
    {
      title: "User Name",
      dataIndex: "username",
      width: "10%",
      key: "name",
      sorter: (a, b) => a.username.localeCompare(b?.username),

      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => handleActiveChild(record?.id)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.userId?.localeCompare(b?.userId),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => showModalPar(text)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },

    {
      title: "Credit Reference",
      dataIndex: "chips",
      sorter: (a, b) => a?.chips - b?.chips,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px", color: "#444" }}>
          {text}
        </span>
      ),
    },

    {
      title: "Balance",
      dataIndex: "chips",
      // width: '5%',
      key: "balance",
      sorter: (a, b) => a.chips - b.chips,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "Cient (P/L)",
      dataIndex: "clientPl",
      // width: '5%',
      sorter: (a, b) => a.clientPl - b.clientPl,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    // {
    //   title: "Default %",
    //   dataIndex: "clientPlPercentage",
    //   sorter: (a, b) => a.clientPlPercentage - b.clientPlPercentage,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },

    {
      title: "Exposure",
      dataIndex: "exposure",
      // width: '5%',
      sorter: (a, b) => a.exposure - b.exposure,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "Available Balance",
      dataIndex: "availabePts",
      // width: '5%',
      sorter: (a, b) => a.availabePts - b.availabePts,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "U St",
      dataIndex: "accountLock",
      // width: '1%',
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record?.accountLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "B St",
      dataIndex: "betLock",
      // width: '1%',
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record.betLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "Exposure Limit",
      dataIndex: "total",
      // width: '2%',
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    {
      title: "PName",
      dataIndex: "pname",
      sorter: (a, b) => a.pname - b.pname,
    },

    {
      title: "Account Type",
      dataIndex: "accountType",
      sorter: (a, b) => a.accountType.length - b.accountType.length,
    },

    // {
    //   title: "Casino Total",
    //   dataIndex: "0",
    //   // width: '13%',
    //   sorter: (a, b) => a.age - b.age,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px", padding: "0px 4px" }}>
    //       {text}
    //     </span>
    //   ),
    // },
    userId == null
      ? {
          title: "Actions",
          dataIndex: "action",
          // width: '35%',

          render: (text, record) => {
            return (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setShowModal("Deposit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  D
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Withdraw");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 8px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  W
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Exposure Limit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  L
                </Button>
                  {/* <Button
                    onClick={() => {
                      setShowModal("Credit");
                      setSelectedItem(record);
                    }}
                    style={{
                      background: "#444",
                      color: "white",
                      padding: "1px 10px",
                      marginRight: "3px",
                      height: "27px",
                      borderRadius: "4px",
                      fontSize: "13.6px",
                    }}
                  >
                    C
                  </Button> */}
                <Button
                  onClick={() => {
                    setShowModal("Password");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  P
                </Button>

                <Button
                  onClick={() => {
                    setShowModal("Status");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "26px",
                    borderRadius: "4px",
                    fontSize: "13.5px",
                    letterSpacing: "-1px",
                  }}>
                  S
                </Button>
              </div>
            );
          },
        }
      : {},
  ];


  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      width: "10%",
      key: "name",
      sorter: (a, b) => a.username.localeCompare(b?.username),

      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => handleActiveChild(record?.id)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.userId?.localeCompare(b?.userId),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => showModalPar(text)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },

    {
      title: "Credit Reference",
      dataIndex: "chips",
      sorter: (a, b) => a?.chips - b?.chips,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px", color: "#444" }}>
          {text}
        </span>
      ),
    },

    {
      title: "Balance",
      dataIndex: "chips",
      // width: '5%',
      key: "balance",
      sorter: (a, b) => a.chips - b.chips,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    // {
    //   title: "Cient (P/L)",
    //   dataIndex: "clientPl",
    //   // width: '5%',
    //   sorter: (a, b) => a.clientPl - b.clientPl,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },
    // {
    //   title: "Default %",
    //   dataIndex: "clientPlPercentage",
    //   sorter: (a, b) => a.clientPlPercentage - b.clientPlPercentage,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },

    {
      title: "Exposure",
      dataIndex: "exposure",
      // width: '5%',
      sorter: (a, b) => a.exposure - b.exposure,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "Available Balance",
      dataIndex: "availabePts",
      // width: '5%',
      sorter: (a, b) => a.availabePts - b.availabePts,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "U St",
      dataIndex: "accountLock",
      // width: '1%',
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record?.accountLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "B St",
      dataIndex: "betLock",
      // width: '1%',
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record.betLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "Exposure Limit",
      dataIndex: "total",
      // width: '2%',
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    {
      title: "PName",
      dataIndex: "pname",
      sorter: (a, b) => a.pname - b.pname,
    },

    {
      title: "Account Type",
      dataIndex: "accountType",
      sorter: (a, b) => a.accountType.length - b.accountType.length,
    },

    // {
    //   title: "Casino Total",
    //   dataIndex: "0",
    //   // width: '13%',
    //   sorter: (a, b) => a.age - b.age,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px", padding: "0px 4px" }}>
    //       {text}
    //     </span>
    //   ),
    // },
    userId == null
      ? {
          title: "Actions",
          dataIndex: "action",
          // width: '35%',

          render: (text, record) => {
            return (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setShowModal("Deposit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  D
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Withdraw");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 8px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  W
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Exposure Limit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  L
                </Button>
                  {/* <Button
                    onClick={() => {
                      setShowModal("Credit");
                      setSelectedItem(record);
                    }}
                    style={{
                      background: "#444",
                      color: "white",
                      padding: "1px 10px",
                      marginRight: "3px",
                      height: "27px",
                      borderRadius: "4px",
                      fontSize: "13.6px",
                    }}
                  >
                    C
                  </Button> */}
                <Button
                  onClick={() => {
                    setShowModal("Password");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  P
                </Button>

                <Button
                  onClick={() => {
                    setShowModal("Status");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "26px",
                    borderRadius: "4px",
                    fontSize: "13.5px",
                    letterSpacing: "-1px",
                  }}>
                  S
                </Button>
              </div>
            );
          },
        }
      : {},
  ];
 

  // const sortedDataSource = [...filteredData].sort((a, b) => a?.username?.localeCompare(b?.username));
  const clasObj = {
    Deposit: "more-modal-deposit",
    Withdraw: "more-modal-withdraw",
    Limit: "more-modal-more",
    Status: "more-modal-more",
    Password: "more-modal-more",
    Credit: "more-modal-more",
  };

  const uType = localStorage.getItem("userType");

  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  console.log(isLoading, "isLoading")

  return (
    <>
    {
      uType != 2 &&  <Table
      columns={columns}
      dataSource={filteredData?.filter((item) => item?.accountType != "User")}
      onChange={handleTableChange}
      pagination={false}
      rowClassName={getRowClassName}
      loading={tableLoading}
      className="tbs"
      style={{ width: "100%", overflowX: "scroll" }}
    />
    }
     {
      uType != 2 &&  <div>
      <h2 className="list">Client List</h2>
    </div>
     }
     
      <Table
        columns={columnsClient}
        dataSource={filteredData?.filter((item) => item?.accountType == "User")}
        onChange={handleTableChange}
        pagination={false}
        rowClassName={getRowClassName}
        loading={tableLoading}
        className="tbs"
        style={{ width: "100%", overflowX: "scroll" }}
      />
      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal("")}
        destroyOnClose={true}
        style={{ top: "10px", left: "0px", height: "100%" }}
        className={clasObj["Deposit"]}
        title={showModal}>
        {showModal === "Deposit" && (
          <Deposit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Withdraw" && <Withdraw selectedItem={selectedItem} />}
        {showModal === "Exposure Limit" && (
          <Limit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Status" && (
          <Status
            selectedItem={selectedItem}
            inactiveUserdataApi={inactiveUserdataApi}
            setShowModal={setShowModal}
          />
        )}
        {showModal === "Password" && (
          <Password selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Credit" && (
          <Credit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
      </Modal>

      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
        style={{ padding: "15px" }}
        width={800}
        className="table-modal-x exposure_modals partnership"
        title={`Partnership Info - ${userIds}`}>
        <ModalsData
          partnershipDetails={partnerShipDetail?.data}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default InactiveUserTable;
