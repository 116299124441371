import React from 'react';
import MatchLedgerHeader from './MatchLedgerHeader';

const MatchLadger = () => {
	return (
		<div className="cash-tran-cont">
			<div className='cash-tran_heading'>
				<p>My Lena Dena</p>
			</div>
                
            <MatchLedgerHeader/>
		</div>
	);
};

export default MatchLadger;
