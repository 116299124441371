import React, { useContext, useState } from "react";
import { Modal, Tabs } from "antd";
import AccountListModal from "../AccountListModal/AccountListModal";
import AccountListLimit from "./AccountListLimit";
import AccountListPassword from "./AccountListPassword";
import AccountListEditProfile from "./AccountListEditProfile";
import AccountListStatus from "./AccountListStatus";
import AccountListUserProfile from "./AccountListUserProfile";
import AccountListAccountHistory from "./AccountListAccountHistory";
import { useMemo } from "react";
import { GrClose } from "react-icons/gr";
import ContextPortal from "../../../Context/PortalContext";
import style from "./AccountListMore.module.scss";
import CasinoAllowed from "./CasinoAllowed";

const onChange = (key) => {
};

const AccountListMore = (props) => {
  const ctx = useContext(ContextPortal);

  const items = [
    {
      key: "1",
      label: `Profile`,
      children: (
        <AccountListUserProfile
          userDetails={props?.data}
          selectedRecord={props.selectedRecord}
        />
      ),
    },

    {
      key: "2",
      label: `Change Password`,
      children: (
        <AccountListPassword
          userDetails={props?.data}
          submitted={props?.setMore}
          onSelectedRecord={props?.selectedRecord}
        />
      ),
    },

    {
      key: "3",
      label: `User Lock`,
      children: (
        <AccountListStatus
          // userDetails={props.selectedRecord}
          data={props?.data}
          onPortalHidden={props?.onPortalHidden}
          passwordHandler={props?.passwordHandler}
          setAccountLock={props?.setAccountLock}
          betLock={props?.betLock}
          accountLock={props?.accountLock}
          setBetLock={props?.setBetLock}
          checkBoxHandler={props?.checkBoxHandler}
          lupassword={props?.lupassword}
          selectedRecord={props?.selectedActionData}
          submitted={props?.setMore}
          error={props?.error}
          setError={props?.setError}
          setMore={props?.setMore}
          dataApi={props?.dataApi}
          onSelectedRecord={props?.selectedRecord}
          inactiveActiveUser={props?.onInactiveActiveUser}
        />
      ),
    },

    {
      key: "4",
      label: `Account History`,
      children: (
        <AccountListAccountHistory userDetails={props?.selectedRecord} />
      ),
    },

    {
      key: "5",
      label: `Edit Profile`,
      children: (
        <AccountListEditProfile
          userDetails={props?.selectedRecord}
          submitted={props?.setMore}
          inactiveActiveUser={props?.onInactiveActiveUser}
        />
      ),
    },
    {
      key: "6",
      label: `Casino Allowed`,
      children: (
        <CasinoAllowed
          userDetails={props?.selectedRecord}
          submitted={props?.setMore}
          inactiveActiveUser={props?.onInactiveActiveUser}
          handleCancel={props?.handleCancel}
          userData={props?.userDetails}
        />
      ),
    },
  ];

  return (
    // <AccountListModal>
    // <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <div className={style["tab-list-profile"]}>
      <Tabs
        defaultActiveKey="1"
        type="card"
        items={items}
        onChange={onChange}
        destroyInactiveTabPane={true}
      />
    </div>

    // </AccountListModal>
  );
};
export default AccountListMore;
