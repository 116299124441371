import React, { useContext, useState } from 'react';
import style from './AccountListCR.module.scss';
import AccountListModal from '../AccountListModal/AccountListModal';
import ContextPortal from '../../../Context/PortalContext';
import { GrClose } from 'react-icons/gr';
import AccountListCRCreditHistory from './AccountListCRCreditHistory';
import AccountListCRCreditDeposit from './AccountListCRCreditDeposit';
import AccountListCRCreditWithdraw from './AccountListCRCreditWithdraw';

const AccountListCR = props => {
	const ctx = useContext(ContextPortal);

	const [isCreditDepositSelected, setIsCreditDepositSelect] = useState(true);
	const [isCreditWithdrawSelected, setIsCreditDepositSelected] = useState(false);
	const [isCreditHistorySelected, setIsCreditHistorySelected] = useState(false);

	const creditDepositHandler = () => {
		setIsCreditDepositSelect(true);
		setIsCreditDepositSelected(false);
		setIsCreditHistorySelected(false);
	};

	const creditWithdrawHandler = () => {
		setIsCreditDepositSelected(true);
		setIsCreditDepositSelect(false);
		setIsCreditHistorySelected(false);
	};

	const creditHistoryHandler = () => {
		setIsCreditHistorySelected(true);
		setIsCreditDepositSelect(false);
		setIsCreditDepositSelected(false);
	};

	return (
		<div className={style['main']}>
			<div className={style['heading']}>
				<p>Credit Activity</p>
			</div>
			<div className={style['credit-deposit_nav']}>
				<p onClick={creditDepositHandler} className={style[isCreditDepositSelected ? 'active-class' : '']}>
					Credit Deposit
				</p>

				<p onClick={creditWithdrawHandler} className={style[isCreditWithdrawSelected ? 'active-class' : '']}>
					Credit Withdraw
				</p>
				<p onClick={creditHistoryHandler} className={style[isCreditHistorySelected ? 'active-class' : '']}>
					Credit History
				</p>
			</div>
			{isCreditWithdrawSelected && <AccountListCRCreditWithdraw userDetail={props?.selectedRecord} withdrawDataSource={props?.dataSource} portalHidden={props?.CRPortlal} />}

			{isCreditDepositSelected && (
				<AccountListCRCreditDeposit userDetail={props?.selectedRecord} depositDataSource={props?.dataSource} portalHidden={props?.CRPortlal} inactiveActiveUser={props.inactiveActiveUser } />
			)}

			{isCreditHistorySelected && <AccountListCRCreditHistory crUserData={props?.selectedRecord} />}
		</div>
	);
};

export default AccountListCR;
