import React, { useEffect, useState } from "react";
import { DatePicker, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MatchLedger } from "../../../Redux/feature/MatchLedger/action";
import { selectMatchLedger } from "../../../Redux/feature/MatchLedger/selector";
import moment from "moment";
import dayjs from "dayjs";
import { useProfitLossLedgerQuery } from "../../../Services/CasinoService";
import { FaSpinner } from "react-icons/fa";

const ProfitLossHeader = () => {
  const timeBefore = moment().subtract(30, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);

  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
   
    {
      title: "Title",
      dataIndex: "matchName",
      key:"matchName"
    },
    {
      title: "CR",
      dataIndex: "netPnl",
      render: (text, record) => {
        return <span className={record?.netPnl > 0 && "text_success"}>{record?.netPnl > 0 ? record?.netPnl :"0"}</span>;
      },

    },
    {
      title: "DR",
      dataIndex: "netPnl",
      key: "netPnl",
      render: (text, record) => {
        return <span className={record?.netPnl < 0 && "text_danger"}>{record?.netPnl < 0 ? record?.netPnl :"0"}</span>;
      },
    },
  ];

  const { data, isFetching, isLoading } = useProfitLossLedgerQuery({
    startDate: startDate,
    endDate: endDate,
    index: 0,
    noOfRecords: 10000,
  }, {refetchOnMountOrArgChange: true});

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  isFetching || isLoading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  return (
    <>
      <div className="ledger_sec">
        <div className="sub_ledger">
          <div style={{ marginBottom: "5px" }}>Start Date</div>
          <DatePicker defaultValue={dayjs(timeBefore)} onChange={onStartDate} />
        </div>
        <div className="sub_ledger">
          <div style={{ marginBottom: "5px" }}>End Date</div>
          <DatePicker defaultValue={dayjs(time)} onChange={onEndDateDate} />
        </div>
        <div className="balnce_show">
          <div className="ledger_balance ">Total : <span className={data?.data?.total < 0?"text_danger":"text_success"}>{(data?.data?.total)?.toFixed(2)}</span></div>
          
        </div>
      </div>
      <div className="acc-cont">
        <div className="account_state_bottom"></div>
        <div className="accountlist-table-container">
          <Table
            columns={columns}
            pagination={{defaultPageSize: 50, pageSizeOptions:[50, 100, 150, 200, 250] }}
            // loading={selector?.loading}
            loading={tableLoading}
            dataSource={data?.data?.list}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    </>
  );
};

export default ProfitLossHeader;
