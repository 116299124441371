import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { Button, Radio, Table, Tooltip } from "antd";
import { AiFillEye } from "react-icons/ai";
import { axiosInstance } from "../../../Services/AxiosInstance";
import "./Pagination.scss";
import "./ViewButtonPortal.scss";
import Loading from "../../../Loading/Loading";
const ViewButtonPortal = ({
  matchId,
  sportId,
  record,
  selectedItem,
  userID,
}) => {
  const [sada, setsada] = useState("");
  const [radioValue, setRadioValue] = useState("matched");
  const [paginationData, setPaginationData] = useState({
    index: 0,
    noOfRecords: 100,
    totalPages: 1,
  });
  const [totalAmount, setTotalAmount] = useState("");
  const [sendSportId, setSendSportId] = useState("");
  const [sendEventId, setSendEventId] = useState("");
  const [DataList, setDataList] = useState([]);
  const [loading, setLoadiing] = useState(false);
  const tabledata = async () => {
    setLoadiing(true);
    setLoadiing(true);
    await axiosInstance
      .post(
        "/report/bet-history",
        {
          index: paginationData?.index,
          noOfRecords: paginationData?.noOfRecords,
          sportId: record,
          matchId: selectedItem,
          userId: userID,
          sportType: 2,
          isDeleted: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setsada(res?.data?.data?.totalBets);
        setTotalAmount(res?.data?.data?.totalStake);
        if (res?.data?.data?.dataList) {
          setPaginationData({
            ...paginationData,
            totalPages: res?.data?.data?.totalPages || 1,
          });
          setDataList(res?.data?.data?.dataList);
        } else {
          setDataList();
        }
      })
      .catch((error) => {
        // message.error(error.response.data.message);
        // if (error.response.status === 401) {
        //   localStorage.removeItem("token");
        //   navigate("/");
        //   message.error(error.response.data.message);
        // }
      });
    setLoadiing(false);
  };
  useEffect(() => {
    tabledata();
  }, [
    sendSportId,
    sendEventId,
    matchId,
    paginationData?.index,
    paginationData?.noOfRecords,
  ]);
  const data = useMemo(
    () =>
      DataList?.map((res, index) => {
        return {
          key: res?.rate + res?.time + res?.amount + index,
          isBack: res?.isback,
          EventType: res?.eventType,
          EventName: res?.eventNamem,
          UserName: res?.username,
          MName: res?.marketname,
          Nation: res?.nation,
          URate: res?.rate,
          Amount: res?.amount,
          PlaceDate: res?.time,
          Detail: (
            <>
              <Tooltip title={res?.deviceInfo}>
                <AiFillEye style={{ fontSize: "18px", cursor: "pointer" }} />
              </Tooltip>
            </>
          ),
        };
      }),
    [DataList]
  );
  const columns = [
    {
      title: "Event Type",
      dataIndex: "EventType",
    },
    {
      title: "Event Name",
      dataIndex: "EventName",
      //   filteredValue: [searchText],
      //   onFilter: (value, record) => {
      //     return (
      //       String(record.EventName)
      //         .toLowerCase()
      //         .includes(value.toLowerCase()) ||
      //       String(record.UserName).toLowerCase().includes(value.toLowerCase()) ||
      //       String(record.MName).toLowerCase().includes(value.toLowerCase()) ||
      //       String(record.Nation).toLowerCase().includes(value.toLowerCase()) ||
      //       String(record.URate).toLowerCase().includes(value.toLowerCase()) ||
      //       String(record.Amount).toLowerCase().includes(value.toLowerCase()) ||
      //       String(record.PlaceDate).toLowerCase().includes(value.toLowerCase())
      //     );
      //   },
    },
    {
      title: "User Name",
      dataIndex: "UserName",
    },
    {
      title: "M Name",
      dataIndex: "MName",
    },
    {
      title: "Nation",
      dataIndex: "Nation",
    },
    {
      title: "U Rate",
      dataIndex: "URate",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
    },
    {
      title: "Place Date",
      dataIndex: "PlaceDate",
    },
    {
      title: "Detail",
      dataIndex: "Detail",
    },
  ];
  const Decrement = () => {
    if (paginationData?.index > 0) {
      setPaginationData({
        ...paginationData,
        index: paginationData?.index - 1,
      });
    }
    // setPageIndex(PageIndex - 1);
  };
  const ResetCounter = () => {
    setPaginationData({ ...paginationData, index: 0 });
  };
  const LastCounter = () => {
    setPaginationData({
      ...paginationData,
      index: paginationData?.totalPages - 1,
    });
  };
  const Increment = () => {
    if (paginationData?.index < paginationData?.totalPages) {
      setPaginationData({
        ...paginationData,
        index: paginationData?.index + 1,
      });
    }
    // setPageIndex(PageIndex + 1);
  };
  if (!loading) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <label className="d-inline-flex align-items-center">
              Show&nbsp;
              <select
                className="custom-select-sm"
                value={paginationData?.noOfRecords}
                onChange={(e) =>
                  setPaginationData({
                    ...paginationData,
                    noOfRecords: Number(e.target.value),
                  })
                }
              >
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                <option value="2000">2000</option>
              </select>
              &nbsp;entries
            </label>
            {/* <div className="input-search" style={{ paddingRight: "10px" }}>
            <Input
              placeholder="search here....."
              name="message"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div> */}
          </div>
          {/* <h3>
            Total Soda: <span style={{ color: "green" }}>{sada}</span> Total
            Amount:<span style={{ color: "green" }}>{totalAmount}</span>
          </h3> */}
        </div>
        <div
          style={{ overflowY: "scroll", height: "400px" }}
          className="row-selector"
        >
          <Table
            columns={columns}
            dataSource={data}
            className="accountTable"
            rowClassName={(record) => {
              return record?.isBack ? "blue" : "pink";
            }}
            pagination={false}
          />
        </div>
        <div className="pagination">
          <ul className="pagination-rounded">
            <ul>
              <li
                role="presentation"
                aria-hidden="true"
                className="page-item disabled"
              >
                <span className="backward-pagination" onClick={ResetCounter}>
                  «
                </span>
              </li>
              <li className="page-item disabled">
                <span className="backward-pagination" onClick={Decrement}>
                  ‹
                </span>
              </li>
              <li role="presentation" className="page-item active">
                <button className="page-index-btn">
                  {paginationData.index + 1}
                </button>
              </li>
              <li className="page-item disabled">
                <span className="forward-pagination" onClick={Increment}>
                  ›
                </span>
              </li>
              <li className="page-item disabled">
                <span className="forward-pagination" onClick={LastCounter}>
                  »
                </span>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Loading />;
      </>
    );
  }
};
export default ViewButtonPortal;
