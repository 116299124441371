import React from "react";
// import "./styles.scss";
import Matchoddindex from "../../components/odds";
const OddsDetail = () => {
  return (
    <div className="odds-container">
      <div className="odds-left-col">
        <Matchoddindex />
      </div>
      <div className="odds-right-col"></div>
    </div>
  );
};

export default OddsDetail;
