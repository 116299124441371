import { Empty, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMCompleteFancy } from "../../../Redux/feature/CompleteFancy/selector";
import { useNavigate, useParams } from "react-router";
import { completeFancy } from "../../../Redux/feature/CompleteFancy/action";
import "./CompleteFancy.scss";
import { FaSpinner } from "react-icons/fa";

const CompleteFancy = ({ title, type }) => {
  const dispatch = useDispatch();
  const selector = useSelector(selectMCompleteFancy);

  const { id } = useParams();

  // const columns = [
  //   {
  //     title: "Title",
  //     dataIndex: "odds",
  //     key: "odds",
  //   },
  //   {
  //     title: "P&L",
  //     dataIndex: "stake",
  //     key: "stake",
  //   },
  //   {
  //     title: "Won By",
  //     dataIndex: "marketname",
  //     key: "marketname",
  //   },
  //   {
  //     title: "Net P&L",
  //     dataIndex: "matchname",
  //     key: "matchname",
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "selectionname",
  //     key: "selectionname",
  //   },
  // ];

  useEffect(() => {
    dispatch(
      completeFancy({
        matchid: id,
      })
    );
  }, [id]);

  const nav = useNavigate();

  const handleShowBet = (mid) => {
    nav(`/pl/live-report/${id}`, { state: { mid } });
  };


  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );


  return (
    <div>
      <div>
        <p className="match_bet">Completed fancy</p>
      </div>

      <div className="acc-cont">
      {selector?.loading ? 
      <Spin  indicator={antIcon} className="loading_active"  size="large">
            <div className="content" />
          </Spin>
          :<div className="accountlist-table-container">
          <table className="table_section">
            <tr>
              <th>Title</th>
              <th>P&L</th>
              <th>Won By</th>
              <th>Net P&L</th>
              <th>Action</th>
            </tr>
            {selector?.data?.data?.list?.length != 0 && (
              <tr>
                <td>Total</td>
                <td
                  className={
                    selector?.data?.data?.total?.pnl > 0
                      ? "text_success"
                      : selector?.data?.data?.total?.pnl < 0
                      ? "text_danger"
                      : ""
                  }>
                  {selector?.data?.data?.total?.pnl}
                </td>
                <td></td>
                <td
                  className={
                    selector?.data?.data?.total?.netpnl > 0
                      ? "text_success"
                      : selector?.data?.data?.total?.netpnl < 0
                      ? "text_danger"
                      : ""
                  }>
                  {selector?.data?.data?.total?.netpnl}
                </td>
                <td></td>
              </tr>
            )}

            {selector?.data?.data?.list?.map((res, id) => {
              return (
                <tr key={res?.id}>
                  <td>{res?.selectionname}</td>
                  <td
                    className={
                      res?.pnl > 0
                        ? "text_success"
                        : res?.pnl < 0
                        ? "text_danger"
                        : ""
                    }>
                    {res?.pnl}
                  </td>
                  <td>{res?.result}</td>
                  <td
                    className={
                      res?.netpnl > 0
                        ? "text_success"
                        : res?.netpnl < 0
                        ? "text_danger"
                        : ""
                    }>
                    {res?.netpnl}
                  </td>
                  <td>
                    <button
                      onClick={() => handleShowBet(res?._id)}
                      className="show_bet">
                      Show Bets
                    </button>
                  </td>
                </tr>
              );
            })}
            {(selector?.data?.data?.list === undefined ||
              selector?.data?.data?.list?.length === 0) && (
              <tr>
                {" "}
                <td colSpan={9}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            )}
          </table>

          {/* <Table
          columns={columns}
          style={{ cursor: "pointer" }}
          dataSource={selector?.data?.data?.list}
          pagination={false}
          onRow={(r) => ({
            onClick: () => nav(`/detail/${sportsId?.id || 4}/${r?.eventId}`),
          })}
        /> */}
        </div>}
        
      </div>
    </div>
  );
};

export default CompleteFancy;
