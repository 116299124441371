import React, { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/AxiosInstance";
import style from "./UserHistoryLogin.module.scss";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import UserHistoryLoginTable from "./UserHistoryLoginTable";
import Loading from "../../../Loading/Loading";
import { loadingRef } from "../../../App";

const UserHistoryLogin = () => {
  const [searchData, setSearchData] = useState([]);
  const [dateTo, setDateTo] = useState(dayjs());
  const [searchValue, setSearchValue] = useState("");
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, "day"));
  const [loginData, setloginData] = useState([]);

  const [tableRecords, setTableRecords] = useState(25);
  // const [accountStatementBody, setAccountStatementBody] = useState({
  //   noOfRecords: tableRecords,
  //   index: 0,
  //   totalPages: 1,
  // });
  const [loading, setLoading] = useState(true)
  const option = searchData?.map((curElm) => {
    return {
      label: `${curElm?.text} (${curElm?.userName})`,
      value: curElm?.text,
    };
  });

  const onRangeChange = (value, dateStrings) => {
    if (value) {
      setDateFrom(value);
    } else {
    }
  };

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    } else {
    }
  };

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setSearchData(res?.data?.data);
      });
  };
  useEffect(() => {
    const loadData = {
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      userId: "",
    };
    loginHistoryApi(loadData);
  }, []);

  const loginHistoryApi = async (data) => {
    loadingRef("historyreport", true);
    const endPoint = "/util/login-history-report";
    const response = await axiosInstance.post(endPoint, data);
    if (response?.data) {
      setloginData(response?.data?.data);
      if(response?.data?.status){
        setLoading(false)
      }
    }
    loadingRef("historyreport", false);
  };

  const [searchVal, setSearchVal] = useState("");

  const loadHandler = () => {
    const loadData = {
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userId: searchValue,
    };
    loginHistoryApi(loadData);
  };

  const resetHandler = () => {
    setDateFrom(dayjs().subtract(7, "day"));
    setDateTo(dayjs());
    setSearchValue("");
    setSearchVal("");
    const loadData = {
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      userId: "",
    };

    loginHistoryApi(loadData);
  };

  return (
    <>
      <div className={style["history_inputs"]}>
        {/* <div style={{display: "flex", justifyContent: "flex-start", width: "100%", gap: "10px", marginBottom: "10px",}}> */}
        <Select
          showSearch
          className={style["select-user"]}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase()?.includes(input?.toLowerCase())
          }
          optionFilterProp="children"
          onChange={(value) => setSearchValue(value)}
          onSearch={onSearch}
          options={option}
          placeholder="Select a person"
          // value={searchValue && searchValue}
        />

        <DatePicker
          value={dateFrom}
          onChange={onRangeChange}
          className={style["select-user"]}
          disabledDate={(d) =>
            !d ||
            d.isBefore(dayjs().subtract(2, "month")) ||
            d.isAfter(dateTo) ||
            d.isAfter(dayjs())
          }
        />

        <DatePicker
          value={dateTo}
          className={style["select-user"]}
          disabledDate={(d) =>
            !d ||
            d.isBefore(dayjs().subtract(2, "month")) ||
            d.isBefore(dateFrom) ||
            d.isAfter(dayjs())
          }
          onChange={onRangeChangeTo}
        />
        {/* </div> */}

        {/* <div style={{display: "flex", width: "100%", justifyContent: "flex-start", gap: "5px" }}> */}
        <div className={style["btn-div-container"]}>
          <button onClick={() => loadHandler()}>Load</button>
          <button className={style["reset"]} onClick={resetHandler}>
            Reset
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* <p>Show</p>
          <select
            defaultValue={25}
            value={tableRecords}
            onChange={(e) => setTableRecords(e.target.value)}
          >
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={200}> 200</option>
          </select>
          <p>Enteries</p> */}
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>Search:</label>
          <input
            placeholder="Search here..."
            type="text"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
      </div>
      {/* </div> */}

      <UserHistoryLoginTable
      loading={loading}
        loginData={loginData}
        searchVal={searchVal}
        tableRecords={tableRecords}
      />
    </>
  );
};

export default UserHistoryLogin;
