import React from 'react';
import colors from '../colors.module.scss';
import Header from './Header';

const MarketAnalysisLogo = () => {
	return (
		// <div style={{ background: colors.themeColor }}>
		<div>
			{/* <MarketAnalysisHeader /> */}
			<Header />
			{/* <MarketAnalysisTable /> */}
		</div>
	);
};

export default MarketAnalysisLogo;
