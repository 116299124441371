import React, { useState, useContext } from "react";
import AccountListModal from "../AccountListModal/AccountListModal";
import style from "./AccountListStatus.module.scss";
import { Slider, Switch } from "antd";
import AccountListButtons from "./AccountListButtons";
import ContextPortal from "../../../Context/PortalContext";
import { MdSettingsBackupRestore } from "react-icons/md";
import { VscDiffRenamed } from "react-icons/vsc";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import axiosInstance from '../../../Services/AxiosInstance';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

import axios from "axios";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListStatus = (props) => {
  const [error, setError] = useState(false);

  const [showPass, setShowPass] = useState(false);

  // const [userId, setUserId] = useState('');
  const [betLock, setBetLock] = useState(props?.onSelectedRecord?.betLock);
  const [accountLock, setAccountLock] = useState(
    props?.onSelectedRecord?.accountLock
  );
  const [casino, setCasinolock] = useState(props?.onSelectedRecord?.lcLock);
  const [virtualCasinoLock, setVirtualCasinoLock] = useState(false);
  const [liveCasinoLock, setLiveCasinoLock] = useState(false);
  const [lupassword, setLupassword] = useState("");
  // const [statusData, setStatusData] = useState([]);

  const ctx = useContext(ContextPortal);

  const passwordHandler = (e) => {
    setLupassword(e.target.value);
    if (lupassword) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const checkBoxHandler = async () => {
    loadingRef("updatebetaccountstatus", true);
    const endPoint = "/user/update-bet-account-status";
    await axiosInstance
      .post(endPoint, {
        userId: props?.onSelectedRecord?.userId,
        betLock,
        accountLock,
        isactive: props?.onSelectedRecord?.active,
        virtualCasinoLock: virtualCasinoLock,
        liveCasinoLock: casino,
        lupassword: lupassword,
      })
      .then((res) => {
        // setStatusData(res);
        if (res?.data?.status) {
          props?.inactiveActiveUser();
          toast.success(res?.data?.message);
          props.setMore(false);
        } else {
          // toast.error(res?.data?.message);
        }
      })

      .catch((err) => {
        if (err?.response?.status === 401) {
          localStorage.clear();
          // message.error(err.message);
        } else {
          loadingRef("updatebetaccountstatus", false);
          return toast.error(err?.response?.data?.message);
        }
      });
    loadingRef("updatebetaccountstatus", false);
  };

  const statusSubmitHandler = async (e) => {
    e.preventDefault();
    if (lupassword?.length === 0) {
      return setError("Password Field is required");
    }
    // if (!lupassword) {
    // 	return setError('');
    // }

    // if (!lupassword) {
    // 	return setError('Enter VAlid Paswsord');
    // }

    if (lupassword) {
      setError(false);
      checkBoxHandler();

      return;
    } else {
      setError(true);
    }
  };
  return (
    <>
      <div className={style["status_sub-heading"]}>
        <p style={{ fontSize: "20px" }}>{props?.onSelectedRecord?.username}</p>
        <p
          className={
            style[
              props?.onSelectedRecord?.active === true ? "active" : "inactive"
            ]
          }
          style={{ fontSize: "18px" }}
        >
          {props?.onSelectedRecord.active ? "Active" : "Inactive"}
        </p>
      </div>

      <div className={style["status_slider"]}>
        <div>
          <p>Live Casino</p>
          <Switch
            checked={casino}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={setCasinolock}
          ></Switch>
        </div>
        <div>
          <p>User Active</p>
          <Switch
            checked={accountLock}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={setAccountLock}
          ></Switch>
        </div>
        <div>
          <p>Bet Active</p>
          <Switch
            checked={betLock}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={setBetLock}
          ></Switch>
        </div>
      </div>
      <div className={style["status_same"]}>
        <label>Transaction Password</label>
        <input
          onChange={passwordHandler}
          type={showPass ? "text" : "password"}
          style={{ border: `1px solid ${error ? "red" : ""}`, outline: "none" }}
        />
        {lupassword?.length > 0 ? (
          showPass ? (
            <AiFillEye
              className={style["status-eye"]}
              onClick={() => setShowPass(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className={style["status-eye"]}
              onClick={() => setShowPass(true)}
            />
          )
        ) : (
          ""
        )}
      </div>
      {error && (
        <p
          style={{
            position: "relative",
            left: "40%",
            color: "red",
            bottom: "20px",
          }}
        >
          {error}
        </p>
      )}

      <div className={style["status_buttons"]}>
        <button
          className={style["status_submit"]}
          type="submit"
          onClick={statusSubmitHandler}
        >
          <span>Submit</span>
          <span className={style["submit_icon"]}>
            <VscDiffRenamed />
          </span>
        </button>
        <button className={style["status_back"]} onClick={ctx?.portalHidden}>
          <span className={style["icons"]}>
            <MdSettingsBackupRestore />
          </span>
          <span>Back</span>
        </button>
      </div>
    </>
  );
};

export default AccountListStatus;
