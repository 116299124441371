import { Provider } from "react-redux";
import "./App.scss";
import RoutesPages from "./routes/Routes";
import axios from "axios";
import { store } from "./Redux/store/Store";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router";
import { axiosInstance } from "./Services/AxiosInstance";
import { useEffect, useState } from "react";
import loader from "./assets/img/Loader.svg";
axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
export let nav;
export let loadingRef;
function App() {
  const [loading, setLoading] = useState({});
  const navigation = useNavigate();
  nav = navigation;
  const token = localStorage.getItem("token");

  const loadingObj = (name, value) => {
    setLoading((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  loadingRef = loadingObj;
  // if (token) {
  //   nav("user-login");
  // }

    document.title = (window.location.hostname).split(".")[1];

  return (
    <Provider store={store}>
      <ToastContainer />
      {/* {!Object.keys(loading).every((key) => loading[key] === false) && (
        <div className="loader-container">
          <img src={loader} alt="" height={60} width={60} />
        </div>
      )} */}
      <RoutesPages />
    </Provider>
  );
}

export default App;
