/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import style from "./CurrentBetSport.module.scss";
import CurrentBetsTable from "./CurrentBetsTable";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { Radio, Select } from "antd";
import Loading from "../../../Loading/Loading";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const CurrentBetSport = ({ betsHandler }) => {
  const [betsButton, setBetsButton] = useState(1);
  const [records, setRecords] = useState(50);
  const [isDeleted, setIsDeleted] = useState(false);
  const [sportsBetData, setSportsbetData] = useState([]);
  const [index, setIndex] = useState(0);

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });

  const handleOption = (e) => {
    const value = e;
    setIsDeleted(value);
  };
  // const handleOptionChange = event => {
  // 	const selectedOption = event.target.value;
  // 	setIsDeleted(selectedOption === 'Deleted');
  // };
  useEffect(() => {
    CurrentBetApi();
  }, [
    isDeleted,
    betsButton,
    records,
    accountStatementBody.index,
    accountStatementBody.noOfRecords,
  ]);

  const [loading, setIsLoading]= useState(true)

  const CurrentBetApi = async () => {
    loadingRef("currentbetSport", true);
    const endPoint = "report/current-bets";
    await axiosInstance
      .post(endPoint, {
        ...accountStatementBody,
        ...{
          betType: +betsButton,
          sportType: 1,
          isDeleted: isDeleted,
        },
      })
      .then((res) => {
        setSportsbetData(res?.data?.data?.dataList);
        if(res?.data?.status){
          setIsLoading(false)
        }else if(res?.data?.status == false){
          setIsLoading(false)

        }
        loadingRef("currentbetSport", false);
        setAccountStatementBody((prev) => {
          return {
            ...prev,
            totalPages: res?.data?.data?.totalPages,
          };
        });
      });
    loadingRef("currentbetSport", false);
  };

  const onChange = (e) => {
    setBetsButton(e.target.value);
  };



  return (
    <>
      <div className={style["current_bet-form"]} onSubmit={betsHandler}>
        <label>Choose Type</label>
        <div className={style["current_bet-bottom"]}>
          <div className={style["current_bet-select"]}>
            <Select
              className={style["sub_select"]}
              style={{ width: "200px", height:"100%" }}
              onChange={handleOption}
              value={isDeleted}
            >
              <Select.Option value={false}>Matched</Select.Option>
              <Select.Option value={true}>Deleted</Select.Option>
            </Select>
          </div>
          <div className={style["radio"]}>
            <Radio.Group onChange={onChange} value={betsButton}>
              <Radio value={1}>All</Radio>
              <Radio value={2}>Back</Radio>
              <Radio value={3}>Lay</Radio>
            </Radio.Group>
          </div>
        </div>
      </div>
      <CurrentBetsTable
        sportsBetData={sportsBetData}
        setRecords={setRecords}
        loading={loading}
        accountStatementBody={accountStatementBody}
        setAccountStatementBody={setAccountStatementBody}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
    </>
  );
};

export default CurrentBetSport;
