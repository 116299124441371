import { Link, useNavigate } from "react-router-dom";
import { useNavRef } from ".";
import { AccountList_Screen } from "../../../utils/linkConstant";
import { cashTran, commReport, dataReport, itemsData, ledgerData, liveMarketData, sportDetails } from "../header/DropDownItemsData";
import { showDrawerRef } from "../header/HeaderComponent";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}


export const items = [
  getItem(
    <span
      onClick={() => {
        showDrawerRef();
        // setClient(true);
        if (AccountList_Screen === window.location.pathname) {
          window.location.replace(AccountList_Screen);
        } else {
          useNavRef(AccountList_Screen);
        }
      }}
    >
      List Of Clients
    </span>,
    "sub1"
  ),
  getItem(
    <Link to={"/"} onClick={() => showDrawerRef()}>
      Market Analysis
    </Link>,
    "sub2"
  ),
  getItem(
    <Link to={"/sport-details"} onClick={() => showDrawerRef()}>
      Sport Details
    </Link>,
    "sub3"
  ),
  getItem("Sport Details", "sub4", " ", sportDetails),
  getItem("Cash Transaction", "sub5", " ", cashTran),
  getItem("Ledger", "sub6", " ", ledgerData),
  // getItem("Data Report", "sub8", " ", dataReport),
  getItem("Commission Report", "sub9", " ", commReport),
  getItem("Live Market", "sub10", " ", liveMarketData),
  getItem("Reports", "sub11", " ", itemsData),
];
