import React, { useEffect, useState } from "react";
import "./ActiveUser.scss";

// import axiosInstance from '../Services/AxiosInstance';
import ActiveUsersTable from "./ActiveUsersTable";
import { axiosInstance } from "../Services/AxiosInstance";
import Pagination from "../Pagination/Pagination";
import { loadingRef } from "../App";
import { useSearchParams } from "react-router-dom";

const ActiveUsers = ({ searchQuery, userId, setSearchQuery, antIcon, setIsLoading, isLoading }) => {

  const [activeClientData, setActiveClientData] = useState([]);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });

  // useEffect(() => {
  //   setAccountStatementBody((prev) => {
  //     return {
  //       ...prev,
  //       username: searchQuery,
  //     };
  //   });
  // }, [searchQuery]);

  const filteredData = activeClientData?.filter((item) =>
    item?.username?.toLowerCase().includes(searchQuery?.toLowerCase())
  );


  const sortedData = filteredData?.slice().sort((a, b) => a?.id - b?.id);

  const startRange = sortedData?.length > 0 ? 1 : 0;
  const endRange = sortedData?.length;
  const totalEntries = activeClientData?.length;

  const showFilteredText = searchQuery !== "";

  const enteriesText = `Showing ${startRange} to ${endRange} of ${endRange} entries ${
    showFilteredText ? `(filtered from ${totalEntries} total entries)` : ""
  }`;

  useEffect(() => {
    activeUserDataApi();
  }, [userId, searchQuery, accountStatementBody?.index]);

 
  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };



  const activeUserDataApi = async () => {
    loadingRef("userchildlistactiv", true);
    const baseUrl = "/user/child-list-active-user";
    await axiosInstance
      .post(baseUrl, {
        ...accountStatementBody,
        ...{ id: userId, username: searchQuery},
      })
      .then((res) => {
        setActiveClientData(res?.data?.data?.dataList);
        if(res?.data?.status){
          setIsLoading(false)
        }else if(res?.data?.status == false){
          setIsLoading(false)

        }
        loadingRef("userchildlistactiv", false);
        setAccountStatementBody((prev) => ({
          ...prev,
          totalPages: res?.data?.data?.totalPages,
        }));
      });
    loadingRef("userchildlistactiv", false);
  };

  // console.log("activeClientData")


  return (
    <>
      <div className="active_user-table">
        <ActiveUsersTable
          filteredData={sortedData}
          getRowClassName={getRowClassName}
          activeUserDataApi={activeUserDataApi}
          className="active-user-table"
          setSearchQuery={setSearchQuery}
          antIcon={antIcon}
          isLoading={isLoading}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "2px",
            marginBottom: "20px",
            width: "100%",
          }}
          >
          {enteriesText}
          <Pagination
            paginationData={accountStatementBody}
            setPaginationData={setAccountStatementBody}
          />
        </div>
      </div>
    </>
  );
};

export default ActiveUsers;
