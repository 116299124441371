import axios from 'axios';
import { toast } from 'react-toastify';
import { nav } from '../App';

// const USER_PASSWORD_RESET = process.env.REACT_APP_API_URL;

// const axiosInstance = axios.create({
// 	baseURL: USER_PASSWORD_RESET
// });

// export default axiosInstance;

export const axiosInstance = axios.create();

// axiosInstance.interceptors.response.use(config => {
// 	return config;
// });

axiosInstance.interceptors.response.use(
	function (config) {
		return config;
	},
	function (err) {

		if (err?.response?.status === 401) {
			localStorage.clear();
			nav('/user-login');
			// window.location.href = '/http://www.dm.com:3000/';
			// message.error(err.message);
		} else if (err?.response?.status !== 401) {
			return toast.error(err?.response?.data?.message);
		}
		// message.error(err?.response?.data?.message);

		return err;
	}
);
