import React from 'react';
import style from './MathOddsLeftRowBottom.module.scss';

const MathOddsLeftRowBottom = () => {
	const resultPredictionArray = [
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '1', amount: { min: 'Min:100', max: 'Max:1000000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:005000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:50000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } },
		{ result: 'SRH will win the toss bhav(SRH vs MI)adv', back: 195, lay: '', amount: { min: 'Min:100', max: 'Max:500000' } }
	];
	return (
		<div>
			<div className={style['bottom-para']}>
				<p>Fancy1 Market</p>
			</div>
			<table className={style['table']}>
				<thead>
					<tr>
						<th className={style['header']}></th>

						<th className={style['back']}>Back</th>
						<th className={style['lay']}>Lay</th>

						<th className={style['amount']}></th>
					</tr>
				</thead>
				<tbody>
					{/* <tr> */}
					{resultPredictionArray.map(data => (
						<tr key={data}>
							<td>
								{data.result}
								<p>0</p>
							</td>
							<td className={style['back']}>{data.back}</td>
							<td className={style['lay']}>{data.lay}</td>
							<div className={style['min-max']}>
								<td>{data.amount.min}</td>
								<td>{data.amount.max}</td>
							</div>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default MathOddsLeftRowBottom;
