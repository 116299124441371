import { createAsyncThunk } from "@reduxjs/toolkit";
import {  matchBetApi } from "../../../Services/ApiEndingPoint";
import { axiosInstance } from "../../../Services/AxiosInstance";


export const matchBet = createAsyncThunk('matchbet', async (payload, {rejectWithValue})=>{
    try{
        const response = await axiosInstance.post(matchBetApi, {...payload});
        if(response){
            return response?.data;
        }
    }catch(error){
        return rejectWithValue(error);
    }
})