import React, { useEffect, useState } from "react";
import style from "./BetsHistory.module.scss";
import BetsHistoryHeader from "./BetsHistoryHeader";
import BetsHistoryTable from "./BetsHistoryTable";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";

const BetsHistory = () => {
  // useEffect(() => {
  // 	betHistoryApi();
  // }, [tableRecords, betType]);

  // const betHistoryApi = async () => {
  // 	const endPoint = '/report/bet-history';
  // 	await axiosInstance
  // 		.post(endPoint, { index: 0, noOfRecords: +tableRecords, sportId: +record, matchId: matchId, userId: '', sportType: betType, isDeleted: deleted })
  // 		.then(res => {
  // 			setBetHistoryData(res?.data?.data?.dataList);
  // 		});
  // };

  // const userHistorySubmitHandler = async e => {
  // 	setLoading(true)

  // 	e.preventDefault();
  // 	const endPoint = 'sport/event-detail-sport-wise';

  // 	// const token = localStorage.getItem('token');
  // 	await axiosInstance
  // 		.post(
  // 			endPoint,
  // 			{ sportId: +record }
  // 			// { headers: { Authorization: `Bearer ${token}` } }
  // 		)
  // 		.then(res => {
  // 			setBetSportType(res?.data?.data);
  // 			setLoading(false)
  // 		});
  // };

  return (
    <>
      <BetsHistoryHeader

      // setIsDeleted={setIsDeleted}
      />
      {/* <BetsHistoryTable betHistoryData={betHistoryData} betSportType={betSportType} setTableRecords={setTableRecords} tableRecords={tableRecords} /> */}
    </>
  );
};

export default BetsHistory;
