import React from 'react';
import ReactDOM from 'react-dom';
import './Modal.scss';

const BackDrop = () => {
	return <div className="backdrop"></div>;
};

const ModalOverlay = props => {
	return <div className="modal">
		<div className=''>{ props.children}</div>
	</div>;
};

const portalElement = document.getElementById('overlay');

const Modal = props => {
	return (
		<React.Fragment>
			{ReactDOM.createPortal(<BackDrop />, portalElement)}
			{ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
		</React.Fragment>
	);
};

export default Modal;
