import React, { useState, useEffect } from "react";
import style from "./AccountListCR.module.scss";
// import axiosInstance from '../../../Services/AxiosInstance';

import { useFormik } from "formik";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListCRCreditWithdraw = (props) => {
  const [showPass, setShowPass] = useState(false);
  
  const [creditWithdraw, setCreditWithdraw] = useState([]);

 

  useEffect(() => {
    withdrawCRApi();
  }, []);

  const withdrawCRApi = async () => {
    loadingRef("creditdata", true);
    const endPoint = "dwc/creditdata";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        { userId: props?.userDetail?.userId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setCreditWithdraw(res.data.data);
      });
    loadingRef("creditdata", false);
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    resetForm,
    errors,
  } = useFormik({
    initialValues: {
      amount: "",
      remark: "",
      lupassword: "",
      userId: props?.userDetail?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        remark: "" == values?.remark ? "Remark field is required. " : undefined,
        amount: "" == values.amount ? "Amount field is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },
    onSubmit: async () => {
      const endPoint = "dwc/wcr";
      loadingRef("dwcwcr", true);
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status) {
          resetForm();
          props.portalHidden(false);
          return toast.success(response?.data?.message);
        } else {
          // return toast.error(response?.data?.message);
        }
      } catch (response) {
        return toast.error(response?.response?.data?.message);
      }
      loadingRef("dwcwcr", false);
    },
  });

  return (
    <form
      className={style["credit-deposit_portal-form"]}
      onSubmit={handleSubmit}
    >
      {/* <ToastContainer style={{ marginTop: '150px' }} /> */}

      <div className={style["same"]}>
        <label>{creditWithdraw?.parentName}</label>
        <input
          className={style["credit-deposit_portal-form_input"]}
          disabled
          type="number"
          value={creditWithdraw?.parentAmount}
        />
        <input
          className={style["credit-deposit_portal-form_input"]}
          type="text"
          // onChange={withdrawHandler}
          disabled
          value={
            values.amount
              ? Number(creditWithdraw?.parentAmount) + Number(values?.amount)
              : ""
          }
        />
      </div>

      <div className={style["same"]}>
        <label>{creditWithdraw?.childName}</label>
        <input
          className={style["credit-deposit_portal-form_input"]}
          disabled
          type="text"
          value={creditWithdraw?.childAmount}
        />
        <input
          className={style["credit-deposit_portal-form_input"]}
          type="number"
          disabled
          value={
            values.amount
              ? Number(creditWithdraw?.childAmount) - Number(values?.amount)
              : ""
          }
        />
      </div>

      <div className={style["same"]}>
        <label>Amount</label>
        <input
          placeholder="Amount"
          type="number"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.amount}
          name="amount"
          style={{
            border: `1px solid ${
              touched?.amount && errors?.amount ? "red" : ""
            }`,
            outline: "none",
          }}
        />
      </div>
      {touched?.amount && errors?.amount ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            bottom: "10px",
            fontSize: "13px",
          }}
        >
          {errors?.amount}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Remark</label>
        <textarea
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          name="remark"
          value={values.remark}
          style={{
            border: `1px solid ${
              touched?.remark && errors?.remark ? "red" : ""
            }`,
            outline: "none",
          }}
        />
      </div>
      {touched?.remark && errors?.remark ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            bottom: "10px",
            fontSize: "13px",
          }}
        >
          {errors?.remark}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Transaction Code</label>
        <input
          type={showPass ? "text" : "password"}
          name="lupassword"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.lupassword}
          style={{
            border: `1px solid ${
              touched?.lupassword && errors?.lupassword ? "red" : ""
            }`,
            outline: "none",
          }}
        />
        {values?.lupassword?.length > 0 ? (
          showPass ? (
            <AiFillEye
              className={style["pass-eye"]}
              onClick={() => setShowPass(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className={style["pass-eye"]}
              onClick={() => setShowPass(true)}
            />
          )
        ) : (
          ""
        )}
      </div>
      {touched?.lupassword && errors?.lupassword ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            bottom: "10px",
            fontSize: "13px",
          }}
        >
          {errors?.lupassword}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["credit-deposit_buttons"]}>
        <button type="submit" style={{ cursor: "pointer" }}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default AccountListCRCreditWithdraw;
