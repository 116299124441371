import React from "react";
// import "./matchOddsTable.scss";
import MathOddsRow from "./MathOddsRow";
import MatchOddsTitle from "./MatchOddsTitle";
const MatchOddsTable = () => {
  return (
    <>
      <MatchOddsTitle />
      <MathOddsRow />
    </>
  );
};

export default MatchOddsTable;
