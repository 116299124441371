import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import style from "./MarketAnalysisLeft.module.scss";
import "./style.scss";
import BookMarkPortal from "..//Portals/BookMarkPortal";
import { Switch, Modal, Collapse, Spin } from "antd";
import MarketAnalysisLeftTable from "./MarketAnalysisLeftTable";
import MarketAnalysisFancy from "../Fancy/MarketAnalysisFancy";
import { useLocation, useParams } from "react-router";
import axios from "axios";
import BookmakerTable from "./BookMakerTable/BookmakerTable";
import MatchOddsPortal from "../Portals/MatchOddsPortal";

import colors from "../../../colors.module.scss";
import { matchDataDataSelect } from "../../../Redux/feature/MatchData/selector";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { toast } from "react-toastify";
import UserBookPortal from "../Portals/UserBookPortal";
import { loadingRef } from "../../../App";
import { FaSpinner } from "react-icons/fa";
export let matchIdRef;
export let bookmakerIdRef;
const MarketAnalysisLeft = ({ setSatevalue, activeKey, setActiveKey }) => {
  const [betNumber, setBetNumber] = useState("");
  const [fancyPnl, setFancyPnl] = useState([]);
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const value = pathParts[2];

  const [showModal, setShowModal] = useState("");
  const [betData, setBetData] = useState(null);
  const [prevState, setPrevState] = useState(null);

  const [betLocked, setBetLocked] = useState(false);
  const [loading, setLoading] = useState(true);

  const [betStatus, setBetStatus] = useState([]);
  const response = useSelector(matchDataDataSelect);
  const sportsMatchTime = response?.data?.data?.map((t) => t?.time);

  const [data, setData] = useState([]);

  const userType = localStorage.getItem("userType");

  useEffect(() => {
    setSatevalue(betData);
  }, [betData]);

  const { Panel } = Collapse;
  const { id } = useParams();

  useEffect(() => {
    betNumberApi();
    const timer = setInterval(() => {
      betNumberApi();
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [id]);

  useEffect(() => {
    fancyOddPnl();
    const timer = setInterval(() => {
      fancyOddPnl();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const fancyOddPnl = async (e) => {
    const endPoint = "bets/fancy-pnl";
    const response = await axiosInstance.post(endPoint, { matchId: id });
    setFancyPnl(response?.data);
  };
  const betNumberApi = async () => {
    const endPoint = `https://oddsapi.247idhub.com/betfair_api/fancy/${id}`;
    const response = await axiosInstance.get(endPoint, {});
    setBetNumber(response);
    if (response?.data?.Odds.length > 0) {
      matchIdRef = response?.data.Odds[0].marketId;
    }
    if (response?.data?.Bookmaker?.length > 0) {
      bookmakerIdRef = response?.data?.Bookmaker[0]?.mid;
    }

    if (response?.status === 200) {
      setLoading(false);
      setBetData((betData) => {
        if (!betData) {
          setPrevState(response?.data);
        } else {
          setPrevState(betData);
        }
        return response?.data;
      });
    }
  };

  const betLockApi = async (marketId) => {
    loadingRef("betsaddremovemarketbetlock", true);
    const endpoint = "bets/add-remove-market-bet-lock";
    const response = await axiosInstance.post(endpoint, {
      matchId: id,
      marketName: marketId,
    });

    if (response?.data) {
      betlockSwitchButtonApi();
      toast.success(response?.data?.message);
    }
    loadingRef("betsaddremovemarketbetlock", false);
  };

  const betlockSwitchButtonApi = async () => {
    const endpoint = "bets/market-bet-lock-list";
    const response = await axiosInstance.post(endpoint, { matchId: id });
    setBetStatus(response?.data?.data);
  };

  const antIcon = <FaSpinner className="spin_roatet" />;

  const marketAnalysisFancySection = useMemo(() => {
    if (betData) {
      return (
        <div
          // style={{
          //
          // }}
          className="fancy-container">
          {Object?.keys(betData)?.map((curElm) => {
            if (
              curElm === "Fancy2" ||
              curElm === "Fancy3" ||
              curElm === "OddEven"
            ) {
              if (betData[curElm]?.length > 0) {
                return (
                  <>
                    <MarketAnalysisFancy
                      betStatus={betStatus}
                      onBetLockApi={betLockApi}
                      setShowModal={setShowModal}
                      betData={betData[curElm]}
                      keys={curElm}
                      prev={prevState[curElm]}
                      fancyPnl={fancyPnl}
                    />
                  </>
                );
              }
            }
          })}
        </div>
      );
    }
    return <></>;
  }, [betData]);

  if (!betData || !prevState) {
    return <></>;
  }
  const userBookApi = async (marketId) => {
    loadingRef("betsuserbook", true);
    const endPoint = "bets/user-book";
    const response = await axiosInstance.post(endPoint, {
      marketId: marketId,
      userId: "",
    });
    setData(response?.data?.data);
    loadingRef("betsuserbook", false);
  };

  return (
    <>
      {loading ? (
        <Spin indicator={antIcon} className="loading_active" size="large">
          <div className="content" />
        </Spin>
      ) : (
        <div className={style["left_table-cont"]}>
          <>
            <div className={style["left_table-heading"]}>
              <p className={style["heading"]}>
                <span>
                  {betData?.Odds[0]?.matchName
                    ? betData?.Odds[0]?.matchName
                    : ""}
                </span>
                <span>
                  {betData?.Odds[0]?.lastMatchTime
                    ? betData?.Odds[0]?.lastMatchTime
                    : ""}
                </span>
                <span>{betData?.Odds[0]?.eventTime}</span>
              </p>
            </div>

            <Collapse
              // defaultActiveKey={["1", "2"]}
              activeKey={activeKey}
              style={{
                background: colors.themeColor2,
                color: "whitesmoke",
                alignItems: "center",
                padding: "",
              }}
              onChange={(key) => setActiveKey(key)}
              // onClick={() => setActiveKey([item.marketId])}
            >
              {betData?.Odds?.map((item, index) => {
                if (item?.Name == "Tied Match") return <></>;
                return (
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "",
                          }}>
                          <span style={{ color: "white" }}>{item?.Name}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}>
                          {userType === "4" && (
                            <div
                              style={{
                                color: "white",
                                background: "#0088CC",
                                padding: "5px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                betLockApi(item?.marketId);
                              }}>
                              {betStatus?.find((res) => res == item?.marketId)
                                ? " Bet / Unlock"
                                : "Bet Lock"}
                            </div>
                          )}
                          <div
                            style={{
                              padding: "5px",
                              background: "#0088CC",
                              color: "white",
                            }}
                            onClick={(e) => {
                              setShowModal("userBook");
                              e.stopPropagation();
                              userBookApi(item?.marketId);
                            }}>
                            User Book
                          </div>
                        </div>
                      </div>
                    }
                    key={item.marketId}>
                    {prevState?.Odds && (
                      <MarketAnalysisLeftTable
                        onData={data}
                        data={item}
                        prev={prevState?.Odds[index]}
                        pnlData={"oddPnl"}
                        marketId={item?.marketId}
                        name={item?.Name}
                      />
                    )}
                  </Panel>
                );
              })}

              {betData?.Bookmaker?.filter((ele) => ele?.t !== "TOSS").length >
              0 ? (
                <Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <span style={{ color: "white", padding: "0" }}>
                          Bookmaker
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}>
                        {userType === "4" && (
                          <div
                            style={{
                              color: "white",
                              background: "#0088CC",
                              padding: "5px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              betLockApi(
                                betData?.Bookmaker?.find(
                                  (type) => type.t !== "TOSS"
                                ).mid
                              );
                            }}>
                            {betStatus?.find(
                              (res) =>
                                res ===
                                betData?.Bookmaker?.find(
                                  (type) => type.t !== "TOSS"
                                ).mid
                            )
                              ? "Bet / Unlock"
                              : "Bet Lock"}
                          </div>
                        )}
                        <div
                          style={{
                            padding: "5px",
                            background: "#0088CC",
                            color: "white",
                          }}
                          onClick={(e) => {
                            setShowModal("userBook");
                            e.stopPropagation();
                            userBookApi(
                              betData?.Bookmaker?.find(
                                (type) => type.t !== "TOSS"
                              ).mid
                            );
                          }}>
                          User Book
                        </div>
                      </div>
                    </div>
                  }
                  key="2">
                  {prevState?.Odds && (
                    <BookmakerTable
                      data={betData?.Bookmaker?.filter(
                        (ele) => ele?.t !== "TOSS"
                      )}
                      prev={prevState?.Bookmaker?.filter(
                        (ele) => ele?.t !== "TOSS"
                      )}
                    />
                  )}
                </Panel>
              ) : (
                ""
              )}

              {betData?.Odds?.map((item, index) => {
                if (item?.Name != "Tied Match") return <></>;
                return (
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "",
                          }}>
                          <span style={{ color: "white" }}>{item?.Name}</span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}>
                          {userType === "4" && (
                            <div
                              style={{
                                color: "white",
                                background: "#0088CC",
                                padding: "5px",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                betLockApi(item?.marketId);
                              }}>
                              {betStatus?.find((res) => res == item?.marketId)
                                ? " Bet / Unlock"
                                : "Bet Lock"}
                            </div>
                          )}
                          <div
                            style={{
                              padding: "5px",
                              background: "#0088CC",
                              color: "white",
                            }}
                            onClick={(e) => {
                              setShowModal("userBook");
                              e.stopPropagation();
                              userBookApi(item?.marketId);
                            }}>
                            User Book
                          </div>
                        </div>
                      </div>
                    }
                    key={item.marketId}>
                    {prevState?.Odds && (
                      <MarketAnalysisLeftTable
                        onData={data}
                        data={item}
                        prev={prevState?.Odds[index]}
                        pnlData={"oddPnl"}
                      />
                    )}
                  </Panel>
                );
              })}

              {betData?.Bookmaker?.length > 2 &&
              betData?.Bookmaker?.filter((ele) => ele?.t == "TOSS").length >
                0 ? (
                <Panel
                  header={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <span style={{ color: "white", padding: "0" }}>
                          Toss
                        </span>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}>
                        {userType === "4" && (
                          <div
                            style={{
                              color: "white",
                              background: "#0088CC",
                              padding: "5px",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              betLockApi(
                                betData?.Bookmaker?.find(
                                  (type) => type.t !== "TOSS"
                                ).mid
                              );
                            }}>
                            {betStatus?.find(
                              (res) =>
                                res ===
                                betData?.Bookmaker?.find(
                                  (type) => type.t !== "TOSS"
                                ).mid
                            )
                              ? "Bet / Unlock"
                              : "Bet Lock"}
                          </div>
                        )}
                        <div
                          style={{
                            padding: "5px",
                            background: "#0088CC",
                            color: "white",
                          }}
                          onClick={(e) => {
                            setShowModal("userBook");
                            e.stopPropagation();
                            userBookApi(
                              betData?.Bookmaker?.find(
                                (type) => type.t == "TOSS"
                              ).mid
                            );
                          }}>
                          User Book
                        </div>
                      </div>
                    </div>
                  }
                  key="3">
                  {prevState?.Odds && (
                    <BookmakerTable
                      data={betData?.Bookmaker?.filter(
                        (ele) => ele?.t !== "TOSS"
                      )}
                      prev={prevState?.Bookmaker?.filter(
                        (ele) => ele?.t == "TOSS"
                      )}
                    />
                  )}
                </Panel>
              ) : (
                ""
              )}

              {betData?.Odds?.filter(
                (item) =>
                  !["Match odds", "Tied Match", "Completed Match"].includes(
                    item?.Name
                  )
              ).map((item, index) => {
                if (!(item?.runners?.length > 0)) return <></>;
              })}
            </Collapse>

            {marketAnalysisFancySection}

            <Modal
              open={showModal}
              footer={null}
              destroyOnClose
              onCancel={() => setShowModal("")}
              className={style["modal-x"]}>
              {/* {showModal === 'completedSession' && <UserBookPortal betNumber={betNumber} />} */}
              {showModal === "userBook" && <UserBookPortal data={data} />}
              {showModal === "matchOdds" && <MatchOddsPortal />}
              {showModal === "bookMark" && <BookMarkPortal />}
              {/* {showModal === 'fancy' && <FancyBookPortal fancyBook={fancyBook} />} */}
            </Modal>
          </>

        </div>
      )}
    </>
  );
};

export default MarketAnalysisLeft;
