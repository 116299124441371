import React, { useState } from 'react';
import style from './Binary.module.scss';
import MarketAnalysisRight from '../../MarketAnalysis/BetDetails/MarketAnalysisRight';
import IframeContainer from '../IFrame/IframeContainer';

const Binary = () => {
	const binaryData = [
		{ name: 'Winner', item1: { fi: '02', se: '0' }, item2: { fi: '02', se: '0' }, item3: { fi: '02', se: '0' } },
		{ name: 'Black', item1: { fi: '02', se: '0' }, item2: { fi: '02', se: '0' }, item3: { fi: '02', se: '0' } },
		{ name: 'Red', item1: { fi: '02', se: '0' }, item2: { fi: '02', se: '0' }, item3: { fi: '02', se: '0' } },
		{ name: 'Odd', item1: { fi: '02', se: '0' }, item2: { fi: '02', se: '0' }, item3: { fi: '02', se: '0' } },
		{ name: 'Even', item1: { fi: '02', se: '0' }, item2: { fi: '02', se: '0' }, item3: { fi: '02', se: '0' } }
	];
	return (
		<div className={style['binary_cont']}>
			<div style={{ display: 'flex', flexDirection: 'column', width: '65%' }}>
				<div style={{ marginBottom: '10px' }}>{/* <IframeContainer /> */}</div>

				{/* <IframeContainer /> */}
				<div className={style['table']}>
					<div className={style['left']}>
						<div className={style['table_head']}>
							<p></p>
							<p>D</p>
							<p>T</p>
							<p>L</p>
						</div>
						<div className={style['table_data']}>
							{binaryData.map(data => {
								return (
									<div className={style['table-desc']} key={data}>
										<p style={{ display: 'flex', justifyContent: 'flex-start', padding: '15px 10px' }}>{data.name}</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item1.fi}</span>
											<span className={style['numb_bottom']}>{data.item1.se}</span>
										</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item2.fi}</span>
											<span className={style['numb_bottom']}>{data.item2.se}</span>
										</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item3.fi}</span>
											<span className={style['numb_bottom']}>{data.item3.se}</span>
										</p>
									</div>
								);
							})}
						</div>
					</div>
					<div className={style['right-table']}>
						<div className={style['table_head']}>
							<p></p>
							<p>D</p>
							<p>T</p>
							<p>L</p>
						</div>

						<div className={style['table_data']}>
							{binaryData.map(data => {
								return (
									<div className={style['table-desc']} key={data}>
										<p style={{ display: 'flex', justifyContent: 'flex-start', padding: '15px 10px', borderRight: '1px solid white' }}>{data.name}</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item1.fi}</span>
											<span className={style['numb_bottom']}>{data.item1.se}</span>
										</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item2.fi}</span>
											<span className={style['numb_bottom']}>{data.item2.se}</span>
										</p>
										<p className={style['numbers']}>
											<span className={style['numb_top']}>{data.item3.fi}</span>
											<span className={style['numb_bottom']}>{data.item3.se}</span>
										</p>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				<div>
					<p style={{ padding: '10px', background: '#7e685aB3', color: 'white', display: 'flex', justifyContent: 'space-between' }}>
						<span>Last Result</span>
						<span>View All</span>
					</p>
				</div>
			</div>
			<div className={style['right']}>
				{/* <MarketAnalysisRight /> */}
			</div>
		</div>
	);
};

export default Binary;
