import styles from './LoadingPlaceholder.module.scss';

// type LoaderVarient = "default" | "no-background";

export default function LoadingPlaceholder({ className, children, width = '100%', height = 'auto', varient = 'default', style = {} }) {
	return (
		<div style={{ ...style, width: width, height: height }} className={`${styles['load-wrapper']} ${className} ${styles[`varient-${varient}`]}`}>
            <div className={styles['activity']}>{children }</div>
		</div>
	);
}
