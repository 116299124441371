import React, { useCallback, useEffect, useState } from 'react';
import UserHistoryHeader from './UserHistoryHeader';
import axios from 'axios';
import UserHistoryTable from './UserHistoryTable';
import dayjs from 'dayjs';
import { axiosInstance } from '../../../Services/AxiosInstance';

const UserHistory = () => {
	const [searchData, setSearchData] = useState([]);
	const [dateTo, setDateTo] = useState(dayjs());
	const [searchValue, setSearchValue] = useState('');
	const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, 'day'));

	const [loginPasswordData, setLoginPasswordData] = useState([]);

	// const onRangeChange = (value, dateStrings) => {
	// 	if (value) {
	// 		setDateFrom(value);
	// 	} else {
	// 	}
	// };

	// const onRangeChangeTo = (value, dateStrings) => {
	// 	if (value) {
	// 		setDateTo(value);
	// 	} else {
	// 	}
	// };

	// const onSearch = useCallback(async value => {
	// 	await axiosInstance.post(`user/search-user-downline?term=${value}&_type=${value}&q=${value}`, {}).then(res => {
	// 		setSearchData(res?.data?.data);
	// 	});
	// }, []);

	// useEffect(() => {
	// 	loginPassHistoryApi('login');
	// }, []);

	// const loginPassHistoryApi = useCallback(
	// 	async data => {
	// 		let endPoint;

	// 		data === 'login' ? (endPoint = '/util/login-history-report') : (endPoint = '/util/password-change-history-report');

	// 		await axiosInstance.post(endPoint, { fromDate: dateFrom.format('YYYY-MM-DD'), toDate: dateTo.format('YYYY-MM-DD'), userId: searchValue }).then(res => {
	// 			setLoginPasswordData(res?.data?.data);
	// 		});
	// 	},
	// 	[dateFrom, dateTo, searchValue]
	// );

	return (
		<div>
			<UserHistoryHeader
				// onChange={value => setSearchValue(value)}
				// onRangeChangeTo={onRangeChangeTo}
				// onRangeChange={onRangeChange}
				// dateFrom={dateFrom}
				// dateTo={dateTo}
				// searchData={searchData}
			/>
			{/* <UserHistoryTable loginPasswordData={loginPasswordData} /> */}
		</div>
	);
};

export default UserHistory;
