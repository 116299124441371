import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Slider } from '../../../Services/ApiEndingPoint';
import { axiosInstance } from '../../../Services/AxiosInstance';

export const sportsDataSlider = createAsyncThunk('sportsDataSlider', async (payload, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(Slider, { ...payload });
		if (response) {
			return response.data;
		}
	} catch (error) {
		return rejectWithValue(error);
	}
});
