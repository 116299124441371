import { Switch } from "antd";
import React, { useState } from "react";
import style from "./AccountListEditProfile.module.scss";
import SubmitButton from "./SubmitButton";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListEditProfile = (props) => {
  const [showPass, setShowPass] = useState(false);

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      lupassword: "",
      userId: props?.userDetails?.userId,
      city: "",
      mobile: "",
      favMaster: false,
      username: "",
    },
    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        username: "" == values?.username ? "Name is required. " : undefined,
        city: "" == values?.city ? "City is required. " : undefined,
        mobile:
          "" == values?.mobile ? "Mobile Number is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },

    onSubmit: async () => {
      const endPoint = "user/update-user-info";
      loadingRef("userupdateusernfo", true);
      await axiosInstance
        .post(endPoint, values)

        .then((res) => {
          if (res.data.status === true) {
            props.inactiveActiveUser();
            resetForm();
            props.submitted(false);
            toast.success(res?.data?.message);
          } else {
            toast.error(res?.data?.message);
          }

          // setDepositStoredData(res);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message);
        });
      loadingRef("userupdateusernfo", false);
    },
  });

  return (
    // <div className={style['edit_profile-cont']}>
    <form className={style["edit_profile-form"]} onSubmit={handleSubmit}>
      <div className={style["edit_profile-field"]}>
        <label>Full Name</label>
        <input
          className={style["edit_profile-input"]}
          placeholder="Full name"
          onChange={handleChange}
          value={values.username}
          name="username"
          onBlur={handleBlur}
          style={{
            border: `1px solid ${
              touched.username && errors.username ? "red" : ""
            }`,
            outline: "none",
          }}
        />
      </div>
      {touched.username && errors?.username ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "31%",
            bottom: "20px",
            fontSize: "13px",
          }}
        >
          {errors.username}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["edit_profile-field"]}>
        <label>City</label>
        <input
          className={style["edit_profile-input"]}
          placeholder="City"
          onChange={handleChange}
          value={values.city}
          name="city"
          onBlur={handleBlur}
          style={{
            border: `1px solid ${touched.city && errors.city ? "red" : ""}`,
            outline: "none",
          }}
        />
      </div>
      {touched.city && errors?.city ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "31%",
            bottom: "20px",
            fontSize: "13px",
          }}
        >
          {errors.city}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["edit_profile-field"]}>
        <label>Mobile No.</label>
        <input
          className={style["edit_profile-input"]}
          placeholder="Mobile No."
          type="Number"
          onChange={handleChange}
          value={values.mobile}
          name="mobile"
          onBlur={handleBlur}
          style={{
            border: `1px solid ${touched.mobile && errors.mobile ? "red" : ""}`,
            outline: "none",
          }}
        />
      </div>
      {touched.mobile && errors?.mobile ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "31%",
            bottom: "20px",
            fontSize: "13px",
          }}
        >
          {errors.mobile}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["edit_profile-favorite"]}>
        <label>Favorite Master</label>
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={values.favMaster}
          onChange={(event, checked) => {
            setFieldValue("favMaster", values.favMaster ? false : true);
          }}
        ></Switch>
      </div>
      <div className={style["edit_profile-field"]}>
        <label>Transaction Code</label>
        <input
          type={showPass ? "text" : "password"}
          className={style["edit_profile-input"]}
          placeholder="Transaction Code"
          onChange={handleChange}
          value={values.lupassword}
          name="lupassword"
          onBlur={handleBlur}
          style={{
            border: `1px solid ${
              touched.lupassword && errors.lupassword ? "red" : ""
            }`,
            outline: "none",
          }}
        />
        {values?.lupassword?.length > 0 ? (
          showPass ? (
            <AiFillEye
              className={style["edit-profile-eye"]}
              onClick={() => setShowPass(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className={style["edit-profile-eye"]}
              onClick={() => setShowPass(true)}
            />
          )
        ) : (
          ""
        )}
      </div>
      {touched?.lupassword && errors?.lupassword ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "31%",
            bottom: "20px",
            fontSize: "13px",
          }}
        >
          {errors.lupassword}{" "}
        </span>
      ) : (
        ""
      )}
      <div className={style["edit_profile-button"]}>
        <button type="submit">Submit</button>
      </div>
    </form>
    // </div>
    // <SubmitButton />
  );
};

export default AccountListEditProfile;
