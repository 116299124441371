import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import style from './MathOddsRightRowModal.module.scss';

const MathOddsRightRowModal = props => {
	const modalArray = ['No', 'UserName', 'Nation', 'BetType', 'Amount', 'User Rate', 'Place Date', 'MatchDate', 'IP', 'Browser Details'];

	const [isMatchedBetVisible, setIsMatchedBetVisible] = useState(true);
	const [isUnMatchedBetVisible, setIsUnMatchedBetVisible] = useState(false);
	const [isBetDeletedVisible, setIsBetDeletedVisible] = useState(false);

	const matchedHandler = () => {
		setIsMatchedBetVisible(true);
		setIsUnMatchedBetVisible(false);
		setIsBetDeletedVisible(false);
	};

	const unMatchHandler = () => {
		setIsMatchedBetVisible(false);
		setIsUnMatchedBetVisible(true);
		setIsBetDeletedVisible(false);
	};

	const deleteHandler = () => {
		setIsMatchedBetVisible(false);
		setIsUnMatchedBetVisible(false);
		setIsBetDeletedVisible(true);
	};

	return (
		<Modal>
			<div className={style['modal-cont']}>
				<div className={style['modal-heading']}>
					<p>View More Bet</p>
					<button onClick={props.closeHandler}>Close</button>
				</div>
				<div className={style['nav-bar']}>
					<p onClick={matchedHandler} className={isMatchedBetVisible ? 'active-class' : 'para'}>
						Matched Bets
					</p>
					<p onClick={unMatchHandler} className={isUnMatchedBetVisible ? 'active-class' : 'para'}>
						UnMatched Bets
					</p>
					<p onClick={deleteHandler} className={isBetDeletedVisible ? 'active-class' : 'para'}>
						Deleted Bets
					</p>
				</div>
				{isMatchedBetVisible && (
					<form className={style['form']}>
						<div className={style['code-amount']}>
							<div className={style['code']}>
								<label>Enter Code</label>
								<input />
							</div>
							<div className={style['amount-div']}>
								<label>Amount</label>
								<input />
								<input />
							</div>
						</div>
						<div className={style['IP_Amount']}>
							<div className={style['ip-address']}>
								<label>IP Address</label>
								<input />
							</div>
							<div className={style['ip_amount-div']}>
								<label>Amount</label>
								<input />
								<button>Search</button>
								<button>View All</button>
							</div>
						</div>
					</form>
				)}

				<table className={style['modal_table']}>
					<thead className={style['modal_head']}>
						<tr>
							{modalArray.map(info => (
								<th key={info}>{info}</th>
							))}
						</tr>
					</thead>
					<tbody className={style['modal_body']}>
						<tr className={style['modal_body-tr']}></tr>
					</tbody>
				</table>
				<div className={style['records-div']}>
					<p className={style['records']}>No Records found</p>
				</div>
			</div>
		</Modal>
	);
};

export default MathOddsRightRowModal;
