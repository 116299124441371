import React, { useState } from 'react';
import style from './AddAccountForm.module.scss';
import AddAccountAccountDetails from './AddAccountAccountDetails';
import AddAccountPersonalDetails from './AddAccountPersonalDetails';
import Commission from './Commission';
import Partnership from './Partnership';
import AddAccountBottom from './AddAccountBottom';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { axiosInstance } from '../../Services/AxiosInstance';

const AddAccountForm = () => {
	const [reference, setReference] = useState('');
	const [exposure, setExposure] = useState('');
	const [clientName, setClientName] = useState('');
	const [userPassword, setUserPassword] = useState('');
	const [retypePass, setRetypePass] = useState('');
	const [fullName, setFullName] = useState('');
	const [phone, setPhone] = useState('');
	const [transPass, setTransPass] = useState('');

	//validation
	const [error, setError] = useState('');
	const [passError, setPassError] = useState('');

	//ACCOUNTDETAILS
	const [data, setData] = useState({
		userRole: ''
	});

	//PARTNERSHIP
	const partnership = localStorage.getItem('partnership');
	const [inputState, setInputState] = useState('');

	const referenceHandler = e => {
		const inputValue = e.target.value;
		const sanitizedValue = inputValue.replace(/[^\d+]/g, '');
		const withoutConsecutivePlus = sanitizedValue.replace(/(\++)+/g, '');
		setReference(withoutConsecutivePlus);
	};

	const exposureHandler = e => {
		const inputValue = e.target.value;
		const sanitizedExposureValue = inputValue.replace(/[^\d+]/g, '');
		const withoutPlus = sanitizedExposureValue.replace(/(\++)+/g, '');
		setExposure(withoutPlus);
	};

	const clientNameHandler = e => {
		setClientName(e.target.value);
		setError('');
	};

	const userPassHandler = e => {
		setUserPassword(e.target.value);
		setPassError('');
	};

	const retypePassHandler = e => {
		setRetypePass(e.target.value);
		setPassError('');
	};

	const fullNameHandler = e => {
		setFullName(e.target.value);
	};

	const transactionPassHandler = e => {
		setTransPass(e.target.value);
	};

	const onSumit = async function (e) {
		e.preventDefault();

		if (!clientName || clientName?.length < 4 || clientName?.length > 8 || !/\d/.test(clientName)) {
			return setError('minimum 4-8 letters and at least one number.');
		}

		if (userPassword?.length < 4 || userPassword?.length > 6) {
			return setPassError('Password should be between 4 and 6 characters.');
		}

		// if (validateGuidRegex.test(userPassword)) {
		// 	alert('musrt');

		// }

		if (userPassword !== retypePass) {
			return setPassError("Passwords doesn't match");
		}

		// if (!/^[a-zA-Z0-9!@#$%^&*()_+{}[\]:;<>,.?~]+$/.test(userPassword)) {
		// 	setPassError('Password can only contain letters, numbers, and a maximum of 2 special characters.');
		// 	return;
		// }

		const baseUrl = '/user/create-user-vg';
		const token = localStorage.getItem('token');

		const response = await axiosInstance.post(
			baseUrl,
			{
				username: fullName,
				city: '',
				lupassword: transPass,
				mobile: '',
				password: userPassword,
				userRole: data.userRole,
				appId: 1,
				userId: clientName,
				sportPartnership: inputState,
				liveCasinoLock: false,
				oddLossCommission: 0,
				fancyLossCommission: 0,
				creditRef: reference,
				netExposure: exposure
			},
			{ headers: { Authorization: `Bearer ${token}` } }
		);

		if (response?.data?.status === true) {
			toast.success(response?.data?.message);
			setReference('');
			setClientName('');
			setFullName('');
			setPhone('');
			setUserPassword('');
			setRetypePass('');
			setTransPass('');
			setExposure('');
			setInputState('');
		}
	};

	return (
		<>
			<form onSubmit={onSumit}>
				<div>
					<div className={style['sub-heading']}>
						<AddAccountPersonalDetails
							retypePass={retypePass}
							userPassword={userPassword}
							phone={phone}
							fullName={fullName}
							clientName={clientName}
							clientNameHandler={clientNameHandler}
							userPassHandler={userPassHandler}
							retypePassHandler={retypePassHandler}
							fullNameHandler={fullNameHandler}
							error={error}
							passError={passError}
							transPass={transPass}
						/>
						<AddAccountAccountDetails
							reference={reference}
							referenceHandler={referenceHandler}
							partnership={partnership}
							setData={setData}
							data={data}
							exposure={exposure}
							exposureHandler={exposureHandler}
						/>
					</div>

					<Commission />

					<Partnership partnership={partnership} inputState={inputState} setInputState={setInputState} data={data} />

					<AddAccountBottom transactionPassHandler={transactionPassHandler} transPass={transPass} setTransPass={setTransPass} />
				</div>
			</form>
		</>
	);
};

export default AddAccountForm;
