import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { items } from "./Items";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "./style.scss";
import { refreshRef, showDrawerRef } from "../header/HeaderComponent";
import {
  AURA_DETAILS,
  AccountList_Screen,
  SPORT_DETAILS,
  SUPERNOWA_DETAILS,
  Q_TECH,
  PROFIT_LOSS_EVENT,
  MY_LEDGER,
  LEDGER_SUPER,
  LEDGER_MASTER,
  LEDGER_AGENT,
  LEDGER_CLIENT,
  CASH_TRANSACTION_S,
  CASH_TRANSACTION_M,
  CASH_TRANSACTION_A,
  CASH_TRANSACTION_C,
  CASH_TRANSACTION_AD,
  REPORT_SUPER,
  REPORT_MASTER,
  REPORT_AGENT,
  REPORT_CLIENT,
  REPORT_ADMIN,
  COMM_REPORT_SUPER,
  COMM_REPORT_MASTER,
  COMM_REPORT_AGENT,
  COMM_REPORT_CLIENT,
  COMM_REPORT_ADMIN,
  ACC_STATEMENT,
  CURRENT_BETS,
  USER_HISTORY,
  BET_HISTORY,
  PROFIT_LOSS,
  LEDGER_ADMIN,
} from "../../../utils/linkConstant";
import { useSelfByAppUrlMutation } from "../../../Services/CasinoService";
export let useNavRef;
const MenuSider = () => {
  const nav = useNavigate();
  useNavRef = nav;

  const [openKeys, setOpenKeys] = useState(["sub1"]);
  const rootSubmenuKeys = ["sub1", "sub2", "sub3", "sub4"];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const userType = localStorage.getItem("userType");

  const [trigger, { data }] = useSelfByAppUrlMutation();

  useEffect(() => {
    trigger({
      appUrl: window.location.hostname.slice(6),
    });
  }, []);

  return (
    <div>
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultSelectedKeys={openKeys}
        items={[
          {
            key: "1",
            label: (
              <Link to="/dashboard" onClick={() => showDrawerRef()}>
                Dashboard
              </Link>
            ),
          },
          {
            key: "2",
            label: (
              <span
                onClick={() => {
                  showDrawerRef();
                  // setClient(true);
                  nav("/matchbox_active-user");
                }}>
                List Of Clients
              </span>
            ),
          },
          {
            key: "3",
            label: (
              <Link onClick={() => showDrawerRef()} to={"/market-analysis"}>
                Market Analysis
              </Link>
            ),
          },
          // {
          //   key: "4",
          //   label: (
          //     <Link to={"/sport-details"} onClick={() => showDrawerRef()}>
          //       Sport Details
          //     </Link>
          //   ),
          // },
          {
            key: "5",
            label: "Sports-Betting",
            children: [
              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={SPORT_DETAILS}>
                    Sports Detail
                  </Link>
                ),
              },
              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={AURA_DETAILS}>
                    Aura Detail
                  </Link>
                ),
              },

              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={SUPERNOWA_DETAILS}>
                    Super Nowa Detail
                  </Link>
                ),
              },
              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={Q_TECH}>
                    Qtech Detail
                  </Link>
                ),
              },
            ],
          },
          {
            key: "6",
            label: "Lena Dena",
            children: [
              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={PROFIT_LOSS_EVENT}>
                    Profit/Loss
                  </Link>
                ),
              },
              {
                label: (
                  <Link onClick={() => showDrawerRef()} to={MY_LEDGER}>
                    My Lena Dena
                  </Link>
                ),
              },
              {
                className: `${userType == 4 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={LEDGER_ADMIN}>
                    Sub Admin Lena Dena
                  </Link>
                ),
              },
              {
                className: `${userType != 5 ? "d-none" : ""}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={LEDGER_SUPER}>
                    Super Master Lena Dena
                  </Link>
                ),
              },
              {
                className: `${userType == 0 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={LEDGER_MASTER}>
                    Master Lena Dena
                  </Link>
                ),
              },
              {
                className: `${userType == 1 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={LEDGER_AGENT}>
                    Agent Lena Dena
                  </Link>
                ),
              },
              {
                className: `${userType == 4 ? "d-none" : ""}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={LEDGER_CLIENT}>
                    Client Lena Dena
                  </Link>
                ),
              },
            ],
          },
          {
            key: "7",
            label: "Cash Transaction",
            children: [
              {
                className: `${userType == 4 ? "" : "d-none"}`,
                label: (
                  <Link
                    onClick={() => showDrawerRef()}
                    to={CASH_TRANSACTION_AD}>
                    Debit/Credit Entry(SA)
                  </Link>
                ),
              },
              {
                className: `${userType == 5 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={CASH_TRANSACTION_S}>
                    Debit/Credit Entry(SM)
                  </Link>
                ),
              },
              {
                className: `${userType == 0 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={CASH_TRANSACTION_M}>
                    Debit/Credit Entry(M)
                  </Link>
                ),
              },
              {
                className: `${userType == 1 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={CASH_TRANSACTION_A}>
                    Debit/Credit Entry(A)
                  </Link>
                ),
              },
              // {
              //   className: `${userType == 4 ? "d-none" : ""}`,
              //   label: (
              //     <Link onClick={() => showDrawerRef()} to={CASH_TRANSACTION_C}>
              //       Debit/Credit Entry(C)
              //     </Link>
              //   ),
              // },
            ],
          },
          {
            key: "9",
            label: "Commission Report",
            children: [
              {
                className: `${userType != 4 ? " d-none " : ""}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={COMM_REPORT_ADMIN}>
                    Sub Admin
                  </Link>
                ),
              },
              {
                className: `${userType != 5 ? "d-none" : ""}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={COMM_REPORT_SUPER}>
                    Super Master
                  </Link>
                ),
              },
              {
                className: `${userType == 0 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={COMM_REPORT_MASTER}>
                    Master
                  </Link>
                ),
              },
              {
                className: `${userType == 1 ? "" : "d-none"}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={COMM_REPORT_AGENT}>
                    Agent
                  </Link>
                ),
              },
              {
                className: `${userType == 4 ? "d-none" : ""}`,
                label: (
                  <Link onClick={() => showDrawerRef()} to={COMM_REPORT_CLIENT}>
                    Client
                  </Link>
                ),
              },
            ],
          },
          {
            key: "10",
            label: "Report",
            children: [
              {
                label: (
                  <Link
                    onClick={() => {
                      refreshRef();
                      showDrawerRef();
                    }}
                    to={ACC_STATEMENT}>
                    Account's Statement
                  </Link>
                ),
              },
              {
                label: (
                  <Link
                    onClick={() => {
                      refreshRef();
                      showDrawerRef();
                    }}
                    to={CURRENT_BETS}>
                    Current bets
                  </Link>
                ),
              },
              {
                label: (
                  <Link
                    onClick={() => {
                      refreshRef();
                      showDrawerRef();
                    }}
                    to={USER_HISTORY}>
                    User History
                  </Link>
                ),
              },
              {
                label: (
                  <Link
                    onClick={() => {
                      refreshRef();
                      showDrawerRef();
                    }}
                    to={BET_HISTORY}>
                    Bet history
                  </Link>
                ),
              },
              // {
              //   label: (
              //     <Link
              //       onClick={() => {
              //         refreshRef();
              //         showDrawerRef();
              //       }}
              //       to={PROFIT_LOSS}>
              //       Profit And Loss
              //     </Link>
              //   ),
              // },
            ],
          },
          {
            key: "11",
            label: "Setting",
            children: [
              {
                className:`${data?.data?.selfAllowed ? "":"d-none"}`,
                label: (
                  <Link to="/set-commission" onClick={() => showDrawerRef()}>
                    Set Commission
                  </Link>
                ),
              },
              {
                label: (
                  <Link to="/markets" onClick={() => showDrawerRef()}>
                    Setting
                  </Link>
                ),
              },
            ],
            // label: (
            //   <Link to="/markets" onClick={() => showDrawerRef()}>
            //   Setting
            //   </Link>
            // ),
          },
        ]}
      />
    </div>
  );
};

export default MenuSider;
