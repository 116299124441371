import React, { useCallback, useEffect, useMemo, useState } from "react";
import style from "./AccountStatementHeader.module.scss";
import { DatePicker, Select } from "antd";

import dayjs from "dayjs";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { accountStatement } from "../../../Redux/feature/AccountStatement/action";
import { axiosInstance } from "../../../Services/AxiosInstance";

const AccountStatementHeader = ({
  setSearchUser,
  setTableRecords,
  submitHandler,
  setSearchId,
  accountType,
  dateTo,
  setDateTo,
  dateFrom,
  setDateFrom,
  setAccountType,
  tableRecords,
  searchId,
}) => {
  const [searchData, setSearchData] = useState([]);

  const initialTableRecords = 25;
  const initialAccountType = 1;

  const dispatch = useDispatch();

  const onRangeChange = (value, dateStrings) => {
    if (value) {
      setDateFrom(value);
    } else {
    }
  };

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    } else {
    }
  };

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setSearchData(res?.data?.data);
      });
  };

  const option = searchData?.map((curElm) => {
    return {
      value: curElm?.id,
      label: `${curElm?.text} (${curElm?.userName})`,
    };
  });

  const resetHandler = () => {
    setDateTo(dayjs());
    setDateFrom(dayjs().subtract(7, "day"));
    setSearchId("");
    setAccountType(initialAccountType);
    setTableRecords(initialTableRecords);
    setSearchUser("");
    setSearchData([]);

    dispatch(
      accountStatement({
        index: 0,
        noOfRecords: initialTableRecords,
        fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        toDate: dayjs().format("YYYY-MM-DD"),
        userid: "",
        type: initialAccountType,
      })
    );
  };

  return (
    <div className={style["account_state_form"]}>
      <div className={style["form-button-div"]}>
        <div className={style["game-name"]}>
          <label>Search By Client Name</label>
          <Select
            showSearch
            value={searchId || undefined}
            placeholder="Search a Client"
            optionFilterProp="children"
            onChange={(value) => setSearchId(value)}
            onSearch={onSearch}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={option}
          />
        </div>
        <div className={style["from-date"]}>
          <label>From</label>
          <DatePicker
            value={dateFrom}
            onChange={onRangeChange}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isAfter(dateTo) ||
              d.isAfter(dayjs())
            }
          />
        </div>
        <div className={style["to-date"]}>
          <label>To</label>
          <DatePicker
            style={{}}
            value={dateTo}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isBefore(dateFrom) ||
              d.isAfter(dayjs())
            }
            onChange={onRangeChangeTo}
          />
        </div>
        <div className={style["statement_type"]}>
          <label>Account Type</label>
          <Select
            onChange={(e) => setAccountType(e)}
            value={accountType}
            defaultValue={1}
          >
            <Select.Option value={1}>All</Select.Option>
            <Select.Option value={3}>Deposit/Withdraw Report</Select.Option>
            <Select.Option value={2}>Game Report</Select.Option>
          </Select>
        </div>
      </div>
      <div className={style["statement_buttons"]}>
        <button className={style["load"]} type="submit" onClick={submitHandler}>
          Load
        </button>
        <button className={style["reset"]} onClick={resetHandler}>
          Reset
        </button>
      </div>
    </div>
  );
};

export default AccountStatementHeader;
