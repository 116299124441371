import React, {  useEffect, useState } from "react";
import style from "./UserHistoryPassword.module.scss";
import dayjs from "dayjs";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { DatePicker, Select } from "antd";
import UserHistoryPasswordTable from "./UserHistoryPasswordTable";
import { loadingRef } from "../../../App";

const UserHistoryPassword = () => {
  const [searchData, setSearchData] = useState([]);
  const [dateTo, setDateTo] = useState(dayjs());
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, "day"));

  const [passwordResetData, setPasswordResetData] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [tableRecords, setTableRecords] = useState(25);

  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(true);

  const option = searchData?.map((curElm) => {
    return {
      label: `${curElm?.text} (${curElm?.userName})`,
      value: curElm?.text,
    };
  });

  const onRangeChange = (value, dateStrings) => {
    if (value) {
      setDateFrom(value);
    } else {
    }
  };

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    } else {
    }
  };

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setSearchData(res?.data?.data);
      });
  };

  useEffect(() => {
    const loadData = {
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userId: searchValue,
    };
    passwordHistoryApi(loadData);
  }, []);
  const passwordHistoryApi = async (data) => {
    loadingRef("changehistoryreport", true);
    const endPoint = "/util/password-change-history-report";
    const response = await axiosInstance.post(endPoint, data);
    setPasswordResetData(response?.data?.data);
    if(response.data.status){
      setLoading(false)
    }
    loadingRef("changehistoryreport", false);
  };

  const loadHandler = () => {
    const loadData = {
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userId: searchValue,
    };
    passwordHistoryApi(loadData);
  };

  const resethandler = () => {
    setDateFrom(dayjs().subtract(7, "day"));
    setDateTo(dayjs());
    setSearchValue([]);
    setSearchVal("");
    const loadData = {
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      userId: "",
    };

    passwordHistoryApi(loadData);
  };

  return (
    <>
      <div className={style["history_inputs"]}>
        <Select
          showSearch
          placeholder="Select a person"
          className={style["select-user"]}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase()?.includes(input?.toLowerCase())
          }
          optionFilterProp="children"
          onChange={(value) => setSearchValue(value)}
          onSearch={onSearch}
          options={option}
          // value={searchValue ? searchValue : "Select a person "}
          // style={{ width: "200px" }}
        />

        <DatePicker
          value={dateFrom}
          onChange={onRangeChange}
          className={style["select-user"]}
          disabledDate={(d) =>
            !d ||
            d.isBefore(dayjs().subtract(2, "month")) ||
            d.isAfter(dateTo) ||
            d.isAfter(dayjs())
          }
        />

        <DatePicker
          value={dateTo}
          className={style["select-user"]}
          disabledDate={(d) =>
            !d ||
            d.isBefore(dayjs().subtract(2, "month")) ||
            d.isBefore(dateFrom) ||
            d.isAfter(dayjs())
          }
          onChange={onRangeChangeTo}
        />

        <div className={style["btn-div-container"]}>
          <button onClick={loadHandler}>Load</button>
          <button className={style["reset"]} onClick={resethandler}>
            Reset
          </button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {/* <p>Show</p>
          <select
            defaultValue={25}
            value={tableRecords}
            onChange={(e) => setTableRecords(e.target.value)}
          >
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>200</option>
          </select>
          <p>Enteries</p> */}
        </div>
        <div>
          <label style={{ marginRight: "10px" }}>Search:</label>
          <input
            placeholder="Search here..."
            type="text"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
          />
        </div>
      </div>
      <UserHistoryPasswordTable
      loading={loading}
        passwordResetData={passwordResetData}
        tableRecords={tableRecords}
        searchVal={searchVal}
      />
    </>
  );
};

export default UserHistoryPassword;
