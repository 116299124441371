import React, {  useEffect, useState } from "react";
import style from "./CurrentBetCasino.module.scss";
import { Select } from "antd";
// import axiosInstance from '../../../Services/AxiosInstance';
import { axiosInstance } from "../../../Services/AxiosInstance";
import CurrentBetsCasinoTable from "./CurrentBetsCasinoTable";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const CurrentBetCasino = ({ setMatchId, matchId }) => {
  const [betsButton, setBetsButton] = useState(1);
  const [records, setRecords] = useState(50);
  const [isDeleted, setIsDeleted] = useState(false);
  const [currentBets, setCurrentBets] = useState([]);
  const [userId, setUserId] = useState("");
  const [userData, setUserData] = useState([]);
  // const [matchId, setMatchId]
  // const [casinoSelector, setCasinoSelector] = useState("");

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const handleOption = (e) => {
    const value = e;
    setIsDeleted(value);
  };
  const [casinoData, setCasinoData] = useState([]);
  const [casinoSelect, setCasinoSelect] = useState([]);
  const [loading, setIsLoading] = useState(true);

  const casinoSelectorHandler = (e) => {
    const endPoint = "casino/casino-tables-by-types";
    setMatchId("")
    axiosInstance
      .post(endPoint, { appUrl: window.location.hostname.slice(6), id: e })
      .then((res) => {
        setCasinoData(res?.data?.data);
     
      });
  };

  // useEffect(() => {
  // 	setLoading(true)
  // 	if (casinoSelector) {
  // 		const endPoint = 'casino/casino-tables-by-types';
  // 		axiosInstance
  // 			.post(
  // 				endPoint,
  // 				{ appUrl: 'admin.maggiexch.com', id: casinoSelector }
  // 			)
  // 			.then(res => {
  // 				setCasinoData(res?.data?.data);
  // 			});
  // 		setLoading(false)
  // 	}
  // }, [casinoSelector]);

  useEffect(() => {
    CasinoListApi();
  }, []);
  const CasinoListApi = async () => {
    loadingRef("allcasinoType", true);
    const endPoint = "casino/all-casino-types";
    const response = await axiosInstance.post(endPoint, {});
    loadingRef("allcasinoType", false);
    setCasinoSelect(response?.data?.data);
  };

  // useEffect(() => {
  // 	casinoSelectorHandler();
  // }, []);

  useEffect(() => {
    CurrentBetApi();
  }, [records, accountStatementBody.index, accountStatementBody.noOfRecords]);

  const CurrentBetApi = async () => {
    loadingRef("currentbetcasino", true);
    const endPoint = "report/current-bets";
    await axiosInstance
      .post(endPoint, {
        ...accountStatementBody,
        ...{
          betType: +betsButton,
          sportType: 2,
          isDeleted: isDeleted,
          userId: userId,
        },
      })
      .then((res) => {
        setCurrentBets(res?.data?.data?.dataList);
        if(res?.data?.status){
          setIsLoading(false)
        }else if(res?.data?.status == false){
          setIsLoading(false)

        }
        loadingRef("currentbetcasino", false);
        setAccountStatementBody((prev) => {
          return {
            ...prev,
            totalPages: res?.data?.data?.totalPages,
          };
        });
      });
    loadingRef("currentbetcasino", false);
  };

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setUserData(res?.data?.data);
      });
  };

  const option = userData?.map((el) => {
    return {
      label: `${el?.text} (${el?.userName})`,
      value: el.text,
    };
  });

  return (
    <>
      <div className={style["current_bet-form"]}>
        <label>Choose Type</label>
        <div className={style["current_bet-bottom"]}>
          <div className={style["current_bet-select"]}>
            <Select
              className={style["sub_select"]}
              onChange={(e) => handleOption(e)}
              value={isDeleted}
              style={{height:"100%"}}
            >
              <Select.Option value={false}>Matched</Select.Option>
              <Select.Option value={true}>Deleted</Select.Option>
            </Select>

            <Select
              // style={{ width: "200px" }}
              className={style["sub_select"]}
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              style={{height:"100%"}}
              onChange={(e) => setUserId(e)}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={option}
            />

            <Select
              placeholder="Select Casino"
              className={style["sub_select"]}
              style={{height:"100%"}}
              onChange={(e) => casinoSelectorHandler(e)}
            >
              {casinoSelect?.map((el) => {
                return (
                  <Select.Option key={el?.id} value={el?.id}>
                    {el?.name}
                  </Select.Option>
                );
              })}
            </Select>

            <Select
              placeholder="Casino List"
              className={style["sub_select"]}
              onChange={(e) => setMatchId(e)}
              style={{height:"100%"}}
              value={matchId?matchId:"Casino List"}
            >
              {casinoData?.map((casino) => {
                return (
                  <Select.Option key={casino} value={casino?.gameId}>
                    {casino?.gameName}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            height: "60px",
            marginBottom: "10px",
          }}
        >
          <button
            className={style["profit_loss-load"]}
            onClick={() => CurrentBetApi()}
            style={{
              padding: "8px 10px",
              fontSize: "14px",
              background: "#2C3E50",
              color: "white",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Load
          </button>
          {/* <button
							style={{ background: 'rgb(239, 242, 247)', color: 'black', border: '1px solid #d9d9d9', fontSize: '15px', padding: '.7rem, .65rem' }}
							onClick={resetHandler}>
							Reset
						</button> */}
        </div>
      </div>
      <CurrentBetsCasinoTable
        currentBets={currentBets}
        loading={loading}
        setRecords={setRecords}
        setAccountStatementBody={setAccountStatementBody}
        accountStatementBody={accountStatementBody}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
      />
    </>
  );
};

export default CurrentBetCasino;
