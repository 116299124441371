import React from "react";
import "./styles.scss";
import ButtonComp from "../button/Button";
import { Link } from "react-router-dom";
const AccountListHeader = () => {
  return (
    <>
      <div className="account-list-header">
        <p>Account List</p>
        <Link to="/market_analysis/Add-account">
          <ButtonComp name="Add Account" />
        </Link>
      </div>
    </>
  );
};

export default AccountListHeader;
