import { createAsyncThunk } from "@reduxjs/toolkit";
import {sportDetails} from '../../../Services/ApiEndingPoint'
import { axiosInstance } from "../../../Services/AxiosInstance";

export const SportDetails = createAsyncThunk('sportdetails', async(payload, {rejectWithValue})=>{
	try{
		const response = await axiosInstance.post(sportDetails, {...payload});
		if(response){
			return response?.data
		}
	}catch(error){
		return rejectWithValue(error)
	}
})