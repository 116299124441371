import React from 'react';
import style from './UserBookPortal.module.scss';
import { Table, Typography } from 'antd';

const MatchOddsPortal = ({ viewMoreData }) => {
	const { Text } = Typography;

	const columns = [{ title: '', dataIndex: '' }];

	return (
		<div className={style['portal_cont']}>
			<div className={style['heading']}>
				<p>Match Odds</p>
			</div>
			<Table
				columns={columns}
				dataIndex={viewMoreData}
				summary={pageData => {
					let totalBorrow = 0;
					pageData?.forEach(({ pnl }) => {
						totalBorrow += pnl.props.children;
					});

					return (
						<>
							<Table.Summary.Row>
								<Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
								<Table.Summary.Cell index={1}>
									<Text style={{ color: totalBorrow > 0 ? 'green' : 'red' }}>{totalBorrow?.toFixed(1)}</Text>
								</Table.Summary.Cell>
							</Table.Summary.Row>
						</>
					);
				}}
			/>
		</div>
	);
};

export default MatchOddsPortal;
