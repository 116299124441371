import React, { useEffect, useState } from "react";
import style from "./ProfitLossCasino.module.scss";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
// import axiosInstance from '../../../Services/AxiosInstance';
import ProfitLossCasinoTable from "./ProfitLossCasinoTable";
import { axiosInstance } from "../../../Services/AxiosInstance";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const ProfitLossCasino = ({setIndex}) => {
  // const [casinoSelector, setCasinoSelector] = useState([]);
  const [casinoData, setCasinoData] = useState([]);
  const [casinoSelect, setCasinoSelect] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [pnlTableData, setPnlTableData] = useState([]);
  const [userID, setUserID] = useState("");
  const [matchId, setMatchId] = useState("");
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, "day"));
  const [dateTo, setDateTo] = useState(dayjs());

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const onRangeChange = (value, dateStrings) => {
    if (value) {
      setDateFrom(value);
    } else {
    }
  };

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    } else {
    }
  };
  const option = profitLossData?.map((el) => {
    return {
      label: `${el?.text} (${el?.userName})`,
      value: el.text,
    };
  });

  const selectCasino = (value) => {
    const endPoint = "casino/casino-tables-by-types";
    setMatchId("");
    // const token = localStorage.getItem('token');
    axiosInstance
      .post(
        endPoint,
        { appUrl: window.location.hostname.slice(6), id: value }
        // { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setCasinoData(res?.data?.data);
      });
  };
  const [sportID, setSportID] = useState("");

  const getSportList = (value) => {
    setSportID(value);
    selectCasino(value);
  };
  useEffect(() => {
    const loadData = {
      matchId: "",
      pageNumber: accountStatementBody.index,
      pageSize: accountStatementBody.noOfRecords,
      noOfRecords: accountStatementBody.noOfRecords,
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userid: "",
      sportId: sportID || 323334,
    };
    pnlApi(loadData);
  }, [accountStatementBody.index, accountStatementBody.noOfRecords]);

  const pnlApi = async (data) => {
    loadingRef("profitlossmatchwise", true);
    const endPoint = "report/profit-loss-match-wise";
    const response = await axiosInstance.post(endPoint, data);
    setPnlTableData(response?.data?.data?.market);
    loadingRef("profitlossmatchwise", false);
    setAccountStatementBody((prev) => {
      return {
        ...prev,
        totalPages: response?.data?.data?.totalRecord,
      };
    });
    loadingRef("profitlossmatchwise", false);
  };

  useEffect(() => {
    CasinoListApi();
  }, []);
  const CasinoListApi = async () => {
    // setLoading(true);
    const endPoint = "casino/all-casino-types";
    // const token = localStorage.getItem('token');
    const response = await axiosInstance.post(
      endPoint,
      {}
      // { headers: { Authorization: `Bearer ${token}` } }
    );
    setCasinoSelect(response?.data?.data);
    // setLoading(false);
  };

  // const options = );

  const viewMoreApi = async () => {
    loadingRef("viewmorebethistory", true);
    const endPoint = "report/bet-history";
    const response = await axiosInstance.post(endPoint, {
      index: 0,
      isDeleted: true,
      matchId: matchId,
      noOfRecords: 100,
      sportId: "",
      sportType: "1",
      userId: "",
    });
    loadingRef("viewmorebethistory", false);
  };

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setProfitLossData(res?.data?.data);
      });
  };

  const loadHandler = () => {
    const loadData = {
      matchId: matchId,
      pageNumber: accountStatementBody.index,
      pageSize: accountStatementBody.noOfRecords,
      noOfRecords: accountStatementBody.noOfRecords,
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userid: userID,
      sportId: sportID || "323334",
    };
    pnlApi(loadData);
  };

  const resethandler = () => {
    setDateFrom(dayjs().subtract(7, "day"));
    setDateTo(dayjs());
    setUserID("");
    setCasinoData([]);
    setMatchId("");
    const loadData = {
      matchId: "",
      pageNumber: accountStatementBody.index,
      pageSize: accountStatementBody.noOfRecords,
      noOfRecords: accountStatementBody.noOfRecords,
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      userid: "",
      sportId: sportID || "323334",
    };
    pnlApi(loadData);
  };

  return (
    <div className={style["cont"]}>
      <div className={style["profit_loss-cont"]}>
        <div className={style["search"]}>
          <label>Search by Client Name</label>

          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={(e) => setUserID(e)}
            onSearch={onSearch}
            value={userID || "Select a person"}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={option}
          />
        </div>
        <div className={style["from-date"]}>
          <label>From</label>
          <DatePicker
            value={dateFrom}
            onChange={onRangeChange}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isAfter(dateTo) ||
              d.isAfter(dayjs())
            }
          />
        </div>
        <div className={style["to-date"]}>
          <label>To</label>
          <DatePicker
            value={dateTo}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isBefore(dateFrom) ||
              d.isAfter(dayjs())
            }
            onChange={onRangeChangeTo}
          />
        </div>
        {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}> */}
        {/* <select
				style={{ width: '15%', padding: '5px', height: '30px', marginTop: '25px', borderRadius: '5px', border: '1px solid grey' }}
				className={style['sub_select']}
				defaultValue="Check Your Casnio"
				value={record}
				onChange={e => setRecord(e.target.value)}>
				{casinoSelect?.map(el => (
					<option value={el.id}>{el.name}</option>
				))}
			</select> */}
        <Select
          placeholder="Select Casino"
          className={style["to-date"]}
          onChange={(value) => getSportList(value)}
          value={sportID || "Select Casino"}
        >
          {/* <Select.Option>Select Casino</Select.Option> */}
          {casinoSelect?.map((casino) => {
            return (
              <Select.Option key={casino?.id + casino?.name} value={casino?.id}>
                {casino?.name}
              </Select.Option>
            );
          })}
        </Select>

        <Select
          placeholder="Casino List"
          className={style["to-date"]}
          onChange={(e) => setMatchId(e)}
          value={matchId || "Casino List"}
        >
          {casinoData?.map((casino) => {
            return (
              <Select.Option key={casino?.gameId} value={casino?.gameId}>
                {casino?.gameName}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      {/* <div> */}
      <div
        style={{
          display: "flex",
          gap: "10px",
          width: "100%",
          alignItems: "center",
          height: "60px",
          marginBottom: "15px",
        }}
      >
        <button
          className={style["profit_loss-load"]}
          onClick={() => loadHandler()}
        >
          Load
        </button>
        <button
          style={{
            background: "rgb(239, 242, 247)",
            color: "black",
            border: "1px solid #d9d9d9",
            fontSize: "15px",
            padding: "10px 15px",
            cursor: "pointer",
          }}
          onClick={resethandler}
        >
          Reset
        </button>
      </div>
      {/* </div> */}
      <ProfitLossCasinoTable
        setTableRecords={setAccountStatementBody}
        tableRecords={accountStatementBody}
        onViewMoreApi={viewMoreApi}
        pnlTableData={pnlTableData}
        userID={userID}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
    </div>
  );
};

export default ProfitLossCasino;
