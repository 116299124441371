// import { Switch } from "antd";
// import React, { useEffect, useState } from "react";
// import SubmitButton from "./SubmitButton";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
// import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import { axiosInstance } from "../../../Services/AxiosInstance";
// import { loadingRef } from "../../../App";
// import {
//   useGetCasinoQuery,
//   useUpdateCasinoMutation,
// } from "../../../Services/CasinoService";
// import "./CasinoAllowed.scss";
// import style from "./SubmitButton.module.scss";

// const CasinoAllowed = (props) => {
//   const [showPass, setShowPass] = useState();
//   const [erroshow, setErrorShow] = useState(false)
  // const [state, setState] = useState({
  //   isAuraAllowed: "",
  //   isSuperNovaAllowed: "",
  //   isQTechAllowed: "",
  //   isVirtualAllowed: "",
  //   isSportBookAllowed: "",
  // });
//   const { data } = useGetCasinoQuery(
//     {
//       userId: props?.userDetails?.userId,
//     },
//     { refetchOnMountOrArgChange: true }
//   );

//   useEffect(() => {
//     data?.data?.map((key) => {
//       setState((prev) => {
//         return {
//           ...prev,
//           [`is${key.casinoName}Allowed`]: key.casinoAllowed,
//         };
//       });
//     });
//   }, [data?.data]);

//   const userType = localStorage.getItem("userType");

//   const [trigger, { data: update }] = useUpdateCasinoMutation();

//   const handleInpvalue = (e) => {
//     setShowPass(e.target.value);
//     setErrorShow(false)
//   };

//   const handleSubmit = () => {

//     if (showPass?.length != 0) {
//       trigger({
//         ...state,
//         lupassword: showPass,
//         liveCasinoLock: false,
//         userId: props?.userDetails?.userId,
//       });
//       props.handleCancel();
//     }
//     if(showPass?.length == undefined){
//         setErrorShow(true)
//     }
//   };

//   console.log(update?.message, "dssadfasda")

//   useEffect(()=>{
//     toast.success(update?.message);
//   }, [update?.status])

//   console.log(erroshow, "sfdsfsfdsfs");


//   return (
//     <>
//       <div className="form">
//         <div className="casino_details usercasino_lock">
//           {data?.data?.map((item, id) => {
//             return (
//               <div className="sub_user_lock" key={id}>
//                 <div className="casino_name">{item?.casinoName}</div>
//                 <div className="casino_switch">
//                   <Switch
//                     defaultChecked={item?.casinoAllowed}
//                     disabled={userType == 4 ? false : true}
//                     onChange={(e) =>
//                       setState((prev) => {
//                         return {
//                           ...prev,
//                           [`is${item.casinoName}Allowed`]: e,
//                         };
//                       })
//                     }
//                   />
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//         {
//             userType == 4 && <>
//             <div className="tra_user">
//           <label>Transaction Password</label>
//           <input
//             style={{ marginLeft: "12px" }}
//             type="password"
//             placeholder="transaction Password"
//             name="lupassword"
//             className="trans_pass"
//             onChange={(e) => handleInpvalue(e)}
//           />
//         </div>
//         {(showPass?.length == 0 || erroshow) && (
//           <p className="error_msg">Transaction Password is required</p>
//         )}
//         <button
//           type="submit"
//           style={{
//             float: "right",
//             marginBottom: "10px",
//             background: "black",
//             padding: "10px",
//             color: "white",
//             border: "none",
//             borderRadius: "5px",
//           }}
//           onClick={handleSubmit}>
//           Submit
//         </button>
//             </>
//         }
        
//       </div>
//     </>
//   );
// };

// export default CasinoAllowed;



import { Button, Input, Switch } from "antd";
import axios from "axios";
import "./CasinoAllowed.scss";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetCasinoMutation, useUpdateCasinoMutation } from "../../../Services/CasinoService";
const CasinoAllowed = (props) => {
  const [casinoData, setCasinoData] = useState([]);
  const [passwordError, setPasswordError] = useState(false);
  const [dataw, setData] = useState({
    isAuraAllowed: true,
    isSuperNovaAllowed: true,
    isQTechAllowed: true,
    isVirtualAllowed: true,
    isSportBookAllowed: true,
    lupassword: "",
    liveCasinoLock: false,
    userId: props?.userDetails?.userId,
  });

  const [handleCasinoLock, { data: update }] = useUpdateCasinoMutation();

  const submitCasinoLock = async (e) => {
    if (dataw.lupassword) {
      setPasswordError(false);
      handleCasinoLock(
        dataw
      )
    } else {
      setPasswordError(true);
    }
  };

  

  const [trigger , { data: casinoLock }] = useGetCasinoMutation();

    useEffect(()=>{
    toast.success(update?.message);
  }, [update?.status])

    useEffect(() => {
      casinoLock?.data?.map((key) => {
      setData((prev) => {
        return {
          ...prev,
          [`is${key.casinoName}Allowed`]: key.casinoAllowed,
        };
      });
    });
  }, [casinoLock?.data]);


  useEffect(() => {
    trigger({userId: props?.userDetails?.userId});
  }, []);



  return (
    <div>
      <div className="form" style={{ padding: "10px" }}>
        {casinoLock?.data?.map((res) => {
          return (
            <div className="row-1">
              <label>{res.casinoName}</label>
              <div
                className="input"
                style={{ justifyContent: "left !important" }}
              >
                <Switch
                  size="small"
                  name={res.casinoName}
                  checked={dataw[`is${res.casinoName}Allowed`]}
                  onChange={(e) =>
                    setData((prev) => {
                      return {
                        ...prev,
                        [`is${res.casinoName}Allowed`]: e,
                      };
                    })
                  }
                />
              </div>
            </div>
          );
        })}
        <div className="row-1">
          <label>
            <Input
              placeholder="Transaction Password"
              type="password"
              style={{ border: passwordError && "1px solid red" }}
              onChange={(e) => {
                if (e.target.value) {
                  setPasswordError(false);
                } else {
                  setPasswordError(true);
                }
                setData((prev) => {
                  return {
                    ...prev,
                    lupassword: e.target.value,
                  };
                });
              }}
            />
          </label>
          <div className="input" style={{ justifyContent: "left !important" }}>
            <Button
              style={{ backgroundColor: "#23292E", color: "white" }}
              onClick={() => submitCasinoLock()}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CasinoAllowed;
