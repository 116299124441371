import React, { useEffect, useState } from "react";
import style from "../AccountStatement/AccountStatementHeader.module.scss";
import { Button, DatePicker, Form, Input, Select } from "antd";

import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { cashTransaction } from "../../../Redux/feature/CashTransaction/action";
import CashTransactionTable from "./CashTransactionTable";
import { cashTransactionTable } from "../../../Redux/feature/CashTransaction/selector";
import moment from "moment";
import { useFilterByUserMutation } from "../../../Services/CasinoService";


const CashTransationsHeader = ({
  setSearchId,
  dateTo,
  setDateTo,
  dateFrom,
  searchId,
  usertype,
}) => {
  const [searchData, setSearchData] = useState([]);
  const time = moment().format("YYYY-MM-DD");
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const selctor = useSelector(cashTransactionTable);

  const [trigger, { data: userDetais, isLoading }] = useFilterByUserMutation();

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    }
  };

const pathName = window.location.pathname;

  useEffect(() => {
    setSearchId('');
    trigger(
      { 
        userId: "", 
        userType: usertype 
      }
    );
    form.resetFields();
  }, [usertype, pathName]);

  const onSearch = async (value) => {
    trigger({ userId: value, userType: usertype });
    setSearchData(userDetais?.data);
  };

  const option = userDetais?.data?.map((curElm) => { 
    return {
      value: curElm?.userId,
      label: `${curElm?.userId}  (${curElm?.userName})`,
    };
  });

  const onFinish = (value) => {
    dispatch(
      cashTransaction({
        userId: value?.clientId,
        collection: value?.collection,
        amount: value?.amount,
        paymenttype: value?.paymentType,
        remarks: value?.rermark,
      })
    );
  };

  return (
    <>
      <div className={style["account_state_form"]}>
        <Form onFinish={onFinish} autoComplete="off" form={form} className="cash_form">
          <div className={style["form-button-div"]}>
            <div className={style["game-name"]}>
              <Form.Item
                label="Search By Client Name"
                name="clientId"
                rules={[
                  {
                    required: true,
                    message: "Client name is required!",
                  },
                ]}>
                <Select
                  showSearch
                  value={searchId || undefined}
                  placeholder="Search a Client"
                  optionFilterProp="children"
                  onChange={(value) => setSearchId(value)}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={option}
                />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item
                label="Collection"
                name="collection"
                rules={[
                  {
                    required: true,
                    message: "Collection is required!",
                  },
                ]}>
                <Select
                  placeholder="Search Cash A/C"
                  options={[
                    {
                      value: "CASH",
                      label: "CASH A/C",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item label="Date" name="date">
                <DatePicker
                  className="range"
                  style={{}}
                  value={dateTo}
                  allowClear={false}
                  disabledDate={(d) =>
                    !d ||
                    d.isBefore(dayjs().subtract(2, "month")) ||
                    d.isBefore(dateFrom) ||
                    d.isAfter(dayjs())
                  }
                  defaultValue={dayjs(time)}
                  onChange={onRangeChangeTo}
                />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[
                  {
                    required: true,
                    message: "Amount is required!",
                  },
                ]}>
                <Input type="number" placeholder="Enter Amount" />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item
                label="Payment Type"
                name="paymentType"
                rules={[
                  {
                    required: true,
                    message: "Payment Type is required!",
                  },
                ]}>
                <Select
                  placeholder="Select Payment Type"
                  options={[
                    {
                      value: "Diya",
                      label: "Payment - Diya",
                    },
                    {
                      value: "Liya",
                      label: "Payment - Liya",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item
                label="Remark"
                name="rermark"
                rules={[
                  {
                    required: true,
                    message: "Remark is required!",
                  },
                ]}>
                <Input type="text" placeholder="Remark" />
              </Form.Item>
            </div>
            <div className={style["game-name"]}>
              <Form.Item wrapperCol={{}}>
                <Button loading={selctor?.loading} className="submit_btn" type="" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
            <div className={style["game-name"]}></div>
            <div className={style["game-name"]}></div>
            <div className={style["game-name"]}></div>
          </div>
        </Form>
      </div>
      <CashTransactionTable
        status={selctor?.status}
        loading={selctor?.loading}
        searchId={searchId}
        userType={usertype}
        isLoading={isLoading}
      />
    </>
  );
};

export default CashTransationsHeader;
