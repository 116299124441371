import React, { useState } from "react";
import style from "./Status.module.scss";
import { VscDiffRenamed } from "react-icons/vsc";
import { MdSettingsBackupRestore } from "react-icons/md";
import { Switch, message } from "antd";
import colors from "../../../colors.module.scss";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import {  toast } from "react-toastify";
import { axiosInstance } from "../../../Services/AxiosInstance";
const Status = ({ selectedItem, inactiveUserdataApi, onSetShowModal }) => {
  const [error, setError] = useState(false);
  const [password, setPassword] = useState("");
  const [betLock, setBetLock] = useState(selectedItem?.betLock);
  const [accountLock, setAccountLock] = useState(selectedItem?.accountLock);

  const passwordHandler = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value) {
      setError(false);
    } else {
      setError(true);
    }
  };
  const checkBoxHandler = async (e) => {
    e.preventDefault();
    const endPoint = "/user/update-bet-account-status";
    // const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post(endPoint, {
        userId: selectedItem?.userId,
        betLock,
        accountLock,
        isactive: selectedItem?.active,
        virtualCasinoLock: selectedItem.vcLock,
        liveCasinoLock: selectedItem.lcLock,
        lupassword: password,
      });
      if (response?.data?.status) {
        inactiveUserdataApi();
        onSetShowModal(false);
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    // setStatusData(res);
    if (password) {
      setError(false);
      // checkBoxHandler();
    } else {
      setError(true);
    }
  };
  return (
    <div className={style["head"]}>
      <div className={style["name"]}>
        <p>{selectedItem?.userId}</p>
        <p
          style={{
            fontSize: "18px",
            color: selectedItem?.active === true ? "green" : "red",
          }}
        >
          {selectedItem?.active ? "Active" : "Inactive"}
        </p>
      </div>
      <div className={style["status_slider"]}>
        <div>
          <p>User Active</p>
          <Switch
            checked={accountLock}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={setAccountLock}
          ></Switch>
        </div>
        <div>
          <p>Bet Active</p>
          <Switch
            checked={betLock}
            checkedChildren="On"
            unCheckedChildren="Off"
            onChange={setBetLock}
          ></Switch>
        </div>
      </div>
      {/* <form className={style['status_portal-form']}> */}
      <div className={style["status_same"]}>
        <label>Transaction Password</label>
        <input
          onChange={passwordHandler}
          type="password"
          value={password}
          style={{
            border: `1px solid ${error ? "red" : ""}`,
            outline: "none",
          }}
        />
      </div>
      {error ? (
        <p
          style={{
            position: "relative",
            left: "45%",
            color: "red",
            bottom: "20px",
          }}
        >
          Enter Valid Password
        </p>
      ) : (
        ""
      )}
      {/* {error ? <p style={{ position: 'relative', left: '40%', color: 'red', bottom: '20px' }}>Enter Valid Password</p> : ''} */}
      {/* </form> */}
      <div className={style["status_buttons"]}>
        <button className={style["status_back"]}>
          <span className={style["icons"]}>
            <MdSettingsBackupRestore />
          </span>
          <span>Back</span>
        </button>
        <button
          className={style["status_submit"]}
          type="submit"
          onClick={checkBoxHandler}
        >
          <span>Submit</span>
          <span className={style["submit_icon"]}>
            <VscDiffRenamed />
          </span>
        </button>
      </div>
    </div>
  );
};
export default Status;
