import React from "react";
import AccountListHeader from "../../components/accountListHeader/AccountListHeader";
import "./styles.scss";
import AccountListTable from "../../components/table/accountListTable/AccountListTable";
const AccountList = () => {
  return (
    <div className="account-list-container">
      <AccountListHeader />
      <AccountListTable />
    </div>
  );
};

export default AccountList;
