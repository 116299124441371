import React, { useEffect, useState } from "react";
import "./AccountDetail.scss";
import { Input, Select } from "antd";
import {
  useAppDetailsQuery,
  useUserDataQuery,
} from "../../../Services/CasinoService";

const AccountDetail = (props) => {
  const userType = localStorage.getItem("userType");
  const [clientType, setClientType] = useState();

  const handleSelectChange = (e, name) => {
    setClientType(e);
    const inputName = name;

    props.setData((prev) => {
      return {
        ...prev,
        [inputName]: e,
      };
    });
  };

  const usertypeArray = {
    0: [
      <Select.Option value={""} key="empty">
        Select
      </Select.Option>,
      <Select.Option value={1} key="0">
        Master
      </Select.Option>,
      <Select.Option value={2} key="1">
        Client
      </Select.Option>,
    ],
    1: [
      <Select.Option value={""} key="empty">
        Select
      </Select.Option>,
      <Select.Option value={1} key="2">
        Agent
      </Select.Option>,
      <Select.Option value={2} key="3">
        Client
      </Select.Option>,
    ],
    2: [
      <Select.Option value={""} key="empty">
        Select
      </Select.Option>,
      <Select.Option value={2} key="4">
        Client
      </Select.Option>,
    ],
    5: [
      <Select.Option value={""} key="empty">
        Select
      </Select.Option>,
      <Select.Option value={1} key="6">
        Super Master
      </Select.Option>,
      <Select.Option value={2} key="7">
        Client
      </Select.Option>,
    ],
    4: [
      <Select.Option value={""} key="empty">
        Select
      </Select.Option>,
      <Select.Option value={1} key="6">
        Sub Admin
      </Select.Option>,
      // <Select.Option value={2} key="7">
      //   Client
      // </Select.Option>,
    ],
  };

  const { data } = useUserDataQuery({}, { refetchOnMountOrArgChange: true });

  const sessCom = [
    "0.00",
    "0.25",
    "0.50",
    "0.75",
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
    "2.25",
    "2.50",
    "2.75",
    "3.00",
  ];
  const casinoCom = [
    "0.00",
    "0.25",
    "0.50",
    "0.75",
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
  ];
  const casinoCom1 = ["0.00", "0.25", "0.50", "0.75", "1.00"];

  const { data: appDetails } = useAppDetailsQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  console.log(data?.data, "datadatadata");


  console.log(typeof data?.data?.mySessionCommission,"fewfweqrqerew" )

  return (
    <div className="account-cont">
      <div className="account">
        <p>Account Detail</p>
      </div>
      <div className="form form_create">
        <div className="first_row">
          <div className="type">
            <label>Account Type:</label>
            <Select
              style={{ width: "100%" }}
              // defaultValue={"Select User Type"}
              className="user-role-select"
              value={props?.data?.userRole}
              onChange={(e) => handleSelectChange(e, "userRole")}>
              {usertypeArray[userType]}
            </Select>
          </div>
          {/* {clientType == 2 && (
            <div className="type">
              <label>Exposure Limit</label>
              <Input
                required
                type="text"
                placeholder="Exposure Limit"
                style={{
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "black",
                  height: "28px",
                }}
                className="exposure-limit-input"
                onChange={props?.exposureHandler}
                value={props?.exposure}
              />
            </div>
          )} */}

          {userType == 4 && clientType != 2 && (
            <div className="type">
              <label>App Url</label>
              <Select
                style={{ paddingTop: "1px", width: "100%" }}
                placeholder="Select App Url"
                className="user-role-select"
                onChange={(e) => props?.setAppId(e)}
                required>
                {appDetails?.data?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm?.appId}>
                        {curElm?.appUrl}
                      </Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
          )}

          <div className="type">
            <label>Balance:</label>
            <Input
              required
              type="text"
              placeholder="Enter Balance"
              style={{
                width: "100%",
                borderRadius: "0px",
                borderColor: "black",
                height: "28px",
              }}
              className="exposure-limit-input"
              onChange={props?.balanceHandler}
              value={props?.balance}
            />
          </div>
        </div>

        {userType == 4 && (
          <div className="first_row ">
            <div className="type">
              <label>Match comm(%)</label>
              <Select
                style={{ paddingTop: "1px", width: "100%" }}
                placeholder="Select Match Comm(%)"
                className="user-role-select"
                // value={props?.appUrlVal}
                onChange={(e) => props?.setMatchComm(e)}
                required>
                {casinoCom?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
            <div className="type">
              <label>Sess Comm(%)</label>
              <Select
                style={{ paddingTop: "1px", width: "100%" }}
                placeholder="Select Sess Comm(%)"
                className="user-role-select"
                onChange={(e) => props?.setSessComm(e)}
                required>
                {sessCom?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
          </div>
        )}

        <div className="first_row ">
          {data?.data?.mySessionCommission !== 0 && userType != 4 ? (
            <div className="type">
              <label>Match comm(%)</label>
              <Select
                style={{ paddingTop: "1px", width: "100%" }}
                placeholder="Select Match Comm(%)"
                className="user-role-select"
                // value={props?.appUrlVal}
                onChange={(e) => props?.setMatchComm(e)}
                required>
                {casinoCom?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </div>
          ) : null}
          {
            data?.data?.mySessionCommission !== 0 && userType != 4 ? <div className="type">
            <label>Sess Comm(%)</label>
            <Select
              style={{ paddingTop: "1px", width: "100%" }}
              placeholder="Select Sess Comm(%)"
              className="user-role-select"
              onChange={(e) => props?.setSessComm(e)}
              required>
              {sessCom?.map((curElm) => {
                return (
                  <>
                    <Select.Option value={curElm}>{curElm}</Select.Option>
                  </>
                );
              })}
            </Select>
          </div> :null
          }

         
          {/* <div className="type">
            <label>Casino Comm(%)</label>
            <Select
              style={{ paddingTop: "1px", width: "100%" }}
              placeholder="Select Casino Comm(%)"
              className="user-role-select"
              onChange={(e) => props?.setCasinoComm(e)}
              required>
              {casinoCom1?.map((curElm) => {
                return (
                  <>
                    <Select.Option value={curElm}>{curElm}</Select.Option>
                  </>
                );
              })}
            </Select>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AccountDetail;
