import React, { useEffect, useState } from "react";
import { DatePicker, Spin, Table } from "antd";
import "./MatchLedgerHeader.scss";
import { useDispatch, useSelector } from "react-redux";
import { MatchLedger } from "../../../Redux/feature/MatchLedger/action";
import { selectMatchLedger } from "../../../Redux/feature/MatchLedger/selector";
import moment from 'moment';
import dayjs from "dayjs";
import { FaSpinner } from "react-icons/fa";

const MatchLedgerHeader = () => {

  const timeBefore = moment().subtract(30, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);

  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Collection Name",
      dataIndex: "collectionName",
      key: "collectionName",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text) => {
        return (
          <span className={text > 0? "text_success": text < 0 ? "text_danger":""}>{text}</span>
        );
      },
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
      render: (text, record) => {
        return (
          <span>{record?.showDate?text +" "+ "( "+record?.dateOnlyStr+" )": text}</span>
        );
      },
    },
    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Rollback",
      dataIndex: "doneBy",
      key: "doneBy",
      render: (text, record) => {
        return (
          <span>{record?.isRollback?"Yes":"No"}</span>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const selector = useSelector(selectMatchLedger);

  useEffect(() => {
    dispatch(
      MatchLedger({
        endDate: endDate,
        index: 0,
        noOfRecords: 100000,
        startDate: startDate,
      })
    );
  }, [endDate, startDate]);

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  selector?.loading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }


  return (
    <>
      <div className="ledger_sec">
        <div className="sub_ledger">
          <div style={{marginBottom:"5px"}}>Start Date</div>
          <DatePicker defaultValue={dayjs(timeBefore)} onChange={onStartDate} />
        </div>
        <div className="sub_ledger">
          <div  style={{marginBottom:"5px"}}>End Date</div>
          <DatePicker defaultValue={dayjs(time)} onChange={onEndDateDate} />
        </div>
        <div className="balnce_show">
          <div className="ledger_balance text_success">
            Lena : {selector?.data?.data?.data?.credit?.toFixed(2)}
          </div>
          <div className="ledger_balance text_danger">
            Dena : {selector?.data?.data?.data?.debit?.toFixed(2)}
          </div>
          <div
            className={`ledger_balance ${
              selector?.data?.data?.data?.balance > 0 ? "text_danger" : "text_success"
            }`}>
            Balance: {Math.abs(selector?.data?.data?.data?.balance?.toFixed(2))}{" "}
            {selector?.data?.data?.data?.balance > 0 ? "( Dena )" : "( Lena )"}
          </div>
        </div>
      </div>
      <div className="acc-cont">
        <div className="account_state_bottom"></div>
        <div className="accountlist-table-container">
          <Table
            columns={columns}
            // pagination={true}
            pagination={{defaultPageSize: 50, pageSizeOptions:[50, 100, 150, 200, 250] }}
            loading={tableLoading}
            dataSource={selector?.data?.data?.list}
            style={{ cursor: "pointer" }}

          />
        </div>
      </div>
    </>
  );
};

export default MatchLedgerHeader;
