import { Empty, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { GiCricketBat } from "react-icons/gi";
import { IoIosFootball } from "react-icons/io";
import { BiTennisBall } from "react-icons/bi";
import { GiBoxingGlove } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { analysisData } from "../../Redux/feature/SportsNameData/selector";
import { marketAnalysis } from "../../Redux/feature/SportsNameData/action";
import LazyLoadedTableData from "../MarketAnalysis/HomePage/LazyLoadedTableData";
import SportDetailsData from "./SportDetailsData";

// const LazyLoadedTableData = React.lazy(() => import('./LazyLoadedTableData'));

const SportDetailsTabs = () => {
  const [sportsId, setSportsId] = useState();
  const data = useSelector(analysisData);
  const dispatch = useDispatch();
  const sportsName = data?.data?.data;

  useEffect(() => {
    dispatch(marketAnalysis());
  }, []);

  const items = sportsName?.map((curElm) => {
    let icon;
    if (curElm?.sportName === "Cricket") {
      icon = <GiCricketBat />;
    } else if (curElm?.sportName === "Football") {
      icon = <IoIosFootball />;
    } else if (curElm?.sportName === "Tennis") {
      icon = <BiTennisBall />;
    } else {
      icon = <GiBoxingGlove />;
    }

    return {
      key: curElm?.sportId,
      label: (
        <p
          onClick={() =>
            setSportsId({ id: curElm?.sportId, sport: curElm?.sportName })
          }
          style={{
            alignItems: "center",
            display: "flex",
            gap: "6px",
            color: "white",
          }}>
          <span
            style={{
              fontSize: "18px",
              position: "relative",
              top: "3px",
              color: "white",
            }}>
            {icon}
          </span>{" "}
          {curElm?.sportName}
        </p>
      ),
      children: <SportDetailsData sportsId={sportsId} />,
    };
  });

  return <div className="sports-tab">{<Tabs items={items} />}</div>;
};

export default SportDetailsTabs;
