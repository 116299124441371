import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { VscDiffRenamed } from 'react-icons/vsc';
import { MdSettingsBackupRestore } from 'react-icons/md';
import style from './Password.module.scss';
import colors from '../../../colors.module.scss';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from 'axios';
import { axiosInstance } from '../../../Services/AxiosInstance';

const Password = ({ selectedItem, setShowModal }) => {
	const { values, handleChange, handleSubmit, setFieldValue, errors, touched, resetForm, handleBlur } = useFormik({
		initialValues: {
			password: '',
			confirmPassword: '',
			lupassword: '',
			userId: selectedItem?.userId
		},
		validateOnChange: true,
		validate: values => {
			const newError = {
				lupassword: '' == values?.lupassword ? 'Login User Password field is required.' : undefined,
				confirmPassword:
					'' == values?.confirmPassword
						? 'Confirm Password field is required. '
						: values.confirmPassword !== values.password
						? 'Confirm password not equal to new password.'
						: undefined,
				password: '' == values?.password ? 'New  Password field is required. ' : undefined
			};
			return Object.fromEntries(Object.entries(newError).filter(([_, v]) => v != null));
		},
		onSubmit: async () => {
			const endPoint = 'user/change-password-child';
			try {
				let data = { ...values, newPassword: values?.password };
				const response = await axiosInstance.post(endPoint, data);
				// setFormData(res.data.data);
				if (response?.data?.status === true) {
					resetForm();
					setShowModal('');
					toast.success(response?.data?.message);
				}
			} catch (error) {
				return toast.error(error?.response?.data?.message);
			}

		}
	});

	return (
		// <Formik initialValues={initialValues} onSubmit={handleSubmit} validate={validateForm} required>
		<>
			
			<form className={style['deposit_portal-form']} onSubmit={handleSubmit}>
				<div className={style['same']}>
					<label> Password</label>

					<input
						type="password"
						placeholder="Password"
						name="password"
						onBlur={handleBlur}
						style={{ border: `1px solid ${touched.password && errors?.password ? 'red' : ''}`, outline: 'none' }}
						className={style['passsword_portal-input']}
						onChange={handleChange}
						value={values?.password}
					/>
				</div>

				<div className={style['same']}>
					<label>Confirm Password</label>
					<input
						type="password"
						placeholder="Confirm Password"
						name="confirmPassword"
						className={style['passsword_portal-input']}
						onChange={handleChange}
						onBlur={handleBlur}
						style={{ border: `1px solid ${touched.confirmPassword && errors?.confirmPassword ? 'red' : ''}`, outline: 'none' }}
						value={values?.confirmPassword}
					/>
				</div>
				{touched.confirmPassword && errors?.confirmPassword ? (
					<span style={{ position: 'relative', color: 'red', left: '12%', bottom: '5px' }}>{errors.confirmPassword} </span>
				) : (
					''
				)}

				<div className={style['same']}>
					<label>Transaction Password</label>
					<input
						type="password"
						placeholder="transaction Password"
						style={{ border: `1px solid ${touched.lupassword && errors?.lupassword ? 'red' : ''}`, outline: 'none' }}
						name="lupassword"
						className={style['passsword_portal-input']}
						onChange={handleChange}
						onBlur={handleBlur}
						value={values?.lupassword}
					/>
				</div>
				{/* <ErrorMessage name="password" component="div" /> */}
				<div className={style['status_buttons']}>
					<button className={style['status_back']}>
						<span className={style['icons']}>
							<MdSettingsBackupRestore />
						</span>
						<span>Back</span>
					</button>
					<button className={style['status_submit']} type="submit">
						<span>Submit</span>
						<span className={style['submit_icon']}>
							<VscDiffRenamed />
						</span>
					</button>
				</div>
			</form>
		</>
		// </Formik>
	);
};

export default Password;
