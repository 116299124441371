import React, { useEffect, useState } from "react";
import { Table, Button, Checkbox, Modal } from "antd";
import "./styles.scss";
import AccountListDeposit from "../AccountListPortal/AccountListDeposit";

import AccountListMore from "../AccountListPortal/AccountListMore";
import AccountListWithdraw from "../AccountListPortal/AccountListWithdraw";
import ContextPortal from "../../../Context/PortalContext";
import AccountListCR from "../AccountListPortal/AccountListCR";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { inactiveUserlist } from "../../../Redux/feature/ClientList/InactiveUser/action";
import { InactiveUserData } from "../../../Redux/feature/ClientList/InactiveUser/selector";
import Pagination from "../../../Pagination/Pagination";
import Loading from "../../../Loading/Loading";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { loadingRef } from "../../../App";

export let activeInactiveRef;

const InActiveUser = ({ userName }) => {
  const [dataSource, setDataSource] = useState([]);
  const [userData, setUserData] = useState("");
  const [data1, setData] = useState([]);
  const [records, setRecords] = useState(100);
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("id");
  // const [index, setIndex] = useState(0);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: records,
    index: 0,
    totalPages: 1,
  });

  const dispatch = useDispatch();

  const selector = useSelector(InactiveUserData);

  const loading = selector?.loading;
  useEffect(() => {
    if (loading) {
      loadingRef("childList", true);
    } else {
      loadingRef("childList", false);
    }
  }, [loading]);

  const showModalProfile = (obj) => {
    const data = data1?.find((item) => item?.id === obj);
    setUserData(data);
  };

  const [isCreditDepositPortalVisible, setIsCreditDepositPortalVisible] =
    useState(false);
  const [isDepositPortalVisible, setIsDepositPortalVisible] = useState(false);
  const [isWithdrawPortalVisible, setIsWithdrawPortalVisible] = useState(false);
  const [credit, setCredit] = useState(false);
  const [limit, setLimit] = useState(false);
  const [password, setPassword] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [more, setMore] = useState(false);

  //userId
  const [SelectedItem, setSelectedItem] = useState(null);

  const [selectedActionData, setSelectedActionData] = useState({});

  // const tableSelector = useSelector(activeInactiveUserSelect);

  const portalHidden = (e) => {
    e.preventDefault();
    setIsDepositPortalVisible(false);
    setIsWithdrawPortalVisible(false);
    setCredit(false);
    setIsStatusChanged(false);
    setPassword(false);
    setLimit(false);
    setIsCreditDepositPortalVisible(false);
    setMore(false);
  };
  const nav = useNavigate();
  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      width: "15%",
      key: "username",
      sorter: (a, b) => a.username.localeCompare(b.username),

      render: (hui, record) => (
        <div
          onClick={() => nav(`/matchbox_active-user?id=${record.id}`)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "3px 10px",
            borderRight: "1px solid black",
            borderRadius: "3px",
            textTransform: "uppercase",
            fontWeight: "600",
            display: "inline-block",
            cursor: "pointer",
          }}
        >
          {hui}
        </div>
      ),
    },

    {
      title: "Credit Referance",
      dataIndex: "chips",
      width: "5%",
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setSelectedActionData(record);
              setIsCreditDepositPortalVisible(true);
            }}
            style={{
              float: "right",
              fontSize: "14px",
              color: "#444",
              padding: "0 5px",
            }}
          >
            {text}
          </p>
        );
      },
    },

    {
      title: "U St",
      dataIndex: "betLock",
      render: (_, record) => (
        <Checkbox
          checked={record?.accountLock}
          disabled
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-class_checkbox"
        ></Checkbox>
      ),
    },

    {
      title: "B St",
      dataIndex: "active",
      render: (_, record) => (
        <Checkbox
          checked={record?.betLock}
          disabled
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-class_checkbox"
        />
      ),
    },

    {
      title: "Exposure Limit",
      dataIndex: "exposure",
      width: "10%",
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px", padding: "0px 5px" }}>
          {text}
        </span>
      ),
    },

    {
      title: "Default %",
      dataIndex: "exposure",
    },

    {
      title: "Account Type",
      dataIndex: "accountType",
      render: (text, record) => {
        const newText = text === "Dealer" ? "Agent" : text;

        return (
          <span style={{ fontSize: "14px", padding: "0px 5px" }}>
            {newText}
          </span>
        );
      },
    },

    {
      title: "Casino Total",
      dataIndex: "exposure",
      render: (text) => <span style={{ float: "right" }}>{text}</span>,
    },
    userId == null
      ? {
          title: "Action",
          dataIndex: "action",
          width: "20%",

          render: (text, record) => {
            return (
              <>
                <Button
                  onClick={() => {
                    setIsCreditDepositPortalVisible(true);
                    setSelectedActionData(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",

                    // fontFamily: "'Roboto Condensed', sans-serif"
                  }}
                >
                  CR
                </Button>
                <Button
                  onClick={() => {
                    setIsDepositPortalVisible(true);
                    setSelectedActionData(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    // fontFamily: "'Roboto Condensed', sans-serif"
                  }}
                >
                  D
                </Button>

                <Button
                  onClick={() => {
                    setIsWithdrawPortalVisible(true);
                    setSelectedActionData(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    // fontFamily: "'Roboto Condensed', sans-serif"
                  }}
                >
                  W
                </Button>

                <Button
                  onClick={() => {
                    showModalProfile(record?.id);
                    setSelectedActionData(record);
                    setMore(true);

                    const { id } = record;

                    setSelectedItem(id);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    display: "inline-block",
                    // fontFamily: "'Roboto Condensed', sans-serif"
                  }}
                >
                  MORE
                </Button>
              </>
            );
          },
        }
      : {},
  ];
  //Client List API
  // const dataApi = async (id = '') => {
  // 	const baseUrl = 'user/child-list-active-inactive-user';
  // 	try {
  // 		await axios.post(baseUrl, { index: 0, noOfRecords: 500, id: id, userName: '' }).then(res => {
  // 			setDataSource(res?.data?.data?.dataList);
  // 			setData(res?.data?.data?.dataList);
  // 		});
  // 	} catch (error) {

  // 		console.error('Error fetching data:', error);
  // 	}
  // };

  const handleCancel = () => {
    setIsDepositPortalVisible(false);
    setIsWithdrawPortalVisible(false);
    setMore(false);
    setIsCreditDepositPortalVisible(false);
  };

  const getRowClassName = (record, index) => {
    return index % 2 === 0 ? "even_row" : "odd_row";
  };

  //   const activeUserHandler = async (id) => {
  //     const baseUrl = "/user/child-list-active-user";
  //     await axiosInstance
  //       .post(baseUrl, {
  //         index: accountStatementBody?.index,
  //         noOfRecords: records,
  //         id: id,
  //       })
  //       .then((res) => {
  //         setDataSource(res?.data?.data?.dataList);
  //         // setRecords(res?.data?.data);
  //       });
  //   };

  //   const inActiveUserHandler = async (id = '') => {
  //   	const baseUrl = '/user/child-list';
  //   	await axios.post(baseUrl, { index: 0, noOfRecords: 500, id: id }).then(res => {
  //   		setDataSource(res?.data?.data?.dataList);
  //   	});
  //   };
  // useEffect(() => {
  //   inactiveUser()
  // }, [accountStatementBody?.index])

  //   const inactiveActiveUser = (id) => {
  //     dispatch(
  //       activeInactive({
  //         index: index,
  //         noOfRecords: records,
  //         id: id,
  //         userName: "",
  //       })
  //     );
  //   };

  // activeInactiveRef = inactiveActiveUser

  useEffect(() => {
    inActiveUserHandler();
  }, [accountStatementBody?.index, userId, userName]);

  const inActiveUserHandler = (id) => {
    dispatch(
      inactiveUserlist({
        index: accountStatementBody.index,
        noOfRecords: records,
        id: userId,
        username: userName,
      })
    );
  };

  useEffect(() => {
    setDataSource(selector?.data?.dataList);
    setAccountStatementBody((prev) => ({
      ...prev,
      totalPages: selector?.data?.totalPages,
    }));
  }, [selector]);

  return (
    <ContextPortal.Provider
      value={{
        portalHidden: portalHidden,
      }}
    >
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowClassName={getRowClassName}
          className="users_table"
        />

        <Modal
          footer={null}
          open={isCreditDepositPortalVisible}
          onCancel={handleCancel}
          destroyOnClose
          style={{ padding: "15px" }}
          width={800}
        >
          <AccountListCR
            onPortalHidden={portalHidden}
            selectedRecord={selectedActionData}
            CRPortlal={setIsCreditDepositPortalVisible}
            inactiveActiveUser={inActiveUserHandler}
          />
        </Modal>

        <Modal
          footer={null}
          open={isDepositPortalVisible}
          onCancel={handleCancel}
          destroyOnClose
          className="more-modal-deposit"
          title="Deposit"
        >
          <AccountListDeposit
            onPortalHidden={portalHidden}
            selectedRecord={selectedActionData}
          />
        </Modal>

        <Modal
          footer={null}
          open={isWithdrawPortalVisible}
          onCancel={handleCancel}
          destroyOnClose
          className="more-modal-withdraw"
          title="Withdraw"
        >
          <AccountListWithdraw
            selectedRecord={selectedActionData}
            onPortalHidden={portalHidden}
          />
        </Modal>

        {/* {limit && <AccountListLimit />}

        {credit && <AccountListCredit />} */}

        {/* {password && (
          <AccountListPassword selectedRecord={selectedActionData} />
        )} */}

        <Modal
          footer={null}
          open={more}
          onCancel={handleCancel}
          destroyOnClose
          width={1000}
          className="more-modal-more"
          title={selectedActionData?.username}
        >
          <AccountListMore
            selectedRecord={selectedActionData}
            onPortalHidden={portalHidden}
            setIsStatusChanged={setIsStatusChanged}
            SelectedItem={SelectedItem}
            dataSource={dataSource}
            setMore={setMore}
            // dataApi={dataApi}
            data={userData}
            onInactiveActiveUser={inActiveUserHandler}
          />
        </Modal>
      </div>
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
      />
    </ContextPortal.Provider>
  );
};

export default InActiveUser;
