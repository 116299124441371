import { createAsyncThunk } from "@reduxjs/toolkit";
import {rejectedBetEndPoint} from '../../../Services/ApiEndingPoint'
import { axiosInstance } from "../../../Services/AxiosInstance";

export const RejectedBet = createAsyncThunk('rejectedbet', async(payload, {rejectWithValue})=>{
	try{
		const response = await axiosInstance.post(rejectedBetEndPoint, {...payload});
		if(response){
			return response?.data
		}
	}catch(error){
		return rejectWithValue(error)
	}
})