import React from "react";
import style from './MathOddsRow.module.scss'
import MathOddsLeftRow from "./MathOddsLeftRow";
import MathOddsRightRow from "./MathOddsRightRow";

const MatchOddsRow = () => {
  return (
  <div className={style["row-cont"]}>
      <div className={style["left-row"]}>
        <MathOddsLeftRow />
      </div>
      <div className={style["right-row"]}><MathOddsRightRow /></div>
    </div>
  )
};

export default MatchOddsRow;
