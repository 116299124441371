import React, { useState } from 'react';
import style from './AddAccountBottom.module.scss';
import colors from '../../colors.module.scss';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const AddAccountBottom = props => {
	const [showPass, setShowPass] = useState(false);

	const handleInputBlur = () => {
		setShowPass(false);
	};

	const toggleShowPass = () => {
		setShowPass(prevShowPass => !prevShowPass);
	};

	return (
		<div className={style['password-cont']}>
			<div className={style['transaction_form']}>
				<label>Transaction Password:</label>

				<input
					required
					placeholder="Transaction Password"
					onChange={props?.transactionPassHandler}
					value={props?.transPass}
					type={showPass ? 'text' : 'password'}
					onBlur={handleInputBlur}
				/>
				{props?.transPass?.length > 0 ? (
					showPass ? (
						<AiFillEye className={style['eye']} onClick={toggleShowPass} />
					) : (
						<AiFillEyeInvisible className={style['eye']} onClick={toggleShowPass} />
					)
				) : null}

				{/* {props?.transPass?.length > 1 || (props?.transPass?.length === 1 && <AiFillEye className={style['eye']} onClick={() => setShowPass(!showPass)} />)} */}
				{/* {props?.transPass?.length > 0 ? (
					showPass ? (
						<AiFillEye className={style['eye']} onClick={() => setShowPass(false)} />
					) : (
						<AiFillEyeInvisible className={style['eye']} onClick={() => setShowPass(true)} />
					)
				) : (
					''
				)} */}
			</div>
			<button className={style['create_account-button']} type="Submit">
				Create Account
			</button>
		</div>
	);
};

export default AddAccountBottom;
