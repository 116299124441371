import React from "react";
import style from "./Partnership.module.scss";

const Partnership = ({ partnership, inputState, setInputState, data }) => {
  const inputChangeHandler = (e) => {
    setInputState(e.target.value);
  };
  return (
    data?.userRole !== 2 && (
      <div className={style["partnership-cont"]}>
        <div className={style["partnership"]}>
          <p>Partnership</p>
        </div>
        <div className={style["partnership-total"]}>
          <div className={style["partnership-breakdown"]}>
            <p className={style["upline"]}>Upline</p>
            <p className={style["downtime"]}>Our</p>
            <p className={style["our"]}>Downline</p>
          </div>
          <div className={style["partnership-amount"]}>
            <p className={style["upline"]}>{100 - Number(partnership)}</p>
            <input
              className={style["downtime1"]}
              min="0"
              placeholder="Enter your Partnership"
              onChange={inputChangeHandler}
              value={inputState}
              type="number"
              required
              onKeyDown={(e)=> {
                if (e.key == "-" || e.key == ".") {
                  e.preventDefault();
                }
}}
              
            />
            <p className={style["our"]}>{partnership - inputState}</p>
          </div>
        </div>
      </div>
    )
  );
};

export default Partnership;
