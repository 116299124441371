import React, { useEffect, useRef, useState } from "react";
import "./MarketAnalysisRight.scss";
import { Table, Modal, Empty, Tabs } from "antd";
// import axiosInstance from '../../../Services/AxiosInstance';
import { useLocation, useParams } from "react-router";
import colors from "../../../colors.module.scss";
import { axiosInstance } from "../../../Services/AxiosInstance";
import ModalViewMore from "../Portals/ModalViewMore";
import { loadingRef } from "../../../App";
import { PiTelevision } from "react-icons/pi";

const MarketAnalysisRight = () => {
  const [betData, setBetData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabData, setTabData] = useState([]);
  const { id } = useParams();

  const [liveMatchShown, setLiveMatchShown] = useState(false);
  const [scoreBoard, setScoreBoard] = useState(false);

  const location = useLocation();
  const pathParts = location.pathname.split("/");

  const value = pathParts[2];
  const ref = useRef(null)
  const scale = (ref.current?.clientWidth || 300) / 280

  useEffect(() => {
    getViewMoreTabData();
    betList();
  }, []);

  const betList = async () => {
    loadingRef("betsbetlistbymatchid", true);
    await axiosInstance
      .post(
        "bets/bet-list-by-matchid",
        { matchId: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setBetData(res?.data?.data);
      })
      .catch((error) => {
        // message.error(error.response.data.message);
        // if (error.response.data.status === 401) {
        //   setLoading((prev) => ({ ...prev, getViewMoreTabData: false }));
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
    loadingRef("betsbetlistbymatchid", false);
  };

  const getViewMoreTabData = async () => {
    await axiosInstance
      .post(
        "bets/market-name-by-matchid",
        { matchId: id },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setTabData(res?.data?.data);
      })
      .catch((error) => {
        // message.error(error.response.data.message);
        // if (error.response.data.status === 401) {
        //   setLoading((prev) => ({ ...prev, getViewMoreTabData: false }));
        //   localStorage.clear();
        //   navigate("/");
        // }
      });
  };

 

  const liveMatch = `https://100tun.online/web/${id}.html`;
 
  // const matchScoreBoard = `https://score.247idhub.com/go-score/template/${value}/${id}`;
  const matchScoreBoard = `https://score.247idhub.com/index.html/event/${id}?theme=crazy-diamond`;

  const dataSource = [];
  betData?.map((res, index) => {
    dataSource.push(
      {
        key: res.marketname + res.userid + index,
        UserName: res.marketname,
        Rate: res.matchedtime?.split(" ")[0],
        Amount: res.matchedtime?.split(" ")[1],
        isback: res.isback,
      },
      {
        key: res.userid + res.marketname + index + 1,
        UserName: res.userid,
        Nation: (
          <div className="nation" style={{ whiteSpace: "pre-wrap" }}>
            {res.selectionname}
          </div>
        ),
        Rate: res.odds,
        isback: res.isback,
        Amount: res.stack,
      },
      {
        key: res.userid + res.odds + index + 5,
      }
    );
  });
  const columns = [
    {
      title: "UserName",
      dataIndex: "UserName",
      key: "UserName",
    },
    {
      title: "Nation",
      dataIndex: "Nation",
      key: "Nation",
    },
    {
      title: "Rate",
      dataIndex: "Rate",
      key: "Rate",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },
  ];

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="right-cont">
      <div className="live" onClick={() => setLiveMatchShown(!liveMatchShown)}>
        <p>Live Match</p>
				<p ><PiTelevision style={{marginTop:"2px"}}/> live stream started</p>

      </div>
      {liveMatchShown && (<div className="tv-score-container">
        <iframe
          width="100%"
          ref={ref}
          height="220px"
          class="live-iframe tv-iframe"
          title="score-iframe"
          src={liveMatch}
          style={{
            aspectRatio: "16/9",
            transform: `scale(${scale})`,
            display: "block",
            height: "100%"
          }}
        />
        </div>
      )}

      <div className="score_card" onClick={() => setScoreBoard(!scoreBoard)}>
        <p>Score Card</p>
      </div>
      {scoreBoard && (
        <iframe
          width="100%"
          height="200px"
          title="score-iframe"
          src={matchScoreBoard}
        ></iframe>
      )}  

      <div className="matched-cont">
        <div className="matched-div">
          <div className="combo-div">
            <p style={{ fontSize: "16px" }}>
              My Bets
              {/* <span
                className={isMatchShown ? "active-class" : "matched"}
                onClick={matchButtonHandler}
              >
                Matched
              </span>
              <span
                className={isUnmatchedShown ? "active-class" : "unmatched"}
                onClick={unmatchedButtonHandler}
              >
                Unmatched
              </span> */}
            </p>
          </div>
          <div>
            <button
              onClick={() => {
                // betList();
                setIsModalOpen(true);
              }}
              style={{ background: colors.themeColor }}
            >
              View More
            </button>
          </div>
        </div>
        <div className="bets-table">
          <Table
            columns={columns}
            dataSource={dataSource}
            rowClassName={(record) => {
              return record.isback ? "blue" : "pink";
            }}
            pagination={false}
            className="bet-list-table"
          />
        </div>
        {/* {isMatchShown && (
					<div className={style['user_name-cont']}>
						<p className={style['user_name-left']}>UserName Nation</p>
						<p className={style['user_name-right']}>
							<span>Rate</span>
							<span>Amount</span>
							<span>PlaceDate</span>
						</p>
					</div>
				)}
				{isUnmatchedShown && (
					<div className={style['user_name-cont']}>
						<p className={style['user_name-left']}>UserName UnMatchedNation</p>
						<p className={style['user_name-right']}>
							<span>Rate</span>
							<span>Amount</span>
							<span>PlaceDate</span>
						</p>
					</div>
				)} */}
      </div>
      {/* <Modal
				className="mark-x"
				title={<div style={{ padding: '10px 15px', background: "#34c38f", alignItems: "center", color: "white", letterSpacing: "0.3px" }}>View More</div>}
				open={viewMoreVisible}
				footer={null}
				destroyOnClose
				onCancel={handleCancel}
				width={1200}
				style={{ top: '10px', left: '0px', height: '100%' }}>
				<ViewMorePortal viewMoreData={viewMoreData} />
			</Modal> */}
      <Modal
        title="View More"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        okText="ok"
        footer={null}
        style={{ top: "8px" }}
        width={1300}
        className="view-more-modal2"
        // cancelButtonProps={{ hidden: isExFlag }}
      >
        {tabData?.length > 0 ? (
          <Tabs
            defaultActiveKey="1"
            type="card"
            destroyInactiveTabPane
            items={tabData?.map((ele, index) => {
              return {
                label: ele,
                key: ele + index,
                children: <ModalViewMore keyName={ele} />,
              };
            })}
            // className="my-Bets-view-more"
          />
        ) : (
          <Empty />
        )}
      </Modal>
    </div>
  );
};
export default MarketAnalysisRight;
