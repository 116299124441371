import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCreditHistory } from "../../../Redux/feature/CreditHistory/selector";
import { ClientList } from "../../../Redux/feature/ClientList/InactiveUser/action";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import style from "./AccountListCRCreditHistory.module.scss";
import { Table } from "antd";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListCRCreditHistory = (props) => {
  const [creditHistory, setCreditHistory] = useState([]);

  useEffect(() => {
    creditHistoryData();
  }, []);

  const columns = [
    {
      title: "S_no",
      dataIndex: "sno",
    },

    {
      title: "Remark",
      dataIndex: "remark",
    },

    {
      title: "pts",
      dataIndex: "pts",
    },

    {
      title: "fromto",
      dataIndex: "fromto",
    },

    {
      title: "Debit",
      dataIndex: "debit",
    },

    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
  ];

  // const tableArray = ['S_no.', 'Remarks', 'Pts', 'fromto', 'Debit', 'Date', 'Credit'];

  const endPoint = "report/account-statement";
  const token = localStorage.getItem("token");
  const creditHistoryData = async () => {
    loadingRef("reportaccoustatementditdata", true);
    await axiosInstance
      .post(
        endPoint,
        {
          noOfRecords: 25,
          index: 0,
          toDate: "00:00:0000",
          fromDate: "00:00:0000",
          userid: props.crUserData.userId,
          type: 4,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setCreditHistory(res.data.data.dataList);
      });
    loadingRef("reportaccoustatementditdata", false);
  };

  // const dispatch = useDispatch();
  // useEffect(() => {
  // 	dispatch(ClientList());
  // }, []);

  return (
    <div className="accountlist-table-container">
      <Table columns={columns} dataSource={creditHistory} pagination={false} />
      {/* <table>
				<thead>
					<tr>
						{tableArray.map(el => (
							<th>{el}</th>
						))}
					</tr>
				</thead>
				<tbody className={style['account_hitory-body']}>
					<tr className={style['account_hitory-tr']}>
						{creditHistory.map(t => (
							<>
								<td>{t.sno}</td>
								<td>{t.remark}</td>
								<td>{t.pts}</td>
								<td>{t.fromto}</td>
								<td>{t.debit}</td>
								<td>{t.date}</td>
								<td>{t.credit}</td>
							</>
						))}
					</tr>
				</tbody>
			</table> */}
    </div>
  );
};

export default AccountListCRCreditHistory;
