export const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Post Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Collection Name",
      dataIndex: "collectionName",
      key: "collectionName",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Done By",
      dataIndex: "doneBy",
      key: "doneBy",
    },
  ];