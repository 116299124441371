import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../../Services/AxiosInstance';
import { fetchDeletedData } from '../../../Services/ApiEndingPoint';

export const fetchDeleted = createAsyncThunk('fetchdelete', async (payload, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(fetchDeletedData, { ...payload });
		if (response) {
			return response?.data?.data;
		}
		
	} catch (error) {
		return rejectWithValue(error);
	}
});
