import { Empty, Tabs } from 'antd';
import axios from 'axios';
import { useEffect, useState } from 'react';
import React from 'react';
import { GiCricketBat } from 'react-icons/gi';
import { IoIosFootball } from 'react-icons/io';
import { BiTennisBall } from 'react-icons/bi';
import { GiBoxingGlove } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { marketAnalysis } from '../../../Redux/feature/SportsNameData/action';
import { analysisData } from '../../../Redux/feature/SportsNameData/selector';
import { matchData } from '../../../Redux/feature/MatchData/action';
import { matchDataDataSelect } from '../../../Redux/feature/MatchData/selector';
import Loading from '../../../Loading/Loading';
import { SportsTab } from '../../../Redux/feature/MarketAnalysis/selector';
import LazyLoadedTableData from './LazyLoadedTableData';
import './Home.scss';

// const LazyLoadedTableData = React.lazy(() => import('./LazyLoadedTableData'));

const MarketAnalysisHome = () => {
	const [sportsId, setSportsId] = useState(null);
	const dispatch = useDispatch();
	const data = useSelector(analysisData);
	const sportsName = data?.data?.data;

	const response = useSelector(matchDataDataSelect);

	// console.log(sportsName, "dfdsfsdfsd")

	useEffect(() => {
		dispatch(marketAnalysis());
	}, []);

	useEffect(() => {
		if (sportsName && sportsName?.length > 0 && !sportsId?.id) {
			setSportsId({ id: sportsName[0]?.sportId, sport: sportsName[0]?.sportName });
		}
	}, [sportsName, sportsId]);

	// useEffect(() => {
	// 	if (sportsId !== null) {
	// 		dispatch(matchData({ id: +sportsId?.id }));
	// 	}
	// }, [sportsId]);

	const items = sportsName?.map(curElm => {
		let icon;
		if (curElm?.sportName === 'Cricket') {
			icon = <GiCricketBat />;
		} else if (curElm?.sportName === 'Football') {
			icon = <IoIosFootball />;
		} else if (curElm?.sportName === 'Tennis') {
			icon = <BiTennisBall />;
		} else {
			icon = <GiBoxingGlove />;
		}

		return {
			key: curElm?.sportId,
			label: (
				<p onClick={() => setSportsId({ id: curElm?.sportId, sport: curElm?.sportName })} style={{ alignItems: 'center', display: 'flex', gap: '6px', color: 'white' }}>
					<span style={{ fontSize: '18px', position: 'relative', top: '3px', color: 'white' }}>{icon}</span> {curElm?.sportName}
				</p>
			),

			// children: <LazyLoadedTableData sportData={sportData} sportsId={sportsId} sportsName={sportsName} />
			children: <LazyLoadedTableData sportsId={sportsId} />
		};
	});

	return <div className="sports-tab">{<Tabs items={items} />}</div>;
};

export default MarketAnalysisHome;
