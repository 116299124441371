import React from 'react';
import LoadingPlaceholder from '../../../LoadingM.jsx';
import style from './TableData.module.scss';
const Bodyloading = () => {
	return (
		<div>
			<>
				<div className={style['sport_name']} style={{ height: '42px' }}>
					<LoadingPlaceholder width="200px" height="100%" style={{ background: '#2C3E50' }} />
				</div>
				<div className={style['combo']}>
					<div className={style['match_name']} style={{ height: '30px' }}>
						<LoadingPlaceholder width="150px" height="100%" />
					</div>
					<div className={style['time']} style={{ height: '30px' }}>
						<LoadingPlaceholder width="150px" height="100%" />
					</div>
				</div>

				<div className={style['heading']}>
					<div className={style['market_combo']}>
						<div className={style['market']}>
							<LoadingPlaceholder height="30px" />
						</div>
					</div>
					<div className={style['desc']} style={{ display: 'flex' }}>
						<div className={style['team_amount']} style={{ gap: '10px', display: 'flex', marginTop: '10px', marginRight: '420px' }}>
							<LoadingPlaceholder height="20px" width="100px" />
							<LoadingPlaceholder width="30px" height="20px" />
						</div>
						<div className={style['team_amount2']} style={{ gap: '10px', display: 'flex', marginTop: '10px', marginRight: '420px' }}>
							<LoadingPlaceholder height="30px" width="100px" />
							<LoadingPlaceholder width="30px" height="30px" />
						</div>

						<div className={style['team_amount3']} style={{ gap: '10px', display: 'flex', marginTop: '10px' }}>
							<LoadingPlaceholder height="30px" width="100px" />
							<LoadingPlaceholder width="30px" height="30px" />
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Bodyloading;
