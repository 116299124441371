import React, { useEffect } from "react";
import "./CasinoAllow.scss";
import { useAllotedCasinoMutation } from "../../../../Services/CasinoService";
import { Switch } from "antd";

const CasinoAllow = ({ setState, state }) => {
  const [trigger , { data: allotedCasino }] = useAllotedCasinoMutation();

  const userType = localStorage.getItem("userType")

  useEffect(()=>{
    trigger();
  }, [])

  console.log(allotedCasino?.data, "dfdfa")

  useEffect(() => {
    allotedCasino?.data?.map((key) => {
      setState((prev) => {
        return {
          ...prev,
          [`is${key.name.replace(" ", "")}Allowed`]: !key.active,
        };
      });
    });
  }, [allotedCasino?.data]);

 

  return (
    <div className="account-cont casino_allow">
      <div className="account">
        <p>Casino Allowed</p>
      </div>
      <div className="form">
        <div className="first_row">
          <div className="casino_details">
            {allotedCasino?.data?.map((item, id) => {
              return (
                <div key={id}>
                  <div className="casino_name">{item?.name}</div>
                  <div className="casino_switch">
                    <Switch
                      defaultChecked= {userType == 4 ? !item?.active : item?.active}
                      disabled={userType == 4 ? false: true}
                      onChange={(e) =>
                        setState((prev) => {
                          return {
                            ...prev,
                            [`is${item.name.replace(" ", "")}Allowed`]:
                              !prev[`is${item.name.replace(" ", "")}Allowed`],
                          };
                        })
                      }
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasinoAllow;
