import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { InactiveUser } from "../../../../Services/ApiEndingPoint";
import { axiosInstance } from "../../../../Services/AxiosInstance";

export const inactiveUserlist = createAsyncThunk(
  "clientList",
  async (payload, { rejectWithValue }) => {
    try {
      //end Point - LogInApi
      const response = await axiosInstance.post(InactiveUser, { ...payload });
      if (response) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
