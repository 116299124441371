import React, { useEffect, useState } from "react";
import style from "./BetHistorySports.module.scss";
import { Select } from "antd";
// import axiosInstance from '../../../Services/AxiosInstance';
import { axiosInstance } from "../../../Services/AxiosInstance";
import BetsHistoryTable from "./BetsHistoryTable";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const BetHistorySports = () => {
  const [sportsType, setSportsType] = useState([]);
  const [sportList, setSportlist] = useState([]);
  const [record, setRecord] = useState();
  const [matchId, setMatchId] = useState();
  const [deleted, setDeleted] = useState(false);
  const [betSportData, setBetSportData] = useState([]);
  const [index, setIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });

  useEffect(() => {
    sportListApi();
  }, []);

  //Sports Name

  const sportListApi = async () => {
    const endPoint = "sport/active-sport-list";
    // const token = localStorage.getItem('token');
    const response = await axiosInstance.post(
      endPoint,
      {}
      // { headers: { Authorization: `Bearer ${token}` } }
    );

    setSportlist(response?.data?.data);
  };

  useEffect(() => {
    if (record) {
      const endPoint = "sport/event-detail-sport-wise";
      setMatchId("");
      // const token = localStorage.getItem('token');

      axiosInstance
        .post(
          endPoint,
          { sportId: record }
          // { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          setSportsType(response?.data?.data);
        });
    }
  }, [record]);

  useEffect(() => {
    betHistory();
  }, [
    accountStatementBody.index,
    accountStatementBody.totalPages,
    accountStatementBody.noOfRecords,
  ]);

  const betHistory = () => {
    loadingRef("bethistorySport", true);
    const endPoint = "/report/bet-history";
    axiosInstance
      .post(endPoint, {
        sportId: +record,
        matchId: matchId,
        userId: "",
        sportType: 1,
        isDeleted: deleted,
        index: accountStatementBody.index,
        noOfRecords: accountStatementBody.noOfRecords,
      })
      .then((res) => {
        setBetSportData(res?.data?.data?.dataList);
        if(res?.data?.status){
          setLoading(false)
        }
        loadingRef("bethistorySport", false);
        setAccountStatementBody((prev) => {
          return {
            ...prev,
            totalPages: res?.data?.data?.totalPages,
          };
        });
        loadingRef("bethistorySport", false);
      });
  };
  // India vs Aus


  return (
    <>
      <div className={style["profit_loss-cont"]}>
        <Select
          defaultValue="Matched"
          className={style["select-match"]}
          onChange={(value) => setDeleted(value)}
          options={[
            {
              value: false,
              label: "Matched",
            },
            {
              value: true,
              label: "Deleted",
            },
          ]}
        />
        <Select
          // value={record ? record : "Select Match"}
          onChange={(e) => {
            setRecord(e);
          }}
          placeholder="Select Match"
          className={style["select-match"]}
          // style={{
          //   padding: "5px",
          //   height: "30px",
          //   borderRadius: "5px",
          //   border: "1px solid grey",
          // }}
        >
          {/* <Select.Option>Select Match</Select.Option> */}
          {sportList?.map((sport) => (
            <Select.Option key={sport?.sportId} value={sport?.sportId}>
              {sport?.sportName}
            </Select.Option>
          ))}
        </Select>

        <Select
          className={style["select-match"]}
          onChange={(e) => setMatchId(e)}
          value={matchId ? matchId : "Select Match list"}
          placeholder="Select Match list"
        >
          {sportsType?.map((el) => {
            return (
              <Select.Option key={el?.eventId} value={el?.eventId}>
                {el?.eventName}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div className={style["profit_loss-date"]}>
        <button
          className={style["bet_history-load"]}
          type="submit"
          onClick={betHistory}
          style={{
            padding: "8px 10px",
            fontSize: "14px",
            background: "#2C3E50",
            color: "white",
            border: "none",
            borderRadius: "3px",
          }}
        >
          Load
        </button>
        {/* <button style={{ background: 'rgb(239, 242, 247)', color: 'black', border: '1px solid #d9d9d9', fontSize: '14px', height: '35px' }}>Reset</button> */}
      </div>

      <BetsHistoryTable
      loading={loading}
        betSportData={betSportData}
        accountStatementBody={accountStatementBody}
        setAccountStatementBody={setAccountStatementBody}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
    </>
  );
};

export default BetHistorySports;
