import { Select, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { AiFillEye } from "react-icons/ai";
import Pagination from "../../../Pagination/Pagination";
import "./ViewButtonPortal.scss";

const SportViewButtonPortal = ({ matchId, userId }) => {
  const [viewData, setViewData] = useState([]);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  useEffect(() => {
    viewButtonApi();
  }, [accountStatementBody.index, accountStatementBody.noOfRecords]);

  const viewButtonApi = async () => {
    const endPoint = "report/bet-history";
    const response = await axiosInstance.post(endPoint, {
      ...accountStatementBody,
      ...{
        sportId: "",
        isDeleted: false,
        matchId: matchId,
        userId: userId,
        sportType: "1",
      },
    });
    setViewData(response?.data?.data?.dataList);
    setAccountStatementBody((prev) => {
      return {
        ...prev,
        totalPages: response?.data?.data?.totalPages,
      };
    });
  };
  const column = [
    {
      title: "User Name",
      dataIndex: "UserName",
    },
    {
      title: "M Name",
      dataIndex: "MName",
    },
    {
      title: "Nation",
      dataIndex: "Nation",
    },
    {
      title: "U Rate",
      dataIndex: "URate",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
    },
    {
      title: "Place Date",
      dataIndex: "PlaceDate",
    },
    {
      title: "Event Type",
      dataIndex: "EventType",
    },
    {
      title: "Event Name",
      dataIndex: "EventName",
    },
    {
      title: "Detail",
      dataIndex: "Detail",
    },
  ];
  const datasource = viewData?.map((res, index) => {
    return {
      key: res?.rate + res?.time + res?.amount + index,
      isBack: res?.isback,
      EventType: res?.eventType,
      EventName: res?.eventNamem,
      UserName: res?.username,
      MName: res?.marketname,
      Nation: res?.nation,
      URate: res?.rate,
      Amount: res?.amount,
      PlaceDate: res?.time,
      Detail: (
        <>
          <Tooltip title={res?.deviceInfo}>
            <AiFillEye style={{ fontSize: "18px", cursor: "pointer" }} />
          </Tooltip>
        </>
      ),
    };
  });
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              paddingLeft: "5px",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <p>Show</p>

              <Select
                defaultValue={25}
                value={accountStatementBody.noOfRecords}
                onChange={(value) =>
                  setAccountStatementBody((prev) => {
                    return {
                      ...prev,
                      noOfRecords: value,
                    };
                  })
                }
              >
                <Select.Option value={25}>25</Select.Option>
                <Select.Option value={100}>100</Select.Option>
                <Select.Option value={200}>200</Select.Option>
              </Select>

              <p>entries</p>
            </div>
            {/* <label className="d-inline-flex align-items-center">
							Show&nbsp;
							<select
								className="custom-select-sm"
								// value={paginationData?.noOfRecords}
								// onChange={e =>
								// 	setPaginationData({
								// 		...paginationData,
								// 		noOfRecords: Number(e.target.value)
								// 	})
								// }
							>
								<option value="100">100</option>
								<option value="250">250</option>
								<option value="500">500</option>
								<option value="1000">1000</option>
								<option value="2000">2000</option>
							</select>
							&nbsp;entries
						</label> */}
            {/* <div className="input-search" style={{ paddingRight: "10px" }}>
          <Input
            placeholder="search here....."
            name="message"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </div> */}
          </div>
          {/* <h3>
          Total Soda: <span style={{ color: "green" }}>{sada}</span> Total
          Amount:<span style={{ color: "green" }}>{totalAmount}</span>
        </h3> */}
        </div>
        <div
          style={{ overflowY: "scroll", height: "400px" }}
          className="row-selector"
        >
          <Table
            columns={column}
            dataSource={datasource}
            className="accountTable"
            rowClassName={(record) => {
              return record?.isBack ? "blue" : "pink";
            }}
            pagination={false}
          />
        </div>
        <Pagination
          paginationData={accountStatementBody}
          setPaginationData={setAccountStatementBody}
        />
        {/* <div className="pagination">
					<ul className="pagination-rounded">
						<ul>
							<li role="presentation" aria-hidden="true" className="page-item disabled">
								<span className="backward-pagination" onClick={ResetCounter}>
									«
								</span>
							</li>
							<li className="page-item disabled">
								<span className="backward-pagination" onClick={Decrement}>
									‹
								</span>
							</li>
							<li role="presentation" className="page-item active">
								<button className="page-index-btn">{paginationData.index + 1}</button>
							</li>
							<li className="page-item disabled">
								<span className="forward-pagination" onClick={Increment}>
									›
								</span>
							</li>
							<li className="page-item disabled">
								<span className="forward-pagination" onClick={LastCounter}>
									»
								</span>
							</li>
						</ul>
					</ul>
				</div> */}
      </div>
    </>
  );
};

export default SportViewButtonPortal;
