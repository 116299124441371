import React, { useMemo } from 'react';
import style from './MathOddsLeftRowHeader.module.scss';

const MathOddsLeftRowHeader = () => {
	const bettingArray = useMemo(
		() => [
			{
				name: 'Sunrisers Hyderabad',
				numberA: { number1: 2.1, number2: 54.34 },
				numberB: { number1: 2.12, number2: 321.5 },
				numberC: { number1: 2.14, number2: 53.62 },
				numberD: { number1: 2.16, number2: 45.97 },
				numberE: { number1: 2.18, number2: 84 },
				numberF: { number1: 2.2, number2: 1.62 }
			},
			{
				name: 'Mumbai Indians',
				numberA: { number1: 1.85, number2: 1.62 },
				numberB: { number1: 1.86, number2: 96.82 },
				numberC: { number1: 1.87, number2: 51.22 },
				numberD: { number1: 1.88, number2: 121.84 },
				numberE: { number1: 1.89, number2: 298.2 },
				numberF: { number1: 1.9, number2: 47.85 }
			}
		],
		[]
	);

	const heading = ['Indian Premier league -> Sunrisers Hyderabad v Mumbai Indians -> MATCH_ODDS -> 4/18/2023 7:30:00 PM'];

	return (
		<div>
			<div className={style['heading']}>
				<p>{heading}</p>
			</div>

			<table className={style['header-table']}>
				<thead>
					<tr>
						<th className={style['runners']}>Runners</th>
						<th className={style['first-header-coloumn']}></th>
						<th className={style['second-header-coloumn']}></th>
						<th className={style['back-middle-header-coloumn']}>Back</th>
						<th className={style['lay-middle-header-coloumn']}>Lay</th>
						<th className={style['third-header-coloumn']}></th>
						<th className={style['fourth-header-coloumn']}></th>
					</tr>
				</thead>
				<tbody className={style['mathOdd_body']}>
					{bettingArray.map(data => (
						<tr key={data}>
							<td className={style['name']}>
								{data.name}
								<p>0</p>
							</td>

							<td className={style['header_first_coloumn_bet']}>
								<span className={style['span']}>{data.numberA.number1}</span> <span className={style['numb-2']}>{data.numberA.number2}</span>
							</td>
							<td className={style['header_second_coloumn_bet']}>
								<span className={style['span']}>{data.numberB.number1}</span> <span className={style['numb-2']}>{data.numberB.number2}</span>
							</td>
							<td className={style['header_third_coloumn_bet']}>
								<span className={style['span']}>{data.numberC.number1}</span> <span className={style['numb-2']}> {data.numberC.number2}</span>
							</td>
							<td className={style['header_fourth_coloumn_bet']}>
								<span className={style['span']}>{data.numberD.number1}</span> <span className={style['numb-2']}>{data.numberD.number2}</span>
							</td>
							<td className={style['header_fifth_coloumn_bet']}>
								<span className={style['span']}>{data.numberE.number1}</span> <span className={style['numb-2']}>{data.numberE.number2}</span>
							</td>
							<td className={style['header_sixth_coloumn_bet']}>
								<span className={style['span']}>{data.numberF.number1}</span> <span className={style['numb-2']}>{data.numberF.number2}</span>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<div className={style['bottom']}>
				<p>Bookmarker Market 0% commission fast bet confirm</p>
			</div>
		</div>
	);
};
export default MathOddsLeftRowHeader;
