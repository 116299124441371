import { Spin, Table } from "antd";
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RejectedBet } from "../../../Redux/feature/RajectedBet/action";
import { useParams } from "react-router";
import { selectRejectedBets } from "../../../Redux/feature/RajectedBet/selector";
import { FaSpinner } from "react-icons/fa";

const columns = [
  {
    title: "Rate",
    dataIndex: "pricevalue",
    key: "pricevalue",
  },
  {
    title: "Amount",
    dataIndex: "stake",
    key: "stake",
  },
  // {
  //   title: 'Type',
  //   dataIndex: 'type',
  //   key: 'type',
  // }
  // ,
  {
    title: "Team",
    dataIndex: "matchname",
    key: "matchname",
  },
  {
    title: "Client",
    dataIndex: "userid",
    key: "userid",
  },
  {
    title: "Agent",
    dataIndex: "dealerid",
    key: "dealerid",
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Loss",
    dataIndex: "stake",
    key: "stake",
  },
  {
    title: "Profit",
    dataIndex: "pnl",
    key: "pnl",
  },
  {
    title: "Bet Status",
    dataIndex: "bet_status",
    key: "bet_status",
  },
];

const RejectedBets = () => {
  const dispatch = useDispatch();

  const {id} = useParams()

  useEffect(()=>{
dispatch(RejectedBet({
	matchid: id
}))
  }, [id])

  const selector = useSelector(selectRejectedBets)

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  selector?.loading ,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }


  return (
    <div>
      <div>
        <p className="match_bet">REJECTED And CANCELLED Bets</p>
      </div>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          pagination={false}
          dataSource={selector?.data}
          style={{ cursor: "pointer" }}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default RejectedBets;
