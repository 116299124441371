import React from 'react'
import SportDetailsTabs from './SportDetailsTabs'
import './SportDetails.scss'

const SportDetails = () => {
  return (
    <div>
        <SportDetailsTabs/>
    </div>
  )
}

export default SportDetails