import React, { useEffect, useMemo } from "react";
// import axiosInstance from '../../../Services/AxiosInstance';
import { useParams } from "react-router";
import style from "./MarketAnalysisTable.module.scss";
import MarketAnalysisLeft from "./MarketAnalysisLeft";
import MarketAnalysisRight from "./MarketAnalysisRight";
import colors from "../../../colors.module.scss";
import MarketAnalysisNav from "./MarketAnalysisNav";
import { useState } from "react";
import LeftButtons from "./LeftButtons";

const MarketAnalysis = () => {
  const [activeKey, setActiveKey] = useState();
  const [data, setData] = useState([]);



  useEffect(() => {
    if (activeKey === undefined) {
     
      if (data?.Odds?.[0]?.marketId)
        setActiveKey([data.Odds?.[0]?.marketId, "2"]);
    }

    return () => {};
  }, [data]);

  // const navValue = useMemo(() => <MarketAnalysisNav />, []);

  return (
    <div className={style["table_cont"]}>
      <div className={style["table_top"]}>{/* <MarketAnalysisNav /> */}</div>
      <div>
        <LeftButtons betData={data} setActiveKey={setActiveKey} />
      </div>

      <div className={style["table_bottom"]}>
        <div className={style["table-left"]}>
          <MarketAnalysisLeft
            setSatevalue={setData}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
          />
        </div>
        <div className={style["table-right"]}>
          <MarketAnalysisRight />
        </div>
      </div>
    </div>
  );
};

export default MarketAnalysis;
