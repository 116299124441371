import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

export const CasinoApi = createApi({
  reducerPath: "CasinoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://adminapi.247idhub.com/admin-new-apis",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes:["popup"],
  endpoints: (build) => ({
    casinoDetails: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/casino-plus-minus`,
          method: "POST",
          body,
        };
      },
    }),
    casinoPlusMinus: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/casino-plus-minus-real`,
          method: "POST",
          body,
        };
      },
    }),
    casinoAllGame: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/casino-round-wise-pnl`,
          method: "POST",
          body,
        };
      },
    }),
    casinoRoundWise: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/casino-round-wise-bet-list`,
          method: "POST",
          body,
        };
      },
    }),
    filterByUser: build.mutation({
      query: (body) => {
        return {
          url: `/bmx/bmx-user-details`,
          method: "POST",
          body,
        };
      },
    }),
    profitLossLedger: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/bmx-ledger-profit-loss`,
          method: "POST",
          body,
        };
      },
    }),
    downlineLedger: build.query({
      query: (body) => {
        return {
          url: `/bmx/report/get-ledger-by-user-type`,
          method: "POST",
          body,
        };
      },
      providesTags: ["popup"]
    }),
    dataReport: build.mutation({
      query: (body) => {
        return {
          url: `/bmx/report/bmx-data-reports`,
          method: "POST",
          body,
        };
      },
    }),
    commReport: build.mutation({
      query: (body) => {
        return {
          url: `/bmx/report/bmx-casino-reports`,
          method: "POST",
          body,
        };
      },
    }),
    profitLoss: build.query({
      query: (body) => {
        return {
          url: `/bmx/profit-loss`,
          method: "POST",
          body,
        };
      },
    }),
    complteFancyOddsClients: build.query({
      query: (body) => ({
        url: "/bmx/report/completed-fancy-odds-clients",
        method: "POST",
        body,
      }),
    }),
    sportPlusMinus: build.query({
      query: (body) => ({
        url: "/bmx/report/match-and-fancy-plus-minus",
        method: "POST",
        body,
      }),
    }),
    dashboard: build.mutation({
      query: () => ({
        url: "/bmx/user/bmx-dashboard",
        method: "POST",
      }),
    }),
    logout: build.mutation({
      query: () => ({
        url: "/login/logout",
        method: "POST",
      }),
    }),
    sportListbyID: build.query({
      query: (body) => ({
        url: "/sport/active-sport-list",
        method: "POST",
        body,
      }),

    }),
    createCasinoList: build.mutation({
      query: (body) => ({
        url: `/user/alloted-casino-list`,
        method: "POST",
        body
      }),
    }),
    searchUserDownline: build.query({
      query: (args) => {
        const { term } = args;
        return {
          url: `/user/search-user-downline?term=${term}&_type=${term}&q=${term}`,
          method: "POST",
        }
      },
    }),
    accountstatement: build.query({
      query: (body) => ({
        url: "/bmx/report/account-statement",
        method: "POST",
        body,
      }),
    }),
    SearchBetMarketId: build.query({
      query: (body) => ({
        url: "/bets/search-bet-market-and-user",
        method: "POST",
        body,
      }),
    }),
    allotedCasino: build.mutation({
      query: (body) => ({
        url: "/user/alloted-casino-list",
        method: "POST",
        body,
      }),
    }),
    appDetails: build.query({
      query: (body) => ({
        url: "/admin/app-detail",
        method: "POST",
        body,
      }),
    }),
    getCasino: build.mutation({
      query: (body) => ({
        url: "/user/get-casino-bet-lock",
        method: "POST",
        body,
      }),
    }),
    updateCasino: build.mutation({
      query: (body) => ({
        url: "/user/update-casino-lock",
        method: "POST",
        body,
      }),
    }),
    marketAnalysis: build.mutation({
      query: (body) => ({
        url: "/market-analysis/dashboard",
        method: "POST",
        body,
      }),
    }),
    accStatement: build.mutation({
      query: (body) => ({
        url: "/bmx/report/account-statement",
        method: "POST",
        body,
      }),
      
    }),
    exposureLimit: build.mutation({
      query: (body) => ({
        url: "/matchBox/matchBox_credit-Exposure-amount",
        method: "POST",
        body,
      }),
    }),
    selfByAppUrl: build.mutation({
      query: (body) => ({
        url: "/login/is-self-by-app-url",
        method: "POST",
        body,
      }),
    }),
    casinoBetListNew: build.query({
      query: (body) => ({
        url: `/bmx/report/casino-plus-minus-real-new`,
        method: "POST",
        body
      }),
    }),
    exposureBetList: build.query({
      query: (body) => ({
        url: `/bets/open-betlist-by-uplineid`,
        method: "POST",
        body
      }),
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: `/bmx/user/create`,
        method: "POST",
        body
      }),
    }),
    clientLedger: build.mutation({
      query: (body) => ({
        url: `/bmx/report/client-up-line-amount`,
        method: "POST",
        body
      }),
    }),
    isUserAvailable: build.mutation({
      query: (body) => ({
        url: `/user/is-userid-available`,
        method: "POST",
        body
      }),
    }),
    partnership: build.mutation({
      query: (body) => ({
        url: "/bmx/report/partnership-by-userid",
        method: "POST",
        body,
      }),
    }),
    cashTransactionPopup: build.mutation({
      query: (body) => ({
        url: `/bmx/create-cash-transaction-popup-form`,
        method: "POST",
        body
      }),
      invalidatesTags: ["popup"]
    }),
    setCommission: build.mutation({
      query: (body) => ({
        url: `/commission-set-subadmin/set-commission`,
        method: "POST",
        body
      }),
    }),
    validateJwt: build.mutation({
      query: (body) => ({
        url: `/util/validate-jwt-token`,
        method: "POST",
        body
      }),
    }),
    getCommission: build.mutation({
      query: (body) => ({
        url: `/commission-set-subadmin/get-commission`,
        method: "POST",
        body
      }),
    }),
    WinnerPnl: build.mutation({
      query: (body) => ({
        url: `/bets/winner-pnl`,
        method: "POST",
        body
      }),
    }),
    userData: build.query({
      query: (body) => ({
        url: `/bmx/user/get-user-data-for-create-user`,
        method: "POST",
        body
      }),
    }),

  }),
});

export const {
  useCasinoDetailsQuery,
  useCasinoPlusMinusQuery,
  useCasinoAllGameQuery,
  useCasinoRoundWiseQuery,
  useFilterByUserMutation,
  useProfitLossLedgerQuery,
  useDownlineLedgerQuery,
  useDataReportMutation,
  useCommReportMutation,
  useProfitLossQuery,
  useComplteFancyOddsClientsQuery, 
  useSportPlusMinusQuery,
  useDashboardMutation,
  useLogoutMutation, 
  useSportListbyIDQuery,
  useCreateCasinoListMutation,
  useLazySearchUserDownlineQuery,
  useLazyAccountstatementQuery,
  useLazySearchBetMarketIdQuery,
  useAllotedCasinoMutation,
  useAppDetailsQuery,
  useGetCasinoMutation,
  useUpdateCasinoMutation, 
  useMarketAnalysisMutation,
  useAccStatementMutation,
  useExposureLimitMutation,
  useSelfByAppUrlMutation,
  useCasinoBetListNewQuery,
  useExposureBetListQuery,
  useCreateUserMutation,
  useClientLedgerMutation,
  useIsUserAvailableMutation,
  usePartnershipMutation, 
  useCashTransactionPopupMutation,
  useSetCommissionMutation,
 useValidateJwtMutation,
 useGetCommissionMutation,
 useWinnerPnlMutation,
 useUserDataQuery
} = CasinoApi;
