import React from "react";
import LoaderImg from "../Loader/Loader.svg";

const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "0px",
        left: "0",
        width: "100%",
        height: "100vh",
        zIndex: "99999999990",
        background: " rgba(0,0,0,0.1)",
      }}
    >
      <img src={LoaderImg} alt="load" style={{ width: "100px" }} />
    </div>
  );
};

export default Loading;
