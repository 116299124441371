import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ActiveInactive } from '../../../Services/ApiEndingPoint';
import { axiosInstance } from '../../../Services/AxiosInstance';

export const activeInactive = createAsyncThunk('inactiv-eactive', async (payload, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(ActiveInactive, { ...payload });
		if (response) {
			return response?.data?.data;
		}
	} catch (error) {
		return rejectWithValue(error);
	}
});

