import React, { useState } from 'react';
import MarketAnalysisFancyTable from './MarketAnalysisFancyTable';

const MarketAnalysisFancy = ({ betData, keys, prev, fancyPnl, setShowModal, onBetLockApi, betStatus }) => {
	const [dataHidden, setDataHidden] = useState(false);
	// name={keyName}
	// data={betData[keyName]}
	// prev={prevState[keyName]}

	return (
		<>
			<MarketAnalysisFancyTable
				setDataHidden={setDataHidden}
				dataHidden={dataHidden}
				fancyData={betData}
				keys={keys}
				setShowModal={setShowModal}
				prev={prev}
				fancyPnl={fancyPnl}
				betLockApi={onBetLockApi}
				betStatus={betStatus}
				// fancyBook={fancyBookData}
			/>
		</>
	);
};

export default MarketAnalysisFancy;
