import React from 'react';
import style from './TeenPatti.module.scss';
import IframeContainer from '../IFrame/IframeContainer';

const TeenPatti = () => {
	const lucky7Iframe = 'http://247idhub.com:3042/51';

	return (
		<div className={style['teen_patticont']} style={{ padding: '0!important' }}>
			<iframe class="live-iframe" className={style['tp-iframe']} title="score-iframe" src={lucky7Iframe}></iframe>
		</div>
	);
};

export default TeenPatti;
