import React, { useEffect, useMemo, useState } from 'react';
import { ImCross } from 'react-icons/im';
import './styles.scss';
import { Menu } from 'antd';
import { FiPlus } from 'react-icons/fi';
import { HiOutlineMinusSm } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { sliderData } from '../../../Redux/feature/Slider/selector';
import { sportsDataSlider } from '../../../Redux/feature/Slider/action';
import { matchDataDataSelect } from '../../../Redux/feature/MatchData/selector';
import { Link } from 'react-router-dom';

const SiderComp = ({ toggleCollapsed, setToggleCollapsed, onClose }) => {
	//Link to open match
	const response = useSelector(matchDataDataSelect);
	const sportData = response?.data?.data;

	const dispatch = useDispatch();
	const selector = useSelector(sliderData);
	const slidingData = selector?.data?.data;
	const [menuKey, setMenuKey] = useState([]);
	const handleClick = key => {
		const newKey = [];
		if (key?.length && key[key.length - 1]?.toString().includes('sport-')) {
			setMenuKey([key[key.length - 1]]);
			return;
		}
		if (menuKey?.length) {
			for (let currentKey of menuKey) {
				if (key.includes(currentKey)) {
					newKey.push(currentKey);
				} else {
					break;
				}
			}
			if (key?.length > menuKey.length) {
				newKey.push(key.pop());
			}
			setMenuKey(newKey);
		} else {
			// is first modal open
			setMenuKey(key);
		}
	};
	useEffect(() => {
		dispatch(sportsDataSlider({}));
	}, []);

	const items = useMemo(() => {
		return slidingData?.map((el, index) => {
			return {
				key: index + 1,
				label: el?.sportName,
				children: el?.matchList?.map((curEl, ind) => {
					return {
						key: curEl?.matchId,
						icon: <div className="icon-vicon"></div>,
						label: <Link onClick={onClose} to={`/detail/${el?.sportId}/${curEl?.matchId}`}> {curEl?.matchName} </Link>
					};
				})
			};
		});
	}, [slidingData]);	


	const handleClose = () => {
	  };

	// const items = slidingData?.map((el, index) => {
	// 	return {
	// 		key: index + 1,
	// 		label: el?.sportName,
	// 		// icon: "icon",
	// 		children: el?.matchList?.map((curEl, ind) => {
	// 			return {
	// 				key: ind,
	// 				label: curEl?.matchName,
	// 				icon: (
	// 					<>
	// 						<div className="icon-vicon"></div>
	// 					</>
	// 				)
	// 			};
	// 		})
	// 	};
	// });

	return (
		<>
			<div className="sider-container">
				<div className="sider-cross-icon" onClick={() => setToggleCollapsed(!toggleCollapsed)}>
					<ImCross />
				</div>
				<h2>Sports</h2>

				<Menu
					onOpenChange={handleClick}
					mode="inline"
					items={items}
					onClose={handleClose}
					inlineIndent={5}
					openKeys={[...menuKey]}
					expandIcon={props => props.isSubMenu && <div className="icon-div">{props.isOpen ? <HiOutlineMinusSm /> : <FiPlus />}</div>}
					className="sider-menu"
				/>
			</div>
		</>
	);
};

export default SiderComp;
