import React, { useState } from "react";
import style from "./MarketAnalysisFancyLeft.module.scss";
import { AiOutlineDown } from "react-icons/ai";
// import axiosInstance from '../../../Services/AxiosInstance';

import { useParams } from "react-router";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { Empty, Modal } from "antd";
import FancyBookPortal from "../Portals/FancyBookPortal";
import "./styles.scss";
import { IoIosArrowForward } from "react-icons/io";

const backColor = ["#72BBEF", "#72BBEFA3", "#72BBEFA3"];
const layColor = ["#F994BA", "#F994BACC", "#F994BACC"];

const MarketAnalysisFancyLeft = ({
  fancyPnl,
  fancyData,
  keys,
  prev,
  betLockApi,
  betStatus,
}) => {
  const [dataHidden, setDataHidden] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const userType = localStorage.getItem("userType");

  const { id } = useParams();

  const oddAbbrev = {
    Fancy2: "F2",
    Fancy3: "F3",
    OddEven: "OE",
  };

  const [fancyBookData, setFancyBookData] = useState([]);

  const fancyBookPortal = async (e) => {
    setShowModal("fancy");
    const endPoint = "bets/fancy-book";
    const response = await axiosInstance.post(endPoint, {
      matchId: id,
      fancyId: e,
    });
    setFancyBookData(response?.data?.data);
  };

  function formatNumber(num) {
    if (num >= 1000) {
      return num / 1000 + "K";
    }
    return num;
  }

  return (
    <>
      <div className={style["fancy_cont"]}>
        {/* <> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            background: "#2C3E50",
            alignItems: "center",
          }}
        >
          <div
            className={style["fancy-header"]}
            onClick={() => setDataHidden(!dataHidden)}
            style={{ cursor: "pointer" }}
          >
            <span style={{ position: "", top: "2px" }}>
              {dataHidden ? <IoIosArrowForward /> : <AiOutlineDown />}
            </span>
            <span>
              <p>{keys}</p>
            </span>
          </div>

          {userType === "4" && (
            <div
              style={{
                width: "20%",
                color: "white",
                background: "#0088CC",
                padding: "5px",
                marginRight: "10px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => betLockApi(oddAbbrev[keys])}
            >
              {betStatus?.find((res) => res === oddAbbrev[keys])
                ? "Bet / Unlock"
                : "Bet Lock"}
            </div>
          )}
        </div>

        <table
          className={style["fancy-table"]}
          style={{ display: dataHidden && "none" }}
        >
          <thead>
            <tr>
              <div className={style["header"]}>
                <th className={style["left"]}></th>
              </div>
              <div className={style["heading"]}>
                <th className={style["no"]}>No</th>
                <th className={style["yes"]}>Yes</th>
              </div>
              <div className={style["amount-cont"]}>
                <th className={style["amount"]}></th>
              </div>
            </tr>
          </thead>

          <tbody>
            {fancyData?.map((el, index) => {
              let bet = 0;
              if (fancyPnl?.data) {
                if (Object?.keys(fancyPnl?.data)?.includes(el?.sid)) {
                  bet = fancyPnl?.data[el?.sid];
                }
              }
              return (
                <tr className={style["body_tr"]} key={index}>
                  <td className={style["results"]}>
                    {el.nation}
                    <p
                      style={{
                        // marginBottom: "2px",
                        // marginTop: "0px",
                        color: bet >= 0 ? "green" : "red",
                        cursor: "pointer",
                      }}
                      onClick={() => fancyBookPortal(el?.sid)}
                    >
                      {bet}
                    </p>
                    {/* <p>{fancyPnl?.data ? fancyPnl?.data : 0}</p> */}
                  </td>

                  <div className={style["bet_rates"]}>
                    <div className={style["data_cont"]}>
                      {el?.gstatus === "SUSPENDED" ? (
                        <div className={style["suspended-cont"]}>
                          <p className={style["suspended"]}>{el?.gstatus}</p>{" "}
                        </div>
                      ) : (
                        ""
                      )}

                      <td
                        className={style["back"]}
                        style={{
                          backgroundColor:
                            el?.b1 > prev[index]?.b1
                              ? "#03B37F"
                              : el?.b1 < prev[index]?.b1
                              ? "#FC4242"
                              : layColor[0],
                        }}
                      >
                        <span className={style["span"]}>{el?.l1}</span>{" "}
                        <span className={style["numb-2"]}>{el?.ls1}</span>
                      </td>
                      <td
                        className={style["lay"]}
                        style={{
                          backgroundColor:
                            el?.l1 > prev[index]?.l1
                              ? "#03B37F"
                              : el?.l1 < prev[index]?.l1
                              ? "#FC4242"
                              : backColor[0],
                        }}
                      >
                        <span className={style["span"]}>{el?.b1}</span>{" "}
                        <span className={style["numb-2"]}>
                          {formatNumber(el?.bs1)}
                        </span>
                      </td>
                    </div>
                  </div>

                  <div className={style["min-max"]}>
                    <div></div>
                    <td>min:{formatNumber(el?.minBet)}</td>
                    <td>max:{formatNumber(el?.maxBet)}</td>
                  </div>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <Modal
        title="Run Amount"
        open={showModal}
        footer={null}
        className="fancy-book-modal"
        destroyOnClose
        onCancel={() => setShowModal("")}
      >
        {fancyBookData ? (
          <FancyBookPortal fancyBookData={fancyBookData} />
        ) : (
          <Empty />
        )}
      </Modal>
    </>
  );
};

export default MarketAnalysisFancyLeft;
