import { Link } from "react-router-dom";
import {
  ACC_STATEMENT,
  CURRENT_BETS,
  USER_HISTORY,
  BET_HISTORY,
  GENERAL_REPORT,
  GAME_REPORT,
  CASINO_REPORT,
  CASINO_RESULT_REPORT,
  PROFIT_LOSS,
  CASH_TRANSACTION_S,
  MY_LEDGER,
  SPORT_DETAILS,
  AURA_DETAILS,
  SUPERNOWA_DETAILS,
  CASH_TRANSACTION_A,
  CASH_TRANSACTION_M,
  CASH_TRANSACTION_C,
  PROFIT_LOSS_EVENT,
  LEDGER_SUPER,
  LEDGER_MASTER,
  LEDGER_AGENT,
  LEDGER_CLIENT,
  REPORT_SUPER,
  REPORT_MASTER,
  REPORT_AGENT,
  REPORT_CLIENT,
  COMM_REPORT_SUPER,
  COMM_REPORT_MASTER,
  COMM_REPORT_AGENT,
  COMM_REPORT_CLIENT,
  Q_TECH,
  CASH_TRANSACTION_AD,
  LEDGER_ADMIN,
  REPORT_ADMIN,
  COMM_REPORT_ADMIN,
} from "../../../utils/linkConstant";
import { refreshRef, showDrawerRef } from "./HeaderComponent";

const userType = localStorage.getItem("userType");

console.log(userType === 0 || userType === 5, "Dsfdsfdsf")

export const itemsData = [
  {
    key: "f1",
    // label: <Link to="/account-statement">Account's Statement</Link>

    label: (
      <Link
        onClick={() => {
          refreshRef();
          showDrawerRef();
        }}
        to={ACC_STATEMENT}
      >
        Account's Statement
      </Link>
    ),
  },
  {
    key: "f2",
    label: (
      <Link
        onClick={() => {
          refreshRef();
          showDrawerRef();
        }}
        to={CURRENT_BETS}
      >
        Current bets
      </Link>
    ),
  },
  {
    key: "f3",
    label: (
      <Link
        onClick={() => {
          refreshRef();
          showDrawerRef();
        }}
        to={USER_HISTORY}
      >
        User History
      </Link>
    ),
  },
  {
    key: "f4",
    label: (
      <Link
        onClick={() => {
          refreshRef();
          showDrawerRef();
        }}
        to={BET_HISTORY}
      >
        Bet history
      </Link>
    ),
  },

  // {
  //   key: "f5",
  //   label: (
  //     <Link
  //       onClick={() => {
  //         refreshRef();
  //         showDrawerRef();
  //       }}
  //       to={PROFIT_LOSS}
  //     >
  //       Profit And Loss
  //     </Link>
  //   ),
  // },
  // {
  // 	key: '6',
  // 	label: <Link to={CASH_TRANSACTION}>Cash Transaction</Link>
  // },
  // {
  // 	key: '7',
  // 	label: <Link to={MY_LEDGER}>My Lena Dena</Link>
  // },
  // {
  // 	key: '8',
  // 	label: <Link to={CASINO_REPORT}>Casino Reports</Link>
  // },

  // {
  // 	key: '9',
  // 	label: <Link to={CASINO_RESULT_REPORT}>Casino Result Report</Link>
  // }
];

export const liveMarketData = [
  {
    key: "l1",
    label: <Link to="live-market-binary">Binary</Link>,
  },
  {
    key: "l2",
    label: <Link to="">Race 20-20</Link>,
  },
  {
    key: "l3",
    label: <Link to="">Queen</Link>,
  },
  {
    key: "l4",
    label: <Link to="">Baccarat</Link>,
  },
  {
    key: "l5",
    label: <Link to="">Sports Casino</Link>,
  },
  {
    key: "l6",
    label: <Link to="">Casino War</Link>,
  },
  {
    key: "l7",
    label: <Link to="">Worli</Link>,
  },
  {
    key: "l8",
    label: <Link to="">3 Cards Judgement</Link>,
  },
  {
    key: "l9",
    label: <Link to="">32 Cards Casino</Link>,
  },
  {
    key: "l10",
    label: <Link to="">Live TeenPatti</Link>,
  },
  {
    key: "l11",
    label: <Link to="live-market-teen-patti">TeenPatti 2.0</Link>,
  },
  {
    key: "l12",
    label: <Link to="">Live Poker</Link>,
  },
  {
    key: "l13",
    label: <Link to="">Andar Bahar</Link>,
  },
  {
    key: "l14",
    label: <Link to="live-market-lucky">Lucky 7</Link>,
  },
  {
    key: "l15",
    label: <Link to="live-market-dragon">Dragon Tiger</Link>,
  },
  {
    key: "l16",
    label: <Link to="live-market-bollywood">Bollywood Casino</Link>,
  },

  {
    key: "l17",
    label: <Link to="">Cricket Casino</Link>,
  },
];


export const sportDetails = [
  {
    key: "s1",
    label: <Link to={SPORT_DETAILS}>Sport Details</Link>,
  },
  {
    key: "s2",
    label: <Link to={AURA_DETAILS}>Aura Details</Link>,
  },
  {
    key: "s3",
    label: <Link to={SUPERNOWA_DETAILS}>Supernowa Details</Link>,
  },
  {
    key: "s3",
    label: <Link to={Q_TECH}>Q Tech Details</Link>,
  },
  
];


console.log(typeof userType, "DFdsfdsfdsf")

export const cashTran = [
  {
    key: "c0",
    className:`${userType == 4 ?"": "d-none "}`,
    label: <Link  to={CASH_TRANSACTION_AD}>Cash Transaction(SA)</Link>,
  },
  {
    key: "c1",
    className: `${userType != 5 ? "d-none" : ""}`,
    label: <Link  to={CASH_TRANSACTION_S}>Cash Transaction(SM)</Link>,
  },
  {
    key: "c2",
    className: `${userType == 0 ? "" : "d-none"}`,
    label: <Link to={CASH_TRANSACTION_M}>Cash Transaction(M)</Link>,
  },
  {
    key: "c3",
    className: `${userType == 1 ? "" : "d-none"}`,
    label: <Link to={CASH_TRANSACTION_A}>Cash Transaction(A)</Link>,
  },
  // {
  //   key: "c4",
  //   label: <Link to={CASH_TRANSACTION_C}>Cash Transaction(C)</Link>,
  // },
]

export const ledgerData = 
[
  {
    key: "l1",
    label: <Link to={PROFIT_LOSS_EVENT}>Profit/Loss</Link>,
  },
  {
    key: "l2",
    label: <Link to={MY_LEDGER}>My Lena Dena</Link>,
  },
  {
    key: "l3",
    className: `${userType != 4 ? "d-none" : ""}`,
    label: <Link to={LEDGER_ADMIN}>Sub Admin Lena Dena</Link>,
  },
  {
    key: "l4",
    className: `${userType != 5 ? "d-none" : ""}`,
    label: <Link to={LEDGER_SUPER}>Super Master Lena Dena</Link>,
  },
  {
    key: "l5",
    className: `${userType == 0 ? "" : "d-none"}`,
    label: <Link to={LEDGER_MASTER}>Master Lena Dena</Link>,
  },
  {
    key: "l6",
    className: `${userType == 1 ? "" : "d-none"}`,
    label: <Link to={LEDGER_AGENT}>Agent Lena Dena</Link>,
  },
  {
    key: "l7",
    className: `${userType == 4 ? "d-none" : ""}`,
    label: <Link to={LEDGER_CLIENT}>Client Lena Dena</Link>,
  },
]

export const dataReport = [
  {
    key: "r1",
    className: `${userType != 4 ? "d-none" : ""}`,
    label: <Link to={REPORT_ADMIN}>Sub Admin Report</Link>,
  },
  {
    key: "r1",
    className: `${userType != 5 ? "d-none" : ""}`,
    label: <Link to={REPORT_SUPER}>Super Master Report</Link>,
  },
  {
    key: "r2",
    className: `${userType == 0 ? "" : "d-none"}`,
    label: <Link to={REPORT_MASTER}>Master Report</Link>,
  },
  {
    key: "r3",
    className: `${userType == 1 ? "" : "d-none"}`,
    label: <Link to={REPORT_AGENT}>Agent Report</Link>,
  },
  {
    key: "r4",
    className: `${userType == "4" ? "d-none" : ""}`,
    label: <Link to={REPORT_CLIENT}>Client Report</Link>,
  },
  
]
export const commReport = [
  {
    key: "co1",
    className: `${userType != 4 ? "d-none" : ""}`,
    label: <Link to={COMM_REPORT_ADMIN}>Sub Admin Report</Link>,
  },
  {
    key: "co1",
    className: `${userType != 5 ? "d-none" : ""}`,
    label: <Link to={COMM_REPORT_SUPER}>Super Master Report</Link>,
  },
  {
    key: "co2",
    className: `${userType == 0 ? "" : "d-none"}`,
    label: <Link to={COMM_REPORT_MASTER}>Master Report</Link>,
  },
  {
    key: "co3",
    className: `${userType == 1 ? "" : "d-none"}`,
    label: <Link to={COMM_REPORT_AGENT}>Agent Report</Link>,
  },
  {
    key: "co4",
    className: `${userType == "4" ? "d-none" : ""}`,
    label: <Link to={COMM_REPORT_CLIENT}>Client Report</Link>,
  },
  
]
