import React, { useState, useCallback, useEffect, useContext } from "react";
import style from "./AccountListCRCreditDeposit.module.scss";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import ContextPortal from "../../../Context/PortalContext";
import { message } from "antd";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { activeInactiveUserSelect } from "../../../Redux/feature/AccountListTable/selector";
import { activeInactive } from "../../../Redux/feature/AccountListTable/action";
import { activeInactiveRef } from "../accountListTable/AccountListTable";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListCRCreditDeposit = (props) => {
  const selector = useSelector(activeInactiveUserSelect);

  const dispatch = useDispatch();

  const ctx = useContext(ContextPortal);
  const [creditDeposit, setCreditDeposit] = useState([]);

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    depositCRApi();
  }, []);

  const depositCRApi = async () => {
    loadingRef("dwcreditdata", true);
    const endPoint = "dwc/creditdata";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        { userId: props?.userDetail?.userId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setCreditDeposit(res?.data?.data);
      });
    loadingRef("dwcreditdata", false);
  };

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    resetForm,
    handleSubmit,
    errors,
  } = useFormik({
    initialValues: {
      amount: "",
      remark: "",
      lupassword: "",
      userId: props?.userDetail?.userId,
    },
    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        remark: "" == values?.remark ? "Remark field is required. " : undefined,
        amount: "" == values.amount ? "Amount field is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },
    onSubmit: async () => {
      const endPoint = "dwc/dcr";
      loadingRef("dwcdcreditdata", true);
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status) {
          resetForm();
          props.inactiveActiveUser();
          props.portalHidden(false);
          loadingRef("dwcdcreditdata", false);
          return toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (response) {
        loadingRef("dwcdcreditdata", false);
        return toast.error(response?.response?.data?.message);
      }
      loadingRef("dwcdcreditdata", false);
    },
  });

  return (
    <form
      className={style["credit-deposit_portal-form"]}
      onSubmit={handleSubmit}
    >
      <div className={style["same"]}>
        <label>{creditDeposit?.parentName}</label>
        <input
          className={style["credit-deposit_portal-form_input"]}
          disabled
          type="number"
          value={creditDeposit?.parentAmount}
        />
        <input
          className={style["credit-deposit_portal-form_input"]}
          type="number"
          disabled
          value={
            values.amount
              ? Number(creditDeposit?.parentAmount) - Number(values.amount)
              : ""
          }
        />
      </div>

      <div className={style["same"]}>
        {/* <label>{props?.userDetail?.username}</label> */}
        <label>{creditDeposit?.childName}</label>
        <input
          className={style["credit-deposit_portal-form_input"]}
          disabled
          type="number"
          value={creditDeposit?.childAmount}
        />
        <input
          className={style["credit-deposit_portal-form_input"]}
          type="number"
          disabled
          value={
            values.amount
              ? Number(creditDeposit?.childAmount) + Number(values.amount)
              : ""
          }
        />
      </div>

      <div className={style["same"]}>
        <label>Amount</label>
        <input
          placeholder="Amount"
          name="amount"
          onChange={handleChange}
          onBlur={handleBlur}
          type="number"
          style={{
            border: `1px solid ${touched.amount && errors.amount ? "red" : ""}`,
            outline: "none",
          }}
          value={values.amount}
        />
      </div>
      {touched.amount && errors?.amount ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            bottom: "10px",
            fontSize: "13px",
          }}
        >
          {errors.amount}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Remark</label>
        <textarea
          type="text"
          name="remark"
          onChange={handleChange}
          onBlur={handleBlur}
          style={{
            border: `1px solid ${touched.remark && errors.remark ? "red" : ""}`,
            outline: "none",
          }}
          value={values.remark}
        />
      </div>
      {touched.remark && errors?.remark ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            bottom: "10px",
            fontSize: "13px",
          }}
        >
          {errors.remark}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Transaction Code</label>
        <input
          type={showPass ? "text" : "password"}
          name="lupassword"
          onChange={handleChange}
          value={values?.lupassword}
          style={{
            border: `1px solid ${
              touched?.lupassword && errors?.lupassword ? "red" : ""
            }`,
            outline: "none",
          }}
        />
        {values?.lupassword?.length > 0 ? (
          showPass ? (
            <AiFillEye
              className={style["pass-eye"]}
              onClick={() => setShowPass(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className={style["pass-eye"]}
              onClick={() => setShowPass(true)}
            />
          )
        ) : (
          ""
        )}
      </div>
      {touched?.lupassword && errors?.lupassword ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "33%",
            fontSize: "13px",
            bottom: "10px",
          }}
        >
          {errors?.lupassword}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["credit-deposit_buttons"]}>
        {/* <button type="submit">Submit</button> */}
        <button style={{ cursor: "pointer" }}>Submit</button>
      </div>
    </form>
  );
};

export default AccountListCRCreditDeposit;
