import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import style from "./AccountListDeposit.module.scss";
import ContextPortal from "../../../Context/PortalContext";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { message } from "antd";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListDeposit = (props) => {
  const ctx = useContext(ContextPortal);

  const [showPass, setShowPass] = useState(false);

  const [depositData, setDepositData] = useState({});

  const [depositPnl, setDepositPnl] = useState(
    !depositData.childUplineAmount ? 0 : depositData.childUplineAmount
  );
  useEffect(() => {
    depositApi();
  }, []);

  const depositApi = async () => {
    loadingRef("dwccreditdataementditdata", true);
    const endPoint = "dwc/creditdata";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        { userId: props?.selectedRecord?.userId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setDepositData(res.data.data);
      });
    loadingRef("dwccreditdataementditdata", false);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      amount: "",
      remark: "",
      lupassword: "",
      userId: props?.selectedRecord?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        remark: "" == values?.remark ? "Remark field is required. " : undefined,
        amount: "" == values.amount ? "Amount field is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },

    // const depositTransApi =
    onSubmit: async () => {
      const endPoint = "dwc/deposit-chips-pnl";
      loadingRef("dwcdepositchipspnldata", true);
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status === 200) {
          resetForm();
          props.portalHidden(false);
          toast?.success(response?.data?.message);
        }
      } catch (response) {
        toast.error(response?.response?.data?.message);
      }
      loadingRef("dwcdepositchipspnldata", false);

      // setDepositStoredData(res);
    },
  });

  return (
    <>
      <form className={style["deposit_portal-form"]} onSubmit={handleChange}>
        {/* <ToastContainer style={{ marginTop: '150px', width: '500px', height: '200px' }} /> */}

        <div className={style["same"]}>
          {/* <label>{props.selectedRecord.userId}</label> */}
          <label>{depositData?.parentName}</label>
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={depositData?.parentAmount}
          />
          <input
            className={style["deposit_portal-form_input"]}
            // onChange={e => setDepositFirstLastField(e)}
            disabled
            value={
              values.amount
                ? Number(depositData?.parentAmount) - Number(values.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          {/* <label>{props.selectedRecord.username}</label> */}
          <label>{depositData?.childName}</label>
          {/* <lable>{props.portalType === "Deposit" ?  }</lable> */}
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={depositData.childAmount}
          />
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={
              values.amount
                ? Number(depositData.childAmount) + Number(values.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>Profit/Loss</label>
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            type="number"
            value={depositPnl}
          />
          <input
            className={style["deposit_portal-form_input"]}
            type="number"
            disabled
            value={
              values.amount ? Number(depositPnl) + Number(values.amount) : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>Amount</label>
          <input
            placeholder="Amount"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            name="amount"
            value={values.amount}
            style={{
              border: `1px solid ${
                touched.amount && errors.amount ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched?.amount && errors?.amount ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "-4%",
            }}
          >
            {errors.amount}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Remark</label>
          <textarea
            onChange={handleChange}
            name="remark"
            onBlur={handleBlur}
            value={values.remark}
            style={{
              border: `1px solid ${
                touched?.remark && errors?.remark ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched?.remark && errors?.remark ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "-3%",
            }}
          >
            {errors.remark}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Transaction Passsword</label>
          <input
            placeholder="Transaction Password"
            type={showPass ? "text" : "password"}
            onChange={handleChange}
            value={values.lupassword}
            onBlur={handleBlur}
            name="lupassword"
            style={{
              border: `1px solid ${
                touched.lupassword && errors.lupassword ? "red" : ""
              }`,
              outline: "none",
            }}
          />
          {values?.lupassword?.length > 0 ? (
            showPass ? (
              <AiFillEye
                className={style["deposit-eye"]}
                onClick={() => setShowPass(false)}
              />
            ) : (
              <AiFillEyeInvisible
                className={style["deposit-eye"]}
                onClick={() => setShowPass(true)}
              />
            )
          ) : (
            ""
          )}
        </div>
        {touched.lupassword && errors?.lupassword ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "5%",
            }}
          >
            {errors.lupassword}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["deposit_buttons"]}>
          <button
            className={style["deposit_submit"]}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button onClick={ctx.portalHidden} className={style["deposit_back"]}>
            Back
          </button>
        </div>
      </form>
    </>
  );
};

export default AccountListDeposit;
