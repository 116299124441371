import { Button, Form, Input, Select, Table } from "antd";
import React, { useEffect } from "react";
import style from "../Report/AccountStatement/AccountStatementHeader.module.scss";
import "./SetCommission.scss";
import { useGetCommissionMutation, useSetCommissionMutation } from "../../Services/CasinoService";
import { toast } from "react-toastify";


const columns = [
  {
    title: "Odds Comm",
    dataIndex: "oddsCommission",
  },
  {
    title: "Fancy Comm",
    dataIndex: "fancyCommission",
    key: "fancyCommission",
  },
  {
    title: "Casino Comm",
    dataIndex: "casinoCommission",
  }
];

const SetCommission = () => {
  const [form] = Form.useForm();

  const [trigger, { data, isLoading, error }] = useSetCommissionMutation();

  const fancyComm = [
    "0.00",
    "0.25",
    "0.50",
    "0.75",
    "1.00",
    "1.25",
    "1.50",
    "1.75",
    "2.00",
  ];
  const casinoComm = ["0.00", "0.25", "0.50", "0.75", "1.00"];

  const onFinish = (value) => {
    trigger({
      fancyCommission: value?.fancycomm,
      oddsCommission: value?.oddscomm,
      casinoCommission: value?.casinocomm,
    });
  };

  useEffect(()=>{
    if(data?.status == true){
        toast.success(data?.message)
        getComm()
        form.resetFields();
    }else if(error?.status == 400 || error?.data?.status == false || data?.status == false){
        toast.error(error?.data?.message || data?.message)
    }
  }, [data, error]);

  // get commission data 

const [getComm, {data: getCommData, isLoading:loading}] = useGetCommissionMutation();

useEffect(()=>{
  getComm()
},[]);


  return (
    <>
    <h2 style={{fontSize: "20px", marginTop:"-7px",
    fontWeight: "400"}}>Set Commission</h2>
    <div className={style["account_state_form"]}>
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        className="cash_form">
        <div className={style["form-button-div"]}>
          <div className="odds_value">
            <Form.Item
              label="Odds Commission"
              name="oddscomm"
              rules={[
                {
                  required: true,
                  message: "Odds Commission is required!",
                },
              ]}>
              <Select placeholder="Select Odds Comm">
                {fancyComm?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="odds_value">
            <Form.Item
              label="Fancy Commission"
              name="fancycomm"
              rules={[
                {
                  required: true,
                  message: "Fancy Commission is required!",
                },
              ]}>
              <Select placeholder="Select Fancy Comm">
                {fancyComm?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className="odds_value">
            <Form.Item
              label="Casino Commission"
              name="casinocomm"
              rules={[
                {
                  required: true,
                  message: "Casino Commission is required!",
                },
              ]}>
              <Select placeholder="Select Casino Comm">
                {casinoComm?.map((curElm) => {
                  return (
                    <>
                      <Select.Option value={curElm}>{curElm}</Select.Option>
                    </>
                  );
                })}
              </Select>
            </Form.Item>
          </div>

          <div className="odds_value">
            <Form.Item className="odds_fancy" wrapperCol={{}}>
              <Button loading={isLoading} className="submit_btn" type="" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </div>
          <div className={style["game-name"]}></div>
          <div className={style["game-name"]}></div>
          <div className={style["game-name"]}></div>
        </div>
      </Form>
      <div className="acc-cont" style={{width: "100%"}}>
        <div className="account_state_bottom"></div>
        <div className="accountlist-table-container">
          <Table
            columns={columns}
            // pagination={true}
            pagination={false}
            loading={loading}
            dataSource={[getCommData?.data]}
            style={{ cursor: "pointer" }}

          />
        </div>
      </div>
    </div>
    </>
  );
};

export default SetCommission;
