import React, { useCallback, useEffect, useState } from "react";
// import style from "./CurrentBets.module.scss";
import CurrentBetHeader from "./CurrentBetHeader";
// import CurrentBetsTable from "./CurrentBetsTable";
// import axiosInstance from '../../../Services/AxiosInstance';
// import axios from "axios";
import { axiosInstance } from "../../../Services/AxiosInstance";

const CurrentBets = () => {
  const [betsButton, setBetsButton] = useState(1);
  const [betSportType, setBetSportType] = useState(1);
  const [isDeleted, setIsDeleted] = useState(false);
  const [record, setRecord] = useState();
  const [matchId, setMatchId] = useState("");
  const [currentBet, setCurrentBet] = useState([]);

  // useEffect(() => {
  // 	CurrentBetApi();
  // }, [betsButton, records, isDeleted, betSportType]);

  // const CurrentBetApi = useCallback(async () => {
  // 	const endPoint = 'report/current-bets';
  // 	await axiosInstance.post(endPoint, { betType: +betsButton, noOfRecords: +records, sportType: +betSportType, index: 0, isDeleted: isDeleted }).then(res => {
  // 		setCurrentBet(res?.data?.data?.dataList);
  // 	});
  // }, [records, betsButton, isDeleted, betSportType]);

  // useEffect(() => {
  // 	casinoApi();
  // }, []);

  // const casinoApi = async () => {

  // 	const endPoint = 'report/bet-history';
  // 	const response = await axiosInstance.post(
  // 		endPoint,
  // 		{
  // 			index: 0,
  // 			noOfRecords: records,
  // 			sportId: casinoSelector,
  // 			matchId: matchId,
  // 			userId: '',
  // 			sportType: +betSportType
  // 		}

  // 	);
  // };

  return (
    <>
      <CurrentBetHeader
        betsButton={betsButton}
        setBetsButton={setBetsButton}
        // betsHandler={betsHandler}
        setBetSportType={setBetSportType}
        betSportType={betSportType}
        setRecord={setRecord}
        record={record}
        setMatchId={setMatchId}
        matchId={matchId}
        // casinoApi={casinoApi}
        setIsDeleted={setIsDeleted}
      />
    </>
  );

  //   const getCurrentBetTable = useMemo(
  //     () => (
  //       <CurrentBetsTable
  //         currentBet={currentBet}
  //         setRecords={setRecords}
  //         records={records}
  //       />
  //     ),
  //     [currentBet, records]
  //   );

  //   return (
  //     <>
  //       <CurrentBetHeader
  //         betsButton={betsButton}
  //         setBetsButton={setBetsButton}
  //         betsHandler={betsHandler}
  //         setBetSportType={setBetSportType}
  //         betSportType={betSportType}
  //         setRecord={setRecord}
  //         record={record}
  //         setMatchId={setMatchId}
  //         casinoSelector={casinoSelector}
  //         setCasinoSelector={setCasinoSelector}
  //         casinoApi={casinoApi}
  //         setIsDeleted={setIsDeleted}
  //       />
  //       {getCurrentBetTable}
  //     </>
  //   );
};

export default CurrentBets;
