import React, { useEffect, useState } from "react";
import style from "./LoginPage.module.scss";
import { BiLogIn } from "react-icons/bi";
import axios from "axios";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../Redux/feature/Auth/action";
import { selectLogin } from "../Redux/feature/Auth/selector";
import { loadingRef } from "../App";
import { useSelfByAppUrlMutation } from "../Services/CasinoService";

export let navRef;

const LoginPage = () => {
  const nav = useNavigate();
  navRef = nav;

  const [enteredUserName, setEnteredUserName] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const slector = useSelector(selectLogin);
  const [trigger, { data }] = useSelfByAppUrlMutation();
  const loginData = window.location.hostname.split('.');

  const LoginData = {
    userId: enteredUserName,
    password: enteredPassword,
    appUrl: window.location.hostname,
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(logIn(LoginData));
  };

  useEffect(() => {
    loadingRef("login", slector?.loading);
  }, [slector]);

  useEffect(() => {
    trigger({
      appUrl: window.location.hostname.slice(6),
    });
  }, []);

  return (
    <div className={style["login_page-cont"]}>
      <div className={style["login_page-sub-cont"]}>
        <div className={style["login_page-heading"]}><img src={data?.data?.logo} alt="logo" /></div>
        <div className={style["login_page-details-cont"]}>
          <form className={style["login_page-details"]}>
            {error && (
              <p
                style={{
                  color: "#721c24",
                  background: "#f8d7da",
                  fontSize: "14px",
                  padding: "10px",
                  width: "100%",
                }}
              >
                The User Name or Password is Incorrect
              </p>
            )}
            <p className={style["login_page-details_signIn"]}>Sign In</p>
            <input
              className={style["login_page-input"]}
              type="text"
              placeholder="Username"
              onChange={(e) => setEnteredUserName(e.target.value)}
              value={enteredUserName}
            />
            {error && <p>{error}</p>}
            <input
              className={style["login_page-input"]}
              type="password"
              placeholder="Password"
              onChange={(e) => setEnteredPassword(e.target.value)}
              value={enteredPassword}
            />
            {error && <p>{error}</p>}
            <button className={style["login_page-login"]} onClick={handleFormSubmit}>
              <span className="login" style={{ marginRight: "10px" }}>
                Login
              </span>
              <span style={{ alignItems: "center" }}>
                <BiLogIn />
              </span>
            </button>
          </form>
        </div>
        <p className={style["tagline"]}>© {loginData[1]}</p>
      </div>
    </div>
  );
};

export default LoginPage;
