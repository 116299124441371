import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import { AccountStatement } from '../../../Services/ApiEndingPoint';
import { axiosInstance } from '../../../Services/AxiosInstance';

export const accountStatement = createAsyncThunk('accountstatement', async (payload, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(AccountStatement, { ...payload });

		if (response) {
			return response?.data?.data;
		}
	} catch (error) {
		return rejectWithValue(error);
	}
});
