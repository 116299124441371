import React, { useEffect, useState } from "react";
import { Button, Checkbox, Modal, Spin, Table } from "antd";
import Deposit from "./Portals/Deposit/Deposit";
import Withdraw from "./Portals/Withdrawn/Withdraw";
import Limit from "./Portals/Limit/Limit";
import Status from "./Portals/Status/Status";
import Credit from "./Portals/Credit/Credit";
import Password from "./Portals/Password/Password";
import "./ActiveUser.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import AccountListMore from "../components/table/AccountListPortal/AccountListMore";
import AccountListCR from "../components/table/AccountListPortal/AccountListCR";
import { useDispatch, useSelector } from "react-redux";
import { activeInactiveUserSelect } from "../Redux/feature/AccountListTable/selector";
import { activeInactive } from "../Redux/feature/AccountListTable/action";
import Exposure from "./Exposure/Exposure";
import ModalsData from "../components/MarketAnalysis/ModalData/ModalData";
import { usePartnershipMutation } from "../Services/CasinoService";

const ActiveUsersColumn = ({
  filteredData,
  getRowClassName,
  activeUserDataApi,
  userName,
  setSearchQuery,
  antIcon,
  isLoading,
}) => {
  const [showModal, setShowModal] = useState("");
  const [records, setRecords] = useState(100);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemMore, setSelectedItemMore] = useState({});
  const nav = useNavigate();
  const [isWithdrawPortalVisible, setIsWithdrawPortalVisible] = useState(false);
  const [isDepositPortalVisible, setIsDepositPortalVisible] = useState(false);
  const [more, setMore] = useState(false);
  const [selectedActionData, setSelectedActionData] = useState({});
  const [isCreditDepositPortalVisible, setIsCreditDepositPortalVisible] =
    useState(false);
  const [isExposure, setIsexposure] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [userData, setUserData] = useState("");
  const [credit, setCredit] = useState(false);
  const [password, setPassword] = useState(false);
  const [limit, setLimit] = useState(false);
  const [data1, setData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: records,
    index: 0,
    totalPages: 1,
  });
  // const [partnershipDetails, setPartnershipDetails] = useState({});
  const [userIds, setUserIds] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const handleCancel = () => {
    setIsDepositPortalVisible(false);
    setIsWithdrawPortalVisible(false);
    setMore(false);
    setIsCreditDepositPortalVisible(false);
    setIsexposure(false);
    setIsModalOpen(false);
  };

  const showModalProfile = (obj) => {
    const data = data1?.find((item) => item?.id === obj);
    setUserData(data);
  };

  const [partnerShipData, { data: partnerShipDetail, isLoading: loading }] =
    usePartnershipMutation();

  const showModalPar = (val) => {
    setUserIds(val);
    const partnerShipDetail = {
      userId: val,
    };
    partnerShipData(partnerShipDetail);
    setIsModalOpen(true);
  };

  const portalHidden = (e) => {
    e.preventDefault();
    setIsDepositPortalVisible(false);
    setIsWithdrawPortalVisible(false);
    setCredit(false);
    setIsStatusChanged(false);
    setPassword(false);
    setLimit(false);
    setIsCreditDepositPortalVisible(false);
    setIsexposure(false);
    setMore(false);
  };

  const tableSelector = useSelector(activeInactiveUserSelect);

  const dispatchEvent = useDispatch();

  const inactiveActiveUser = () => {
    dispatchEvent(
      activeInactive({
        index: accountStatementBody?.index,
        noOfRecords: records,
        id: userId,
        username: userName,
      })
    );
  };

  useEffect(() => {
    inactiveActiveUser();
  }, [accountStatementBody?.index, userId, userName]);

  useEffect(() => {
    setDataSource(tableSelector?.data);
    setAccountStatementBody((prev) => ({
      ...prev,
      totalPages: tableSelector?.data?.totalPages,
    }));
  }, [tableSelector]);

  const handleActiveChild = (val) => {
    setSearchQuery("");
    nav(`/matchbox_active-user?id=${val}`);
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "username",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={
            () => handleActiveChild(record.id)
            //  setUserId(record?.userId)
          }
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.userId?.localeCompare(b?.userId),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => showModalPar(text)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },

    {
      title: "Credit Reference",
      dataIndex: "chips",
      sorter: (a, b) => a?.chips - b?.chips,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setSelectedActionData(record);
              setIsCreditDepositPortalVisible(true);
            }}
            style={{
              float: "right",
              fontSize: "14px",
              color: "#444",
              padding: "0 5px",
              cursor: "pointer",
            }}>
            {text}
          </p>
        );
      },
    },

    {
      title: "Balance",
      dataIndex: "chips",
      key: "balance",
      sorter: (a, b) => a.chips - b.chips,
      render: (text, records) => {
        return (
          <span style={{ float: "right", fontSize: "14px" }}>
            {Number(records?.chips) + Number(records?.clientPl)}
          </span>
        );
      },
    },

    // {
    //   title: "Cient (P/L)",
    //   dataIndex: "clientPl",
    //   sorter: (a, b) => a.clientPl - b.clientPl,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },
    // {
    //   title: "Default %",
    //   dataIndex: "clientPlPercentage",
    //   sorter: (a, b) => a.clientPlPercentage - b.clientPlPercentage,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },

    {
      title: "Exposure",
      dataIndex: "exposure",
      sorter: (a, b) => a.exposure - b.exposure,
      render: (text, record) => (
        <span
          onClick={() => {
            setSelectedActionData(record);
            setIsexposure(true);
          }}
          style={{ float: "right", fontSize: "14px", cursor: "pointer" }}>
          {Number(text)?.toFixed(2)}
        </span>
      ),
    },

    {
      title: "Available Balance",
      dataIndex: "availabePts",
      sorter: (a, b) => a.availabePts - b.availabePts,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "U St",
      dataIndex: "accountLock",
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record?.accountLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "B St",
      dataIndex: "betLock",
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record.betLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "Exposure Limit",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    {
      title: "PName",
      dataIndex: "pname",
      sorter: (a, b) => a.pname - b.pname,
    },

    {
      title: "Account Type",
      dataIndex: "accountType",
      sorter: (a, b) => a.accountType - b.accountType,
    },

    // {
    //   title: "Casino Total ",
    //   dataIndex: "0",
    //   sorter: (a, b) => a.age - b.age,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px", padding: "0px 4px" }}>
    //       {text}
    //     </span>
    //   ),
    // },
    userId == null
      ? {
          title: "Actions",
          dataIndex: "action",

          render: (text, record) => {
            return (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setShowModal("Deposit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  D
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Withdraw");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 8px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  W
                </Button>
                <Button
                  onClick={() => {
                    setIsCreditDepositPortalVisible(true);
                    setSelectedActionData(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}>
                  CR
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Exposure Limit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  L
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Password");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  P
                </Button>

                <Button
                  onClick={() => {
                    setShowModal("Status");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "26px",
                    borderRadius: "4px",
                    fontSize: "13.5px",
                    letterSpacing: "-1px",
                  }}>
                  S
                </Button>
                <Button
                  onClick={() => {
                    showModalProfile(record?.id);
                    setSelectedActionData(record);
                    setMore(true);

                    const { id } = record;

                    setSelectedItemMore(id);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    display: "inline-block",
                  }}>
                  MORE
                </Button>
              </div>
            );
          },
        }
      : {},
  ]

  const columnsClient = [
    {
      title: "User Name",
      dataIndex: "username",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.username?.localeCompare(b?.username),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={
            () => handleActiveChild(record.id)
            //  setUserId(record?.userId)
          }
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },
    {
      title: "User ID",
      dataIndex: "userId",
      sortDirections: ["descend", "ascend"],
      width: "10%",
      key: "name",
      sorter: (a, b) => a?.userId?.localeCompare(b?.userId),
      render: (text, record) => (
        // <a href="/users_details " >
        // onCli
        <span
          onClick={() => showModalPar(text)}
          style={{
            fontSize: "14px",
            background: "#444",
            color: "#fff",
            padding: "5px 10px",
            borderRight: "1px solid black",
            borderRadius: "4px",
            textTransform: "uppercase",
            fontWeight: "bold",
            display: "inline-block",
            cursor: "pointer",
          }}>
          {text}
        </span>
        // </a>
      ),
    },

    {
      title: "Credit Reference",
      dataIndex: "chips",
      sorter: (a, b) => a?.chips - b?.chips,
      sortDirections: ["descend", "ascend"],
      render: (text, record) => {
        return (
          <p
            onClick={() => {
              setSelectedActionData(record);
              setIsCreditDepositPortalVisible(true);
            }}
            style={{
              float: "right",
              fontSize: "14px",
              color: "#444",
              padding: "0 5px",
              cursor: "pointer",
            }}>
            {text}
          </p>
        );
      },
    },

    {
      title: "Balance",
      dataIndex: "chips",
      key: "balance",
      sorter: (a, b) => a.chips - b.chips,
      render: (text, records) => {
        return (
          <span style={{ float: "right", fontSize: "14px" }}>
            {Number(records?.chips) + Number(records?.clientPl)}
          </span>
        );
      },
    },

    {
      title: "Cient (P/L)",
      dataIndex: "clientPl",
      sorter: (a, b) => a.clientPl - b.clientPl,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    // {
    //   title: "Default %",
    //   dataIndex: "clientPlPercentage",
    //   sorter: (a, b) => a.clientPlPercentage - b.clientPlPercentage,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
    //   ),
    // },

    {
      title: "Exposure",
      dataIndex: "exposure",
      sorter: (a, b) => a.exposure - b.exposure,
      render: (text, record) => (
        <span
          onClick={() => {
            setSelectedActionData(record);
            setIsexposure(true);
          }}
          style={{ float: "right", fontSize: "14px", cursor: "pointer" }}>
          {Number(text)?.toFixed(2)}
        </span>
      ),
    },

    {
      title: "Available Balance",
      dataIndex: "availabePts",
      sorter: (a, b) => a.availabePts - b.availabePts,
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },

    {
      title: "U St",
      dataIndex: "accountLock",
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record?.accountLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "B St",
      dataIndex: "betLock",
      render: (_, record) => (
        <Checkbox
          disabled
          checked={record.betLock}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="active-user-checkbox"></Checkbox>
      ),
    },

    {
      title: "Exposure Limit",
      dataIndex: "total",
      sorter: (a, b) => a.total - b.total,
      sortDirections: ["descend", "ascend"],
      render: (text) => (
        <span style={{ float: "right", fontSize: "14px" }}>{text}</span>
      ),
    },
    {
      title: "PName",
      dataIndex: "pname",
      sorter: (a, b) => a.pname - b.pname,
    },

    {
      title: "Account Type",
      dataIndex: "accountType",
      sorter: (a, b) => a.accountType - b.accountType,
    },

    // {
    //   title: "Casino Total ",
    //   dataIndex: "0",
    //   sorter: (a, b) => a.age - b.age,
    //   render: (text) => (
    //     <span style={{ float: "right", fontSize: "14px", padding: "0px 4px" }}>
    //       {text}
    //     </span>
    //   ),
    // },
    userId == null
      ? {
          title: "Actions",
          dataIndex: "action",

          render: (text, record) => {
            return (
              <div style={{ display: "flex" }}>
                <Button
                  onClick={() => {
                    setShowModal("Deposit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  D
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Withdraw");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 8px",
                    marginRight: "3px",
                    height: "28px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  W
                </Button>
                <Button
                  onClick={() => {
                    setIsCreditDepositPortalVisible(true);
                    setSelectedActionData(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}>
                  CR
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Exposure Limit");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  L
                </Button>
                <Button
                  onClick={() => {
                    setShowModal("Password");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "13.6px",
                  }}>
                  P
                </Button>

                <Button
                  onClick={() => {
                    setShowModal("Status");
                    setSelectedItem(record);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "0px 10px",
                    marginRight: "3px",
                    height: "26px",
                    borderRadius: "4px",
                    fontSize: "13.5px",
                    letterSpacing: "-1px",
                  }}>
                  S
                </Button>
                <Button
                  onClick={() => {
                    showModalProfile(record?.id);
                    setSelectedActionData(record);
                    setMore(true);

                    const { id } = record;

                    setSelectedItemMore(id);
                  }}
                  style={{
                    background: "#444",
                    color: "white",
                    padding: "1px 9px",
                    marginRight: "3px",
                    height: "27px",
                    borderRadius: "4px",
                    fontSize: "14px",
                    fontWeight: "600",
                    cursor: "pointer",
                    display: "inline-block",
                  }}>
                  MORE
                </Button>
              </div>
            );
          },
        }
      : {},
  ]

  console.log(
    filteredData?.filter((item) => item?.accountType == "User"),
    "sadasdasdasdasda"
  );

  filteredData?.filter((item) => {
    if (item?.accountType == "User") {
      return;
    }
  });

  // const sortedDataSource = [...filteredData].sort((a, b) => a?.username - b?.username);
  const clasObj = {
    Deposit: "more-modal-deposit",
    Withdraw: "more-modal-withdraw",
    Limit: "more-modal-more",
    Status: "more-modal-more",
    Password: "more-modal-more",
    Credit: "more-modal-more",
  };
  const uType = localStorage.getItem("userType");
  const tableLoading = {
    spinning: isLoading || tableSelector?.loading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  };
  return (
    <>
      {uType != 2 && (
        <>
          <Table
            columns={columns}
            dataSource={filteredData?.filter(
              (item) => item?.accountType != "User"
            )}
            pagination={false}
            rowClassName={getRowClassName}
            className="tbs"
            loading={tableLoading}
            style={{ width: "100%", overflowX: "scroll" }}
          />

          <div>
            <h2 className="list">Client List</h2>
          </div>
        </>
      )}

      <Table
        columns={columnsClient}
        dataSource={filteredData?.filter((item) => item?.accountType == "User")}
        pagination={false}
        rowClassName={getRowClassName}
        className="tbs"
        loading={tableLoading}
        style={{ width: "100%", overflowX: "scroll" }}
      />
      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal("")}
        destroyOnClose
        style={{ top: "10px", left: "0px", height: "100%" }}
        className={clasObj["Deposit"]}
        title={showModal}>
        {showModal === "Deposit" && (
          <Deposit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Withdraw" && (
          <Withdraw selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Exposure Limit" && (
          <Limit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Status" && (
          <Status
            selectedItem={selectedItem}
            activeUserDataApi={activeUserDataApi}
            setShowModal={setShowModal}
          />
        )}
        {showModal === "Password" && (
          <Password selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
        {showModal === "Credit" && (
          <Credit selectedItem={selectedItem} setShowModal={setShowModal} />
        )}
      </Modal>
      <Modal
        footer={null}
        open={more}
        onCancel={handleCancel}
        destroyOnClose
        width={1000}
        title={selectedActionData?.username}
        className="more-modal-more">
        <AccountListMore
          selectedRecord={selectedActionData}
          onPortalHidden={portalHidden}
          setIsStatusChanged={setIsStatusChanged}
          SelectedItem={selectedItemMore}
          dataSource={dataSource}
          setMore={setMore}
          // dataApi={dataApi}
          data={userData}
          handleCancel={handleCancel}
          onInactiveActiveUser={inactiveActiveUser}
        />
      </Modal>

      <Modal
        footer={null}
        open={isCreditDepositPortalVisible}
        onCancel={handleCancel}
        destroyOnClose
        style={{ padding: "15px" }}
        width={800}
        className="table-modal-x">
        <AccountListCR
          onPortalHidden={portalHidden}
          selectedRecord={selectedActionData}
          CRPortlal={setIsCreditDepositPortalVisible}
          inactiveActiveUser={inactiveActiveUser}
        />
      </Modal>

      <Modal
        footer={null}
        open={isExposure}
        onCancel={handleCancel}
        destroyOnClose
        style={{ padding: "15px" }}
        width={800}
        className="table-modal-x exposure_modals"
        title="Exposure">
        <Exposure selectedActionData={selectedActionData} />
      </Modal>

      <Modal
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
        destroyOnClose
        style={{ padding: "15px" }}
        width={800}
        className="table-modal-x exposure_modals partnership"
        title={`Partnership Info - ${userIds}`}>
        <ModalsData
          partnershipDetails={partnerShipDetail?.data}
          loading={loading}
        />
      </Modal>
    </>
  );
};

export default ActiveUsersColumn;
