import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ClientList } from '../../../Services/ApiEndingPoint';

export const CreditHistory = createAsyncThunk('credit', async (payload, { rejectWithValue }) => {
	try {
		const response = await axios.post(ClientList, { ...payload });
	} catch (error) {
		return rejectWithValue(error);
	}
});
