import React, { useState } from "react";
import style from "./FancyBookPortal.module.scss";
import { Empty, Table } from "antd";
// import "./Betlock.scss";
const FancyBookPortal = ({ fancyBookData }) => {
  return (
    <div style={{ height: "350px", overflowY: "scroll" }}>
      <div className="heading-pnl" style={{ display: "flex" }}>
        <h4
          style={{
            width: "200px",
            margin: "2px",
            paddingLeft: "5px",
            color: "rgb(136 154 168)",
            fontWeight: "500",
          }}
        >
          Run
        </h4>
        <h4
          style={{
            margin: "2px",

            color: "rgb(136 154 168)",
            fontWeight: "500",
          }}
        >
          Amount
        </h4>
      </div>
      {fancyBookData?.map((res, index) => {
        return (
          <React.Fragment key={res?.pnl + index + 1}>
            <div
              className="pnl"
              style={{
                display: "flex",
                border: "1px solid rgb(60 70 72)",
              }}
            >
              <p
                style={{
                  width: "200px",
                  margin: "2px",
                  paddingLeft: "5px",
                  color: "rgb(136 154 168)",
                  fontWeight: "500",
                }}
              >
                {res?.odds}
              </p>
              <p
                style={{
                  margin: "2px",
                  color: res?.pnl >= 0 ? "Green" : "red",
                  fontWeight: "500",
                }}
              >
                {res?.pnl}
              </p>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default FancyBookPortal;
