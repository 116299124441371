import React, { useState } from "react";
import { DatePicker, Dropdown, Empty, Space, Spin, Table } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { useCasinoDetailsQuery } from "../../Services/CasinoService";
import { FaSpinner } from "react-icons/fa";

const CasinoDetails = ({ isAura, id }) => {
  const timeBefore = moment().subtract(20, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);
  const [rouletteDate, setRouletteDate] = useState();
  const [isAuraDetails, setIsAuraDetails] = useState();

  const handleDroupDown = (val) => {
    setRouletteDate(val);
    setIsAuraDetails(isAura);
  };

  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const nav = useNavigate();

  

  const items = [
    {
      label: (
        <p
        onClick={() => handlePlusMinus("matchId")}
          className="title_section">
         { `${isAura} Plus Minus`}
        </p>
      ),
      key: "0",
    },
    {
      label: (
        <p className="title_section" onClick={() => handleDisplayGame("matchId")}>
          Display Game
        </p>
      ),
      key: "1",
    },
  ];


  const { data, isLoading, isFetching } = useCasinoDetailsQuery({
    casinoId: id,
    endDate: endDate,
    startDate: startDate,
  }, {refetchOnMountOrArgChange: true});


  const handlePlusMinus = () => {
    nav(`/casino/${id}/plus-minus-type`, { state: { rouletteDate, isAuraDetails } });
  };

  const handleDisplayGame = () => {
    nav(`/casino/${id}/all-games`, { state: { rouletteDate, isAuraDetails } });
  };

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  return (
    <div>
      <div className="date_sec">
        <div className="sub_date_sec">
          <div style={{ marginBottom: "5px" }}>Start Date</div>
          <DatePicker defaultValue={dayjs(timeBefore)} onChange={onStartDate} />
        </div>
        <div className="sub_date_sec">
          <div style={{ marginBottom: "5px" }}>End Date</div>
          <DatePicker defaultValue={dayjs(time)} onChange={onEndDateDate} />
        </div>
      </div>
      { (isLoading || isFetching) ?
        <Spin  indicator={antIcon} className="loading_active"  size="large">
        <div className="content" />
      </Spin> : <div className="accountlist-table-container">
        <table className="table_section">
          <tr>
            <th></th>
            <th>Name</th>
            <th>Date</th>
            <th className="text-right">Comm Liya</th>
            <th className="text-right">Comm Diya</th>
            <th className="text-right">Comm Liya Share Wise</th>
            <th className="text-right">Comm Diya Share Wise</th>
            <th className="text-right">Pnl</th>
          </tr>
          {data?.data?.map((res, id) => {
              return (
                <tr key={res?.key}>
                  <td width="4%" style={{ cursor: "pointer" }}>
                    <Dropdown
                      className="table_dropdown"
                      menu={{
                        items,
                        className: "sport_list",
                      }}
                      trigger={["click", "contextMenu"]}>
                      <p
                        onClick={(e) => {
                          e.preventDefault();
                          handleDroupDown(
                            res?.date
                          );
                        }}>
                        <Space>
                          <CaretDownOutlined />
                        </Space>
                      </p>
                    </Dropdown>
                  </td>
                  <td className="">{res?.name}</td>
                  <td className="">{res?.date}</td>
                  <td className="text-right">{res?.comm}</td>
                  <td className="text-right">{res?.commDiya}</td>
                  <td className="text-right"
                    >
                    {res?.commLiyaShareWise == null ?"NA":(res?.commLiyaShareWise)?.toFixed(2) || 0}
                  </td>
                  <td className="text-right"
                    >
                    {res?.commDiyaShareWise == null?"NA":(res?.commDiyaShareWise)?.toFixed(2) || 0}
                  </td>
                  <td className={`text-right ${res?.netPnl<0?"text_danger":"text_success"}`}>{(res?.netPnl)?.toFixed(2)}</td>
                </tr>
              );
            })}
            {(data?.data === undefined ||
              data?.data?.length === 0) && (
              <tr>
                {" "}
                <td colSpan={9}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </td>
              </tr>
            )}
        </table>
      </div>
      }
      
    </div>
  );
};

export default CasinoDetails;
