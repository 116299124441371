import React, { useEffect, useState } from "react";
import AddAccountPersonalDetails from "../../../pages/addAccount/AddAccountPersonalDetails";
import style from "./PersonalDetail.module.scss";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useIsUserAvailableMutation } from "../../../Services/CasinoService";

const PersonalDetail = (props) => {
  const [showPass, setShowPass] = useState(false);

  const [trigger, {data}] = useIsUserAvailableMutation();

 useEffect(()=>{
  trigger(
    {
      userId: props.clientName
    }
  )
 }, [props.clientName])



  return (
    <div className={style["personal-cont"]}>
      <div className={style["personal"]}>
        <p>Personal Detail</p>
      </div>
      <div className={style["form"]}>
        <div>
          <label className={style["label"]}>User Id:</label>
          <input
            required
            type="text"
            autoComplete="off"
            id="clientName"
            placeholder="User Id"
            onChange={(e) =>
              !e.target.value.includes(" ") && props.clientNameHandler(e)
            }
            value={props.clientName}
          />
          {props.clientName.length < 2 && (
            <p style={{ color: "red", fontSize: "12px", position: "absolute" }}>
              {props.error}
            </p>
          )}
          { data?.status == false &&
            <p style={{ color: "red", fontSize: "12px", position: "absolute" }}>
            {data?.message}
          </p>
          }
        </div>
        <div className={style["pass_field"]}>
          <label>Password</label>
          <input
            required
            autoComplete="off"
            type={!showPass ? "password" : "text"}
            placeholder="Password"
            onChange={props.userPassHandler}
            value={props?.userPassword}
            id="userPassword"
            // onBlur={handleInputBlue}
            // onFocus={handleInputFocus}
            style={{ outline: "none" }}
            // inputMode="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,15}$"
            // pattern="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,15}$"
            // title="Password must contain at least 1 letter, 1 digit, and be at least 8 characters long."
          />
          {props?.userPassword.length > 0 ? (
            showPass ? (
              <AiFillEye
                className={style["peye"]}
                onClick={() => setShowPass(false)}
              />
            ) : (
              <AiFillEyeInvisible
                className={style["peye"]}
                onClick={() => setShowPass(true)}
              />
            )
          ) : null}
          {props.userPassword !== props.retypePass && (
            <p style={{ color: "red", fontSize: "12px", position: "absolute" }}>
              {props.passError}
            </p>
          )}
        </div>
        <div style={{position:"relative"}}>
          <label>Retype Password:</label>
          <input
            required
            type="password"
            placeholder="Retype Password"
            autoComplete="off"
            onChange={props.retypePassHandler}
            value={props.retypePass}
            style={{
              border: props.notMatchPassword ? "1px solid red" : "",
              outline: "none",
            }}
          />
          {props.notMatchPassword && (
            <span style={{ color: "red",
            position: "absolute",
            left: "0",
            bottom: "-19px"}}>Password doesn't Match</span>
          )}
        </div>
        <div>
          <label>User Name:</label>
          <input
            required
            type="text"
            placeholder="User Name"
            onChange={props.fullNameHandler}
            value={props.fullName}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetail;
