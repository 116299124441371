import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Spin, Table } from "antd";
import {
  useClientLedgerMutation,
  useDownlineLedgerQuery,
} from "../../../Services/CasinoService";
import Deposit from "../../../MarketAnalysisLogo/Portals/Deposit/Deposit";
import Withdraw from "../../../MarketAnalysisLogo/Portals/Withdrawn/Withdraw";
import LedgerPopUp from "../LedgerPopUp";
import "./LedgerData.scss";
import { FaSpinner } from "react-icons/fa";

const LedgerTable = ({ type, userType, setShowModal, setSelectedItem, lenaType, dataSource, loading }) => {
  const columns = [
    {
      title: "User ID",
      dataIndex: "userId",
      key: "userId",
    },
    {
      title: "User Name",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: "right",
      render: (text, record) => <span>{Math.abs(record?.balance)}</span>,
    },
    {
      title: "Settlement",
      dataIndex: "settlement",
      key: "settlement",
      hidden: userType !== 3 || lenaType == "Clear",
      render: (text, record) => (
        <span>
          <button
            onClick={() => {
              setShowModal(type);
              setSelectedItem(record);
            }}
            className="withdraw_dena"
          >
            {type === "Deposit" ? "Deposit" : "Withdraw"}
          </button>
        </span>
      ),
    },
    {
      title: lenaType,
      dataIndex: lenaType,
      key: "settlement",
      hidden: userType === 3 || lenaType == "Clear",
      render: (text, record) => (
        <span>
          <button
            onClick={() => {
              setShowModal(lenaType);
              setSelectedItem(record);
            }}
            className="withdraw_dena"
          >
            {lenaType}
          </button>
        </span>
      ),
    },
  ].filter((item) => !item.hidden);

  return (
    <div className="table_section">
      <div className="table_section">
      <Spin {...loading}>
        <Table
          className="live_table limit_update"
          bordered
          pagination={false}
          columns={columns}
          dataSource={dataSource}
        />
      </Spin>
    </div>
    </div>
  );
};

const LedgerData = ({ userType, Listname }) => {
  const [lenaBalance, setLenaBalance] = useState(0);
  const [denaData, setDenaBalance] = useState(0);
  const [clearData, setClearData] = useState(0);
  const [showModal, setShowModal] = useState("");
  const [selectedItem, setSelectedItem] = useState({});
  const [afterDepositWith, setAfterDepositWith] = useState(false);

  const {
    data: ledger,
    isFetching,
    isLoading,
  } = useDownlineLedgerQuery(
    {
      userType: userType,
    },
    { refetchOnMountOrArgChange: true }
  );

  const [trigger, { data: clientData }] = useClientLedgerMutation();

  useEffect(() => {
    if (Listname === "Client") {
      trigger();
    }
  }, [Listname]);

  useEffect(() => {
    if (afterDepositWith) {
      trigger();
    }
  }, [afterDepositWith]);

  useEffect(() => {
    const processData = (data) =>
      data?.map((res) => res?.balance).reduce((prev, curr) => Number(prev) + Number(curr), 0);

    const processDataForList = (data) => ({
      lena: processData(data?.lena),
      dena: processData(data?.dena),
      clear: processData(data?.clear),
    });

    const processDataAndSetState = (data) => {
      const processedData = processDataForList(data);
      setLenaBalance(processedData.lena);
      setDenaBalance(processedData.dena);
      setClearData(processedData.clear);
    };

    if (Listname === "Client") {
      processDataAndSetState(clientData?.data);
    } else {
      processDataAndSetState(ledger?.data);
    }
  }, [ledger?.data, clientData?.data]);

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  isFetching || isLoading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  const renderTable = (title, dataSource, type) => (
    <LedgerTable
      type={type}
      userType={userType}
      setShowModal={setShowModal}
      setSelectedItem={setSelectedItem}
      lenaType={title}
      dataSource={dataSource}
      loading={tableLoading}
    />
  );

  return (
    <>
      <div className="cash-tran-cont">
        <div className="cash-tran_heading">
          <p>{`${Listname} Lena Dena`}</p>
        </div>
        <Row className="main_super_super_ledger">
          <Col xl={7} xs={24} lg={7} md={24} span={8}>
            <div className="super_ledger item1">
              <div>Lena</div>
              <div>{lenaBalance?.toFixed(2) || 0.0}</div>
            </div>
            {renderTable("Lena", Listname === "Client" ? clientData?.data?.lena : ledger?.data?.lena, "Deposit")}
          </Col>
          <Col xl={7} xs={24} lg={7} md={24}>
            <div className="super_ledger item2">
              <div>Dena</div>
              <div>{Math.abs(denaData?.toFixed(2)) || 0.0}</div>
            </div>
            {renderTable("Dena", Listname === "Client" ? clientData?.data?.dena : ledger?.data?.dena, "Withdraw")}
          </Col>
          <Col xl={7} xs={24} lg={7} md={24}>
            <div className="last_item">
              <div className="super_ledger item3">
                <div>Clear</div>
                <div>{clearData?.toFixed(2) || 0.0}</div>
              </div>
              {renderTable("Clear", Listname === "Client" ? clientData?.data?.clear : ledger?.data?.clear, "Clear")}
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        open={showModal}
        footer={null}
        onCancel={() => setShowModal("")}
        destroyOnClose
        style={{ top: "10px", left: "0px", height: "100%" }}
        className={`more-modal-withdraw`}
        title={showModal}
      >
        {showModal === "Deposit" && (
          <Deposit selectedItem={selectedItem} setAfterDepositWith={setAfterDepositWith} lede={1} setShowModal={setShowModal} />
        )}
        {showModal === "Withdraw" && (
          <Withdraw selectedItem={selectedItem} setAfterDepositWith={setAfterDepositWith} lede={1} setShowModal={setShowModal} />
        )}
        {(showModal === "Lena" || showModal === "Dena") && (
          <LedgerPopUp selectedItem={selectedItem} setShowModal={setShowModal} setAfterDepositWith={setAfterDepositWith} showModal={showModal} />
        )}
      </Modal>
    </>
  );
};

export default LedgerData;
