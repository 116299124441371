import { useState } from 'react';
import style from './AddAccountPersonalDetails.module.scss';
import colors from '../../colors.module.scss';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

const AddAccountPersonalDetails = props => {
	const [showPass, setShowPass] = useState(false);
	const handleInputBlue = () => {
		setShowPass(false);
	};

	return (
		<div className={style['personal-cont']}>
			<div className={style['personal']}>
				<p>Personal Detail</p>
			</div>
			<div className={style['form']}>
				<div>
					<label className={style['label']}>Client Name:</label>
					<input required type="text" placeholder="Client Name" onChange={e => !e.target.value.includes(' ') && props?.clientNameHandler(e)} value={props?.clientName} />
					{props?.error && <p style={{ color: 'red', fontSize: '12px', position: 'absolute' }}>{props?.error}</p>}
				</div>

				<div className={style['pass-cont']}>
					<label>User Password:</label>
					<input
						required
						type={showPass ? 'text' : 'password'}
						placeholder="Transaction Password"
						onChange={props?.userPassHandler}
						value={props?.userPassword}
						onBlur={handleInputBlue}
					/>
					{props?.userPassword?.length > 0 ? (
						showPass ? (
							<AiFillEye className={style['peye']} onClick={() => setShowPass(false)} />
						) : (
							<AiFillEyeInvisible className={style['peye']} onClick={() => setShowPass(true)} />
						)
					) : null}
					{/* {props?.userPassword !== props?.retypePass && <p style={{ color: 'red', fontSize: '12px', position: 'absolute' }}>{props?.passError}</p>} */}
					{props?.passError && <p style={{ color: 'red', fontSize: '12px', position: 'absolute' }}>{props?.passError}</p>}
					{/* {props?.userPassword?.length > 0 ? (
						showPass ? (
							<AiFillEye className={style['eye']} onClick={() => setShowPass(false)} />
						) : (
							<AiFillEyeInvisible className={style['eye']} onClick={() => setShowPass(true)} />
						)
					) : (
						''
					)} */}
				</div>
				<div>
					<label>Retype Password:</label>
					<input required type="password" placeholder="Retype Password" onChange={props?.retypePassHandler} value={props?.retypePass} />
				</div>
				<div>
					<label>Full Name:</label>
					<input required type="text" placeholder="Full Name" onChange={props?.fullNameHandler} value={props?.fullName} />
				</div>
			</div>
		</div>
	);
};

export default AddAccountPersonalDetails;
