export const Home_Screen = '/';
export const AccountList_Screen = '/accountlist';
export const Odds_Deatil_Screen = 'detail';
export const ADD_ACCOUNT = 'addaccount';
export const ACC_STATEMENT = 'account-statement';
export const CURRENT_BETS = 'current-bets';
// export const GENERAL_REPORT = 'general-report';
export const CASH_TRANSACTION_S = '/supermaster/cash-transaction';
export const CASH_TRANSACTION_M = '/master/cash-transaction';
export const CASH_TRANSACTION_A = '/agent/cash-transaction';
export const CASH_TRANSACTION_C = '/client/cash-transaction';
export const CASH_TRANSACTION_AD = '/subadmin/cash-transaction';

export const MY_LEDGER = 'my-ledger';
export const DELETED_LENDEN = 'deletedlenden';
export const GAME_REPORT = 'game-report';
export const CASINO_REPORT = 'casino-report';
export const PROFIT_LOSS = 'profit-loss';
export const CASINO_RESULT_REPORT = 'casino-result-report';
export const LIVE_MARKET_BINARY = 'live-market-binary';
export const LIVE_MARKET_BOLLYWOOD = 'live-market-bollywood';
export const LIVE_MARKET_LUCKY = 'live-market-lucky';
export const LIVE_MARKET_DRAGON = 'live-market-dragon';
export const LIVE_MARKET_TEEN_PATTI = 'live-market-teen-patti';
export const BINARY_DATE = '/binary-date';
export const PASSWORD_RESET = 'password-reset';
export const USER_LOGIN = 'user-login';
export const USER_HISTORY = 'user-history';
export const BET_HISTORY = 'bet-history';
export const MARKET_ANALYSIS = '/detail/:id/:id';
// export const MARKET_ANALYSIS_LOGO = 'market_analysis';
export const MATCHBOX_LOGO = 'matchbox_active-user';
export const ADD = '/market_analysis/:id';
export const USERS = '/users_details';
export const SPORT_DETAILS = '/sport-details'
export const MATCH_SLIPS = '/match-slips/:id'
export const FANCY_SLIPS = '/fancy-slips/:id'
export const COMPLETE_FANCY = '/completed-fancy-slips/:id'
export const LIVE_REPORT = '/pl/live-report/:id'
export const REJECTES_BET = '/rejectedBetsByEvent/:id'
export const AURA_DETAILS = '/casino/aura-details'
export const SUPERNOWA_DETAILS = '/casino/supernowa'
export const Q_TECH = '/casino/qtech'
export const CASINO_PLUS_MINUS = '/casino/:id/plus-minus-type'
export const CASINO_ALL_GAME = '/casino/:id/all-games'
export const SHOW_BET = '/casino/show-bets/:id'
export const LEDGER_ADMIN = '/ledger-admin'
export const LEDGER_SUPER = '/ledger-super'
export const LEDGER_MASTER = '/ledger-master'
export const LEDGER_AGENT = '/ledger-agent'
export const LEDGER_CLIENT = '/ledger-client'
// export const LEDGER_SUBADMIN = '/ledger-subadmin'
export const PROFIT_LOSS_EVENT = '/events/matchledger'
export const REPORT_ADMIN = '/report/subadmin'
export const REPORT_SUPER = '/report/supermaster'
export const REPORT_MASTER = '/report/master'
export const REPORT_AGENT = '/report/agent'
export const REPORT_CLIENT = '/report/client'
export const COMM_REPORT_ADMIN = '/comm-report-admin'
export const COMM_REPORT_SUPER = '/comm-report-super'
export const COMM_REPORT_MASTER = '/comm-report-master'
export const COMM_REPORT_AGENT = '/comm-report-agent'
export const COMM_REPORT_CLIENT = '/comm-report-client'
export const PLUS_MINUS = '/plus-minus-report/:id'
export const PLUS_MINUS_REPORT = '/Events/:id/plus-minus-report'
export const LEDGER_DETAILS = '/Events/ladger-details'
export const CASH_TRA = '/cash-transanction'
export const MARKET = '/markets'
