import { Formik, Form, Field, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { VscDiffRenamed } from "react-icons/vsc";
import { MdSettingsBackupRestore } from "react-icons/md";
import style from "./Withdraw.module.scss";
import colors from "../../../colors.module.scss";
import { useFormik } from "formik";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/AxiosInstance";

const Withdraw = ({ selectedItem, setShowModal, setAfterDepositWith }) => {
  const [withdrawData, setWithdrawData] = useState({});

  useEffect(() => {
    withdrawApi();
  }, []);

  const withdrawApi = async () => {
    const endPoint = "dwc/depositwithdrawdata";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        { userId: selectedItem?.userId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setWithdrawData(res?.data?.data);
      });
  };
  const {
    values,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    errors,
    handleBlur,
  } = useFormik({
    initialValues: {
      amount: "",
      remark: "",
      lupassword: "",
      userId: selectedItem?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        remark: "" == values?.remark ? "Remark field is required. " : undefined,
        amount: "" == values.amount ? "Amount field is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },

    onSubmit: async () => {
      const endPoint = "dwc/withdraw-chips-pnl";
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status) {
          resetForm();
          setShowModal("");
          setAfterDepositWith(true);
          return toast.success(response?.data?.message);
        } else {
        }
      } catch (error) {
        toast?.error(error?.response?.data?.message);
      }
    },
  });

  return (
    <>
      <form className={style["deposit_portal-form"]} autoComplete="off" onSubmit={handleChange}>
        <div className={style["same"]}>
          <label>{withdrawData?.parentName}</label>
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={withdrawData.parentAmount}
          />
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={
              values.amount
                ? Number(withdrawData.parentAmount) + Number(values.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>{withdrawData?.childName}</label>
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={withdrawData.childAmount}
          />
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={
              values.amount
                ? Number(withdrawData.childAmount) - Number(values.amount)
                : ""
            }
          />
        </div>
        <div className={style["same"]}>
          <label>Profit/Loss</label>
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={withdrawData?.childUplineAmount}
          />
          <input
            className={style["deposit_portal-form_input"]}
            disabled
            value={
              values?.amount
                ? Number(withdrawData?.childUplineAmount) + Number(values?.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>Amount</label>
          <input
            placeholder="Amount"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            name="amount"
            autoComplete="withdraw_amount"
            id="withdraw_amount"

            value={values.amount}
            style={{
              border: `1px solid ${
                touched.amount && errors.amount ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.amount && errors?.amount ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "-3%",
            }}
          >
            {errors.amount}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Remark</label>
          <textarea
            onChange={handleChange}
            name="remark"
            onBlur={handleBlur}
            autoComplete="off"
            value={values.remark}
            style={{
              border: `1px solid ${
                touched.remark && errors.remark ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.remark && errors?.remark ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "-3%",
            }}
          >
            {errors.remark}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Transaction Passsword</label>
          <input
            placeholder="Transaction Password"
            type="password"
            onChange={handleChange}
            value={values.lupassword}
            onBlur={handleBlur}
            name="lupassword"
            autoComplete="off"
            style={{
              border: `1px solid ${
                touched.lupassword && errors.lupassword ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.lupassword && errors?.lupassword ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "5%",
            }}
          >
            {errors.lupassword}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["status_buttons"]}>
          <button className={style["status_back"]}>
            <span className={style["icons"]}>
              <MdSettingsBackupRestore />
            </span>
            <span>Back</span>
          </button>
          <button
            className={style["status_submit"]}
            type="submit"
            onClick={handleSubmit}
          >
            <span>Submit</span>
            <span className={style["submit_icon"]}>
              <VscDiffRenamed />
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default Withdraw;
