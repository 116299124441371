import React, { useEffect, useRef, useState } from "react";
import style from "./TransactionPassword.module.scss";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const TransactionPassword = (props) => {
  const [showPass, setShowPass] = useState(false);

  // const handleInputBlur = () => {
  //   setShowPass(!showPass);
  // };

  return (
    <div className={style["password-cont"]}>
      <div className={style["transaction_form"]}>
        <label>Transaction Password:</label>
        <input
          required
          placeholder="Transaction Password"
          type={showPass ? "text" : "password"}
          onChange={props.transactionPassHandler}
          value={props.transPass}
          // onBlur={handleInputBlur}
        />
        {props?.transPass?.length > 0 ? (
          showPass ? (
            <AiFillEye
              className={style["peye"]}
              onClick={() => setShowPass(false)}
            />
          ) : (
            <AiFillEyeInvisible
              className={style["peye"]}
              onClick={() => setShowPass(true)}
            />
          )
        ) : null}
      </div>
      <button className={style["create_account-button"]} type="Submit">
        Create Account
      </button>
    </div>
  );
};

export default TransactionPassword;
