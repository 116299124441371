import React, { useEffect, useState } from "react";
import { Layout, Drawer, Slider } from "antd";
import { Outlet, useLocation, useNavigate } from "react-router";
import HeaderComponent from "../components/layout/header/HeaderComponent";
import "./styles.scss";
import SiderComp from "../components/layout/sider/SiderComp";
import { useValidateJwtMutation } from "../Services/CasinoService";

const MainLayout = () => {
  const { Header, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const nav = useNavigate();

  const { pathname } = useLocation();

  const onClose = () => {
    setCollapsed(false);
  };
  document.title = window.location.hostname.split(".")[1];

  const [trigger, { data: jwtData, error }] = useValidateJwtMutation();

  useEffect(() => {
    if (error?.status == 401) {
      localStorage.clear();
      nav("/user-login");
    }
  }, [error]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    let interval;
    if (token !== null) {
      interval = setInterval(() => {
        trigger();
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [token, trigger]);

  return (
    // <div style={{ background: color.themeColor, height: '00px' }}>
    <Layout className="main-layout">
      <Header className="antd-header-comp">
        <HeaderComponent
          toggleCollapsed={collapsed}
          setToggleCollapsed={setCollapsed}
        />
      </Header>
      <Layout>
        <Drawer
          placement="left"
          closable={false}
          onClose={onClose}
          open={collapsed}
          key="1"
          className={
            pathname === "/matchbox_active-user"
              ? "match_drawer-comp"
              : "antd-drawer-comp"
          }
          width={300}>
          <SiderComp
            onClose={onClose}
            toggleCollapsed={collapsed}
            setToggleCollapsed={setCollapsed}
          />
        </Drawer>

        <Content className="content-comp">
          <Outlet />
        </Content>
      </Layout>
    </Layout>
    // </div>
  );
};

export default MainLayout;
