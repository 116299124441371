import React from 'react';
import style from './AddAccount.module.scss';
import AddAccountForm from './AddAccountForm';
import colors from '../../colors.module.scss';

// import Commission from './Commission';
// import Partnership from './Partnership';

// import AddAccountBottom from './AddAccountBottom';

const AddAccount = () => {
	return (
		<div className={style['addaccount-cont']}>
			<div className={style['addaccount-heading']}>Add Account</div>
			<AddAccountForm />
		</div>
	);
};

export default AddAccount;
