import React, { useEffect, useState } from "react";
import style from "./LogoAddHome.module.scss";
import PersonalDetail from "../PersonalDetail/PersonalDetail";
import AccountDetail from "../AccountDetail/AccountDetail";
import Partnership from "../Partnership/Partnership";
import TransactionPassword from "../TransactionPassword/TransactionPassword";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";
import CasinoAllow from "./CasinoAloow/CasinoAllow";
import { useCreateUserMutation } from "../../../Services/CasinoService";

const LogoAddHome = () => {
  const [clientName, setClientName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [retypePass, setRetypePass] = useState("");
  const [fullName, setFullName] = useState("");
  const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [transPass, setTransPass] = useState("");
  const [reference, setReference] = useState("");
  const [exposure, setExposure] = useState("");
  const [balance, setBalance] = useState("");
  const [matchComm, setMatchComm] = useState("");
  const [commType, setCommType] = useState("");
  const [inputState, setInputState] = useState("");
  const [sessComm, setSessComm] = useState("");
  const [casinoComm, setCasinoComm] = useState("");
  const [appId, setAppId] = useState();
  const [notMatchPassword, setNotMatchPassword] = useState(false);
  const [state, setState] = useState({
    isAuraAllowed: "",
    isSuperNovaAllowed: "",
    isQTechAllowed: "",
    isVirtualAllowed: "",
    isSportBookAllowed: "",
  });

  const referenceHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^\d+]/g, "");
    const withoutConsecutivePlus = sanitizedValue.replace(/(\++)+/g, "");
    setReference(withoutConsecutivePlus);
  };

  const exposureHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedExposureValue = inputValue.replace(/[^\d+]/g, "");
    const withoutPlus = sanitizedExposureValue.replace(/(\++)+/g, "");
    setExposure(withoutPlus);
  };
  const balanceHandler = (e) => {
    const inputValue = e.target.value;
    const sanitizedExposureValue = inputValue.replace(/[^\d+]/g, "");
    const withoutPlus = sanitizedExposureValue.replace(/(\++)+/g, "");
    setBalance(withoutPlus);
  };

  const clientNameHandler = (e) => {
    setClientName(e.target.value);
  };

  const userPassHandler = (e) => {
    setUserPassword(e.target.value);
    if (retypePass == e.target.value) {
      setNotMatchPassword(false);
    } else {
      setNotMatchPassword(true);
    }
  };

  const retypePassHandler = (e) => {
    setRetypePass(e.target.value);
    if (userPassword == e.target.value) {
      setNotMatchPassword(false);
    } else {
      setNotMatchPassword(true);
    }
  };

  const fullNameHandler = (e) => {
    setFullName(e.target.value);
  };

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const phoneHandler = (e) => {
    setPhone(e.target.value);
  };

  const transactionPassHandler = (e) => {
    setTransPass(e.target.value);
  };

  //ACCOUNTDETAILS
  const [data, setData] = useState({
    userRole: "",
  });
  const partnership = localStorage.getItem("partnership");

  const [trigger, { data: createUser, error }] = useCreateUserMutation();

  // console.log(error?.data?.message, "createUser")

  useEffect(() => {
    toast.error(error?.data?.message);
  }, [error?.data]);

  const onSumit = async function (e) {
    e.preventDefault();
    const baseUrl = "user/create-user-vg";
    // const baseUrl = "/bmx/user/create";
    if (userPassword == retypePass) {
      loadingRef("createAccount", true);
      setNotMatchPassword(false);
      // const response = await axiosInstance.post(baseUrl, {
      //   userId: clientName,
      //   username: fullName,
      //   city: "",
      //   userRole: data.userRole,
      //   password: userPassword,
      //   sportPartnership: inputState,
      //   oddLossCommission: matchComm || 0,
      //   lupassword: transPass,
      //   liveCasinoLock: 0,
      //   fancyLossCommission: sessComm || 0,
      //   creditRef: reference,
      //   netExposure: exposure,
      //   appId: appId,
      //   casinoCommission: casinoComm || 0,
      //   isAuraAllowed: state?.isAuraAllowed,
      //   isSuperNovaAllowed: state?.isSuperNovaAllowed,
      //   isQTechAllowed: state?.isQTechAllowed,
      //   isVirtualAllowed: state?.isVirtualAllowed,
      //   isSportBookAllowed: state?.isSportBookAllowed,
      // });
      trigger({
        userId: clientName,
        username: fullName,
        city: "",
        userRole: data.userRole,
        password: userPassword,
        sportPartnership: inputState,
        oddLossCommission: matchComm || 0,
        lupassword: transPass,
        liveCasinoLock: false,
        casinoPartnership: 0,
        fancyLossCommission: sessComm || 0,
        casinoCommission: casinoComm || 0,
        commType: "bbb",
        appId: appId,
        amount: balance,
        isAuraAllowed: state?.isAuraAllowed,
        isSuperNovaAllowed: state?.isSuperNovaAllowed,
        isQTechAllowed: state?.isQTechAllowed,
        isVirtualAllowed: state?.isVirtualAllowed,
        isSportBookAllowed: state?.isSportBookAllowed,
      });
      if (createUser?.status === true) {
        setReference("");
        setClientName("");
        setCity("");
        setFullName("");
        setPhone("");
        setUserPassword("");
        setRetypePass("");
        setTransPass("");
        setExposure("");
        setAppId("");
        setSessComm("");
        setCasinoComm("");
        setInputState("");
        setData((prev) => {
          return {
            ...prev,
            userRole: "",
          };
        });
      }
      // if (response?.data?.status) {
      //   setReference("");
      //   setClientName("");
      //   setCity("");
      //   setFullName("");
      //   setPhone("");
      //   setUserPassword("");
      //   setRetypePass("");
      //   setTransPass("");
      //   setExposure("");
      //   setAppId("");
      //   setSessComm("");
      //   setCasinoComm("");
      //   setInputState("");
      //   setData((prev) => {
      //     return {
      //       ...prev,
      //       userRole: "",
      //     };
      //   });
      //   toast.success(response?.data?.message);
      // }
      loadingRef("createAccount", false);
    } else {
      setNotMatchPassword(true);
    }
  };

  useEffect(() => {
    toast.success(createUser?.message);
    setReference("");
    setClientName("");
    setCity("");
    setFullName("");
    setPhone("");
    setUserPassword("");
    setRetypePass("");
    setTransPass("");
    setExposure("");
    setAppId("");
    setSessComm("");
    setCasinoComm("");
    setInputState("");
    setBalance("")
    setMatchComm('')
    setData((prev) => {
      return {
        ...prev,
        userRole: "",
      };
    });
  }, [createUser?.status === true]);

  // const appUrlApi = async () => {
  //   const endPoint = "admin/app-detail";
  //   const response = await axiosInstance.post(endPoint, {});
  //   setAppId(response.data?.data);
  // };
  // useEffect(() => {
  //   appUrlApi();
  // }, []);

  return (
    <form
      onSubmit={onSumit}
      style={{
        zIndex: "10",
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        borderRadius: "5px",
        padding: "10px 15px",
      }}>
      <div>
        <p style={{ fontSize: "2rem", padding: "10px 0px 20px 0" }}>
          Add Account
        </p>
        <div className={style["sub-heading"]}>
          <PersonalDetail
            retypePass={retypePass}
            userPassword={userPassword}
            phone={phone}
            fullName={fullName}
            city={city}
            clientName={clientName}
            clientNameHandler={clientNameHandler}
            userPassHandler={userPassHandler}
            retypePassHandler={retypePassHandler}
            fullNameHandler={fullNameHandler}
            cityHandler={cityHandler}
            phoneHandler={phoneHandler}
            notMatchPassword={notMatchPassword}
          />
          <AccountDetail
            reference={reference}
            referenceHandler={referenceHandler}
            partnership={partnership}
            setData={setData}
            data={data}
            exposureHandler={exposureHandler}
            exposure={exposure}
            appId={appId}
            setMatchComm={setMatchComm}
            setSessComm={setSessComm}
            setCasinoComm={setCasinoComm}
            setAppId={setAppId}
            balance={balance}
            balanceHandler={balanceHandler}
          />
        </div>

        <div className={style["sub-heading session-heading"]}>
          <div className={style["session-heading"]}></div>
          <CasinoAllow setState={setState} state={state} />
        </div>
        <Partnership
          partnership={partnership}
          inputState={inputState}
          setInputState={setInputState}
          data={data}
        />
        <TransactionPassword
          className={style["transaction-password"]}
          transactionPassHandler={transactionPassHandler}
          transPass={transPass}
          data={data}
        />
        {/* <PersonalDetail /> */}
      </div>
    </form>
  );
};

export default LogoAddHome;
