import React, { useEffect, useState } from 'react';
import style from './AccountStatement.module.scss';
import AccountStatementHeader from './AccountStatementHeader';
import AccountStatementTable from './AccountStatementTable';
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from 'axios';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { accountStatement } from '../../../Redux/feature/AccountStatement/action';
import { stateAccountData } from '../../../Redux/feature/AccountStatement/selector';

const AccountStatement = props => {
	const initialTableRecords = 25;
	const initialAccountType = 1;

	const [dateTo, setDateTo] = useState(dayjs());
	const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, 'day'));
	const [searchId, setSearchId] = useState('');
	const [accountType, setAccountType] = useState(initialAccountType);
	const [tableRecords, setTableRecords] = useState(initialTableRecords);
	const [index, setIndex] = useState(0);

	const [searchUser, setSearchUser] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			accountStatement({
				index: index,
				noOfRecords: +tableRecords,
				fromDate: dateFrom.format('YYYY-MM-DD'),
				toDate: dateTo.format('YYYY-MM-DD'),
				userid: searchId,
				type: +accountType
			})
		);
	}, [tableRecords, index]);

	const submitHandler = e => {
		e.preventDefault();
		dispatch(
			accountStatement({
				index: index,
				noOfRecords: +tableRecords,
				fromDate: dateFrom.format('YYYY-MM-DD'),
				toDate: dateTo.format('YYYY-MM-DD'),
				userid: searchId,
				type: +accountType
			})
		);
	};

	return (
		<div className={style['account_state-cont']}>
			<div className={style['account_state_heading']}>
				<p>Account Statement</p>
			</div>

			<AccountStatementHeader
				dateTo={dateTo}
				dateFrom={dateFrom}
				setDateTo={setDateTo}
				setDateFrom={setDateFrom}
				submitHandler={submitHandler}
				setSearchId={setSearchId}
				accountType={accountType}
				setAccountType={setAccountType}
				tableRecords={tableRecords}
				searchId={searchId}
				setTableRecords={setTableRecords}
				setSearchUser={setSearchUser}
			/>

			<AccountStatementTable dateFrom={dateFrom} dateTo={dateTo} accountType={accountType} searchId={searchId} setTableRecords={setTableRecords} index={index} setIndex={setIndex} tableRecords={tableRecords} setSearchUser={setSearchUser} searchUser={searchUser} />
		</div>
	);
};

export default AccountStatement;
