import { Tabs } from "antd";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ActiveUsers from "./ActiveUsers";
import InactiveUsers from "./InactiveUsers";
import "./MarketAnalysisHeader.scss";
import UsersHeader from "./UsersHeader";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../Services/AxiosInstance";
import { FaSpinner } from "react-icons/fa";
const Header = () => {
  const nav = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get("id");

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const items = [
    {
      key: "1",
      label: (
        <span
          onClick={() => { if(userId !== null){
              nav(`/matchbox_active-user?id=${userId}`)
              setIsLoading(true)
            }else{
              setIsLoading(true)
              nav("/matchbox_active-user");
            }
          }}
        >
          Active Users
        </span>
      ),
      children: <ActiveUsers setIsLoading={setIsLoading} isLoading={isLoading} antIcon={antIcon} setSearchQuery={setSearchQuery} searchQuery={searchQuery} userId={userId} />,
    },

    {
      key: "2",
      label: (
        <span
          onClick={() => {
            if(userId !== null){
              nav(`/matchbox_active-user?id=${userId}`);
              setIsLoading(true)
            }else{
              setIsLoading(true)
              nav("/matchbox_active-user");
            }
          }}
        >
          Deactive Users
        </span>
      ),
      children: <InactiveUsers setIsLoading={setIsLoading} isLoading={isLoading} antIcon={antIcon} setSearchQuery={setSearchQuery} userId={userId} searchQuery={searchQuery} />,
    },
  ];

  const onChangeTab = (key) => {
  };

  const uType = localStorage.getItem("userType")


  return (
    <>
      <div className="logo_cont">
        <div className="header" style={{}}>
          <span className="list">{uType == 4 ?"Sub Admin List":uType == 5 ?"Super Master List": uType == 0?"Master List":uType == 1?"Agent List" :"Client List"}</span>
          <Link to="/market_analysis/Add-account">
            <span className="add">Add Account</span>
          </Link>
        </div>
        <UsersHeader
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
        />
        <div className="users">
          <Tabs
            defaultActiveKey="1"
            type="card"
            items={items}
            onChange={onChangeTab}
            destroyInactiveTabPane={true}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
