import React, { useMemo, useState } from "react";
import style from "./CurrentBetsTable.module.scss";
import { Select, Spin, Table, Tooltip } from "antd";
import { AiFillEye } from "react-icons/ai";
import Pagination from "../../../Pagination/Pagination";
import { FaSpinner } from "react-icons/fa";

const CurrentBetsTable = ({
  sportsBetData,
  accountStatementBody,
  setAccountStatementBody,
  loading
}) => {
  const [searchData, setSearchData] = useState("");

  const columns = [
    {
      title: "Event Type",
      dataIndex: "eventType",
      key: "eventType",
      filteredValue: [searchData],
      onFilter: (value, record) => {
        return (
          String(record?.eventNamem)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.eventType)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.username)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.time).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.nation).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.marketname)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record?.amount).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.rate).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Event Name",
      dataIndex: "eventNamem",
      key: "eventNamem",
    },
    {
      title: "User Name",
      dataIndex: "username",
    },
    {
      title: "M Name",
      dataIndex: "marketname",
    },
    {
      title: "Nation",
      dataIndex: "nation",
    },
    {
      title: "U Rate",
      dataIndex: "rate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Volume",
      dataIndex: "price",
    },
    {
      title: "Place Date",
      dataIndex: "time",
    },
    {
      title: "Detail",
      dataIndex: "deviceInfo",

      render: (text, record) => {
        return (
          <>
            <Tooltip title={record.deviceInfo}>
              <AiFillEye style={{ cursor: "pointer" }} />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const searchHandler = (e) => {
    setSearchData(e.target.value);
  };
  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  loading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }
  return (
    <>
      <div className={style["table_cont"]}>
        <div className={style["current_bet_bottom"]}>
          <div className={style["row-selector"]}>
            <p>Show</p>
            <Select
              value={accountStatementBody.noOfRecords}
              onChange={(e) =>
                setAccountStatementBody((prev) => {
                  return {
                    ...prev,
                    noOfRecords: e,
                  };
                })
              }
            >
              <Select.Option value={50}>50</Select.Option>
              <Select.Option value={100}>100</Select.Option>
              <Select.Option value={200}>200</Select.Option>
            </Select>
            <p>entries</p>
          </div>
          <div className={style["search"]}>
            <label>Search:</label>
            <input
              type="text"
              onChange={searchHandler}
              value={searchData}
              placeholder="Search here..."
            />
          </div>
        </div>
      </div>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          dataSource={sportsBetData}
          pagination={false}
          rowClassName={(record) => {
            return record?.isback ? "blue" : "pink";
          }}
          loading={tableLoading}
        />
      </div>
      {/* <div className={style['current_bet-bottom']}>
				<p>Showing 0 to 0 of 0 enteries</p>
				<div className={style['current_bet-button-cont']}>
					<button>Previous</button>
					<button>Next</button>
				</div>
			</div> */}
    </>
  );
};

export default CurrentBetsTable;
