import React from "react";
import MarketAnalysisHome from "../../components/MarketAnalysis/HomePage/MarketAnalysisHome";

const Home = () => {
  return (
    <>
      <MarketAnalysisHome />
    </>
  );
};
export default Home;
