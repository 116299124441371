import React from "react";
import style from "./AccountListPassword.module.scss";




// import axiosInstance from '../../../Services/AxiosInstance';

import { useFormik } from "formik";

import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListPassword = (props) => {
  

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      lupassword: "",
      userId: props?.onSelectedRecord?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        lupassword:
          "" == values?.lupassword
            ? "Login User Password field is required."
            : undefined,
        confirmPassword:
          "" == values?.confirmPassword
            ? "Confirm Password field is required. "
            : values.confirmPassword !== values.password
            ? "Confirm password should be equal to the new password."
            : undefined,
        password:
          "" == values?.password ? "Password field is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },
    onSubmit: async () => {
      const endPoint = "user/change-password-child";
      const token = localStorage.getItem("token");
      let data = { ...values, newPassword: values?.password };

      loadingRef("changepasswordchild", true);
      await axiosInstance
        .post(endPoint, data, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          // setFormData(res.data.data);
          if (res.data.status === true) {
            resetForm();
            toast.success(res?.data?.message);
            props.submitted(false);
          }
          loadingRef("changepasswordchild", false);
        })
        .catch((errors) => {
          loadingRef("changepasswordchild", false);
        });
      loadingRef("changepasswordchild", false);
    },
  });

  return (
    // <div className={style['password_portal-cont']}>

    <form className={style["password_portal-form"]} onSubmit={handleSubmit}>
      {/* <ToastContainer /> */}

      <div className={style["same"]}>
        <label> Password</label>

        <input
          type="password"
          placeholder="Password"
          name="password"
          // pattern="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,15}$"
          // title="Password must contain at least 1 letter, 1 digit, and be at least 8 characters long."
          onBlur={handleBlur}
          style={{
            border: `1px solid ${
              touched?.password && errors?.password ? "red" : ""
            }`,
            outline: "none",
          }}
          className={style["passsword_portal-input"]}
          onChange={handleChange}
          value={values?.password}
        />
      </div>
      {touched?.password && errors?.password ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "28%",
            bottom: "5px",
          }}
        >
          {errors?.password}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Confirm Password</label>
        <input
          type="password"
          placeholder="Confirm Password"
          name="confirmPassword"
          className={style["passsword_portal-input"]}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{
            border: `1px solid ${
              touched.confirmPassword && errors?.confirmPassword ? "red" : ""
            }`,
            outline: "none",
          }}
          value={values?.confirmPassword}
        />
      </div>
      {touched?.confirmPassword && errors?.confirmPassword ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "28%",
            bottom: "5px",
          }}
        >
          {errors?.confirmPassword}{" "}
        </span>
      ) : (
        ""
      )}

      <div className={style["same"]}>
        <label>Transaction Password</label>
        <input
          type="password"
          placeholder="transaction Password"
          style={{
            border: `1px solid ${
              touched.lupassword && errors?.lupassword ? "red" : ""
            }`,
            outline: "none",
          }}
          name="lupassword"
          className={style["passsword_portal-input"]}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.lupassword}
        />
      </div>
      {touched?.lupassword && errors?.lupassword ? (
        <span
          style={{
            position: "relative",
            color: "red",
            left: "28%",
            bottom: "5px",
          }}
        >
          {errors?.lupassword}{" "}
        </span>
      ) : (
        ""
      )}

      <button
        type="submit"
        style={{
          float: "right",
          marginBottom: "10px",
          background: "black",
          padding: "10px",
          color: "white",
          border: "none",
          borderRadius: "5px",
        }}
      >
        Submit
      </button>
    </form>
  );
};

export default AccountListPassword;
