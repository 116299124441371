import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SportDetails } from "../../Redux/feature/SportDetails/action";
import { selectSportDetails } from "../../Redux/feature/SportDetails/selector";
import { DatePicker, Divider, Dropdown, Empty,  Select, Space, Spin } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import Pagination from "../../Pagination/Pagination";
import { FaSpinner } from "react-icons/fa";

const SportDetailsData = ({ sportsId }) => {
  const initialTableRecords = 25;
  const dispatch = useDispatch();
  const timeBefore = moment().subtract(20, "days").format("YYYY-MM-DD");
  const time = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(timeBefore);
  const [endDate, setEndDate] = useState(time);
  const [matchId, setMatchId] = useState(0);
  const [dataNameee, setDataNameee] = useState("");
  const [InPlay, setInPlay] = useState();
  const [statusStr, setStatusStr] = useState("");
  const [activeTabData, setActtiveTabData] = useState(4);
  const [totalPage, setTotalPage] = useState(1);
	const [tableRecords, setTableRecords] = useState(initialTableRecords);
	const [index, setIndex] = useState(0);

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: tableRecords,
    index: index,
    totalPages: totalPage,
  });

  const selector = useSelector(selectSportDetails);

  const onStartDate = (date, dateString) => {
    setStartDate(dateString);
  };
  const onEndDateDate = (date, dateString) => {
    setEndDate(dateString);
  };

  const getMatchId = (matchId, inPlay, sportName, statusStraVal) => {
    setMatchId(matchId);
    setDataNameee(sportName);
    setInPlay(inPlay);
    setStatusStr(statusStraVal);
  };

  useEffect(()=>{
    setAccountStatementBody({
      noOfRecords: tableRecords,
      index: index,
      totalPages: selector?.data?.data?.totalPages,
    });
  }, [selector?.data?.data])


  useEffect(() => {
    dispatch(
      SportDetails({
        endDate: endDate,
        index: index,
        noOfRecords: tableRecords,
        sportId: sportsId?.id || 4,
        startDate: startDate,
      })
    );
  }, [sportsId, startDate, endDate, tableRecords, index]);


  const nav = useNavigate();


  const items = [
    {
      label: (
        <Link
          to={`/detail/${activeTabData}/${matchId}`}
          className="title_section"
          style={{
            display: `${
              statusStr === "In Play" || statusStr === "Upcoming"
                ? "block"
                : "none"
            }`,
          }}>
          Match and Session Position
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <p className="title_section" onClick={() => handlePlusMinus(matchId)}>
          Match and Session Plus Minus
        </p>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          // onClick={() => setDropdownStates(false)}
          className="title_section"
          to={`/match-slips/${matchId}`}>
          Display Match Bets
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link
          // onClick={() => setDropdownStates(false)}
          className="title_section"
          to={`/fancy-slips/${matchId}`}>
          Display Session Bets
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link
          // onClick={() => setDropdownStates(false)}
          className="title_section"
          to={`/completed-fancy-slips/${matchId}`}>
          Completed Fancies
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Link
          // onClick={() => setDropdownStates(false)}
          className="title_section"
          to={`/rejectedBetsByEvent/${matchId}`}>
          Rejected Bet
        </Link>
      ),
      key: "5",
    },
  ];

  const handlePlusMinus = (matchId) => {
    nav(`/plus-minus-report/${matchId}`, { state: { dataNameee } });
  };

  const handleTableRecordsChange = (value) => {
    setTableRecords(value);
  };

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  // console.log(selector?.loading, "selector")

  return (
    <div>
      <div className="date_sec">
        <div className="sub_date_sec">
          <div style={{ marginBottom: "5px" }}>Start Date</div>
          <DatePicker defaultValue={dayjs(timeBefore)} onChange={onStartDate} />
        </div>
        <div className="sub_date_sec">
          <div style={{ marginBottom: "5px" }}>End Date</div>
          <DatePicker defaultValue={dayjs(time)} onChange={onEndDateDate} />
        </div>
        <div className="row-selector sport_selector" style={{ alignItems: "center" }}>
          <p style={{marginRight:"5px"}}>Show</p>
          <Select
            defaultValue={25}
            value={tableRecords}
            onChange={handleTableRecordsChange}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={200}>200</Select.Option>
          </Select>
          <p style={{marginLeft:"5px"}}>entries</p>
        </div>
      </div>
      { selector?.loading ? <Spin  indicator={antIcon} className="loading_active" size="large">
        <div className="content" />
      </Spin> :
        <div className="accountlist-table-container">
        <table className="table_section">
          <tr>
            <th></th>
            <th>Code</th>
            <th>Name</th>
            <th>Setting</th>
            <th>Time</th>
            <th>Declare</th>
            <th>Won by</th>
            <th className="text-right">Plus Minus</th>
            <th className="text-right">Upline Amount</th>
          </tr>
          {/* {isLoading || isFetching ? (
            <div className="spin_icon comp_spin">
              <Spin size="large" />
            </div>
          ) : (
            ""
          )} */}
          {selector?.data?.data?.data?.map((res, id) => {
            return (
              <tr key={res?.key}>
                <td style={{ cursor: "pointer" }}>
                  <Dropdown
                    className="table_dropdown"
                    menu={{
                      items,
                      className: "sport_list",
                    }}
                    trigger={["click", "contextMenu"]}>
                    <p
                      onClick={(e) => {
                        e.preventDefault();
                        getMatchId(
                          res?.eventId,
                          res?.inPlay,
                          res?.eventName,
                          res?.statusStr
                        );
                      }}>
                      <Space>
                        <CaretDownOutlined />
                      </Space>
                    </p>
                  </Dropdown>
                </td>
                <td>{res?.eventId}</td>
                <td>{res?.eventName}</td>
                <td>{res?.statusStr}</td>
                <td>{moment(res?.eventDate).format("YYYY-MM-DD, h:mm A")}</td>
                <td>{res?.isLedgerCreated === true ? "YES" : "NO"}</td>
                <td>{res?.winner === null ? "" : res?.winner}</td>
                <td
                  className={`text-right ${
                    res?.plusMinus < 0
                      ? "text_danger"
                      : res?.plusMinus > 0
                      ? "text_success"
                      : ""
                  }`}>
                  {res?.plusMinus === null ? "0" : res?.plusMinus}
                </td>
                <td
                  className={`text-right ${
                    res?.upLineAmount < 0
                      ? "text_danger"
                      : res?.upLineAmount > 0
                      ? "text_success"
                      : ""
                  }`}>
                  {res?.upLineAmount === null ? "0" : res?.upLineAmount}
                </td>
              </tr>
            );
          })}
          {(selector?.data?.data?.data === undefined ||
            selector?.data?.data?.data?.length === 0) && (
            <tr>
              {" "}
              <td colSpan={9}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          )}
        </table>
    </div>
      }
      
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      /> 
    </div>
  );
};

export default SportDetailsData;
