import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { LogInApi, PasswordReset } from "../../../Services/ApiEndingPoint";
import { navRef } from "../../../LoginPage/LoginPage";
import { message } from "antd";
import { axiosInstance } from "../../../Services/AxiosInstance";

const setLocalStorageItems = (data) => {
  localStorage.setItem("token", data?.token);
  localStorage.setItem("user", JSON.stringify(data));
  localStorage.setItem("userType", data?.userType);
  localStorage.setItem("partnership", data?.partnership);
  localStorage.setItem("username", data?.username);
};

export const logIn = createAsyncThunk(
  "login/logIn",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(LogInApi, { ...payload });

      if (response.data.status === false) {
        message.error(response.data.message);
        return rejectWithValue(response.data.message);
      }
      setLocalStorageItems(response.data);

      if (response.data.passwordtype === "old") {
        navRef("/password-reset");
       
      } else {
        navRef("/");
      }


    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
