import { createAsyncThunk } from "@reduxjs/toolkit";
import {completeFancyEndPoint} from '../../../Services/ApiEndingPoint'
import { axiosInstance } from "../../../Services/AxiosInstance";

export const completeFancy = createAsyncThunk('completefancy', async(payload, {rejectWithValue})=>{
	try{
		const response = await axiosInstance.post(completeFancyEndPoint, {...payload});
		if(response){
			return response?.data
		}
	}catch(error){
		return rejectWithValue(error);
	}
})