import React, { useState } from "react";
import { axiosInstance } from "../Services/AxiosInstance";
import { Button, Input } from "antd";
import style from "./ChangePassword.module.scss";
import { toast } from "react-toastify";
import { loadingRef } from "../App";
import { useNavigate } from "react-router";
import { USER_LOGIN } from "../utils/linkConstant";

const ChangePassword = () => {
  const [loginData, setloginData] = useState({
    currentPassword: "",
    password: "",
    newPassword: "",
  });
  const [error, setError] = useState({
    currentPassword: false,
    password: false,
    newPassword: false,
    passwordNotMatch: false,
  });
  const passwordResetApi = async () => {
    loadingRef("chnagePasswor", true);
    const endPoint = "user/change-password-self";
    const response = await axiosInstance.post(endPoint, loginData);
    if (response.data.status) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.message);
    }
    loadingRef("chnagePasswor", false);
  };

  const nav = useNavigate()

  const onSumbit = () => {
    let isSuccess = false;
    for (const key of Object.keys(loginData)) {
      setError((prev) => {
        return { ...prev, [key]: Boolean(!loginData[key]) };
      });
    }
    for (const key of Object.keys(loginData)) {
      const value = Boolean(loginData[key]);
      if (!value) {
        isSuccess = false;
        break;
      } else {
        isSuccess = true;
      }
    }
    if (loginData.password == loginData.newPassword) {
      setError((prev) => ({ ...prev, passwordNotMatch: false }));
      if (isSuccess) {
        passwordResetApi(loginData);
        localStorage.clear();
        nav(USER_LOGIN);
      }
    } else {
      setError((prev) => ({ ...prev, passwordNotMatch: true }));
    }
    //
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "newPassword") {
      if (value == loginData.password) {
        setError((prev) => {
          return {
            ...prev,
            passwordNotMatch: false,
          };
        });
      } else {
        setError((prev) => {
          return {
            ...prev,
            passwordNotMatch: true,
          };
        });
      }
    }
    if (!value) {
    } else if (value) {
      setloginData((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          [name]: false,
        };
      });
    }
    setloginData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const redBorder = "1px solid red";
  const greenBorder = "1px solid green";

  const handle = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // const rgex = value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,15}$/);
    setError((prev) => {
      return {
        ...prev,
      };
    });
    setloginData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <div className={style["cont"]}>
      <p
        style={{ fontSize: "20px", paddingBlock: "10px", paddingBottom: "0px" }}
      >
        Change Password
      </p>
      <Input
        placeholder="Old Password"
        type="password"
        value={loginData.currentPassword}
        name="currentPassword"
        style={{ border: error.currentPassword ? redBorder : greenBorder }}
        onChange={handleChange}
      />
      <Input
        placeholder="New Password"
        type="password"
        value={loginData.password}
        name="password"
        required
        style={{ border: error.password ? redBorder : greenBorder }}
        onChange={handle}
      />
      {error.password && (
        <span style={{ fontSize: "12px", marginTop: "-20px" }}>
          Password is required.
        </span>
      )}
      <Input
        placeholder="Confirm Password"
        type="password"
        value={loginData.newPassword}
        name="newPassword"
        style={{ border: error.newPassword ? redBorder : greenBorder }}
        onChange={handleChange}
      />
      {error?.passwordNotMatch && (
        <span style={{ color: "red" }}>Password doesn't match</span>
      )}
      <Button onClick={() => onSumbit()} className={style["sub_button"]}>
        Submit
      </Button>
    </div>
  );
};

export default ChangePassword;
