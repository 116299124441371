import React, { useEffect, useState } from "react";
import style from "./PasswordReset.module.scss";
import { logo } from "../utils/imgConstant";
import { useNavigate } from "react-router";
// import axiosInstance from '../Services/AxiosInstance';
import axios from "axios";
import { axiosInstance } from "../Services/AxiosInstance";
import { toast } from "react-toastify";
import { useSelfByAppUrlMutation } from "../Services/CasinoService";

const PasswordReset = () => {
  const [enteredCurrentPassword, setEnteredCurrentPassword] = useState("");
  const [enteredNewPassword, setEnteredNewPassword] = useState("");
  const [enteredConfirmPassword, setEnteredConfirmPassword] = useState("");
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [passwordCheckMessage, setPasswordCheckMessage] = useState("");
  const navigate = useNavigate();

  const passwordChangeHandler = async (e) => {
    e.preventDefault();
    if (enteredNewPassword == enteredConfirmPassword) {
      setPasswordNotMatch(false);
      const baseUrl = "/user/first-login-cp";
      const user = JSON.parse(localStorage.getItem("user") || "");
      const token = localStorage.getItem("token");
      const response = await axiosInstance.post(
        baseUrl,
        {
          userid: user.userId,
          newPassword: enteredNewPassword,
          currentPassword: enteredCurrentPassword,
          oldPassword: enteredCurrentPassword,
          token,
        },

        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response?.data?.status) {
        toast.success(response.data?.message);
        navigate("/user-login");
      } else {
        toast.error(response.data?.message);
      }
    } else {
      setPasswordNotMatch(true);
    }
  };

  const [trigger, {data}] = useSelfByAppUrlMutation();

  useEffect(()=>{
    trigger({
      appUrl:window.location.hostname.slice(6),
      // appUrl:"mir247.com",
    })
  }, [])

  return (
    <div className={style["pass_reset-cont"]}>
      <div className={style["pass_reset-sub-cont"]}>
        <div className={style["pass_reset-heading"]}>
          <img src={data?.data?.logo} alt="logo" />
        </div>
        <div className={style["pass_reset-details-cont"]}>
          <form
            className={style["pass_reset-details"]}
            onSubmit={passwordChangeHandler}
          >
            {passwordCheckMessage && (
              <div className={style["password_update_message"]}>
                {passwordCheckMessage}
              </div>
            )}
            <p>Update Password</p>
            <input
              className={style["pass_reset-input"]}
              type="password"
              required
              placeholder="Old Password"
              onChange={(e) => setEnteredCurrentPassword(e.target.value)}
              value={enteredCurrentPassword}
            />

            <input
              className={style["pass_reset-input"]}
              type="password"
              required
              placeholder="New Password"
              // pattern="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{6,15}$"
              // title="Password must contain at least 1 letter, 1 digit, and be at least 8 characters long."
              onChange={(e) => setEnteredNewPassword(e.target.value)}
              value={enteredNewPassword}
            />
            <input
              required
              className={style["pass_reset-input"]}
              type="password"
              placeholder="Confirm Password"
              style={{ border: passwordNotMatch && "1px solid red" }}
              onChange={(e) => setEnteredConfirmPassword(e.target.value)}
              value={enteredConfirmPassword}
            />
            {passwordNotMatch && (
              <span style={{ width: "100%", textAlign: "left" }}>
                Password doesn't match
              </span>
            )}
            <button className={style["pass_reset-confirm"]}>Confirm</button>
          </form>
        </div>
        <p className={style["tagline"]}>© MATCHBOX9</p>
      </div>
    </div>
  );
};

export default PasswordReset;
