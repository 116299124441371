import React from 'react';
import style from './Lucky.module.scss';

const Lucky7 = () => {
	const lucky7Iframe = 'http://247idhub.com:3042/53';
	return (
		<div className={style['cont']} style={{ padding: '0!important' }}>
			<iframe class="live-iframe" className={style['lucky-iframe']} title="score-iframe" src={lucky7Iframe}></iframe>
		</div>
	);
};

export default Lucky7;
