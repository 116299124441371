import React, { useEffect, useState } from "react";
import style from "./Credit.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { VscDiffRenamed } from "react-icons/vsc";
import { MdSettingsBackupRestore } from "react-icons/md";
import colors from "../../../colors.module.scss";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { creditDataP } from "../../../Redux/feature/ActiveUserActionButtons/Credit/action";
import { creditDataSelect } from "../../../Redux/feature/ActiveUserActionButtons/Credit/selector";
import { axiosInstance } from "../../../Services/AxiosInstance";

const Credit = ({ selectedItem, setShowModal }) => {
  const selectorP = useSelector(creditDataSelect);
  const creditDataSelector = selectorP?.data?.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(creditDataP({ userId: selectedItem?.userId }));
  }, [dispatch, selectedItem?.userId]);

  // const depositApi = async () => {
  // 	const endPoint = '/matchBox/matchBox_credit-Exposure-amount';
  // 	const token = localStorage.getItem('token');
  // 	await axios.post(endPoint, { userId: selectedItem?.userId }, { headers: { Authorization: `Bearer ${token}` } }).then(res => {
  // 		setCreditData(res?.data?.data);
  // 	});
  // };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      luPassword: "",
      creditRef: "",
      userId: selectedItem?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        luPassword:
          "" == values?.luPassword
            ? "Login User Password field is required."
            : undefined,
        creditRef: "" == values?.creditRef ? "Limit is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },

    onSubmit: async () => {
      const endPoint = "matchBox/update-credit";
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status) {
          resetForm();
          setShowModal("");
          // props.portalHidden(false);
          return toast.success(response?.data?.message);
        } 
      } catch (response) {
        return toast.error(response?.response?.data?.message);
      }
    },
  });

  return (
    <>
      <form className={style["deposit_portal-form"]} style={{ gap: "10px" }}>
        <div className={style["same"]}>
          <label>Old Credit</label>
          <input
            placeholder="Amount"
            name="amount"
            disabled
            style={{ border: "1px solid #666", background: "#dddddd" }}
            value={creditDataSelector?.creditref}
          />
        </div>

        <div className={style["same"]}>
          <label>New Limit</label>
          <input
            onChange={handleChange}
            name="creditRef"
            autoComplete="credit_data"
            id="credit_data"
            onBlur={handleBlur}
            value={values.creditRef}
            type="number"
            style={{
              border: `1px solid ${
                touched.creditRef && errors.creditRef ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.creditRef && errors?.creditRef ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "15px",
              left: "-8%",
            }}
          >
            {errors.creditRef}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Transaction Passsword</label>
          <input
            placeholder="Transaction Password"
            type="password"
            onChange={handleChange}
            value={values.luPassword}
            onBlur={handleBlur}
            autoComplete="off"
            // onMou
            name="luPassword"
            style={{
              border: `1px solid ${
                touched.luPassword && errors.luPassword ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.luPassword && errors?.luPassword ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "5%",
            }}
          >
            {errors.luPassword}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["status_buttons"]}>
          <button className={style["status_back"]}>
            <span className={style["icons"]}>
              <MdSettingsBackupRestore />
            </span>
            <span>Back</span>
          </button>
          <button
            className={style["status_submit"]}
            type="submit"
            onClick={handleSubmit}
          >
            <span>Submit</span>
            <span className={style["submit_icon"]}>
              <VscDiffRenamed />
            </span>
          </button>
        </div>
      </form>
    </>
  );
};

export default Credit;
