import { Pagination, Table } from "antd";
import React, { useState } from "react";
import { useExposureBetListQuery } from "../../Services/CasinoService";

const Exposure = ({selectedActionData}) => {
    console.log(selectedActionData, "dsfwfdsfs")
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const columns = [
    {
      title: "User Id",
      dataIndex: "userid",
    },
    {
      title: "odds",
      dataIndex: "odds",
      key: "odds",
    },
    {
      title: "Selection Name",
      dataIndex: "marketname",
    },
    {
      title: "Stack",
      dataIndex: "stack",
    },
    {
      title: "liability",
      dataIndex: "liability",
    },
    {
      title: "Date",
      dataIndex: "matchedtime",
    },
  ];

  const { data, isLoading, isFetching } = useExposureBetListQuery({
    userId: selectedActionData?.userId,
    index: 0,
    noOfRecords: 1000,
  }, {refetchOnMountOrArgChange:true});

  return (  
    <>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          dataSource={data?.data?.betList}
          pagination={{pageSize:50,  pageSizeOptions:[50, 100, 150, 200, 250] }}
          loading={isFetching || isLoading}
          rowClassName={(record, index) => (record.isback ?'back' : "lay")}
        />
      </div>
    </>
  );
};

export default Exposure;
