import React from 'react';
import style from './Bollywood.module.scss';
import { AmarAkbarAnthony } from '../IFrame/IframeContainer';
import MarketAnalysisRight from '../../MarketAnalysis/BetDetails/MarketAnalysisRight';

const Bollywood = () => {
	const aaaIframe = 'http://247idhub.com:3042/54';

	return (
		<div className={style['aaa-cont']}>
			<iframe class="live-iframe" className={style['aaa-iframe']} title="score-iframe" src={aaaIframe}></iframe>
		</div>
	);
};

export default Bollywood;
