import React, { useEffect, useState } from "react";
import { VscDiffRenamed } from "react-icons/vsc";
import { MdSettingsBackupRestore } from "react-icons/md";
import style from "../../MarketAnalysisLogo/Portals/Withdrawn/Withdraw.module.scss";
import { useCashTransactionPopupMutation } from "../../Services/CasinoService";
import { Button, Form, Input } from "antd";
import { toast } from "react-toastify";
import './LedgerPopUp.scss'
import TextArea from "antd/es/input/TextArea";

const LedgerPopUp = ({
  selectedItem,
  setShowModal,
  showModal,
}) => {
  const [amount, setAmount] = useState("");
  const [form] = Form.useForm();
  const [trigger, { data, error }] = useCashTransactionPopupMutation();


  useEffect(() => {
    if (data?.status === true) {
      toast.success(data?.message);
      form?.resetFields();
      setShowModal(false)
    } else if (data?.status === false || error?.data?.message) {
      toast.error(data?.message || error?.data?.message);
      setShowModal(false)
    }
  }, [data?.data, error]);

  const onFinish = (value) => {
    trigger({
      userId: selectedItem?.userId,
      collection: "CASH",
      amount: value.amount,
      paymenttype: showModal == "Lena" ? "Liya" : "Diya",
      remarks: value.remarks,
      transactionPassword: value.password,
    });
  };


  return (
    <>
      <Form
        name="basic"
        form={form}
        className="lena_dena_modals"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        >
        <div >
          <Form.Item label="Client" name="client">
            <Input
              disabled
              defaultValue={selectedItem?.userId}
              value={selectedItem?.userId}
              type="text"
              placeholder="Client Name"
            />
          </Form.Item>
        </div>

        <div >
          <Form.Item
            label="Amount"
            name="amount"
            required
            rules={[
              {
                required: true,
                message: "Enter Amount",
              },
            ]}>
            <Input type="number" placeholder="Enter Amount" />
          </Form.Item>
        </div>

        <div >
          <Form.Item
            label="Remark"
            name="remark"
            required
            rules={[
              {
                required: true,
                message: "Enter Remark",
              },
            ]}>
            <TextArea type="textArea" placeholder="Remarks" />
          </Form.Item>
        </div>

        <div >
          <Form.Item
            label="Transaction Password"
            name="password"
            required
            rules={[
              {
                required: true,
                message: "Enter Transaction Password",
              },
            ]}>
            <Input type="password" placeholder="Remarks" />
          </Form.Item>
        </div>

        <div >
          <Button
            className="lena_dena_btn"
            htmlType="submit">
            <span>Submit</span>
            <span className={style["submit_icon"]}>
              <VscDiffRenamed />
            </span>
          </Button>
        </div>
      </Form>
    </>
  );
};

export default LedgerPopUp;
