import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import style from "./AccountListWithdraw.module.scss";

import ContextPortal from "../../../Context/PortalContext";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import { useFormik } from "formik";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListWithdraw = (props) => {
  const ctx = useContext(ContextPortal);

  const [withdrawData, setWithdrawData] = useState({});
  const [showPass, setShowPass] = useState(false);

  const [withdrawPnl, setWithdrawPnl] = useState(
    withdrawData?.childUplineAmount
  );

  useEffect(() => {
    withdrawApi();
  }, []);
  const withdrawApi = async () => {
    loadingRef("dwcdepositwithdrawdatadata", true);
    const endPoint = "dwc/depositwithdrawdata";
    const token = localStorage.getItem("token");
    await axiosInstance
      .post(
        endPoint,
        { userId: props?.selectedRecord?.userId },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((res) => {
        setWithdrawData(res.data.data);
      })
      .catch((err) => {
        loadingRef("dwcdepositwithdrawdatadata", false);
      });
    loadingRef("dwcdepositwithdrawdatadata", false);
  };

  //calculation

  const { values, handleChange, handleSubmit, errors, handleBlur, touched } =
    useFormik({
      initialValues: {
        amount: "",
        remark: "",
        lupassword: "",
        userId: props?.selectedRecord?.userId,
      },

      validateOnChange: false,
      validate: (values) => {
        const newError = {
          lupassword: !values?.lupassword
            ? "Login User Password field is required."
            : undefined,
          remark: !values?.remark ? "Remark field is required. " : undefined,
          amount: !values.amount ? "Amount field is required. " : undefined,
        };
        return Object.fromEntries(
          Object.entries(newError).filter(([_, v]) => v != null)
        );
      },

      // const depositTransApi =
      onSubmit: async () => {
        const endPoint = "dwc/withdraw-chips-pnl";
        loadingRef("ddwwithdrawchipspnldata", true);
        try {
          const response = await axiosInstance.post(endPoint, values);
          if (response?.data?.status) {
            props.onPortalHidden(false);
            toast.success(response?.data?.message);
          } else {
            toast.error(response?.data?.message);
          }
        } catch (response) {
          toast.error(response?.response?.data?.message);
        }
        loadingRef("ddwwithdrawchipspnldata", false);
      },
    });

  return (
    <>
      <form className={style["withdraw_portal-form"]}>
        <div className={style["same"]}>
          {/* <label>{props.selectedRecord.userId}</label> */}
          <label>{withdrawData?.parentName}</label>
          <input
            className={style["withdraw_portal-form_input"]}
            disabled
            value={withdrawData?.parentAmount}
          />
          <input
            className={style["withdraw_portal-form_input"]}
            disabled
            value={
              values.amount
                ? Number(withdrawData?.parentAmount) + Number(values.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          {/* <label>{props.selectedRecord.username}</label> */}
          <label>{withdrawData?.childName}</label>
          {/* <lable>{props.portalType === "Withdraw" ?  }</lable> */}
          <input
            className={style["withdraw_portal-form_input"]}
            disabled
            value={withdrawData?.childAmount}
          />
          <input
            className={style["withdraw_portal-form_input"]}
            disabled
            value={
              values.amount
                ? Number(withdrawData?.childAmount) - Number(values.amount)
                : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>Profit/Loss</label>
          <input
            className={style["withdraw_portal-form_input"]}
            disabled
            type="number"
            value={withdrawPnl}
          />
          <input
            className={style["withdraw_portal-form_input"]}
            type="number"
            disabled
            value={
              values?.amount ? Number(withdrawPnl) - Number(values?.amount) : ""
            }
          />
        </div>

        <div className={style["same"]}>
          <label>Amount</label>
          <input
            placeholder="Amount"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.amount}
            name="amount"
            style={{
              border: `1px solid ${
                touched?.amount && errors?.amount ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched?.amount && errors?.amount ? (
          <span
            style={{
              position: "relative",
              color: "red",
              left: "-3%",
              bottom: "10px",
              fontSize: "13px",
            }}
          >
            {errors?.amount}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Remark</label>
          <textarea
            name="remark"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.remark}
            style={{
              border: `1px solid ${
                touched?.remark && errors?.remark ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched?.remark && errors?.remark ? (
          <span
            style={{
              position: "relative",
              color: "red",
              left: "-3%",
              bottom: "10px",
              fontSize: "13px",
            }}
          >
            {errors.remark}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Transaction Passsword</label>
          <input
            placeholder="Transaction Password"
            type={showPass ? "text" : "password"}
            onChange={handleChange}
            value={values.lupassword}
            name="lupassword"
            onBlur={handleBlur}
            style={{
              border: `1px solid ${
                touched.lupassword && errors.lupassword ? "red" : ""
              }`,
              outline: "none",
            }}
          />
          {values?.lupassword?.length > 0 ? (
            showPass ? (
              <AiFillEye
                className={style["withdraw-eye"]}
                onClick={() => setShowPass(false)}
              />
            ) : (
              <AiFillEyeInvisible
                className={style["withdraw-eye"]}
                onClick={() => setShowPass(true)}
              />
            )
          ) : (
            ""
          )}
        </div>
        {touched.lupassword && errors?.lupassword ? (
          <span
            style={{
              position: "relative",
              color: "red",
              left: "5%",
              bottom: "10px",
              fontSize: "13px",
            }}
          >
            {errors.lupassword}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["withdraw_buttons"]}>
          <button
            className={style["withdraw_submit"]}
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button onClick={ctx.portalHidden} className={style["withdraw_back"]}>
            Back
          </button>
        </div>
      </form>
    </>
  );
};

export default AccountListWithdraw;
