import { Spin, Table, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const UserHistoryLoginTable = ({ loginData, searchVal, tableRecords, loading }) => {
  const columns = [
    {
      title: "User Name",
      dataIndex: "userid",
      filteredValue: [searchVal],
      onFilter: (value, record) => {
        return (
          String(record?.userid).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.ip).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.lastLogin).toLowerCase().includes(value.toLowerCase())
        );
      },
    },

    {
      title: "Date / Time",
      dataIndex: "lastLogin",
    },

    {
      title: "IP",
      dataIndex: "ip",
    },

    {
      title: "Detail",
      dataIndex: "deviceInfo",
    },
  ];
  const dataSource = loginData?.map((res) => {
    return {
      userid: res?.userid,
      ip: res?.ip,
      lastLogin: res?.lastLogin,
      deviceInfo: (
        <Tooltip title={res.deviceInfo}>
          <BsFillEyeFill style={{ cursor: "pointer" }} />
        </Tooltip>
      ),
    };
  });


  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  loading ,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={dataSource}
        // pagination={false}
        loading={tableLoading}
        pagination={{defaultPageSize: 50, pageSizeOptions:[50, 100, 150, 200, 250] }}
      />
    </>
  );
};

export default UserHistoryLoginTable;
