import React from 'react';
import style from './Commission.module.scss';
import colors from '../../colors.module.scss';

const Commission = () => {
	return (
		<div className={style['commission-cont']}>
			<div className={style['commission']}>
				<p>Commission Setting</p>
			</div>
			<div className={style['commission-total']}>
				<div className={style['commission-breakdown']}>
					<p className={style['upline']}>Upline</p>
					<p className={style['downtime']}>Downline</p>
					<p className={style['our']}>Our</p>
				</div>
				<div className={style['commission-amount']}>
					<p className={style['upline']}>0</p>
					<p className={style['downtime']}>0</p>
					<p className={style['our']}>0</p>
				</div>
			</div>
		</div>
	);
};

export default Commission;
