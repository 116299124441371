import React, { useState } from "react";
import style from "./CurrentBetHeader.module.scss";
import CurrentBetSport from "./CurrentBetSport";
import CurrentBetCasino from "./CurrentBetCasino";
import { Tabs } from "antd";

const CurrentBetHeader = ({
  setBetsButton,
  betsHandler,
  setBetSportType,
  betSportType,
  record,
  setRecord,
  setMatchId,
  casinoSelector,
  setIsDeleted,
  matchId
}) => {
  const items = [
    {
      key: "1",
      label: `Sport`,
      children: (
        <CurrentBetSport
          betsHandler={betsHandler}
          setBetsButton={setBetsButton}
          setIsDeleted={setIsDeleted}
        />
      ),
    },

    {
      key: "2",
      label: `Casino`,
      children: (
        <CurrentBetCasino
          betsHandler={betsHandler}
          setRecord={setRecord}
          record={record}
          setMatchId={setMatchId}
          casinoSelector={casinoSelector}
          setIsDeleted={setIsDeleted}
          matchId={matchId}
        />
      ),
    },
  ];

  const onChange = (key) => {
    setBetSportType(key);
  };

  return (
    <div className={style["current_bet-cont"]}>
      <div className={style["current_bet-heading"]}>Current Bets</div>

      {/* <div className={style['sport_type']}>
				<p onClick={() => setBetSportType(1)} id="1" value={1} className={style[betSportType === 1 ? 'active_class' : '']}>
					Sport
				</p>
				<p onClick={() => setBetSportType(2)} id="2" value={2} className={style[betSportType === 2 ? 'active_class' : '']}>
					Casino
				</p>
			</div> */}

      <Tabs
        defaultActiveKey="1"
        type="card" //
        items={items}
        onChange={onChange}
        destroyInactiveTabPane={true}
      />
    </div>
  );
};

export default CurrentBetHeader;
