import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Select, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { matchBet } from "../../../Redux/feature/MatchBet/action";
import { useParams } from "react-router";
import { matchBetSelector } from "../../../Redux/feature/MatchBet/selector";
import Pagination from "../../../Pagination/Pagination";
import { FaSpinner } from "react-icons/fa";
const MatchBetTable = ({ type }) => {
  const [searchData, setSearchData] = useState([]);
  const [searchId, setSearchId] = useState("");
  const [marketData, setMarketData] = useState("");
  const [records, setRecords] = useState(100);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: records,
    index: 0,
    totalPages: 1,
  });

  const dispatch = useDispatch();
  const selector = useSelector(matchBetSelector);
  const columns = [
    {
      title: "Rate",
      dataIndex: "odds",
      key: "odds",
    },
    {
      title: "Amount",
      dataIndex: "stake",
      key: "stake",
    },
    {
      title: "Type",
      dataIndex: "marketname",
      key: "marketname",
    },
    {
      title: "Team",
      dataIndex: "matchname",
      key: "matchname",
    },
    {
      title: "Selection Name",
      dataIndex: "selectionname",
      key: "selectionname",
    },
    {
      title: "Client",
      dataIndex: "userid",
      key: "userid",
    },
    {
      title: "Agent",
      dataIndex: "dealerid",
      key: "dealerid",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Profit/Loss",
      dataIndex: "netpnl",
      key: "netpnl",
      render: (text) => {
        return (
          <span className={text > 0? "text_success": text < 0 ? "text_danger":""}>{text}</span>
        );
      },
    },
    {
      title: "Volume",
      dataIndex: "pricevalue",
      key: "pricevalue",
      hidden:type == 1 ? false : true
    },
  ].filter(item => !item.hidden);

  // console.log(selector?.data, "dfafadsfsddas")

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setSearchData(res?.data?.data);
      });
  };

  const option = searchData?.map((curElm) => {
    return {
      value: curElm?.id,
      label: `${curElm?.text} (${curElm?.userName})`,
    };
  });

  const { id } = useParams();

  useEffect(() => {
    dispatch(
      matchBet({
        matchId: id,
        type: type,
        userId: searchId,
      })
    );
  }, [type, id]);

  const onFinish = (value) => {
    dispatch(
      matchBet({
        matchId: id,
        type: type,
        userId: searchId,
      })
    );
  };

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  !selector?.status ,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }


  return (
    <div className="acc-cont">
      <div className="account_state_bottom">
        <Form
          onFinish={onFinish}
          autoComplete="off"
          className="cash_form myBet">
          <Form.Item
            label="Search By Client Name"
            name="clientId"
            rules={[
              {
                required: true,
                message: "Select User Id!",
              },
            ]}>
            <Select
              showSearch
              value={searchId || undefined}
              placeholder="Search a Client"
              optionFilterProp="children"
              onChange={(value) => setSearchId(value)}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={option}
            />
          </Form.Item>

          <Form.Item wrapperCol={{}}>
            <Button className="submit_btn" type="" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          pagination={{
            defaultPageSize: 50,
            pageSizeOptions: [50, 100, 150, 200, 250],
          }}
          dataSource={selector?.data}
          rowClassName={(record, index) =>
            (record?.isback ?"back":"lay" )
          }
          style={{ cursor: "pointer" }}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};
export default MatchBetTable;
