import React from 'react';
import MatchOddsTable from './matchOdds/MatchOddsTable';

const Matchoddindex = () => {
	return (
		<>
			<MatchOddsTable />
		</>
	);
};

export default Matchoddindex;
