import React, { useEffect, useState } from "react";
import "./styles.scss";
import { logo } from "../../../utils/imgConstant";
import { BsCaretDownFill } from "react-icons/bs";
import { FaSearchPlus } from "react-icons/fa";
import {
  cashTran,
  commReport,
  dataReport,
  itemsData,
  ledgerData,
  liveMarketData,
  sportDetails,
} from "./DropDownItemsData";
import DropDownComp from "../../dropDown/DropDownComp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CASH_TRANSACTION_A,
  CASH_TRANSACTION_AD,
  CASH_TRANSACTION_M,
  CASH_TRANSACTION_S,
  COMM_REPORT_ADMIN,
  COMM_REPORT_AGENT,
  COMM_REPORT_CLIENT,
  COMM_REPORT_MASTER,
  COMM_REPORT_SUPER,
  LEDGER_ADMIN,
  LEDGER_AGENT,
  LEDGER_CLIENT,
  LEDGER_MASTER,
  LEDGER_SUPER,
  MATCHBOX_LOGO,
  MY_LEDGER,
  PROFIT_LOSS_EVENT,
} from "../../../utils/linkConstant";
import DropHeader from "../dropHeader/DropHeader";
import { Drawer, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import { USER_LOGIN } from "../../../utils/linkConstant";
import axios from "axios";
import { axiosInstance } from "../../../Services/AxiosInstance";
import ChangePassword from "../../../LoginPage/ChangePassword";
import { useMediaQuery } from "../../useMedia";
import MenuSider from "../menuSider";
import { useSelfByAppUrlMutation } from "../../../Services/CasinoService";
export let showDrawerRef;
export let refreshRef;
const HeaderComponent = ({ toggleCollapsed, setToggleCollapsed }) => {
  const isMobile = useMediaQuery("(min-width: 720px)");
  const [dropDownToggle, setDropDownToggle] = useState(false);
  const [client, setClient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchLength, setSearchLength] = useState("");
  const { pathname } = useLocation();


  const userName = localStorage.getItem("username");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const items = [
    {
      label: (
        <div onClick={() => setIsModalOpen(true)}>Change Password</div>
      ),
      key: "1",
      // path: { PASSWORD_RESET }
    },
    {
      label: <p onClick={() => loggedOutApi()}>Logout</p>,
      key: "2",

      // path: { USER_LOGIN }
    },
  ];

  const menuProps = {
    items,
    // onClick: handleMenuClick
  };

  const changeHandler = (e) => {
    setSearchLength(e.target.value);
  };

  const nav = useNavigate();
  const loggedOutApi = async () => {
    const baseUrl = "login/logout";
    const token = localStorage.getItem("token");

    await axiosInstance.post(baseUrl, {}).then((res) => {
      if (res.data.status) {
        localStorage.clear();
        nav(USER_LOGIN);
      }
    });
  };

  const searchIcon = () => {
    if (searchLength.length > 3) {
      setShowModal(true);
      // return;
    } else {
      alert("Please Select User");
    }
  };

  const menu = (
    <>
      <Menu>
        {items.map((item) => (
          <Menu.Item key={item.key}>{item.label}</Menu.Item>
        ))}
      </Menu>
    </>
  );

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  showDrawerRef = onClose;

  const [keyNew, setKeyNew] = useState(0);
  const refershNow = () => {
    setKeyNew((prev) => prev + 1);
  };
  refreshRef = refershNow;

  const userType = localStorage.getItem("userType");

  const [trigger, { data }] = useSelfByAppUrlMutation();

  useEffect(() => {
    trigger({
      appUrl: window.location.hostname.slice(6),
       // appUrl:"mir247.com",
    });
  }, []);

  return (
    <>
      <Drawer
        // title="Basic Drawer"
        placement="left"
        onClose={onClose}
        open={open}
        className="left-drawer">
        <MenuSider />
      </Drawer>
      <div className="header_cont">
        <div className="nav-container">
          <div className="nav-left-col">
            <div className="logo">
              <Link to="/matchbox_active-user">
                <img
                  style={{ marginLeft: "4px" }}
                  src={data?.data?.logo}
                  alt=""
                />
              </Link>
            </div>
            <div
              className={
                open || toggleCollapsed
                  ? "sider-toggle-icon2"
                  : "sider-toggle-icon"
              }
              onClick={() => {
                isMobile ? setToggleCollapsed(!toggleCollapsed) : showDrawer();
              }}>
              <div className="bar1 bar"></div>
              <div className="bar2 bar"></div>
              <div className="bar3 bar"></div>
            </div>
            <div className="nav-menu">
              <ul>
                <li
                  onClick={() => {
                    setClient(true);
                    // if (AccountList_Screen === window.location.pathname) {
                    //   window.location.replace(AccountList_Screen);
                    // } else {
                    nav(MATCHBOX_LOGO);
                    // }
                  }}>
                  {/* <Link to={AccountList_Screen}> */}
                  List Of Clients
                  {/* </Link> */}
                </li>

                <li
                  onClick={() => {
                    setClient(true);
                  }}>
                  <Link to={"/market-analysis"}>Market Analysis</Link>
                </li>
                <li
                  onClick={() => {
                    setClient(true);
                  }}>
                  <Link to={"/dashboard"}>Dashboard</Link>
                </li>

                <li>
                  <DropDownComp
                    name="Sport Betting"
                    icon={<BsCaretDownFill />}
                    items={sportDetails}
                  />
                </li>
                <li>
                  <DropDownComp
                    name="Cash Transaction"
                    icon={<BsCaretDownFill />}
                    items={[
                      {
                        key: "c0",
                        className: `${userType == 4 ? "" : "d-none "}`,
                        label: (
                          <Link to={CASH_TRANSACTION_AD}>
                            Cash Transaction(SA)
                          </Link>
                        ),
                      },
                      {
                        key: "c1",
                        className: `${userType != 5 ? "d-none" : ""}`,
                        label: (
                          <Link to={CASH_TRANSACTION_S}>
                            Cash Transaction(SM)
                          </Link>
                        ),
                      },
                      {
                        key: "c2",
                        className: `${userType == 0 ? "" : "d-none"}`,
                        label: (
                          <Link to={CASH_TRANSACTION_M}>
                            Cash Transaction(M)
                          </Link>
                        ),
                      },
                      {
                        key: "c3",
                        className: `${userType == 1 ? "" : "d-none"}`,
                        label: (
                          <Link to={CASH_TRANSACTION_A}>
                            Cash Transaction(A)
                          </Link>
                        ),
                      },
                      // {
                      //   key: "c4",
                      //   className: `${userType == "4" ? "d-none" : ""}`,

                      //   label: (
                      //     <Link to={CASH_TRANSACTION_C}>
                      //       Cash Transaction(C)
                      //     </Link>
                      //   ),
                      // },
                    ]}
                  />
                </li>
                <li>
                  <DropDownComp
                    name="Lena Dena"
                    icon={<BsCaretDownFill />}
                    items={[
                      {
                        key: "l1",
                        label: <Link to={PROFIT_LOSS_EVENT}>Profit/Loss</Link>,
                      },
                      {
                        key: "l2",
                        label: <Link to={MY_LEDGER}>My Lena Dena</Link>,
                      },
                      {
                        key: "l3",
                        className: `${userType != 4 ? "d-none" : ""}`,
                        label: <Link to={LEDGER_ADMIN}>Sub Admin Lena Dena</Link>,
                      },
                      {
                        key: "l4",
                        className: `${userType != 5 ? "d-none" : ""}`,
                        label: (
                          <Link to={LEDGER_SUPER}>Super Master Lena Dena</Link>
                        ),
                      },
                      {
                        key: "l5",
                        className: `${userType == 0 ? "" : "d-none"}`,
                        label: <Link to={LEDGER_MASTER}>Master Lena Dena</Link>,
                      },
                      {
                        key: "l6",
                        className: `${userType == 1 ? "" : "d-none"}`,
                        label: <Link to={LEDGER_AGENT}>Agent Lena Dena</Link>,
                      },
                      {
                        key: "l7",
                        className: `${userType == "4" ? "d-none" : ""}`,

                        label: <Link to={LEDGER_CLIENT}>Client Lena Dena</Link>,
                      },
                    ]}
                  />
                </li>
                {/* <li>
                  <DropDownComp
                    name="Data Report"
                    icon={<BsCaretDownFill />}
                    items={[
                      {
                        key: "r1",
                        className: `${userType != 4 ? "d-none" : ""}`,
                        label: <Link to={REPORT_ADMIN}>Sub Admin Report</Link>,
                      },
                      {
                        key: "r1",
                        className: `${userType != 5 ? "d-none" : ""}`,
                        label: <Link to={REPORT_SUPER}>Super Master Report</Link>,
                      },
                      {
                        key: "r2",
                        className: `${userType == 0 ? "" : "d-none"}`,
                        label: <Link to={REPORT_MASTER}>Master Report</Link>,
                      },
                      {
                        key: "r3",
                        className: `${userType == 1 ? "" : "d-none"}`,
                        label: <Link to={REPORT_AGENT}>Agent Report</Link>,
                      },
                      {
                        key: "r4",
                        label: <Link to={REPORT_CLIENT}>Client Report</Link>,
                      },
                      
                    ]}
                  />
                </li> */}
                <li>
                  <DropDownComp
                    name="Commission Report"
                    icon={<BsCaretDownFill />}
                    items={[
                      {
                        key: "co1",
                        className: `${userType != 4 ? "d-none" : ""}`,
                        label: (
                          <Link to={COMM_REPORT_ADMIN}>Sub Admin Report</Link>
                        ),
                      },
                      {
                        key: "co1",
                        className: `${userType != 5 ? "d-none" : ""}`,
                        label: (
                          <Link to={COMM_REPORT_SUPER}>
                            Super Master Report
                          </Link>
                        ),
                      },
                      {
                        key: "co2",
                        className: `${userType == 0 ? "" : "d-none"}`,
                        label: (
                          <Link to={COMM_REPORT_MASTER}>Master Report</Link>
                        ),
                      },
                      {
                        key: "co3",
                        className: `${userType == 1 ? "" : "d-none"}`,
                        label: <Link to={COMM_REPORT_AGENT}>Agent Report</Link>,
                      },
                      {
                        key: "co4",
                        className: `${userType == "4" ? "d-none" : ""}`,

                        label: (
                          <Link to={COMM_REPORT_CLIENT}>Client Report</Link>
                        ),
                      },
                    ]}
                  />
                </li>
                {
                  data?.data?.selfAllowed &&  <li
                  onClick={() => {
                    setClient(true);
                  }}>
                  <Link to={"/set-commission"}>Set Commission</Link>
                </li>
                }
               
                {/* <li>
									<Link to="/sport-details">Sport Details</Link>
								</li> */}
                {/* <li>
                  <DropDownComp
                    name="Live Market"
                    icon={<BsCaretDownFill />}
                    items={liveMarketData}
                  />
                </li> */}
                <li>
                  <DropDownComp
                    name="Reports"
                    icon={<BsCaretDownFill />}
                    items={itemsData}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="nav-right-col">
            <div
              className="drop-down-user-name"
              onClick={() => setDropDownToggle(!dropDownToggle)}>
              <Space wrap>
                <Dropdown overlay={menu}>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "0.9rem",
                      fontWeight: "600",
                    }}>
                    <Space>
                      {userName}
                      <DownOutlined />
                    </Space>
                  </p>
                </Dropdown>
              </Space>
            </div>
            {/* <div className="input-div">
              <form>
                <input
                  type="text"
                  placeholder="All Client"
                  value={searchLength}
                  onChange={changeHandler}
                />
              </form>
              <FaSearchPlus onClick={searchIcon} />

              <Modal
                open={showModal}
                footer={null}
                onCancel={() => setShowModal("")}
                destroyOnClose
                width={1300}
                style={{
                  top: "10px",
                  left: "0px",
                  height: "100%",
                  width: "100%",
                }}>
                <SearchModal />
              </Modal>
              <Modal
                open={changePasswordModal}
                footer={null}
                destroyOnClose
                onCancel={() => setChangePasswordModal("")}>
                <ChangePassword />
              </Modal>
            </div> */}
          </div>
        </div>
      </div>
      {/* {pathname === "/matchbox_active-user" && <DropHeader />} */}

      <Modal
        className="change_pass"
        open={isModalOpen}
        footer={false} 
        onCancel={handleCancel}         
        >
        <div className="ch_pass">
         <ChangePassword setIsModalOpen={setIsModalOpen}/>
        </div>
      </Modal>
    </>
  );
};

export default HeaderComponent;
