import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { deletedCashTransation } from '../../../Services/ApiEndingPoint'; 
import { axiosInstance } from '../../../Services/AxiosInstance';

export const delertedHistory = createAsyncThunk('deletedhistory', async (payload, { rejectWithValue }) => {
	try {
		const response = await axiosInstance.post(deletedCashTransation, { ...payload });
		if (response) {
			return response?.data?.data;
		}
	} catch (error) {
		return rejectWithValue(error);
	}
});
