import React, { useState } from "react";
import "./ProfitLossTable.scss";
import { Button, Modal, Select, Table } from "antd";
import ViewMorePortal from "../../MarketAnalysis/Portals/ViewMorePortal";
import ViewButtonPortal from "./ViewButtonPortal";
import SportViewButtonPortal from "./SportViewButtonPortal";
import { accountStatement } from "../../../Redux/feature/AccountStatement/action";

const ProfitLossTable = ({
  record,
  pnlTableData,
  userId,
  setTableRecords,
  tableRecords,
}) => {
  const [searchData, setSearchData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [matchId, setMatchId] = useState("");

  const handleCancel = () => {
    setShowModal(false);
  };
  const openShowModal = (id) => {
    setMatchId(id);
    setShowModal(true);
  };
  const data = pnlTableData?.map((res, index) => {
    return {
      key: res?.pnl + res.credit + res?.commssionMila + index,
      matchName: res?.matchName,
      pnl: (
        <span style={{ color: res.pnl >= 0 ? "green" : "red" }}>
          {Number(res?.pnl).toFixed(2)}
        </span>
      ),
      uplineAmount: (
        <span style={{ color: res?.credit >= 0 ? "green" : "red" }}>
          {res?.uplineAmount}
        </span>
      ),
      Date: res?.createdon,
      Action: (
        <Button
          style={{
            border: "none",
            color: "white",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            padding: "5px 15px",
            marginTop: "0px",
          }}
          onClick={() => openShowModal(res?.matchId)}
        >
          View
        </Button>
      ),
      // commssionMila: (
      //   <span style={{ color: res.commssionMila >= 0 ? "green" : "red" }}>
      //     {res.commssionMila}
      //   </span>
      // ),
      // commssionDiya: (
      //   <span style={{ cursor: "pointer" }}>{res?.commssionDiya}</span>
      // ),
    };
  });

  const columns = [
    {
      title: "Match Name",
      dataIndex: "matchName",
      filteredValue: [searchData],
      onFilter: (value, record) => {
        return (
          String(record.pnl).toLowerCase().includes(value.toLowerCase()) ||
          String(record.matchName)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.uplineAmount)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.Date).toLowerCase().includes(value.toLowerCase())
          // String(record.commssionDiya).toLowerCase().includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Pnl",
      dataIndex: "pnl",
      key: "pnl",
    },
    {
      title: "Upline Amount",
      dataIndex: "uplineAmount",
      key: "uplineAmount",
    },
    {
      title: "Date / Time",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Action",
      dataIndex: "Action",
    },
    // {
    //   title: "commssionMila",
    //   dataIndex: "commssionMila",
    // },
    // {
    //   title: "commssionDiya",
    //   dataIndex: "commssionDiya",
    // },
  ];

  const searchHandler = (e) => {
    setSearchData(e.target.value);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <p>Show</p>
          <Select
            // defaultValue={accountStatement.noOfRecords}
            value={tableRecords.noOfRecords}
            onChange={(e) =>
              setTableRecords((prev) => {
                return {
                  ...prev,
                  noOfRecords: e,
                };
              })
            }
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={50}>50</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={200}>200</Select.Option>
          </Select>
          <p>Enteries</p>
        </div>
        <div
          style={{
            marginRight: "7px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <label>Search:</label>
          <input
            type="text"
            onChange={searchHandler}
            value={searchData}
            placeholder="Search here..."
          />
        </div>
      </div>
      <div className="accountlist-table-container">
        <Table columns={columns} dataSource={data} pagination={false} />
      </div>
      <Modal
        className="more-modal-more"
        title="Bet History"
        footer={null}
        open={showModal}
        onCancel={handleCancel}
        destroyOnClose
        style={{ padding: "15px" }}
        width={"1000px"}
      >
        {showModal && (
          <SportViewButtonPortal
            record={record}
            matchId={matchId}
            userId={userId}
          />
        )}
      </Modal>
    </>
  );
};

export default ProfitLossTable;
