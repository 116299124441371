import React, { useEffect, useState } from "react";
import "./AccountStatementTable.scss";
import { Modal, Select, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { stateAccountData } from "../../../Redux/feature/AccountStatement/selector";
import Pagination from "../../../Pagination/Pagination";
import { accountStatement } from "../../../Redux/feature/AccountStatement/action";
import AccountListModal from "./AccountListModal";
import "./styles.scss";
import { loadingRef } from "../../../App";
import { FaSpinner } from "react-icons/fa";
const AccountStatementTable = ({
  tableRecords,
  setTableRecords,
  searchUser,
  setSearchUser,
  setIndex,
  index,
  dateFrom,
  dateTo,
  searchId,
  accountType,
}) => {
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: tableRecords,
    index: index,
    totalPages: 1,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [marketData, setMarketData] = useState("");
  const selector = useSelector(stateAccountData);

  const showModal = (record) => {
    setSelectedRow(record);
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const accountData = selector?.data?.dataList;
  const loadingState = selector;

  useEffect(() => {
    if (loadingState?.loading) {
      loadingRef("accountStatement", loadingState?.loading);
    }
    loadingRef("accountStatement", loadingState?.loading);
  }, [loadingState]);

  const dispatch = useDispatch();
  useEffect(() => {
    selector?.data?.currentPage &&
      setAccountStatementBody({
        noOfRecords: tableRecords,
        index: selector?.data?.currentPage - 1,
        totalPages: selector?.data?.totalPages,
      });
  }, [tableRecords, selector?.data?.totalPages]);
  useEffect(() => {
    dispatch(
      accountStatement({
        index: accountStatementBody.index,
        noOfRecords: +tableRecords,
        fromDate: dateFrom.format("YYYY-MM-DD"),
        toDate: dateTo.format("YYYY-MM-DD"),
        userid: searchId,
        type: +accountType,
      })
    );
  }, [tableRecords, accountStatementBody?.index]);
  const columns = [
    {
      title: "Sr.No",
      dataIndex: "sno",
    },
    {
      title: "Date / Time",
      dataIndex: "date",
      key: "date",
      filteredValue: [searchUser],
      onFilter: (value, record) => {
        return (
          String(record?.fromto).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.remark).toLowerCase().includes(value.toLowerCase()) ||
          String(record?.date).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text) => {
        return (
          <span style={{ color: text >= 0 ? "green" : "red" }}>{text}</span>
        );
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      render: (text) => {
        return (
          <span style={{ color: text >= 0 ? "green" : "red" }}>{text}</span>
        );
      },
    },
    {
      title: "Pts",
      dataIndex: "pts",
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "From To",
      dataIndex: "fromto",
      key: "fromto",
    },
  ];
  const searchHandler = (e) => {
    setSearchUser(e.target.value);
  };
  const handleTableRecordsChange = (value) => {
    setTableRecords(value);
  };


  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  selector?.loading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  return (
    <div className="acc-cont">
      <div className="account_state_bottom">
        <div className="row-selector" style={{ alignItems: "center" }}>
          <p>Show</p>
          <Select
            defaultValue={25}
            value={tableRecords}
            onChange={handleTableRecordsChange}
          >
            <Select.Option value={25}>25</Select.Option>
            <Select.Option value={100}>100</Select.Option>
            <Select.Option value={200}>200</Select.Option>
          </Select>
          <p>entries</p>
        </div>
        <div className="search">
          <label>Search:</label>
          <input
            type="text"
            onChange={searchHandler}
            value={searchUser}
            placeholder="Search here..."
          />
        </div>
      </div>
      <div className="accountlist-table-container">
        <Table
          columns={columns}
          pagination={false}
          dataSource={accountData}
          onRow={(record) => ({
            onClick: () => {
              showModal(true);
              setMarketData(record);
            },
          })}
          style={{ cursor: "pointer" }}
          loading={tableLoading}
        />
      </div>
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
      <Modal
        title={
          <div
            style={{ padding: "10px", color: "white", background: "#34C38F" }}
          >
            Bet List
          </div>
        }
        footer={null}
        pagination={false}
        open={marketData?.marketid ? isModalVisible : ""}
        onCancel={handleCancel}
        width={"1000px"}
        className="bet-list-modal"
        destroyOnClose
      >
        {isModalVisible && (
          <AccountListModal marketData={marketData} searchId={searchId} />
        )}
      </Modal>
    </div>
  );
};
export default AccountStatementTable;
