import { Spin, Table, Tooltip } from "antd";
import React, { useMemo, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";

const UserHistoryPasswordTable = ({ passwordResetData, searchVal, loading }) => {
  const columns = [
    {
      title: "User Name",
      dataIndex: "userId",
      key: "userId",
      filteredValue: [searchVal],
      onFilter: (value, record) => {
        return (
          String(record?.userId).toLowerCase().includes(value?.toLowerCase()) ||
          String(record?.ip).toLowerCase().includes(value?.toLowerCase()) ||
          String(record?.userid).toLowerCase().includes(value?.toLowerCase()) ||
          String(record?.createdOn).toLowerCase().includes(value?.toLowerCase())
        );
      },
    },

    {
      title: "Date / Time",
      dataIndex: "createdOn",
    },

    {
      title: "IP",
      dataIndex: "ipAddress",
      key: "ip",
    },

    {
      title: "Detail",
      dataIndex: "deviceInfo",
      // dataIndex: 'AiFillEye'

      render: (text, record) => {
        return (
          <>
            <Tooltip title={record.deviceInfo}>
              <BsFillEyeFill style={{ cursor: "pointer" }} />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  loading ,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={passwordResetData}
        pagination={false}
        loading={tableLoading}
      />
    </>
  );
};

export default UserHistoryPasswordTable;
