import React from "react";
import style from "./BetsHistoryHeader.module.scss";
import { Tabs } from "antd";
import BetHistoryCasino from "./BetHistoryCasino";
import BetHistorySports from "./BetHistorySports";

const BetsHistoryHeader = () => {
  const items = [
    {
      key: "1",
      label: `Sports`,
      children: <BetHistorySports />,
    },

    {
      key: "2",
      label: `Casino`,
      children: <BetHistoryCasino />,
    },
  ];

  return (
    <div className={style["profit_loss-cont"]}>
      <div className={style["profit_loss-heading"]}>Bet History</div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        items={items}
        destroyInactiveTabPane={true}
      />

      {/* <div className={style['profit_loss-date']}>
				<div style={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center', height: '60px', marginBottom: '10px' }}>
					<button className={style['bet_history-load']} type="submit" onClick={betHistoryApi}>
						Load
					</button>
					<button style={{ background: 'rgb(239, 242, 247)', color: 'black', border: '1px solid #d9d9d9', fontSize: '14px', height: '35px' }}>Reset</button>
				</div>
			</div> */}
    </div>
  );
};

export default BetsHistoryHeader;
