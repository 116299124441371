import React, { useContext, useEffect, useState } from "react";
import style from "./ProfitLoss.module.scss";
import ProfitLossHeader from "./ProfitLossHeader";
import ProfitLossTable from "./ProfitLossTable";
// import axiosInstance from '../../../Services/AxiosInstance';
import axios from "axios";
import dayjs from "dayjs";
import { selectLogin } from "../../../Redux/feature/ProfitLoss/selector";
import { useSelector } from "react-redux";
import ProfitLossCasinoTable from "./ProfitLossCasinoTable";
import { axiosInstance } from "../../../Services/AxiosInstance";

const ProfitLoss = () => {
  return (
    <div>
      <ProfitLossHeader />

      {/* <ProfitLossTable setTableRecords={setTableRecords} tableRecords={tableRecords} profitLossTable={profitLossTable} /> */}
      {/* <ProfitLossCasinoTable /> */}
    </div>
  );
};

export default ProfitLoss;
