import React, { useState } from "react";
import style from "./ProfitLossHeader.module.scss";
import { Tabs } from "antd";
import ProfitLossSports from "./ProfitLossSports";
import ProfitLossCasino from "./ProfitLossCasino";

const onChangeTab = (key) => {};

const ProfitLossHeader = ({}) => {
	const [index, setIndex] = useState(0);
  const items = [
    {
      key: "1",
      label: `Sports`,
      children: <ProfitLossSports setIndex={setIndex}/>,
    },

    {
      key: "2",
      label: `Casino`,
      children: <ProfitLossCasino setIndex={setIndex}/>,
    },
  ];
  return (
    <div className={style["profit_loss-cont"]}>
      <div className={style["profit_loss-heading"]}>Profit and Loss</div>

      <Tabs
        defaultActiveKey="1"
        type="card"
        items={items}
        onChange={onChangeTab}
        destroyInactiveTabPane={true}
      />
      <div className={style["profit_loss-date"]}>
        {/* <div className={style['type']}>
					<div className={style['search']}>
						<label>Search by Client Name</label>

						<Select
							style={{ width: '200px' }}
							showSearch
							placeholder="Select a person"
							optionFilterProp="children"
							onChange={onChange}
							onSearch={onSearch}
							filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							options={option}
						/>
					</div>
				</div> */}
        {/* <div className={style['']}>
					<label>From</label>
					<DatePicker
						value={dateFrom}
						onChange={onRangeChange}
						disabledDate={d => !d || d.isBefore(dayjs().subtract(2, 'month')) || d.isAfter(dateTo) || d.isAfter(dayjs())}
					/>
				</div>
				<div className={style['']}>
					<label>To</label>
					<DatePicker
						value={dateTo}
						disabledDate={d => !d || d.isBefore(dayjs().subtract(2, 'month')) || d.isBefore(dateFrom) || d.isAfter(dayjs())}
						onChange={onRangeChangeTo}
					/>
				</div> */}

        {/* <div style={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center', height: '60px', marginBottom: '10px' }}>
					<button className={style['profit_loss-load']} onClick={profitLossApi}>
						Load
					</button>
					<button style={{ background: 'rgb(239, 242, 247)', color: 'black', border: '1px solid #d9d9d9', fontSize: '14px', height: '35px' }}>Reset</button>
				</div> */}
      </div>
    </div>
  );
};

export default ProfitLossHeader;
