/* eslint-disable no-unused-expressions */
import { createSlice } from '@reduxjs/toolkit';
import { accountStatement } from './action';

const INITIAL_VALUE = {
	data: null,
	loading: false,
	error: false
};

const slice = createSlice({
	name: 'accountStatement',
	initialState: INITIAL_VALUE,
	reducers: { hui: state => {} },
	extraReducers: builder => {
		// return builder
		builder
			.addCase(accountStatement.pending, state => {
				state.data = null;
				state.loading = true;
				state.error = false;
			})
			.addCase(accountStatement.fulfilled, (state, action) => {
				state.data = action.payload;
				state.loading = false;
				state.error = false;
			})
			.addCase(accountStatement.rejected, (state, action) => {
				state.data = null;
				state.loading = false;
				state.error = true;
			});
	}
});

export const { hui } = slice.actions;
export default slice.reducer;
