import { Empty } from "antd";
import React, { useCallback, useEffect, } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import style from "./TableData.module.scss";
import Bodyloading from "./Bodyloading";
import { useMarketAnalysisMutation } from "../../../Services/CasinoService";

// function LazyLoadedTableData({ sportData, sportsId }) {
function LazyLoadedTableData({ sportsId }) {

  const [trigger, { data, isLoading }] = useMarketAnalysisMutation();

  console.log(sportsId?.id, "dsfsfsdfsdf");

  useEffect(() => {
    trigger({ id: sportsId?.id || 4 });
  }, [sportsId?.id]);


  const arr = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
  ];
  if (isLoading) {
    // debugger;

    return (
      <>
        {arr.map((t) => {
          return <Bodyloading />;
        })}
      </>
    );
  } else if (data?.data) {
    return (
      <>
        {data?.data?.map((desc) => {
          return (
            <>
              {desc?.marketData?.find(
                (t) =>
                  t?.marketName === "Match Odds" || t?.marketName?.includes("Winner") ||
                  t?.marketName?.includes("Bookmaker")
              ) && (
                <>
                  <p className={style["sport_name"]}>{sportsId?.sport}</p>
                  <div className={style["combo"]}>
                    <p className={style["match_name"]}>{desc?.matchName}</p>
                    <p className={style["time"]}>
                      {desc?.time}
                      <AiFillCheckCircle
                        style={{
                          color: "rgb(160, 25, 25)",
                          fontSize: "18px",
                        }}
                      />
                    </p>
                  </div>
                </>
              )}

              <Link
                to={`/detail/${desc?.sportId}/${desc?.matchId}`}
                key={desc?.matchId}
                className={style["market_data-link"]}>
                {desc?.marketData
                  .filter(
                    (data) =>
                      data?.marketName === "Match Odds" || data?.marketName?.includes("Winner") ||
                      data?.marketName?.includes("Bookmaker")
                  )
                  ?.map((el) => {
                    console.log(el, "eleleell")
                    return (
                      <>
                        <div className={style["heading"]}>
                          <div className={style["market_combo"]}>
                            <p className={style["market"]}>
                              {el?.marketName}
                              <AiFillCheckCircle
                                className={style["check-icon"]}
                              />
                            </p>
                          </div>
                          <div key={el.marketId} className={style["desc"]}>
                            <p className={style["team_amount"]}>
                              <span style={{ marginRight: "0px" }}>
                                {el?.selectionName1}
                              </span>
                              <span
                                style={{
                                  color: "green",
                                  color:
                                    el?.selectionAmount1 >= 0 ? "green" : "red",
                                }}>
                                {el?.selectionAmount1}
                              </span>
                            </p>
                            <p className={style["team_amount2"]}>
                              <span>{el?.selectionName2}</span>{" "}
                              <span
                                style={{
                                  color:
                                    el?.selectionAmount2 >= 0 ? "green" : "red",
                                }}>
                                {" "}
                                {el?.selectionAmount2}
                              </span>
                            </p>
                            {el?.selectionName3 ? (
                              <p className={style["team_amount3"]}>
                                <span>{el?.selectionName3}</span>{" "}
                                <span
                                  style={{
                                    color: el?.selectionAmount3 >= 0 
                                      ? "green"
                                      : "red",
                                  }}>
                                  {el?.selectionAmount3}
                                </span>
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </Link>
            </>
          );
        })}
      </>
    );
  } else {
    return <Empty />;
  }
}

export default LazyLoadedTableData;
