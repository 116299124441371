import React, { useState } from 'react';
import style from './Partnership.module.scss';
import colors from '../../colors.module.scss';

const Partnership = props => {
	// const [inputState, setInputState] = useState('');

	const inputChangeHandler = e => {
		props?.setInputState(e.target.value);
	};

	return (
		props?.data?.userRole !== 2 && (
			<div className={style['partnership-cont']}>
				<div className={style['partnership']}>
					<p>Partnership</p>
				</div>
				<div className={style['partnership-total']}>
					<div className={style['partnership-breakdown']}>
						<p className={style['upline']}>Upline</p>
						<p className={style['downtime']}>Downline</p>
						<p className={style['our']}>Our</p>
					</div>
					<div className={style['partnership-amount']}>
						<p className={style['upline']}>{props.partnership}</p>
						<input required type="number" className={style['downtime']} min="1" max="90" onChange={inputChangeHandler} value={props.inputState} />
						<p className={style['our']}>{props.partnership - props.inputState}</p>
					</div>
				</div>
			</div>
		)
	);
};

export default Partnership;
