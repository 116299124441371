import React, { useCallback, useState } from 'react';
import style from './UserHistoryHeader.module.scss';
import { DatePicker, Select, Tabs } from 'antd';
import dayjs from 'dayjs';
import UserHistoryLogin from './UserHistoryLogin';
import UserHistoryPassword from './UserHistoryPassword';

const UserHistoryHeader = ({ onChange, onSearch, searchData, dateFrom, dateTo, onRangeChange, onRangeChangeTo, loginPassHistoryApi }) => {
	const option = searchData?.map(curElm => {
		return {
			label: curElm?.id,
			value: curElm?.text
		};
	});

	const [activeClass, setActiveClass] = useState('login');

	// const loadHandler = useCallback(
	// 	e => {
	// 		e.preventDefault();
	// 		loginPassHistoryApi(activeClass);
	// 	},
	// 	[activeClass, loginPassHistoryApi]
	// );

	const items = [
		{
			key: '1',
			label: `Login History`,
			children: (
				<UserHistoryLogin
					
				/>
			)
		},

		{
			key: '2',
			label: `Change Password History`,
			children: (
				<UserHistoryPassword
					
				/>
			)
		}
	];

	return (
		<>
			<div className={style['history_heading']}>User History</div>
			<Tabs defaultActiveKey="1" type="card" items={items} destroyInactiveTabPane={true} />
			{/* <div className={style['history_nav']} style={{ cursor: 'pointer' }}>
				<p
					onClick={() => {
						setActiveClass('login');
						loginPassHistoryApi('login');
					}}
					className={activeClass === 'login' ? style['active_class'] : ''}>
					Login History
				</p>
				<p
					onClick={() => {
						setActiveClass('password');
						loginPassHistoryApi('password');
					}}
					className={activeClass === 'password' ? style['active_class'] : ''}
					style={{ cursor: 'pointer' }}>
					Change Password History
				</p>
			</div> */}

			{/* <div className={style['history_inputs']}>
				<Select
					showSearch
					placeholder="Select a person"
					filterOption={(input, option) => (option?.label ?? '').toLowerCase()?.includes(input?.toLowerCase())}
					optionFilterProp="children"
					onChange={onChange}
					onSearch={onSearch}
					options={option}
				/>

				<DatePicker
					value={dateFrom}
					onChange={onRangeChange}
					disabledDate={d => !d || d.isBefore(dayjs().subtract(2, 'month')) || d.isAfter(dateTo) || d.isAfter(dayjs())}
				/>

				<DatePicker
					value={dateTo}
					disabledDate={d => !d || d.isBefore(dayjs().subtract(2, 'month')) || d.isBefore(dateFrom) || d.isAfter(dayjs())}
					onChange={onRangeChangeTo}
				/>
				<button onClick={loadHandler}>Load</button>
				<button className={style['reset']}>Reset</button>
			</div> */}
		</>
	);
};

export default UserHistoryHeader;
