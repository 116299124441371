import React, { useState } from 'react';
import { Select } from 'antd';
import style from './AddAccountAccountDetails.module.scss';
import colors from '../../colors.module.scss';

const AddAccountAccountDetails = props => {
	const userType = localStorage.getItem('userType');
	const partData = props.partnership;

	const handleSelectChange = (e, name) => {
		const inputName = name;

		const value = e;

		props.setData(prev => {
			return {
				...prev,
				[inputName]: value
			};
		});
	};

	const usertypeArray = {
		0: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={1} key="0">
				Master
			</Select.Option>,
			<Select.Option value={2} key="1">
				Client
			</Select.Option>
		],
		1: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={1} key="2">
				Agent
			</Select.Option>,
			<Select.Option value={2} key="3">
				Client
			</Select.Option>
		],
		2: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={2} key="4">
				Client
			</Select.Option>
		],
		4: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={1} key="5">
				Sub Admin
			</Select.Option>
		],
		5: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={1} key="6">
				Super Master
			</Select.Option>,
			<Select.Option value={2} key="7">
				Client
			</Select.Option>
		],
		4: [
			<Select.Option value={''} key="empty">
				Select
			</Select.Option>,
			<Select.Option value={1} key="6">
				Sub Admin
			</Select.Option>,
			<Select.Option value={2} key="7">
				Client
			</Select.Option>
		]
	};
	return (
		<div className={style['account-cont']}>
			<div className={style['account']}>
				<p>Account Detail</p>
			</div>
			<div className={style['form']}>
				<div className={style['first_row']}>
					<div className={style['type']}>
						<label>Account Type:</label>
						<Select
							required
							style={{ width: '100%' }}
							// defaultValue={"Select User Type"}
							value={props.data.userRole || ''}
							onChange={e => handleSelectChange(e, 'userRole')}>
							{usertypeArray[userType]}
						</Select>
					</div>
					<div className={style['credit']}>
						<label>Credit Reference</label>
						<input required type="text" placeholder="Credit Reference" style={{ width: '100%' }} onChange={props?.referenceHandler} value={props?.reference} />
					</div>
				</div>

				<div style={{ marginTop: '5px', display: "flex", flexDirection: "column" }}>
					<label>Exposure Limit</label>
					<input required type="text" placeholder="Exposure Limit" style={{ width: '50%' }} onChange={props?.exposureHandler} value={props?.exposure} />
				</div>
				
			</div>

			
			
		</div>
	);
};

export default AddAccountAccountDetails;
