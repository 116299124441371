import React, { useEffect, useState } from "react";
import style from "./ProfitLossSports.module.scss";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
// import axiosInstance from '../../../Services/AxiosInstance';
import ProfitLossTable from "./ProfitLossTable";
import { axiosInstance } from "../../../Services/AxiosInstance";
import Loading from "../../../Loading/Loading";
import Pagination from "../../../Pagination/Pagination";
import { loadingRef } from "../../../App";

const ProfitLossSports = ({setIndex}) => {
  const [sportsType, setSportsType] = useState([]);
  const [sportList, setSportlist] = useState([]);
  const [profitLossData, setProfitLossData] = useState([]);
  const [userId, setUserId] = useState("");
  const [pnlTableData, setPnlTableData] = useState([]);
  const [casionData, setCasionData] = useState("");
  const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, "day"));
  const [dateTo, setDateTo] = useState(dayjs());
  const [matchId, setMatchId] = useState("");
  const [record, setRecord] = useState();

  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: 100,
    index: 0,
    totalPages: 1,
  });
  const onRangeChange = (value, dateStrings) => {
    if (value) {
      setDateFrom(value);
    } else {
    }
  };

  const onRangeChangeTo = (value, dateStrings) => {
    if (value) {
      setDateTo(value);
    } else {
    }
  };

  const option = profitLossData?.map((el) => {
    return {
      label: `${el?.text} (${el?.userName})`,
      value: el?.text,
    };
  });

  useEffect(() => {
    sportListApi();
  }, []);

  //Sports Name

  const onSearch = async (value) => {
    await axiosInstance
      .post(
        `user/search-user-downline?term=${value}&_type=${value}&q=${value}`,
        {}
      )
      .then((res) => {
        setProfitLossData(res?.data?.data);
      });
  };
  const sportListApi = async () => {
    const endPoint = "sport/active-sport-list";
    const response = await axiosInstance.post(endPoint, {});
    setSportlist(response?.data?.data);
  };

  useEffect(() => {
    if (record) {
      // setRecord([]);
      const endPoint = "sport/event-detail-sport-wise";
      setMatchId("");
      axiosInstance
        .post(endPoint, { sportId: record })
        .then((response) => setSportsType(response?.data?.data));
    }
  }, [record]);
  const options = sportList?.map(
    (sport) => {
      return {
        label: sport?.sportName,
        value: sport?.sportId,
      };
    }

    // <option key={sport?.sportId} value={sport?.sportId}>
    // 	{sport?.sportName}
    // </option>
  );

  // const viewMoreApi = async () => {
  // 	const endPoint = 'report/bet-history';
  // 	const response = await axiosInstance.post(endPoint, { index: 0, isDeleted: true, matchId: matchId, noOfRecords: 100, sportId: '', sportType: '1', userId: '' });
  // };
  const loadHandler = () => {
    const loadData = {
      matchId: matchId,
      pageNumber: accountStatementBody.index,
      pageSize: accountStatementBody.noOfRecords,
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userId: userId,
      sportId: record,
    };
    pnlApi(loadData);
  };

  useEffect(() => {
    const data = {
      matchId: matchId,
      pageNumber: accountStatementBody.index,
      pageSize: accountStatementBody.noOfRecords,
      fromDate: dateFrom.format("YYYY-MM-DD"),
      toDate: dateTo.format("YYYY-MM-DD"),
      userId: userId,
      sportId: record || 4,
    };
    pnlApi(data);
  }, [accountStatementBody.index, accountStatementBody.noOfRecords]);

  const pnlApi = async (data) => {
    loadingRef("profitlossmatch", true);
    const endPoint = "report/profit-loss-match-wise";
    const response = await axiosInstance.post(endPoint, data);
    setPnlTableData(response?.data?.data?.market);
    loadingRef("profitlossmatch", false);
    setAccountStatementBody((prev) => {
      return {
        ...prev,
        totalPages: response?.data?.data?.totalRecord,
      };
    });
    loadingRef("profitlossmatch", false);
  };

  const resetHandler = () => {
    setDateFrom(dayjs().subtract(7, "day"));
    setDateTo(dayjs());
    setUserId("");
    setCasionData([]);
    setMatchId("");
    setRecord("");
    const loadData = {
      matchId: "",
      pageNumber: 0,
      pageSize: accountStatementBody.noOfRecords,
      fromDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      userId: "",
      sportId: "",
    };
    pnlApi(loadData);
  };

  return (
    <div className={style["cont"]}>
      <div className={style["profit_loss-cont"]}>
        <div className={style["search"]}>
          <label>Search by Client Name</label>

          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select a person"
            optionFilterProp="children"
            onChange={(e) => setUserId(e)}
            onSearch={onSearch}
            value={userId || "Select a person"}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={option}
          />
        </div>
        <div className={style["from-date"]}>
          <label>From</label>
          <DatePicker
            value={dateFrom}
            onChange={onRangeChange}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isAfter(dateTo) ||
              d.isAfter(dayjs())
            }
          />
        </div>
        <div className={style["to-date"]}>
          <label>To</label>
          <DatePicker
            value={dateTo}
            disabledDate={(d) =>
              !d ||
              d.isBefore(dayjs().subtract(2, "month")) ||
              d.isBefore(dateFrom) ||
              d.isAfter(dayjs())
            }
            onChange={onRangeChangeTo}
          />
        </div>

        {/* <select
				value={record}
				onChange={e => setRecord(e.target.value)}
				style={{ width: '15%', padding: '5px', height: '30px', marginTop: '25px', borderRadius: '5px', border: '1px solid grey' }}>
				{sportList?.map(sport => (
					<option key={sport.sportId} value={sport.sportId}>
						{sport.sportName}
					</option>
				))}
			</select> */}
        <Select
          placeholder="Select Sport"
          options={options}
          onChange={setRecord}
          className={style["to-date"]}
          value={record || "Select Sport"}
          // style={{ width: "15%", marginTop: "25px" }}
        />

        <Select
          placeholder="Select Match"
          className={style["to-date"]}
          // style={{ width: "15%", marginTop: "25px" }}
          onChange={(e) => setMatchId(e)}
          value={matchId || "Select Match"}
        >
          {sportsType?.map((el) => {
            return (
              <Select.Option key={el?.eventId} value={el?.eventId}>
                {el?.eventName}
              </Select.Option>
            );
          })}
        </Select>
      </div>
      <div>
        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            alignItems: "center",
            height: "60px",
            marginBottom: "15px",
          }}
        >
          <button
            className={style["profit_loss-load"]}
            onClick={() => loadHandler()}
            style={{
              cursor: "pointer",
              padding: "8px 10px",
              fontSize: "14px",
              background: "#2C3E50",
              color: "white",
              border: "none",
              borderRadius: "3px",
            }}
          >
            Load
          </button>
          <button
            style={{
              background: "rgb(239, 242, 247)",
              color: "black",
              border: "1px solid #d9d9d9",
              fontSize: "14px",
              cursor: "pointer",
              padding: "8px 10px",
            }}
            onClick={resetHandler}
          >
            Reset
          </button>
        </div>
      </div>
      <ProfitLossTable
        pnlTableData={pnlTableData}
        setTableRecords={setAccountStatementBody}
        tableRecords={accountStatementBody}
        // profitLossTable={profitLossTable}
        record={record}
        matchId={matchId}
        userId={userId}
      />
      <Pagination
        paginationData={accountStatementBody}
        setPaginationData={setAccountStatementBody}
        setIndex={setIndex}
      />
    </div>
  );
};

export default ProfitLossSports;
