import React, { useEffect, useState } from "react";
import { DatePicker, Dropdown, Empty, Space, Spin, Table } from "antd";
import { useCasinoBetListNewQuery, useCasinoPlusMinusQuery } from "../../../Services/CasinoService";
import { useLocation, useParams } from "react-router";

const CasinoPlusMinus = () => {
  const uType = localStorage.getItem("userType");

  const { state } = useLocation();
  const { id } = useParams();

  // const { data } = useCasinoPlusMinusQuery(
  //   {
  //     casinoId: id,
  //     date: state?.rouletteDate,
  //   },
  //   { refetchOnMountOrArgChange: true }
  // );

  const  {data, isLoading} = useCasinoBetListNewQuery({
    casinoId: id,
    date: state?.rouletteDate,
  },
  { refetchOnMountOrArgChange: true })


  return (
    <>
    <div>
      <div>
        <p className="match_bet casino_rept">{`${
          uType == 5
            ? "Sub Admin "
            : uType == 0
            ? "Master "
            : uType == 1
            ? "Super"
            : uType == 2
            ? "Agent "
            :uType == 4?"Sub Admin": ""
        } Company Report`}</p>
        <div>
          {state?.isAuraDetails } ({state?.rouletteDate})
        </div>
      </div>
      <br />
      {/* <div className="accountlist-table-container">
        <table className="table_section">
          <tr>
            <th>Code</th>
            <th>Casino Amt</th>
            <th>Comm</th>
            <th>Total Amount</th>
            <th>My Share</th>
            <th>Net Amount</th>
          </tr>
          <tr >
            <td>{data?.data?.userId}</td>
            <td>{data?.data?.casinoAmount}</td>
            <td>{data?.data?.comm}</td>
            <td>{data?.data?.totalAmount}</td>
            <td>{data?.data?.myShare}</td>
            <td
              className={
                data?.data?.finalAmount < 0 ? "text_danger" : "text_success"
              }>
              {data?.data?.finalAmount}
            </td>
          </tr>
          <tr style={{background:"#ddd"}} >
            <td>Total</td>
            <td>{data?.data?.casinoAmount}</td>
            <td>{data?.data?.comm}</td>
            <td>{data?.data?.totalAmount}</td>
            <td>{data?.data?.myShare}</td>
            <td
              className={
                data?.data?.finalAmount < 0 ? "text_danger" : "text_success"
              }>
              {data?.data?.finalAmount}
            </td>
          </tr>

          {(data?.data === undefined || data?.data?.length === 0) && (
            <tr>
              {" "}
              <td colSpan={9}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </td>
            </tr>
          )}
        </table>
      </div> */}

<div style={{ position: "relative", overflowX:"scroll" }}>
      {/* <MapInteractionCSS
        defaultValue={{
          scale: 1,
          translation: { x: 0, y: 0 },
        }}
        minScale={0.2}
        maxScale={3}
        translationBounds={{
          xMax: 200,
          yMax: 100,
        }}> */}
        <table className="plus-table plus_minus_sec">
          <tbody>
            {data?.data &&
              Object.keys(data.data).map((item) => (
                <RecursiveTable style={{width:"100%"}} data={data.data[item]} title={item} />
              ))}
          </tbody>
        </table>
      {/* </MapInteractionCSS> */}
      {isLoading && (
        <div className="plus_spin">
          <Spin size="large" />
        </div>
      )}
    </div>
    </div>
    </>
  );
};

export default CasinoPlusMinus;

const RecursiveTable = ({ data, title }) => {
  const [arrayState, setArrayState] = useState([]);

  const uType = localStorage.getItem("userType");
  useEffect(() => {
    let returnArr = [];

    if (data?.groupName === "admin") {
      returnArr.push(
        <tr className="admin_color">
          <td>&nbsp;</td>
          <td style={{ whiteSpace: "nowrap" }}>Admin </td>
          <td colSpan={35}>
            <strong>{title}</strong>
          </td>
        </tr>
      );
    }else if (data?.groupName === "subadmin") {
      returnArr.push(
        <tr className="sub_color">
          <td>&nbsp;</td>
          <td colSpan={2} style={{ whiteSpace: "nowrap" }}>Sub Admin </td>
          <td colSpan={34}>
            <strong>{title}</strong>
          </td>
        </tr>
      );
    } else if (data?.groupName === "supermaster") {
      returnArr.push(
        <tr className="master_color">
          <td colSpan={4}>&nbsp;</td>
          <td style={{ whiteSpace: "nowrap" }}>Super Master</td>
          <td colSpan={32}>
            <strong>{title}</strong>
          </td>
        </tr>
      );
    } else if (data?.groupName === "master") {
      returnArr.push(
        <tr className="super_color">
          <td colSpan={6}>&nbsp;</td> <td>Master </td>
          <td colSpan={31}>
            <strong>{title}</strong>
          </td>
        </tr>
      );
    } else if (data?.groupName === "agent") {
      returnArr.push(
        <tr className="agent_color">
          <td colSpan={8}>&nbsp;</td> <td>Agent </td>
          <td colSpan={29}>
            <strong>{title}</strong>
          </td>
        </tr>
      );
    }
    if (data) {
      Object.keys(data).forEach((item) => {
        if (!["groupName", "total", "users"].includes(item)) {
          returnArr = [
            ...returnArr,
            <RecursiveTable data={data[item]} title={item} />,
          ];
        } else if (item === "users") {
          const clientRows = Object.keys(data[item]).map((userKey) => (
            <>
              <tr className="border_tr">
                <td>
                  <strong>{userKey}</strong>
                </td>
                {/* <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  {data[item][userKey].matchAmount?.toFixed(2)}
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  {data[item][userKey].sessionAmount?.toFixed(2)}
                </td> */}
                <td
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  <strong>{data[item][userKey].totalAmount?.toFixed(2)}</strong>
                </td>


                <td
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  {data[item][userKey]?.totalComm?.toFixed(2)}
                </td>

               
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  <strong>
                    {data[item][userKey]?.dealer?.totalComm?.toFixed(2)}
                  </strong>
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  {data[item][userKey]?.dealer?.netAmount?.toFixed(2)}
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }>
                  {data[item][userKey]?.dealer?.shareAmount?.toFixed(2)}
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                      ? ""
                      : "d_none"
                  }
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}>
                  <strong>
                    {data[item][userKey]?.dealer?.finalAmount?.toFixed(2)}
                  </strong>
                </td>
               
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                  }>
                  <strong>
                    {data[item][userKey]?.master?.totalComm?.toFixed(2)}
                  </strong>
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                  }>
                  {data[item][userKey]?.master?.netAmount?.toFixed(2)}
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                  }>
                  {data[item][userKey]?.master?.shareAmount?.toFixed(2)}
                </td>
                <td
                  className={
                    uType == 5 || uType == 0 || uType == 1  || uType == 4? "" : "d_none"
                  }
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}>
                  <strong>
                    {data[item][userKey]?.master?.finalAmount?.toFixed(2)}
                  </strong>
                </td>
                
                <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                  <strong>
                    {data[item][userKey]?.superMaster?.totalComm?.toFixed(2)}
                  </strong>
                </td>
                <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                  {data[item][userKey]?.superMaster?.netAmount?.toFixed(2)}
                </td>
                <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                  {data[item][userKey]?.superMaster?.shareAmount?.toFixed(2)}
                </td>
                <td
                  className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}>
                  <strong>
                    {data[item][userKey]?.superMaster?.finalAmount?.toFixed(2)}
                  </strong>
                </td>
               
                <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                  <strong>
                    {data[item][userKey]?.subAdmin?.totalComm?.toFixed(2)}
                  </strong>
                </td>
                <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                  {data[item][userKey]?.subAdmin?.netAmount?.toFixed(2)}
                </td>
                <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                  {data[item][userKey]?.subAdmin?.shareAmount?.toFixed(2)}
                </td>
                <td
                  className={uType == 5 || uType == 4 ? "" : "d_none"}
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}>
                  <strong>
                    {data[item][userKey]?.subAdmin?.finalAmount?.toFixed(2)}
                  </strong>
                </td>

                
                <td className={ uType != 4? "d_none" : ""}>
                  <strong>
                    {data[item][userKey]?.admin?.totalComm?.toFixed(2)}
                  </strong>
                </td>
                <td className={ uType != 4? "d_none" : ""}>
                  {data[item][userKey]?.admin?.netAmount?.toFixed(2)}
                </td>
                <td className={uType != 4 ? "d_none" : ""}>
                  {data[item][userKey]?.admin?.shareAmount?.toFixed(2)}
                </td>
                <td
                  className={uType != 4 ? "d_none" : ""}
                  style={{
                    borderRightWidth: 2,
                    borderRightColor: "rgb(174, 174, 174)",
                  }}>
                  <strong>
                    {data[item][userKey]?.admin?.finalAmount?.toFixed(2)}
                  </strong>
                </td>


              </tr>
            </>
          ));
          const dynamicHeader = (
            <tr style={{ textAlign: "center", color: "#545454" }}>
               <th
                colSpan={1}
                className={
                  data?.groupName === "agent"
                    ? "agentBackgroundColor"
                    : data?.groupName === "master"
                    ? "masterBackgroundColor"
                    : data?.groupName === "supermaster" ?"superBackgroundColor":data?.groupName === "subadmin"?"subBackgroundColor": data?.groupName == "admin"?"adminBackgroundColor":""
                }>
                {" "}
                {title}
              </th>
              <th></th>

            
              <th
                className={`sub_agent_heading ${
                  uType == 0 || uType == 1 || uType == 2 || uType == 5 || uType == 4
                    ? ""
                    : "d_none"
                }`}
                >
                CLIENT PLUS MINUS
              </th>
              <th
                className={`sub_agent_heading ${
                  uType == 0 || uType == 1 || uType == 2 || uType == 5 || uType == 4
                    ? ""
                    : "d_none"
                }`}
                colSpan={4}>
                AGENT PLUS MINUS
              </th>
              <th
                className={`sub_agent_heading ${
                  uType == 0 || uType == 1 || uType == 5 || uType == 4 ? "" : "d_none" 
                }`}
                colSpan={4}>
                MASTER PLUS MINUS
              </th>
              <th
                className={`sub_agent_heading ${
                  uType == 0 || uType == 5 || uType == 4 ? "" : "d_none" 
                }`}
                colSpan={4}>
                SUPERMASTER PLUS MINUS
              </th>
              <th
                className={`sub_agent_heading ${uType == 5 || uType == 4 ? "" : "d_none"}`}
                colSpan={4}>
                SUBADMIN PLUS MINUS
              </th>
              <th
                className={`sub_agent_heading ${ uType != 4 ? "d_none" : ""}`}
                colSpan={4}>
                ADMIN PLUS MINUS
              </th>
            </tr>
          );

          const dynamicSubHeader = (
            <tr style={{ textAlign: "center" }} className="border_tr">
              <td>
                <strong>CLIENT</strong>
              </td>
              {/* <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>M AMT</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>SESS.</strong>
              </td> */}
              <td
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>CASINO AMT</strong>
              </td>
              {/* <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>M. COM</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>S. COM</strong>
              </td> */}
              <td
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                <strong>CASINO COM</strong>
              </td>
              {/* <td
                className={uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4 ? "" : "d_none"
                }>
                <strong>M. COM</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2  || uType == 4? "" : "d_none"
                }>
                <strong>S. COM</strong>
              </td> */}
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2  || uType == 4? "" : "d_none"
                }>
                <strong>CASINO COM</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2  || uType == 4? "" : "d_none"
                }>
                <strong>NET AMT</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2  || uType == 4?  "" : "d_none"
                }>
                <strong>SHR AMT</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4 ? "" : "d_none"
                }
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                <strong>FINAL</strong>
              </td>
              {/* <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                <strong>M. COM</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                <strong>S. COM</strong>
              </td> */}
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1  || uType == 4? "" : "d_none"
                }>
                <strong>CASINO COM</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                <strong>NET AMT</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                <strong>SHR AMT</strong>
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                <strong>FINAL</strong>
              </td>
              {/* <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                <strong>M. COM</strong>
              </td>
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                <strong>S. COM</strong>
              </td> */}
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                <strong>CASINO COM</strong>
              </td>
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                <strong>NET AMT</strong>
              </td>
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                <strong>SHR AMT</strong>
              </td>
              <td
                className={uType == 5 || uType == 0  || uType == 4? "" : "d_none"}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                <strong>FINAL</strong>
              </td>
              {/* <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                <strong>M. COM</strong>
              </td>
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                <strong>S. COM</strong>
              </td> */}
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                <strong>CASINO COM</strong>
              </td>
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                <strong>NET AMT</strong>
              </td>
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                <strong>SHR AMT</strong>
              </td>
              <td
                className={uType == 5 || uType == 4 ? "" : "d_none"}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                <strong>FINAL</strong>
              </td>

              {/* <td className={ uType != 4? "d_none" : ""}>
                <strong>M. COM</strong>
              </td>
              <td className={uType != 4? "d_none" : ""}>
                <strong>S. COM</strong>
              </td> */}
              <td className={uType != 4 ? "d_none" : ""}>
                <strong>CASINO COM</strong>
              </td>
              <td className={ uType != 4 ? "d_none" : ""}>
                <strong>NET AMT</strong>
              </td>
              <td className={uType != 4 ? "d_none" : ""}>
                <strong>SHR AMT</strong>
              </td>
              <td
                className={uType != 4 ? "d_none" : ""}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                <strong>FINAL</strong>
              </td>
            </tr>
          );
          returnArr = [
            ...returnArr,
            dynamicHeader,
            dynamicSubHeader,
            ...clientRows,
          ];
        }
      });
      if (data?.total) {
        const totalRows = (
          <>
            <tr className="border_tr">
              <td>&nbsp;</td>
            </tr>
            <tr className="border_tr">
              <td>
                <strong>{data.groupName} .TOTAL</strong>
              </td>
              {/* <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.matchAmount?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.sessionAmount?.toFixed(2)}
              </td> */}
              <td
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.totalAmount?.toFixed(2)}
              </td>

            
              <td
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.totalComm?.toFixed(2)}
              </td>

              
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.dealer?.totalComm?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.dealer?.netAmount?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }>
                {data?.total?.dealer?.shareAmount?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 2 || uType == 4
                    ? ""
                    : "d_none"
                }
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                {data?.total?.dealer?.finalAmount?.toFixed(2)}
              </td>
            
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                {(data?.total?.master?.totalComm).toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                {data?.total?.master?.netAmount?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }>
                {data?.total?.master?.shareAmount?.toFixed(2)}
              </td>
              <td
                className={
                  uType == 5 || uType == 0 || uType == 1 || uType == 4 ? "" : "d_none"
                }
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                {data?.total?.master?.finalAmount?.toFixed(2)}
              </td>
             
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                {data?.total?.superMaster?.totalComm?.toFixed(2)}
              </td>
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                {data?.total?.superMaster?.netAmount?.toFixed(2)}
              </td>
              <td className={uType == 5 || uType == 0 || uType == 4 ? "" : "d_none"}>
                {data?.total?.superMaster?.shareAmount?.toFixed(2)}
              </td>
              <td
                className={uType == 5 || uType == 0  || uType == 4? "" : "d_none"}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                {data?.total?.superMaster?.finalAmount?.toFixed(2)}
              </td>
             
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                {data?.total?.subAdmin?.totalComm?.toFixed(2)}
              </td>
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                {data?.total?.subAdmin?.netAmount?.toFixed(2)}
              </td>
              <td className={uType == 5 || uType == 4 ? "" : "d_none"}>
                {data?.total?.subAdmin?.shareAmount?.toFixed(2)}
              </td>
              <td
                className={uType == 5 || uType == 4 ? "" : "d_none"}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                {data?.total?.subAdmin?.finalAmount?.toFixed(2)}
              </td>

              
              <td className={uType != 4  ? "d_none" : ""}>
                {data?.total?.admin?.totalComm?.toFixed(2)}
              </td>
              <td className={uType != 4  ? "d_none" : ""}>
                {data?.total?.admin?.netAmount?.toFixed(2)}
              </td>
              <td className={uType != 4  ? "d_none" : ""}>
                {data?.total?.admin?.shareAmount?.toFixed(2)}
              </td>
              <td
                className={uType != 4  ? "d_none" : ""}
                style={{
                  borderRightWidth: 2,
                  borderRightColor: "rgb(174, 174, 174)",
                }}>
                {data?.total?.admin?.finalAmount?.toFixed(2)}
              </td>

            </tr>
          </>
        );
        returnArr = [...returnArr, totalRows];
      }
    }
    setArrayState(returnArr);
  }, [data]);

  return arrayState;
  if (data) return <></>;
};

