import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space, Spin, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { filterCashTransactionUserSelect } from "../../../Redux/feature/ClientCashTransactionList/selector";
import { filterCashTransaction } from "../../../Redux/feature/ClientCashTransactionList/action";
import { AiFillCaretDown } from "react-icons/ai";
import { delertedHistory } from "../../../Redux/feature/DeletedCashTransation/action";
import { useNavigate } from "react-router-dom";
import { selectdeletedData } from "../../../Redux/feature/DeletedCashTransation/selector";
import { FaSpinner } from "react-icons/fa";
const CashTransactionTable = ({ searchId, loading, userType, status, isLoading }) => {
  const [userData, setUserData] = useState([]);
  const selector = useSelector(filterCashTransactionUserSelect);
  const deletedSelector = useSelector(selectdeletedData);
  const [deletedId, setDeletedData] = useState();
  const nav = useNavigate();

  const dispatch = useDispatch();
  // const userType = localStorage.getItem("userType");

  useEffect(() => {
    dispatch(
      filterCashTransaction({
        userTyep: userType,
        userId: searchId,
      })
    );
  }, [searchId, userType, loading, deletedSelector?.loading, status]);

  const clientData = selector?.data;
  useEffect(() => {
    if (clientData?.cashtransection !== undefined) {
      const userResult = JSON.parse(clientData?.cashtransection);
      setUserData(userResult?.results);
    }
  }, [clientData?.cashtransection]);

  const handelDeleteItem = (id) => {
    setDeletedData(id);
  };

  const handleDelete = () => {
    dispatch(
      delertedHistory({
        _id: deletedId,
      })
    );
  };
  const items = [
    {
      label: <div onClick={handleDelete}>Delete</div>,
      key: "0",
    },
  ];

  const columns = [
    {
      dataIndex: "droupdown",
      key: "droupdown",
      render: (text, record) => {
        return (
          <>
            {!record?.isRollback && record?.doneBy?.length > 0 && (
              <div className="droup_menu">
                <Dropdown
                  menu={{
                    items,
                  }}
                  trigger={["click"]}
                  style={{ position: "relative" }}>
                  <div onClick={(e) => handelDeleteItem(record?._id?.$oid)}>
                    <Space className="droup_down">
                      <AiFillCaretDown />
                    </Space>
                  </div>
                </Dropdown>
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Post Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Collection Name",
      dataIndex: "collectionName",
      key: "collectionName",
    },
    {
      title: "Debit",
      dataIndex: "debit",
    },
    {
      title: "Credit",
      dataIndex: "credit",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (text, record) => {
        return (
          <>
            <p className={text > 0? "text_success": text < 0 ? "text_danger":""}>{text}</p>
          </>
        );
      },
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Remark",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Done By",
      dataIndex: "doneBy",
      key: "doneBy",
    },
  ];

  const deletedDataHandle = () => {
    nav("/deletedlenden", { state: { searchId } });
  };
  const antIcon = (
    <FaSpinner className="spin_roatet"/>
  );

  const tableLoading = {
    spinning:  deletedSelector?.loading || isLoading,
    indicator: <Spin indicator={antIcon} spinning={true}></Spin>,
  }
  return (
    <>
      {userData?.length !== 0 && (
        <>
          <div className="lena_dena">
            <div className="text_danger">
              Dena : {clientData?.lenadenabalance?.credit}
            </div>
            <div className="text_success">
              Lena : {clientData?.lenadenabalance?.debit}
            </div>
            <div
              className={
                clientData?.lenadenabalance?.balance < 0
                  ? "text_danger"
                  : "text_success"
              }>
              Balance: {Math.abs(clientData?.lenadenabalance?.balance)}{" "}
              {clientData?.lenadenabalance?.balance < 0 ? "Dena" : "Lena"}
            </div>
            <div>
              <Button className="deleted_btn" onClick={deletedDataHandle}>
                Deleted
              </Button>
            </div>
          </div>
          <div className="acc-cont">
            <div className="account_state_bottom"></div>
            <div className="accountlist-table-container">
              <Table
                columns={columns}
                pagination={false}
                dataSource={userData}
                loading={tableLoading}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default CashTransactionTable;
