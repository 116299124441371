import React from 'react';
import ReactDOM from 'react-dom';
import './AccountListModal.scss';

const BackDrop = () => {
	return <div className="account_list_backdrop"></div>;
};

const ModalOverlay = props => {
	return (
		<div className="account_list_modal">
			<div className="">{props.children}</div>
		</div>
	);
};

const portalElement = document.getElementById('account_list_overlay');

const AccountListModal = props => {
	return (
		<React.Fragment>
			{ReactDOM.createPortal(<BackDrop />, portalElement)}
			{ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
		</React.Fragment>
	);
};

export default AccountListModal;
