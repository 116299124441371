import { combineReducers } from 'redux';
import authReducer from '../feature/Auth/reducer';
import creditHistoryReducer from '../feature/CreditHistory/reducer';
import anaysisReducer from '../feature/SportsNameData/reducer';
import matchDataReducer from '../feature/MatchData/reducer';
import creditDataReducer from '../feature/ActiveUserActionButtons/Credit/reducer';
import sportsDataSliderReducer from '../feature/Slider/reducer';
import statementaccountReducer from '../feature/AccountStatement/reducer';
import clientListReducer from '../feature/ClientList/InactiveUser/reducer';
import activeinactiveReducer from '../feature/AccountListTable/reducer'
import cashTransaction  from '../feature/ClientCashTransactionList/reducer';
import deletedReport from '../feature/DeletedCashTransation/reducer'
import fetchDeletedData from '../feature/FetchDeletedData/reducer'
import cashTransactionForm from '../feature/CashTransaction/reducer'
import matchLedgerData from '../feature/MatchLedger/reducer'
import sportDetailsData from '../feature/SportDetails/reducer'
import matchBet from '../feature/MatchBet/reducer'
import completeFancy from '../feature/CompleteFancy/reducer'
import rejectedBet from '../feature/RajectedBet/reducer'
import { configureStore } from '@reduxjs/toolkit';
import { CasinoApi } from '../../Services/CasinoService';
import { ActiveMatchesApi } from '../../Services/ActiveMatchesApi';


const reducer = combineReducers({
	auth: authReducer,
	list: clientListReducer,
	credit: creditHistoryReducer,
	marketAnalysis: anaysisReducer,
	matchData: matchDataReducer,
	creditdata: creditDataReducer,
	slider: sportsDataSliderReducer,
	accountStat: statementaccountReducer,
	inactiveActive: activeinactiveReducer,
	cashTran:cashTransaction,
	deletData: deletedReport,
	fetchDeletedData: fetchDeletedData,
	cashTransForm:cashTransactionForm,
	matchLedger:matchLedgerData,
	sportDetails: sportDetailsData,
	completeFancyData:completeFancy,
	matchData:matchBet,
	rejectedBet:rejectedBet,
	[CasinoApi.reducerPath]:CasinoApi.reducer,
	[ActiveMatchesApi.reducerPath] : ActiveMatchesApi.reducer,

});


export const store = configureStore({
	reducer,
	middleware:(m)=>m().concat(CasinoApi.middleware).concat(ActiveMatchesApi.middleware)
});
