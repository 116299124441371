import React, { useEffect, useState } from 'react';
import './CashTranstions.scss'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import AccountStatementHeader from '../AccountStatement/AccountStatementHeader';
import CashTransationsHeader from './CashTransationsHeader';
// import { accountStatement } from '../../../Redux/feature/AccountStatement/action';
// import { stateAccountData } from '../../../Redux/feature/AccountStatement/selector';

const CashTranstions = ({userType, name}) => {
	const initialTableRecords = 25;
	const initialAccountType = 1;

	const [dateTo, setDateTo] = useState(dayjs());
	const [dateFrom, setDateFrom] = useState(dayjs().subtract(7, 'day'));
	const [searchId, setSearchId] = useState('');
	const [accountType, setAccountType] = useState(initialAccountType);
	const [tableRecords, setTableRecords] = useState(initialTableRecords);
	const [index, setIndex] = useState(0);

	const [searchUser, setSearchUser] = useState('');

	return (
		<div className="cash-tran-cont">
			<div className='cash-tran_heading'>
				<p>{name} Cash Transaction</p>
			</div>
                
            <CashTransationsHeader
            dateTo={dateTo}
            dateFrom={dateFrom}
            setDateTo={setDateTo}
            setDateFrom={setDateFrom}
            setSearchId={setSearchId}
            accountType={accountType}
            setAccountType={setAccountType}
            tableRecords={tableRecords}
            searchId={searchId}
            setTableRecords={setTableRecords}
            setSearchUser={setSearchUser}
			usertype={userType}
            />
		</div>
	);
};

export default CashTranstions;
