import React, { useEffect, useState } from "react";
import style from "./AccountListUserProfile.module.scss";
import SubmitButton from "./SubmitButton";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { loadingRef } from "../../../App";

const AccountListUserProfile = (props) => {
  const [userData, setUserData] = useState({});
  const partnershipInfo = [
    { id: "p1", desc: "Partnership Name:", info: "Partnership With No Return" },
    { id: "p2", desc: "user Part:", info: userData?.userPartnership },
    { id: "p3", desc: "Our Part:", info: userData?.ourPartnirshp },
    { id: "p4", desc: "Remark:", info: userData?.username },
  ];

  const addInfo = [
    { id: "a1", desc: "User Name:", info: userData?.username },
    { id: "", desc: "Mobile Number", info: userData?.mobile },
    { id: "a4", desc: "Credit Pts:", info: userData?.creidtReference },
    { id: "a5", desc: "Pts:", info: userData?.availableBalance },
    { id: "a6", desc: "Client P/L:", info: Number(userData?.clientPL)?.toFixed(2) },
    { id: "a7", desc: "Created Date:", info: userData?.createdDate },
  ];

  useEffect(() => {
    userProfile();
  }, []);


  const userProfile = async () => {
    loadingRef("userchildprofile", true);
    const endPoint = "user/child-profile";
    const response = await axiosInstance.post(endPoint, {
      userId: props?.selectedRecord?.userId,
    });
    setUserData(response?.data?.data);
    loadingRef("userchildprofile", false);
  };

  return (
    <div className={style["user_profile-combo"]}>
      <div className={style["user_profile-sub_combo"]}>
        <div className={style["info"]}>
          <div className={style["info_top"]}>
            <button>H</button>
            <p>{userData?.username}</p>
            <p>{userData?.userid}</p>
          </div>
          {/* <div className={style['info_bottom']}>
import React from "react";
import style from "./AccountListUserProfile.module.scss";
import SubmitButton from "./SubmitButton";

const AccountListUserProfile = (props) => {
  const partnershipInfo = [
    { id: "p1", desc: "Partnership Name:", info: "Partnership With No Return" },
    { id: "p2", desc: "user Part:", info: 90.0 },
    { id: "p3", desc: "Our Part:", info: 10.0 },
    { id: "p4", desc: "Remark:", info: props?.userDetails?.username },
  ];

  const addInfo = [
    { id: "a1", desc: "User Name:", info: props?.userDetails?.username },
    { id: "a4", desc: "Credit Pts:", info: props?.userDetails?.chips },
    { id: "a5", desc: "Pts:", info: props?.userDetails?.pts },
    { id: "a6", desc: "Client P/L:", info: props?.userDetails?.clientPl },
    { id: "a7", desc: "Created Date:", info: "2023-04-19 07:47:09" },
  ];

  return (
    <div className={style["user_profile-combo"]}>
      <div className={style["user_profile-sub_combo"]}>
        <div className={style["info"]}>
          <div className={style["info_top"]}>
            <button>H</button>
            <p>{props?.userDetails?.username}</p>
            <p>dial1445</p>
          </div>
          {/* <div className={style['info_bottom']}>
						<p></p>
						<p></p>
					</div> */}
        </div>
        <div className={style["partnership"]}>
          <h3>Partnership Information</h3>
          {partnershipInfo.map((el) => (
            <div className={style["partnership_details"]} key={el}>
              <div className={style["part_desc"]}>
                <p>{el.desc}</p>
              </div>
              <div className={style["part_info"]}>
                <p>{el.info}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={style["user_profile-additional_info"]}>
        <h3>Additional Information</h3>
        <div className={style["add_info-details"]}>
          {addInfo?.map((data) => (
            <div className={style["add_info-sub_details"]} key={data}>
              <div className={style["add_desc"]}>
                <p>{data?.desc}</p>
              </div>
              <div className={style["add_info"]}>
                <p>{data?.info}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccountListUserProfile;
