import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CreditData } from '../../../../Services/ApiEndingPoint';

export const creditDataP = createAsyncThunk('creditData', async (payload, { rejectWithValue }) => {
	try {
		const response = await axios.post(CreditData, { ...payload });
		if (response) {
			return response?.data;
		}
	} catch (error) {
		return rejectWithValue(error);
	}
});
