import { createSlice } from "@reduxjs/toolkit";
import { cashTransaction } from "./action";

const INITIAL_VALUE = {
    data: null,
    loading: false,
    error: false
}

const slice = createSlice({
    name:'cashTransactionForm',
    initialState: INITIAL_VALUE,
    reducers:{hui:state=>{}},
    extraReducers: builder =>{
        builder
            .addCase(cashTransaction.pending, state=>{
                state.data = null;
                state.loading= true;
                state.error = false
            })
            .addCase(cashTransaction.fulfilled, (state, action)=>{
                state.data = action.payload;
                state.loading=false;
                state.error = false;
            })
            .addCase(cashTransaction.rejected, (state, action)=>{
                state.data=null;
                state.loading=false;
                state.error = true
            })
    }
});

export const {hui} = slice.actions;

export default slice.reducer;