import { Radio, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { axiosInstance } from "../../../Services/AxiosInstance";
import Loading from "../../../Loading/Loading";

const AccountListModal = ({ marketData, searchId }) => {
  const [betsButton, setBetsButton] = useState(1);
  const [accountModalData, setAccountModalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const accountListApi = async () => {
    setLoading(true);
    const endPoint = "bets/search-bet-market-and-user";
    const response = await axiosInstance.post(endPoint, {
      marketId: marketData?.marketid,
      userId: searchId,
      betType: betsButton,
    });

    setAccountModalData(response?.data?.data);

    setLoading(false);
  };

  useEffect(() => {
    accountListApi();
  }, [betsButton]);

  const columns = [
    {
      title: "UserId",
      dataIndex: "userid",
    },
    {
      title: "Nation",
      dataIndex: "selectionname",
    },
    {
      title: "Rate",
      dataIndex: "pricevalue",
    },
    {
      title: "Amount",
      dataIndex: "stack",
      render: (text) => {
        return (
          <span style={{ color: text > 0 ? "green" : "red" }}>{text}</span>
        );
      },
    },
    {
      title: "Win",
      dataIndex: "netpnl",
      render: (text) => {
        return (
          <span style={{ color: text > 0 ? "green" : "red" }}>{text}</span>
        );
      },
    },
    { title: "Date", dataIndex: "matchedtime" },
    { title: "IP", dataIndex: "userip" },
    {
      title: "B Details",
      dataIndex: "deviceinfo",
      render: (text, record) => {
        return (
          <>
            <Tooltip title={record?.deviceInfo}>
              <p style={{ color: "green" }}>Detail</p>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const onChange = (e) => {
    setBetsButton(e.target.value);
  };

    return (
      <div className="acc-modal">
        <span style={{ fontSize: "20px", padding: "5px 10px" }}>
          {marketData?.remark}
        </span>

        <div
          className="radio"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
          }}
        >
          <span>
            <Radio.Group onChange={onChange} value={betsButton}>
              <Radio value={1}>All</Radio>
              <Radio value={2}>Back</Radio>
              <Radio value={3}>Lay</Radio>
            </Radio.Group>
          </span>
          <span style={{ display: "flex", gap: "5px" }}>
            <p style={{ display: "flex", gap: "5px" }}>
              <span>Total Soda :</span>
              <span
                style={{
                  color: accountModalData?.totalBets > 0 ? "green" : "red",
                }}
              >
                {accountModalData?.totalBets}
              </span>
            </p>
            <p style={{ display: "flex", gap: "5px" }}>
              <span>Total Amount :</span>
              <span
                style={{
                  color: accountModalData?.totalStake > 0 ? "green" : "red",
                }}
              >
                {accountModalData?.totalStake}
              </span>
            </p>
          </span>
        </div>

        <span></span>
        <span></span>
        <div className="accountlist-table-container">
          <Table
            columns={columns}
            dataSource={accountModalData?.betList}
            pagination={false}
            loading={loading}
            rowClassName={(record) => {
              return record?.isback ? "blue" : "pink";
            }}
          />
        </div>
      </div>
    );
  
};
export default AccountListModal;
