import React from 'react';
import ProfitLossHeader from './ProfitLossHeader';

const ProfitLossLedger = () => {
	return (
		<div className="cash-tran-cont">
			<div className='cash-tran_heading'>
				<p>Profit/Loss</p>
			</div>
                
            <ProfitLossHeader/>
		</div>
	);
};

export default ProfitLossLedger;

