export const LogInApi = '/login/auth';
// export const LogInApi = '/bmx/login/auth-app-url-wise';
export const PasswordReset = '/user/first-login-cp';
export const ClientList = '/user/child-list-active-user';
export const ProfitLossApi = '/sport/active-sport-list';
export const MarketAnalysis = '/sport/active-sport-list';
export const MatchData = '/market-analysis/dashboard';

//ActiveUser Actions
export const CreditData = '/matchBox/matchBox_credit-Exposure-amount';

//Slider
export const Slider = '/enduser/left-menu-data-open';

//Report
export const AccountStatement = '/report/account-statement';

//ClientList

//Inactive-Active
export const ActiveInactive = 'user/child-list-active-inactive-user';
//Inactive
export const InactiveUser = '/user/child-list';

// Cash Transaction
export const CashTranstion = '/bmx/create-cash-transection';

// Filter Cash Transaction
export const FilterCashTranstion = '/bmx/filter-by-client-cash-transection';

// deleted cash transaction

export const deletedCashTransation = "/bmx/delete-cash-transection"

//deteted data fetch

export const  fetchDeletedData = '/bmx/fetch-deleted-transection'

// Match Ledger

export const matchLedgerEndPoint = "/bmx/report/get-my-ledger"

// Sport Details 

export const sportDetails = "/bmx/report/sport-details"


//Metch Bet Data

export const matchBetApi = "/bmx/fancy-fetch-by-match-id"

// Complete Fancy 

export const completeFancyEndPoint = "/bmx/completed-fancy"


// Profit and Loass

export const profitAndLoss = "/bmx/profit-loss"
// Rejected Bet Api

export const rejectedBetEndPoint = "/bmx/rejected-and-cancle-bets"

// casino report details

export const casinoResult = "/bmx/report/casino-plus-minus"