import React, { useCallback, useEffect, useState } from "react";
import { Table, Switch, Button, Space, Checkbox, Modal, Input } from "antd";
import "./styles.scss";
import axios from "axios";
import AccountListDeposit from "../AccountListPortal/AccountListDeposit";
import AccountListLimit from "../AccountListPortal/AccountListLimit";
import AccountListCredit from "../AccountListPortal/AccountListCredit";
import AccountListPassword from "../AccountListPortal/AccountListPassword";
import AccountListMore from "../AccountListPortal/AccountListMore";
import AccountListWithdraw from "../AccountListPortal/AccountListWithdraw";
import ContextPortal from "../../../Context/PortalContext";
import AccountListCR from "../AccountListPortal/AccountListCR";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineFileExcel, AiOutlineFilePdf } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { inactiveUserlist } from "../../../Redux/feature/ClientList/InactiveUser/action";
import { InactiveUserData } from "../../../Redux/feature/ClientList/InactiveUser/selector";
import { activeInactive } from "../../../Redux/feature/AccountListTable/action";
import { activeInactiveUserSelect } from "../../../Redux/feature/AccountListTable/selector";
import { axiosInstance } from "../../../Services/AxiosInstance";
import Pagination from "../../../Pagination/Pagination";
import ActiveAndInActive from "./ActiveAndInActive";
import ActiveUser from "./ActiveUser";
import InActiveUser from "./InActiveUser";
import Loading from "../../../Loading/Loading";
import { useNavigate } from "react-router";

export let activeInactiveRef;

const AccountListTable = () => {
  const [dataSource, setDataSource] = useState([]);
  const [userData, setUserData] = useState("");
  const [data1, setData] = useState([]);
  const [records, setRecords] = useState(10);
  const [index, setIndex] = useState(0);
  const [accountStatementBody, setAccountStatementBody] = useState({
    noOfRecords: records,
    index: index,
    totalPages: 1,
  });

  const [activeButton, setActiveButton] = useState("");

  const selector = useSelector(InactiveUserData);

  const showModalProfile = (obj) => {
    const data = data1?.find((item) => item?.id === obj);
    setUserData(data);
  };

  const [isCreditDepositPortalVisible, setIsCreditDepositPortalVisible] =
    useState(false);
  const [isDepositPortalVisible, setIsDepositPortalVisible] = useState(false);
  const [isWithdrawPortalVisible, setIsWithdrawPortalVisible] = useState(false);
  const [credit, setCredit] = useState(false);
  const [limit, setLimit] = useState(false);
  const [password, setPassword] = useState(false);
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [more, setMore] = useState(false);

  //userId

  const tableSelector = useSelector(activeInactiveUserSelect);

  // useEffect(() => {
  //   inactiveActiveUser();
  // }, []);

  const portalHidden = (e) => {
    e.preventDefault();
    setIsDepositPortalVisible(false);
    setIsWithdrawPortalVisible(false);
    setCredit(false);
    setIsStatusChanged(false);
    setPassword(false);
    setLimit(false);
    setIsCreditDepositPortalVisible(false);
    setMore(false);
  };

  // const columns = [
  //   {
  //     title: "User Name",
  //     dataIndex: "username",
  //     width: "15%",
  //     key: "username",
  //     sorter: (a, b) => a.username.localeCompare(b.username),

  //     render: (hui, record) => (
  //       <div
  //         // onClick={() => dataApi(record?.userId)}
  //         style={{
  //           fontSize: "14px",
  //           background: "#444",
  //           color: "#fff",
  //           padding: "3px 10px",
  //           borderRight: "1px solid black",
  //           borderRadius: "3px",
  //           textTransform: "uppercase",
  //           fontWeight: "600",
  //           display: "inline-block",
  //         }}
  //       >
  //         {hui}
  //       </div>
  //     ),
  //   },

  //   {
  //     title: "Credit Referance",
  //     dataIndex: "chips",
  //     width: "5%",
  //     render: (text, record) => {
  //       return (
  //         <p
  //           onClick={() => setIsCreditDepositPortalVisible(true)}
  //           style={{
  //             float: "right",
  //             fontSize: "14px",
  //             color: "#444",
  //             padding: "0 5px",
  //           }}
  //         >
  //           {text}
  //         </p>
  //       );
  //     },
  //   },

  //   {
  //     title: "U St",
  //     dataIndex: "betLock",
  //     render: (_, record) => (
  //       <Checkbox
  //         checked={record?.accountLock}
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //         className="active-class_checkbox"
  //       ></Checkbox>
  //     ),
  //   },

  //   {
  //     title: "B St",
  //     dataIndex: "active",
  //     render: (_, record) => (
  //       <Checkbox
  //         checked={record?.betLock}
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //         className="active-class_checkbox"
  //       />
  //     ),
  //   },

  //   {
  //     title: "Exposure Limit",
  //     dataIndex: "exposure",
  //     width: "10%",
  //     render: (text) => (
  //       <span style={{ float: "right", fontSize: "14px", padding: "0px 5px" }}>
  //         {text}
  //       </span>
  //     ),
  //   },

  //   {
  //     title: "Default %",
  //     dataIndex: "exposure",
  //   },

  //   {
  //     title: "Account Type",
  //     dataIndex: "accountType",
  //     render: (text, record) => {
  //       const newText = text === "Dealer" ? "Agent" : text;

  //       return (
  //         <span style={{ fontSize: "14px", padding: "0px 5px" }}>
  //           {newText}
  //         </span>
  //       );
  //     },
  //   },

  //   {
  //     title: "Casino Total",
  //     dataIndex: "exposure",
  //     render: (text) => <span style={{ float: "right" }}>{text}</span>,
  //   },

  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     width: "20%",

  //     render: (text, record) => {
  //       return (
  //         <>
  //           <Button
  //             onClick={() => {
  //               setIsCreditDepositPortalVisible(true);
  //               setSelectedActionData(record);
  //             }}
  //             style={{
  //               background: "#444",
  //               color: "white",
  //               padding: "1px 9px",
  //               marginRight: "3px",
  //               height: "27px",
  //               borderRadius: "4px",
  //               fontSize: "14px",
  //               fontWeight: "600",
  //               cursor: "pointer",

  //               // fontFamily: "'Roboto Condensed', sans-serif"
  //             }}
  //           >
  //             CR
  //           </Button>
  //           <Button
  //             onClick={() => {
  //               setIsDepositPortalVisible(true);
  //               setSelectedActionData(record);
  //             }}
  //             style={{
  //               background: "#444",
  //               color: "white",
  //               padding: "1px 9px",
  //               marginRight: "3px",
  //               height: "27px",
  //               borderRadius: "4px",
  //               fontSize: "14px",
  //               fontWeight: "600",
  //               cursor: "pointer",
  //               // fontFamily: "'Roboto Condensed', sans-serif"
  //             }}
  //           >
  //             D
  //           </Button>

  //           <Button
  //             onClick={() => {
  //               setIsWithdrawPortalVisible(true);
  //               setSelectedActionData(record);
  //             }}
  //             style={{
  //               background: "#444",
  //               color: "white",
  //               padding: "1px 9px",
  //               marginRight: "3px",
  //               height: "27px",
  //               borderRadius: "4px",
  //               fontSize: "14px",
  //               fontWeight: "600",
  //               cursor: "pointer",
  //               // fontFamily: "'Roboto Condensed', sans-serif"
  //             }}
  //           >
  //             W
  //           </Button>

  //           <Button
  //             onClick={() => {
  //               showModalProfile(record?.id);
  //               setSelectedActionData(record);
  //               setMore(true);

  //               const {
  //                 id,
  //                 userId,
  //                 betLock,
  //                 accountLock,
  //                 active,
  //                 vcLock,
  //                 lcLock,
  //               } = record;

  //               setSelectedItem(id);
  //             }}
  //             style={{
  //               background: "#444",
  //               color: "white",
  //               padding: "1px 9px",
  //               marginRight: "3px",
  //               height: "27px",
  //               borderRadius: "4px",
  //               fontSize: "14px",
  //               fontWeight: "600",
  //               cursor: "pointer",
  //               display: "inline-block",
  //               // fontFamily: "'Roboto Condensed', sans-serif"
  //             }}
  //           >
  //             MORE
  //           </Button>
  //         </>
  //       );
  //     },
  //   },
  // ];
  //Client List API
  // const dataApi = async (id = '') => {
  // 	const baseUrl = 'user/child-list-active-inactive-user';
  // 	try {
  // 		await axios.post(baseUrl, { index: 0, noOfRecords: 500, id: id, userName: '' }).then(res => {
  // 			setDataSource(res?.data?.data?.dataList);
  // 			setData(res?.data?.data?.dataList);
  // 		});
  // 	} catch (error) {

  // 		console.error('Error fetching data:', error);
  // 	}
  // };

  // const handleCancel = () => {
  //   setIsDepositPortalVisible(false);
  //   setIsWithdrawPortalVisible(false);
  //   setMore(false);
  //   setIsCreditDepositPortalVisible(false);
  // };

  // const getRowClassName = (record, index) => {
  //   return index % 2 === 0 ? "even_row" : "odd_row";
  // };

  // const activeUserHandler = async (id) => {
  //   const baseUrl = "/user/child-list-active-user";
  //   await axiosInstance
  //     .post(baseUrl, {
  //       index: accountStatementBody?.index,
  //       noOfRecords: records,
  //       id: id,
  //     })
  //     .then((res) => {
  //       setDataSource(res?.data?.data?.dataList);
  //       // setRecords(res?.data?.data);
  //     });
  // };

  //   const inActiveUserHandler = async (id = '') => {
  //   	const baseUrl = '/user/child-list';
  //   	await axios.post(baseUrl, { index: 0, noOfRecords: 500, id: id }).then(res => {
  //   		setDataSource(res?.data?.data?.dataList);
  //   	});
  //   };

  useEffect(() => {
    // activeUserHandler()
    setAccountStatementBody({
      noOfRecords: 100,
      index: dataSource?.currentPage - 1,
      totalPages: dataSource?.totalPages,
    });
  }, [index]);

  // const inactiveActiveUser = (id) => {
  //   dispatch(
  //     activeInactive({
  //       index: index,
  //       noOfRecords: records,
  //       id: id,
  //       userName: "",
  //     })
  //   );
  // };

  // activeInactiveRef = inactiveActiveUser

  useEffect(() => {
    setDataSource(tableSelector?.data);
  }, [tableSelector]);

  // const inActiveUserHandler = (id) => {
  //   dispatch(
  //     inactiveUserlist({
  //       index: 0,
  //       noOfRecords: 500,
  //       id: id,
  //     })
  //   );
  // };

  useEffect(() => {
    setDataSource(selector?.data);
  }, [selector]);

  const activeButtonHandler = (buttonType) => {
    setActiveButton(buttonType);
  };
  const nav = useNavigate();
  const [activeComponent, setActiveComponent] = useState(0);
  const [userName, setUserName] = useState("");

  const compObj = {
    0: <ActiveAndInActive userName={userName} />,
    1: <ActiveUser userName={userName} />,
    2: <InActiveUser userName={userName} />,
  };

  // if (loading) {
  // 	return <Loading />;
  // } else {
  return (
    <ContextPortal.Provider
      value={{
        portalHidden: portalHidden,
      }}
    >
      <div className="search-div">
        <Input
          placeholder="search user"
          style={{ width: "200px", marginBlock: "2px", height: "35px" }}
          onChange={(e) => setUserName(e.target.value)}
          value={userName}
        />
      </div>
      <div className="accountlist-table">
        <div className="format">
          <p className="pdf-icon" style={{ cursor: "pointer" }}>
            <span className="icon">
              <AiOutlineFilePdf />
            </span>
            <span className="pdf">PDF</span>
          </p>

          <p className="excel-icon" style={{ cursor: "pointer" }}>
            <span className="icon">
              <AiOutlineFileExcel />
            </span>
            <span className="excel">Excel</span>
          </p>
          <button
            className={activeButton === "active" ? "active-class" : ""}
            style={{
              padding: "7px",
              background: "#2C3E50",
              border: "2px solid #2C3E50",
              color: "white",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              setUserName("");
              nav(`/matchbox_active-user`);
              setActiveComponent(1);
              activeButtonHandler("active");
            }}
          >
            Active Users
          </button>
          <button
            className={activeButton === "inactive" ? "active-class" : ""}
            style={{
              padding: "7px",
              background: "#2C3E50",
              border: "2px solid #2C3E50",
              color: "white",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={() => {
              setUserName("");
              nav(`/matchbox_active-user`);
              setActiveComponent(2);

              activeButtonHandler("inactive");
            }}
          >
            Inactive Users
          </button>
        </div>
        {compObj[activeComponent]}
      </div>
    </ContextPortal.Provider>
  );
};
// };

export default AccountListTable;
