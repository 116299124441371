import React, { useEffect } from "react";
import style from "./Limit.module.scss";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { VscDiffRenamed } from "react-icons/vsc";
import { MdSettingsBackupRestore } from "react-icons/md";
import colors from "../../../colors.module.scss";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { creditDataP } from "../../../Redux/feature/ActiveUserActionButtons/Credit/action";
import { creditDataSelect } from "../../../Redux/feature/ActiveUserActionButtons/Credit/selector";
import axios from "axios";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { useExposureLimitMutation } from "../../../Services/CasinoService";

const Limit = ({ selectedItem, setShowModal }) => {
  const [trigger, { data }] = useExposureLimitMutation();

  useEffect(() => {
    trigger({ userId: selectedItem?.userId });
  }, [selectedItem?.userId]);

  console.log(data?.data?.netexposure, "dasdasdas");

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    resetForm,
    handleBlur,
  } = useFormik({
    initialValues: {
      luPassword: "",
      netExposure: "",
      userId: selectedItem?.userId,
    },

    validateOnChange: true,
    validate: (values) => {
      const newError = {
        luPassword:
          "" == values?.luPassword
            ? "Login User Password field is required."
            : undefined,
        netExposure:
          "" == values?.netExposure ? "Limit is required. " : undefined,
      };
      return Object.fromEntries(
        Object.entries(newError).filter(([_, v]) => v != null)
      );
    },

    onSubmit: async () => {
      const endPoint = "matchBox/update-limit";
      try {
        const response = await axiosInstance.post(endPoint, values);
        if (response?.data?.status) {
          resetForm();
          // props.portalHidden(false);
          setShowModal("");
          return toast.success(response?.data?.message);
        } else {
        }
      } catch (response) {
        return toast.error(response?.response?.data?.message);
      }
    },
  });

  return (
    <>
      <div className={style["deposit_portal-form"]} style={{ gap: "10px" }}>
        <div className={style["same"]}>
          <label>Old Limit</label>
          <input
            placeholder="Amount"
            name="amount"
            disabled
            style={{ border: "1px solid #666", background: "#dddddd" }}
            value={data?.data?.netexposure}
          />
        </div>

        <div className={style["same"]}>
          <label>New Limit</label>
          <input
            onChange={handleChange}
            name="netExposure"
            onBlur={handleBlur}
            type="number"
            // autoComplete="off"
            autoComplete="limit"
            id="limits"
            value={values.netExposure}
            style={{
              border: `1px solid ${
                touched.netExposure && errors.netExposure ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.netExposure && errors?.netExposure ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "15px",
              left: "-8%",
            }}>
            {errors.netExposure}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["same"]}>
          <label>Transaction Passsword</label>
          <input
            placeholder="Transaction Password"
            type="password"
            onChange={handleChange}
            value={values.luPassword}
            onBlur={handleBlur}
            autoComplete="off"
            name="luPassword"
            style={{
              border: `1px solid ${
                touched.luPassword && errors.luPassword ? "red" : ""
              }`,
              outline: "none",
            }}
          />
        </div>
        {touched.luPassword && errors?.luPassword ? (
          <span
            style={{
              position: "relative",
              color: "red",
              fontSize: "13px",
              bottom: "10px",
              left: "5%",
            }}>
            {errors.luPassword}{" "}
          </span>
        ) : (
          ""
        )}

        <div className={style["status_buttons"]}>
          <button className={style["status_back"]}>
            <span className={style["icons"]}>
              <MdSettingsBackupRestore />
            </span>
            <span>Back</span>
          </button>
          <button
            className={style["status_submit"]}
            type="submit"
            onClick={handleSubmit}>
            <span>Submit</span>
            <span className={style["submit_icon"]}>
              <VscDiffRenamed />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Limit;
