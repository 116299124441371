import React, { useEffect, useState } from "react";
import style from "./MarketAnalysisLeft.module.scss";
import colors from "../../../colors.module.scss";
import { useParams } from "react-router";
import axios from "axios";
import { axiosInstance } from "../../../Services/AxiosInstance";
import { useWinnerPnlMutation } from "../../../Services/CasinoService";
const backColor = ["#72BBEF", "#72BBEFA3", "#72BBEFA3"];
const layColor = ["#F994BA", "#F994BACC", "#F994BACC"];
const MarketAnalysisLeftTable = ({ data, prev, marketId, name }) => {
  const [oddData, setOddData] = useState([]);
  const { id } = useParams();
  const oddPnl = () => {
    const endPoint = "bets/odds-pnl";
    axiosInstance.post(endPoint, { matchId: id }).then((res) => {
      setOddData(res?.data?.data);
    });
  };

  const [trigger, { data: oddsPnl }] = useWinnerPnlMutation();

  useEffect(() => {
    oddPnl();
    const timer = setInterval(() => {
      oddPnl();
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    trigger({
      marketId,
    });
  }, [marketId]);

  const [oddsObject, setOddsObject] = useState({});

  useEffect(() => {
    let resultObject = {};

    oddsPnl?.data.forEach((item) => {
      resultObject[item.selctionId] = item.liability;
    });

    setOddsObject(resultObject);
  }, [oddsPnl]);

  function formatNumber(num) {
    if (num >= 1000) {
      return num / 1000 + "K";
    }
    return num;
  }

  console.log(name, "oddsObject");

  return (
    <>
      <table className={style["mathOdd_table"]}>
        <thead className={style["mathOdd_head"]}>
          <tr className={style["blank_tr"]}></tr>
          <tr className={style["mathOdd_tr"]}>
            <th className={style["min_heading"]}>
              Min:{formatNumber(data?.minBet)} Max:{formatNumber(data?.maxBet)}
            </th>
            <th className={style["first"]}></th>
            <th className={style["second"]}></th>
            <th className={style["back-middle"]}>Back</th>
            <th className={style["lay-middle"]}>Lay</th>
            <th className={style["third"]}></th>
            <th className={style["fourth"]}></th>
          </tr>
        </thead>
        <tbody className={style["mathOdd_body"]}>
          {data?.runners?.map((dataItem, runnerIndex) => {
            return (
              <tr className={style["mathOdd_body_tr"]} key={runnerIndex}>
                <td className={style["name"]}>
                  {dataItem?.name}

                  {name?.includes("Winner") ? (
                    <p>
                      <p
                        style={{
                          color:
                            oddsObject[dataItem?.selectionId] < 0
                              ? "red"
                              : "green",
                        }}>
                        {oddsObject[dataItem?.selectionId] || "0"}
                      </p>
                    </p>
                  ) : (
                    <p>
                      {oddData?.map((res) => {
                        if (data?.marketId !== res?.marketId) return <></>;
                        const oddsPnl = {
                          [res?.selection1]: res?.pnl1,
                          [res?.selection2]: res?.pnl2,
                          [res?.selection3]: res?.pnl3,
                        };
                        return (
                          <p
                            style={{
                              color:
                                oddsPnl[dataItem?.selectionId] < 0
                                  ? "red"
                                  : "green",
                            }}>
                            {oddsPnl[dataItem?.selectionId] || "0"}
                          </p>
                        );
                      })}
                    </p>
                  )}
                </td>

                {dataItem?.ex?.availableToBack
                  ?.map((curEl, index) => (
                    <>
                      <td
                        // className={style[`first-middle-${index}`]}
                        key={curEl}
                        style={{
                          textAlign: "center",
                          backgroundColor:
                            curEl?.price >
                            prev?.runners[runnerIndex]?.ex?.availableToBack[
                              index
                            ]?.price
                              ? "#03B37F"
                              : curEl?.price <
                                prev?.runners[runnerIndex]?.ex?.availableToBack[
                                  index
                                ]?.price
                              ? "#FC4242"
                              : backColor[index],
                          border: "1px solid #fff",
                        }}>
                        <span className={style["span"]}>{curEl?.price}</span>
                        <span className={style["numb-2"]}>{curEl?.size}</span>
                      </td>
                    </>
                  ))
                  .reverse()}
                {dataItem?.ex?.availableToLay?.map((curElm, index) => {
                  return (
                    <>
                      <td
                        key={curElm + index}
                        style={{
                          textAlign: "center",
                          backgroundColor:
                            curElm?.price >
                            prev?.runners[runnerIndex]?.ex?.availableToLay[
                              index
                            ].price
                              ? "#03B37F"
                              : curElm?.price <
                                prev?.runners[runnerIndex]?.ex?.availableToLay[
                                  index
                                ].price
                              ? "#FC4242"
                              : layColor[index],
                          border: "1px solid #fff",
                        }}>
                        <span className={style["span"]}>{curElm?.price}</span>
                        <span className={style["numb-2"]}>{curElm?.size}</span>
                      </td>
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default MarketAnalysisLeftTable;
