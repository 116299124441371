import React from "react";
import { Button } from "antd";
// import "./matchOddsTitle.scss";
const MatchOddsTitle = () => {
  return (
    <div>
      {/* <div className="title-row">
        <div className="left-row col">
          <p>Runners</p>
        </div>
        <div className="right-row col">
          <Button></Button>
          <Button></Button>
          <Button>back</Button>
          <Button>lay</Button>
          <Button></Button>
          <Button></Button>
        </div>
      </div> */}
    </div>
  );
};

export default MatchOddsTitle;
